import _ from 'lodash';
import { createSelector } from 'reselect';
import { INITIAL_PAGE_SIZE, DEFAULT_SORT } from './searchUtils';
import { getLimitedIAAccess } from 'selectors/session';

export const getData = state => _.get(state, 'searchInvoices.data', []);
export const getTotalCount = state =>
  _.get(state, 'searchInvoices.totalCount', 0);
export const getCount = state => _.get(state, 'searchInvoices.count', 0);
export const getSortBy = state => _.get(state, 'searchInvoices.sortBy', []);

const getStatusFilter = state =>
  _.get(state, 'searchInvoices.filters.status', []);

const IA_STATUS_LABELS = [
  'Paid',
  'Hold',
  'Invoice Approved',
  'Future Release',
  'C&C Review',
  'Revisions',
  'Edits',
  'Resubmitted',
  'Processing',
];
export const getStatusWithRole = createSelector(
  [getStatusFilter, getLimitedIAAccess],
  (status, limitedIAAccess = false) => {
    return limitedIAAccess
      ? status.filter(s => IA_STATUS_LABELS.includes(s.label))
      : status.slice();
  },
);
//fetch filter badges count
export const getFilterBadgesCount = state =>
  _.get(state, 'searchInvoices.badgesCount', []);

//translate sortBy in the format the H+ API needs it from React-Table
export const getSortByForReq = createSelector([getSortBy], (sortBy = []) => {
  if (sortBy.length === 0) return DEFAULT_SORT;
  const userSort = sortBy[0];
  const newSort = DEFAULT_SORT.slice();

  _.remove(newSort, s => s.id === userSort.id);

  newSort.unshift({
    id: userSort.id,
    order: userSort.desc ? 'desc' : 'asc',
  });

  return newSort;
});

export const getLoading = state =>
  _.get(state, 'searchInvoices.loading', false);

const getLocalPageSize = state =>
  _.get(state, 'searchInvoices.pageSize', INITIAL_PAGE_SIZE);
const getLocalPages = state => _.get(state, 'searchInvoices.pages', 0);

export const getPageSize = createSelector(
  [getLocalPageSize, getLocalPages],
  (size, pages) => {
    const val = size * pages;
    return Number.isInteger(val) ? val : INITIAL_PAGE_SIZE;
  },
);

//filters

export const getFilters = state => _.get(state, 'searchInvoices.filters', []);

const filterTypes = {
  invoiceEditsSent: 'key',
  invoiceWeekendingDate: 'key',
  status: 'key',
  invoiceDescription: 'key',
  invoiceId: 'key',
  invoiceType: 'key',
  futureReleaseDate: 'key',
};

export const getAllFilters = createSelector(
  [getFilters, getLimitedIAAccess],
  (filters, limitedIAAccess) => {
    const currentFilters = [];
    for (const filterName in filters) {
      if (Object.hasOwnProperty.call(filters, filterName)) {
        const array = filters[filterName];
        const apiFilter = {
          field: filterName,
          type: filterTypes[filterName],
          values: array.reduce((acc, val) => {
            if (
              val.selected &&
              (!limitedIAAccess ||
                (limitedIAAccess &&
                  (filterName !== 'status' ||
                    (filterName === 'status' &&
                      IA_STATUS_LABELS.includes(val.label)))))
            ) {
              acc.push(val.value);
            }
            return acc;
          }, []),
        };
        if (apiFilter.values.length > 0) currentFilters.push(apiFilter);
      }
    }

    return currentFilters;
  },
);

//search filter behavior
export const getCustomFilter = (state, filterName) =>
  _.get(state, `searchInvoices.searchFilters.${filterName}`, '');

export const getListByFilterName = (state, filterName) => {
  return _.get(state, `searchInvoices.filters.${filterName}`, []);
};

export const getFilteredDropdown = createSelector(
  [getCustomFilter, getListByFilterName],
  (searchFilter, list) => {
    if (!searchFilter) return list;
    const filteredList = list.filter(option => {
      if (option.label === null) {
        return -1;
      }

      return (
        option.label.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0
      );
    });

    return filteredList;
  },
);

export const getSelectedCount = createSelector(
  [getListByFilterName],
  list => list.filter(f => f.selected === true).length,
);

export const getRemovedSelectedOption = state =>
  _.get(state, `searchInvoices.removedSelectedOption`, false);

export const getSelectedInvoice = state =>
  _.get(state, 'searchInvoices.selectedInvoice', '');
export const getSelectedInvoiceCount = state =>
  _.get(state, 'searchInvoices.selectedInvoiceCount', '');
