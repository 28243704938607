import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import {
  ModalFooter,
  ModalContent,
  ModalTitle,
} from 'components/Shared/ModalDialog';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// actions
import { hide as hideModal } from 'actions/modalDialog';
import { logout, checkAuthentication } from 'actions/authenticate';
import { IDLE_COUNTDOWN } from 'components/Shared/constants/index';
//selectors
import { getModalParams } from 'selectors/modalDialog';

const MODAL_DIALOG = 'SessionTimeout';

const useStyles = makeStyles(({ palette }) => ({
  button: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 8,
    marginRight: 8,

    '&:last-child': {
      marginRight: 4,
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    overflow: 'visible',
  },
  counterText: {
    color: palette.warning.main,
  },
}));

const mapStateToProps = state => ({
  modalParams: getModalParams(state, MODAL_DIALOG),
});

const mapDispatchToProps = dispatch => ({
  onLogout: () => {
    dispatch(hideModal({ dialog: MODAL_DIALOG }));
    dispatch(logout());
  },
  onExtend: () => {
    dispatch(hideModal({ dialog: MODAL_DIALOG }));
    dispatch(checkAuthentication());
  },
});

function SessionTimeout({ onExtend, onLogout, modalParams }) {
  const classes = useStyles();

  const [timeout, setTimer] = useState(IDLE_COUNTDOWN);

  const tick = useCallback(() => {
    const { lastActive, IDLE_TIMEOUT } = modalParams;

    const now = new Date();
    const timeSinceActive = Math.floor((now - lastActive) / 1000);

    if (timeSinceActive > IDLE_COUNTDOWN + IDLE_TIMEOUT) {
      //This logout fires when the user is not on the window
      setTimeout(() => {
        console.warn('You have been logged out due to inactivity.');
        onLogout();
      }, 1000);
      return;
    } else if (timeSinceActive > IDLE_TIMEOUT) {
      const timeShowingModal = timeSinceActive - IDLE_TIMEOUT;
      const timeRemaining = IDLE_COUNTDOWN - timeShowingModal;
      setTimer(timeRemaining);
    }
  }, [modalParams, onLogout]);

  useEffect(() => {
    const interval = setInterval(tick, 1000);
    return () => clearInterval(interval);
  }, [tick]);

  useEffect(() => {
    if (timeout === 0) {
      //This one fires when the window is open
      console.warn('H+ has been logged out due to inactivity.');
      onLogout();
    }
  }, [onLogout, timeout]);

  const padZero = num => (num > 9 ? num : '0' + num);
  const getTime = () => {
    const minutes = Math.floor(timeout / 60);
    const seconds = timeout - minutes * 60;

    return padZero(minutes) + ':' + padZero(seconds);
  };

  return (
    <>
      <ModalTitle>{'Session Timeout'}</ModalTitle>
      <ModalContent className={classes.content}>
        <Typography>
          {`You are about to be logged off in `}
          <span className={classes.counterText}>{getTime()}</span>
          {` due to inactivity.`}
          <br />
          {`Do you wish to stay logged in?`}
        </Typography>
      </ModalContent>
      <ModalFooter className={classes.footer}>
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          name="cancel"
          onClick={onLogout}
        >
          {'Logout'}
        </Button>
        <Button
          className={classes.button}
          color="primary"
          type="submit"
          variant="contained"
          onClick={onExtend}
        >
          {'Stay Logged In'}
        </Button>
      </ModalFooter>
    </>
  );
}

SessionTimeout.propTypes = {
  onLogout: PropTypes.func.isRequired,
  onExtend: PropTypes.func.isRequired,
  modalParams: PropTypes.object.isRequired,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withModalDialog({
    dialog: MODAL_DIALOG,
    maxWidth: 'md',
    skipUnmount: true,
  }),
)(SessionTimeout);
