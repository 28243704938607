import { produce } from 'immer';
import * as actions from 'actions/dropdownMenu';

const initialState = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.showMenu}`: {
        if (draft[action.menu]) {
          draft[action.menu].isOpen = true;
        }
        break;
      }

      case `${actions.hideMenu}`: {
        if (draft[action.menu]) {
          draft[action.menu].isOpen = false;
        }
        break;
      }

      case `${actions.initMenu}`: {
        // do not init twice
        if (!draft[action.menu]) {
          draft[action.menu] = { isOpen: false };
        }
        break;
      }

      case `${actions.destroyMenu}`: {
        delete draft[action.menu];
        break;
      }

      default:
    }
  });
