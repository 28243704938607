import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';
import { Dialog } from '@mui/material';

const style = () => ({
  root: {
    '& > *': {
      overflowY: 'auto',
      fontFamily: 'inherit',
      opacity: '1 !important',
      visibility: 'visible !important', //prevent closing on hot-reload
    },
  },
  rounded: {
    '& .MuiDialog-paper': { borderRadius: '20px' },
  },
});

class Modal extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool,
    isRendered: PropTypes.bool,
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    onRequestClose: PropTypes.func.isRequired,
    noEscClose: PropTypes.bool,
    clickAwayClose: PropTypes.bool,
    roundedCorners: PropTypes.bool,
    sx: PropTypes.object,
  };

  static defaultProps = {
    fullScreen: false,
    isRendered: false,
    maxWidth: 'sm',
    noEscClose: false,
    clickAwayClose: false,
  };

  render() {
    const {
      children,
      classes,
      fullScreen,
      isRendered,
      onRequestClose,
      maxWidth,
      noEscClose,
      clickAwayClose,
      roundedCorners = false,
      sx = {},
    } = this.props;

    return (
      <Dialog
        className={clsx(classes.root, { [classes.rounded]: roundedCorners })}
        sx={{ '.MuiDialog-paper': sx }}
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        open={isRendered}
        onClose={(e, reason) => {
          if (
            (reason === 'escapeKeyDown' && noEscClose === false) ||
            (reason === 'backdropClick' && clickAwayClose === true)
          ) {
            onRequestClose();
          }
        }}
      >
        {children}
      </Dialog>
    );
  }
}

export default withStyleSheet('Modal', style)(Modal);
