import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';
import { DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = ({ breakpoints, palette }) => ({
  root: {
    minHeight: '1em',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'inherit',
    '&>*': {
      fontSize: 18,
    },
  },
  fullWidthChildrenRoot: {
    width: '100%',
  },
  closeBtn: {
    position: 'absolute',
    top: 20,
    right: 45,
    height: 10,
    width: 10,
    fontSize: 10,
    color: palette.gray['+2'],
  },
  [breakpoints.down('md')]: {
    root: {
      padding: 14,
    },
  },
});

class ModalTitle extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
  };

  static defaultProps = {
    onClose: undefined,
  };

  render() {
    const { children, classes, className, fullWidth, onClose } = this.props;

    return [
      <div className={className} key={'Modal-Container'}>
        <DialogTitle key={`Dialog-Title`} className={classes.root}>
          <div className={fullWidth && classes.fullWidthChildrenRoot}>
            {children}
          </div>

          {!!onClose && (
            <div>
              <IconButton className={classes.closeBtn} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
          )}
        </DialogTitle>
      </div>,
    ];
  }
}

export default withStyleSheet('ModalTitle', style)(ModalTitle);
