import React from 'react';
import { InputAdornment, TextField, CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  rateLoading: {
    padding: '8px 4px',
    fontSize: 14,
  },
  loadingCircle: {
    position: 'relative',
    height: '20px !important',
    width: '20px !important',
    left: '9px',
  },
}));

const LoadingTextField = props => {
  const classes = useStyles();

  return (
    <TextField
      InputProps={{
        classes: {
          input: classes.rateLoading,
        },
        startAdornment: (
          <InputAdornment position="start">
            <CircularProgress className={classes.loadingCircle} />
          </InputAdornment>
        ),
      }}
      variant="outlined"
    />
  );
};

export default LoadingTextField;
