const { useLocation, useRouteMatch, useHistory } = require('react-router-dom');

/**
 *
 * Drop in replacement for react-router-domV5 withRouter that uses hooks as withRouter is removed from router v6
 * withRouter also adds a 'staticContext' prop that is not used in this implementation
 *
 * // Usage
 *  This is HOC for legacy support for components that need match, location, and history props and can't use hooks without refactoring
 */
const withRouter = BaseComponent => props => {
  const location = useLocation();
  const match = useRouteMatch();
  const history = useHistory();

  if (props.location) {
    console.warn(
      BaseComponent.name || BaseComponent.displayName,
      'withRouter: props.location is already defined and will be overridden',
    );
  }
  if (props.match) {
    console.warn(
      BaseComponent.name || BaseComponent.displayName,
      'withRouter: props.match is already defined and will be overridden',
    );
  }
  if (props.history) {
    console.warn(
      BaseComponent.name || BaseComponent.displayName,
      'withRouter: props.history is already defined and will be overridden',
    );
  }

  return (
    <BaseComponent
      {...props}
      location={location}
      match={match}
      history={history}
    />
  );
};

export default withRouter;
