import { buildUrl } from '../api';

const URL_EVENTS = '/projects/:projectId/events';
const URL_USERS = '/projects/:projectId/users';
const URL_INVITE_USERS = '/projects/:projectId/users/invite';
const URL_IMPERSONATE = '/projects/:projectId/impersonate';
const URL_UPDATE_USER = '/projects/:projectId/users/update';
const URL_EXPORT_USERS = '/projects/:projectId/users/export';
const URL_PROJECT_ADMIN_PERMISSION = '/admin/projectAdmin';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ clientV2 }) => ({
  async events({ projectId }) {
    const url = buildUrl(URL_EVENTS, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async users({ projectId }) {
    const url = buildUrl(URL_USERS, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async inviteUsers({ projectId, invites }) {
    const url = buildUrl(URL_INVITE_USERS, { projectId });
    const rsp = await clientV2.post(url, invites);
    return rsp.data;
  },

  async impersonate({ projectId, user }) {
    const url = buildUrl(URL_IMPERSONATE, { projectId });
    const rsp = await clientV2.post(url, { userId: user });
    return rsp.data;
  },

  async removeImpersonation({ projectId }) {
    const url = buildUrl(URL_IMPERSONATE, { projectId });
    const rsp = await clientV2.delete(url, {});
    return rsp.data;
  },

  async updateUser({ projectId, invites }) {
    const url = buildUrl(URL_UPDATE_USER, { projectId });
    const rsp = await clientV2.post(url, invites);
    return rsp.data;
  },

  async exportUsers({ projectId, userIds }) {
    const url = buildUrl(URL_EXPORT_USERS, { projectId });
    const rsp = await clientV2.post(url, userIds);
    return rsp.data;
  },
  async confirmProjectAdminPermission({ data }) {
    const rsp = await clientV2.post(URL_PROJECT_ADMIN_PERMISSION, data);
    return rsp.data;
  },
});
