import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

import Cell from './Cell';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    zIndex: 0,
    height: 30,
    '&:hover': {
      backgroundColor: palette.action.hover,
      cursor: 'pointer',
    },
  },
  odd: {
    backgroundColor: palette.primary.table,
  },
  even: {
    backgroundColor: palette.background.paper,
  },
  selected: {
    backgroundColor: palette.primary['+7'],
    '&:hover': {
      backgroundColor: palette.primary['+7'],
    },
  },
}));

const Row = props => {
  const classes = useStyles();
  const { prepareRow, row, rowIndex, onClickTimecard, currentUserRole } = props;

  const [
    rowClicked,
    // setRowClicked
  ] = useState(false);

  prepareRow(row);

  const { key, ...rowProps } = row.getRowProps();

  return (
    <tr
      key={key}
      {...rowProps}
      className={clsx(classes.root, {
        [classes.odd]: rowIndex % 2 === 0 && !rowClicked,
        [classes.even]: rowIndex % 2 === 1 && !rowClicked,
        [classes.selected]: rowClicked,
      })}
      // keeping for now until they decide if they want the visual click rep
      // onClick={e => {
      //   setRowClicked(true);
      //   setTimeout(() => {
      //     //setTimeout lets the selected be visible
      //     // onClickTimecard({ timecard: row.original });
      //   }, 0);
      // }}
    >
      {row.cells.map(cell => (
        <Cell
          cell={cell}
          key={cell.column.id}
          onClickTimecard={onClickTimecard}
          row={row}
          currentUserRole={currentUserRole}
        />
      ))}
    </tr>
  );
};

Row.propTypes = {
  prepareRow: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  onClickTimecard: PropTypes.func.isRequired,
  currentUserRole: PropTypes.string.isRequired,
};

export default Row;
