import { all, delay, call, takeLatest, put, select } from 'redux-saga/effects';
import { matchPath } from 'react-router';
import {
  getFormValues,
  formValueSelector,
  isPristine,
  reset,
  change,
} from 'redux-form';
import _ from 'lodash';
import moment from 'moment';
import {
  getCurrentProjectWorkSight,
  getCurrentProjectDbCodeId,
} from 'selectors/project';
import camelCase from 'camelcase-keys';
import { Box } from '@mui/material';
//actions
import { push } from 'redux-first-history';
import * as actions from './actions';
import { hide as hideModal, show as showModal } from 'actions/modalDialog';
import { showAlert } from 'actions/alert';
import { store as storeTimecards } from 'actions/timecards';

import { fetchUserInfo } from 'sagas/userInfo';

// selectors
import { getProject as currentProject } from 'selectors/routeParams';
import { getProjectUser } from 'selectors/session';
import { getSettings, getColumnSettings } from 'selectors/settings';
import { getProjectDetails } from 'selectors/project';
import { getLocation } from 'selectors/router';
import { getTimecards } from 'selectors/timecard/common';

import { doesDayAllowTimes } from 'utils/weekUtils';
import { empDealSort } from 'utils/helperFunctions';

import * as sel from './selectors';
import { getDefaultPayCodes } from 'selectors/flags';
import {
  TIMECARD_INCOMPLETE,
  TIMECARD_PENDING_EMP_REVIEW,
} from 'components/Shared/constants';
import {
  CONFIRM_EDIT_MODAL,
  MOBILE_DAY_MODAL,
  DEALMEMO_DIALOG,
  DELETE_TC_ALLOWANCE_DIALOG,
  DELETE_TC_DIALOG,
  SUBMIT_REMINDER_MODAL,
  DEL_TC_FROM_LIST,
  ALLOWANCE_DIALOG,
  DAY_LEVEL_COMMENT_MODEL,
} from 'feature/EmployeeTimecard/Modals/modalNames';
import { FORM_NAME } from 'feature/EmployeeTimecard/selectors';

import {
  db,
  NEW_TC_ID,
  NEW_ALLO_ID,
  convertTimesToDec,
  convertTimesToMil,
  composeAllowanceV2,
  makeAllowanceFromDealAllo,
} from 'feature/EmployeeTimecard/empTimecardUtils';
import {
  delayOnValue,
  delayOnValueObj,
  isRegionCanada,
  setWorkLocationForCanada,
  removeNoValueFromTimecard,
  removeEpiObj,
  addEpisodeObj,
  removeInactiveDays,
  removeInvalidDealDays,
} from 'utils/helperFunctions';
import { defaultTimecard, defaultDay } from 'utils';
import { convertToFormData } from 'utils/weekUtils';
import { formatDateUTC } from 'utils/formatDate';

const formSelector = formValueSelector(FORM_NAME);

export function* init(api, debug, params) {
  try {
    yield put(actions.setLoading({ variant: 'timecard', loading: true }));
    const projectId = yield select(currentProject);
    db('init projectId: ', projectId);
    yield put(actions.setSavedOnce({ reset: true }));
    //Check for single batch URL
    let match = matchPath(window.location.pathname, {
      path: '/projects/:projectId/me/timecards/:timecardId',
      exact: false,
      strict: false,
    });
    const {
      params: { timecardId },
    } = match;

    yield put(actions.checkProjectUser());
    yield put(actions.fetchEpisodes());
    yield put(actions.fetchAllowanceTypes());
    //data fetch for all timecards
    if (timecardId === NEW_TC_ID) {
      yield call(initCreateTimecard, api, debug, params);
    } else {
      yield call(initFetchTimecard, api, debug, { timecardId });
    }

    yield put(actions.parseColumns());
  } catch (e) {
    debug(e);
    yield put(showAlert());
    yield put(actions.setLoading({ variant: 'timecard', loading: false }));
  }
}

export function* initCreateTimecard(api, debug, params) {
  try {
    yield put(actions.setLoading({ variant: 'timecard', loading: true }));
    const projectId = yield select(currentProject);
    db('initCreateTimecard projectId: ', projectId);

    const newTimecard = yield call(buildTimecard, api, debug);

    yield put(actions.storeTimecard({ timecard: newTimecard }));

    yield put(actions.fetchSupportData());
  } catch (e) {
    debug(e);
  } finally {
    yield put(actions.setLoading({ variant: 'timecard', loading: false }));
  }
}

export function* initFetchTimecard(api, debug, params) {
  try {
    yield put(actions.setLoading({ variant: 'timecard', loading: true }));
    db('initFetchTimecard');

    const { timecardId } = params;

    yield call(fetchTimecard, api, debug, { timecardId });

    yield put(actions.fetchSupportData());
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
    yield put(actions.setLoading({ variant: 'timecard', loading: false }));
  }
}

export function* fetchTimecard(api, debug, params) {
  const { timecardId } = params;
  const projectId = yield select(currentProject);

  const callArray = [
    call(api.employeeTimecard.fetchTimecard, {
      timecardId,
      projectId,
    }),
    call(fetchAllowances, api, debug, { timecardId }),
  ];

  const [timecard, allowances] = yield all(callArray);
  timecard.allowances = allowances;

  yield put(actions.setEndsOn({ endsOn: timecard.endsOn }));

  yield addRoundingToDeal(api, debug, timecard.dealMemo);

  const episodes = yield delayOnValueObj(sel.getEpisodes);
  addEpisodeObj(timecard, episodes);

  yield put(actions.storeTimecard({ timecard }));
}

function* fetchDealMemos(api, debug, params) {
  yield put(actions.setLoading({ variant: 'dealMemos', loading: true }));

  try {
    const activeUser = yield delayOnValueObj(getProjectUser, { timeout: 5000 });
    const endsOn = yield select(sel.getEndsOn);
    const projectWorkSightId = yield delayOnValue(getCurrentProjectWorkSight, {
      timeout: 5000,
    });

    const weekEnding = moment(endsOn, 'YYYY-MM-DDT00:00:00');
    const endDate = weekEnding.format('YYYY-MM-DDT00:00:00');
    const weekStarting = weekEnding.clone().subtract(6, 'd');
    const startDate = weekStarting.format('YYYY-MM-DDT00:00:00');

    const employeeId = activeUser.workSightId;
    const dealMemos = yield call(api.employees.dealMemos, {
      projectId: projectWorkSightId,
      employeeId,
      startDate,
      endDate,
      withPayroll: true,
    });

    yield put(actions.storeDealMemos({ dealMemos }));
  } catch (e) {
    debug(e);
  } finally {
    yield put(actions.setLoading({ variant: 'dealMemos', loading: false }));
  }
}

export function* parseColumns(api, debug, params) {
  try {
    const colSettings = yield select(getColumnSettings);
    const c = yield select(sel.getColumns);

    const columns = _.cloneDeep(c);

    const pairColumns = ['meal1', 'meal2', 'meal3', 'ndm'];

    Object.keys(colSettings).forEach(key => {
      const setting = colSettings[key];
      let accessor = key;
      let isPair = false;
      if (pairColumns.includes(key)) {
        accessor = key + 'Out';
        isPair = true;
      } else if (key === 'lastManIn') {
        accessor = 'lastMan1In';
      }

      const col = columns.find(col => col.accessor === accessor);

      if (!col) {
        db('column not found: ', accessor);
        return;
      }

      if (setting.employeeMandatory) {
        col.display = 'show';
        col.required = true;
      } else if (setting.employeeVisible) {
        col.display = 'show';
      } else if (setting.employeeAdditionalFields) {
        col.display = 'additional';
      } else if (col.display !== 'always') {
        col.display = 'hide';
      }

      col.description = setting.employeeDescription;
      col.isDescriptionVisible =
        setting.employeeDescription && key === 'ndm' ? true : false;

      if (isPair) {
        accessor = key + 'In';
        const col = columns.find(col => col.accessor === accessor);
        if (col) {
          if (setting.employeeMandatory) {
            col.display = 'show';
            col.required = true;
          } else if (setting.employeeVisible) {
            col.display = 'show';
          } else if (setting.employeeAdditionalFields) {
            col.display = 'additional';
          } else {
            col.display = 'hide';
          }
        }
      }
    });
    yield put(actions.storeColumns({ columns }));
    yield call(checkFieldVisibilityPreReq, api, debug, { fieldsReady: true });
    yield delay(100);
    yield select(sel.getFieldsPreReq);
  } catch (e) {
    debug(e);
  } finally {
  }
}

//things we need to fetch after we have the timecard data in redux
export function* fetchSupportData(api, debug) {
  try {
    yield put(actions.fetchTimecardList());
    yield put(actions.fetchDayTypes());

    yield call(checkFieldVisibilityPreReq, api, debug, { timecardReady: true });

    //TODO - not a default field, we probably can conditionally call this one
    yield put(actions.fetchWorkLocations());

    const timecardId = yield delayOnValue(formSelector, {
      args: ['id'],
      timeout: 5000,
    });
    db('fetchSupportData timecardId: ', timecardId);
    if (!!timecardId && timecardId !== NEW_TC_ID) {
      yield put(actions.fetchComments());
      yield put(actions.fetchTimecardHistory());
    }
    yield put(actions.fetchMilitaryTime());
  } catch (e) {
    debug(e);
  } finally {
  }
}

function* fetchAllowances(api, debug, params = {}) {
  try {
    let { timecardId } = params;

    if (!timecardId) timecardId = yield select(sel.getTimecardId);

    const projectId = yield select(currentProject);

    const types = yield delayOnValueObj(sel.getAllowanceTypes);

    const allowances = yield call(api.timecards.loadTCAllowances, {
      projectId,
      timecardId,
    });

    allowances.forEach(allowance => {
      const allowanceType = types.find(
        t => t.id === allowance.htgAllowanceTypeId,
      );
      if (!_.isEmpty(allowanceType)) {
        allowance.allowanceType = allowanceType;
      }
    });

    return allowances;
  } catch (e) {
    console.error('Error fetching allowances');
    debug(e);
    return [];
  }
}

function* addRoundingToDeal(api, debug, dealMemo) {
  try {
    const projectId = yield select(currentProject);

    if (dealMemo?.id && !dealMemo?.roundTo) {
      const roundTos = yield select(sel.getRoundTos);

      let roundTo = roundTos[dealMemo?.id];

      if (!roundTo) {
        const rounding = yield call(api.employees.roundTo, {
          projectId,
          dealMemoIds: [dealMemo.id],
        });
        if (rounding.length > 0) {
          roundTo = rounding[0].roundTo;
          const newRoundTwos = _.cloneDeep(roundTos);
          newRoundTwos[dealMemo.id] = roundTo;
          yield put(actions.storeRoundTos({ roundTos: newRoundTwos }));
        }
      }

      if (roundTo) dealMemo.roundTo = roundTo;
    }
  } catch (error) {
    debug(error);
  }
}

//Are we ready to toggle field visibility based on existing data yet?
function* checkFieldVisibilityPreReq(api, debug, params) {
  try {
    db('checkFieldVisibilityPreReq', params);
    const { timecardReady, fieldsReady } = params;
    yield delay(50);
    const fpr = yield select(sel.getFieldsPreReq);
    const fieldPreReq = _.cloneDeep(fpr);

    fieldPreReq.timecardReady = timecardReady || fieldPreReq.timecardReady;
    fieldPreReq.fieldsReady = fieldsReady || fieldPreReq.fieldsReady;

    yield put(actions.setFieldVisibilityPreReq({ fieldPreReq }));
    if (fieldPreReq.timecardReady && fieldPreReq.fieldsReady) {
      yield put(actions.updateFieldVisibility());
    }
  } catch (e) {
    debug(e);
  } finally {
  }
}

//build the localTimecard for creating a new timecard
export function* buildTimecard(api, debug, params) {
  try {
    const projectId = yield select(currentProject);

    const activeUser = yield delayOnValueObj(getProjectUser, { timeout: 5000 });

    const projectWorkSightId = yield delayOnValue(getCurrentProjectWorkSight, {
      timeout: 5000,
    });
    const {
      department,
      // dealMemos,
      batchTemplate: {
        htgCountryId,
        htgStateId,
        htgCityId,
        htgCountyId,
        htgSubdivisionId,
      },
    } = activeUser;

    const countries = yield call(api.locations.countriesV1);
    const country =
      _.cloneDeep(countries.find(c => c.id === htgCountryId)) || null;

    let countryId = null;
    if (!htgCountryId) {
      const caRegion = isRegionCanada(localStorage.getItem('region'));
      if (caRegion) {
        const ctry = _.find(countries, country => country.code === 'CA');
        countryId = ctry?.id;
      } else {
        const ctry = _.find(countries, country => country.code === 'US');
        countryId = ctry?.id;
      }
    }

    //fetch states based on htgCountry.  If null get CA if region set ot CA and US states by default
    const states = yield call(api.locations.statesV1, {
      projectId,
      countryId: htgCountryId ?? countryId,
    });

    const state = states.find(state => state.id === htgStateId) || null;

    let cities = [];
    if (htgStateId) {
      cities = yield call(api.locations.citiesV1, { stateId: htgStateId });
    }
    const city = cities?.find(city => city.id === htgCityId) || null;

    let counties = [];
    if (htgCountyId) {
      counties = yield call(api.locations.countiesV1, {
        stateId: htgStateId,
      });
    }
    const county = counties?.find(county => county.id === htgCountyId) || null;

    let subdivisions = [];
    if (htgSubdivisionId) {
      subdivisions = yield call(api.locations.subdivisionsV1, {
        pageSize: -1,
      });
    }
    const subdivision =
      subdivisions?.find(subdivision => subdivision.id === htgSubdivisionId) ||
      null;

    const startsOn = moment().startOf('week').format('YYYY-MM-DDT00:00:00');
    const endsOn = moment().endOf('week').format('YYYY-MM-DDT00:00:00');
    yield put(actions.setEndsOn({ endsOn }));

    const dealMemo = yield call(fetchInitialDeal, api, debug, {
      projectWorkSightId,
      activeUser,
      startsOn,
      endsOn,
    });

    const htgContractId = _.get(dealMemo, 'htgContract.id', '');

    const settings = yield select(getSettings);

    let allowances = [];

    if (
      settings.myTimecardAutoAllowances &&
      dealMemo?.dealMemoAllowances?.length > 0
    ) {
      const allowanceTypes = yield delayOnValueObj(sel.getAllowanceTypes);
      const defaultNonTax = yield delayOnValueObj(getDefaultPayCodes);
      dealMemo.dealMemoAllowances.forEach((dealAllowance, i) => {
        if (dealAllowance.frequency === 'F') {
          const allowance = makeAllowanceFromDealAllo({
            dealAllowance,
            allowanceTypes,
            defaultNonTax,
            dealMemo,
          });
          if (allowance) allowances.push(allowance);
        }
      });
    }

    // create timecard
    let id = NEW_TC_ID;
    const newTimecard = (function () {
      return {
        ...defaultTimecard,
        id,
        htgCountryId,
        allowances,
        country,
        htgStateId,
        state,
        htgCityId,
        county,
        htgCountyId,
        subdivision,
        htgSubdivisionId,
        htgContractId,
        city,
        startsOn,
        endsOn,
        projectId: Number(projectId),
        dealMemo,
        isExempt: !!dealMemo.exempt,
        departmentId: department.id,
        departmentName: department.name,
        wtcLayoutName:
          department?.wtcLayoutName || settings?.wtcLayout?.label || null,
        days: (function () {
          return new Array(7).fill().map((day, i) => {
            return _.cloneDeep({
              ...defaultDay,
              htgCountryId,
              subdivision,
              htgSubdivisionId,
              country,
              // isDayActive: false,
              date: `${moment(startsOn)
                .add(i, 'day')
                .format('YYYY-MM-DD')}T00:00:00`,
            });
          });
        })(),
      };
    })();

    yield addRoundingToDeal(api, debug, newTimecard.dealMemo);

    return newTimecard;
  } catch (e) {
    debug(e);
  } finally {
  }
}

export function* fetchInitialDeal(api, debug, params) {
  const { projectWorkSightId, activeUser, startsOn, endsOn } = params;

  // dealMemo Call
  const dealMemoList = yield call(api.employees.dealMemos, {
    projectId: projectWorkSightId,
    employeeId: activeUser.workSightId,
    startDate: startsOn,
    endDate: endsOn,
    withPayroll: true,
  });

  if (dealMemoList.length === 0) {
    const fullDealList = yield call(api.employees.dealMemos, {
      projectId: projectWorkSightId,
      employeeId: activeUser.workSightId,
      withPayroll: true,
    });
    if (fullDealList.length > 0) {
      yield put(
        showAlert({
          message: (
            <Box>
              <Box>No Deal Memo found for this week.</Box>
              <Box>Change work week to proceed.</Box>
            </Box>
          ),
          variant: 'error',
        }),
      );
    } else {
      yield put(
        showAlert({
          message: (
            <Box>
              <Box>No valid Deal Memos found</Box>
              <Box>Contact support</Box>
            </Box>
          ),
          variant: 'error',
        }),
      );
    }
  }

  dealMemoList.sort(empDealSort);

  const dealMemo = dealMemoList[0] || {};

  return dealMemo;
}

function* fetchDayTypes(api, debug) {
  try {
    const type = 'dayType';
    const parentValue = yield delayOnValue(sel.getHtgContractId);

    const params = {
      parentValue,
      type,
      pageSize: -1,
    };

    const dayTypes = yield call(api.timecards.searchByTypes, {
      type,
      params,
    });

    yield put(actions.storeDayTypes({ dayTypes }));
  } catch (e) {
    yield put(showAlert());
    debug(e);
  } finally {
  }
}

export function* fetchAllowanceTypes(api, debug, params) {
  try {
    const projectId = yield select(currentProject);
    let types = yield select(sel.getAllowanceTypes);

    if (types.length > 0) {
      return;
    }

    const data = yield call(api.projects.allowances, { projectId });

    yield put(actions.storeAllowanceTypes({ allowanceTypes: data, projectId }));
  } catch (e) {
    debug(e);
  }
}

function* fetchWorkLocations(api, debug) {
  try {
    const type = 'locationType';
    const htgContractId = yield delayOnValue(sel.getHtgContractId);
    const htgUnionId = yield delayOnValue(sel.getHtgUnionId);
    const project = yield select(getProjectDetails);
    const caRegion = isRegionCanada(project.region);
    const params = caRegion
      ? {
          options: { htgContractId, htgUnionId },
          pageSize: 500,
        }
      : { options: { htgContractId, htgUnionId } };

    const workLocations = yield call(api.timecards.searchByTypes, {
      type,
      params,
    });

    yield put(actions.storeWorkLocations({ workLocations }));
  } catch (e) {
    yield put(showAlert());
    debug(e);
  } finally {
  }
}

export function* fetchEpisodes(api, debug, params) {
  try {
    yield put(actions.setLoading({ variant: 'fetchEpisodes', loading: true }));

    // const randDelay = Math.floor(Math.random() * 1000);
    // yield delay(randDelay);
    // yield put(actions.storeEpisodes({ episodes }));
    const projectId = yield select(currentProject);

    const episodes = yield call(api.timecards.getEpisodes, { projectId });
    yield put(actions.storeEpisodes({ episodes }));
  } catch (e) {
    debug(e);
  } finally {
    yield put(actions.setLoading({ variant: 'fetchEpisodes', loading: false }));
  }
}

export function* fetchComments(api, debug) {
  try {
    yield put(actions.setLoading({ variant: 'comments', loading: true }));

    const timecard = yield select(getFormValues(FORM_NAME));

    const timecardEntryHeaderId = timecard.entryHeaderId;

    const projectId = yield select(currentProject);
    const comments = yield call(api.reviews.getTimecardComments, {
      projectId,
      timecardEntryHeaderId,
    });
    // const notes = camelCase(data, { deep: true });
    yield put(actions.storeComments({ comments }));
    yield put(actions.setLoading({ variant: 'comments', loading: false }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}

export function* saveComment(api, debug, params) {
  try {
    yield put(actions.setLoading({ variant: 'comments', loading: true }));
    const { referenceDate, commentType } = params;
    const timecardId = yield select(sel.getTimecardId);
    const comment = yield select(sel.getNewComment);

    const projectId = yield select(currentProject);

    const data = {
      comment,
      type: commentType ? commentType : 'timecard',
      referenceDate: referenceDate
        ? formatDateUTC(referenceDate, 'YYYY-MM-DDTHH:mm:ss')
        : formatDateUTC(new Date()),
    };
    if (timecardId === NEW_TC_ID) {
      const user = yield select(getProjectUser);
      const role = user.role;

      data.role = role;
      data.userName = user.fullName;
      data.createdAt = formatDateUTC(new Date(), 'YYYY-MM-DDTHH:mm:ss');
      data.id = crypto.randomUUID();
      const c = yield select(sel.getComments);
      const comments = _.cloneDeep(c);
      comments.unshift(data); //new comment at top

      yield put(actions.storeComments({ comments }));
    } else {
      yield call(api.employeeTimecard.createTimeCardNotes, {
        projectId,
        timecardId,
        data,
      });

      yield put(actions.fetchComments());
    }
    yield put(actions.setNewComment({ comment: '' }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
    const isMobile = yield select(sel.getIsMobile);
    if (!isMobile) {
      yield put(hideModal({ dialog: DAY_LEVEL_COMMENT_MODEL }));
    }
    yield put(actions.setLoading({ variant: 'comments', loading: false }));
  }
}

export function* copyFromPrevWeek(api, debug, params) {
  try {
    const projectId = yield select(currentProject);
    const project = yield select(getProjectDetails);
    yield put(actions.setLoading({ variant: 'timecard', loading: true }));
    const { copyTimecard, selectedTimecard } = params;
    const endsOn = yield select(sel.getEndsOn);
    const startsOn = moment(endsOn)
      .startOf('week')
      ?.format('YYYY-MM-DDT00:00:00');
    const activeUser = yield select(getProjectUser);
    const { department } = activeUser;
    const settings = yield select(getSettings);
    const activeDeal = yield select(sel.getActiveDealMemo);
    const data = {
      htgDealmemoId: activeDeal.id,
      sourceTimecardDealMemoId: copyTimecard.dealMemo.id,
      sourceTimecardId: copyTimecard.timecardId,
      startsOn,
      endsOn,
      wtcLayoutName:
        department?.wtcLayoutName || settings?.wtcLayout?.label || null,
      existingTimecardId: Number(selectedTimecard?.id)
        ? selectedTimecard?.id
        : null,
      project: {
        worksightId: project.worksightId,
      },
    };

    const currentUser = yield select(getProjectUser);
    const workLocations = yield select(sel.getWorkLocations);
    const { batchTemplate = {} } = currentUser;
    const locationTypeId = batchTemplate?.locationTypeId || null;
    const template = workLocations.find(
      template => template.id === locationTypeId,
    );
    const workLoc = workLocations.find(loc => loc.code === 'S');
    if (!!template?.id) {
      data.locationTypeId = template.id;
    } else if (!!workLoc?.id) {
      data.locationTypeId = workLoc.id;
    } else {
      data.locationTypeId = null;
    }

    const newTimecard = camelCase(data, { deep: true });
    const formData = new FormData();
    for (const key in newTimecard) {
      if (Object.hasOwnProperty.call(newTimecard, key)) {
        const element = newTimecard[key];
        formData.append(key, JSON.stringify(element));
      }
    }

    const timecard = yield call(api.employeeTimecard.createTimecard, {
      projectId,
      timecard: formData,
    });
    yield put(reset(FORM_NAME));
    yield delay(250);
    const currentTab = yield getCurrentQuerySearch(debug);

    yield put(
      push(`/projects/${projectId}/me/timecards/${timecard.id}${currentTab}`),
    );
    yield put(actions.init());
  } catch (e) {
    debug(e);
  } finally {
    yield put(actions.setLoading({ variant: 'timecard', loading: false }));
  }
}

export function* saveAllowance(api, debug, params) {
  try {
    yield put(
      actions.setLoading({ variant: 'savingAllowance', loading: true }),
    );
    const { data, refreshAfter = true, dealMemo } = params;

    const timecardId = yield select(sel.getTimecardId);

    const isNewAllowance = data.worksightId.includes(NEW_ALLO_ID);
    let updatedData = { ...data };
    if (isNewAllowance && data.allowanceTypeFlag === 'M') {
      const dealAllowance = dealMemo.dealMemoAllowances;
      const dealAllowObj = dealAllowance?.find(
        da =>
          da.payCode1?.code === data.allowanceType.code ||
          da.payCode2?.code === data.allowanceType.code,
      );
      updatedData = {
        ...updatedData,
        freeField1: dealAllowObj?.customField1 || dealMemo.customField1 || null,
        freeField2: dealAllowObj?.customField2 || dealMemo.customField2 || null,
        freeField3: dealAllowObj?.customField3 || dealMemo.customField3 || null,
        freeField4: dealAllowObj?.customField4 || dealMemo.customField4 || null,
        accountCode: dealAllowObj?.account || dealMemo.wageAccount || null,
        series: dealAllowObj?.series || dealMemo.series || null,
        location: dealAllowObj?.location || dealMemo.location || null,
        set: dealAllowObj?.set || dealMemo.set || null,
        insurance: dealAllowObj?.insurance || dealMemo.insurance || null,
      };
    }
    const allowance = composeAllowanceV2(updatedData);
    if (!allowance?.locationType?.code) {
      const currentUser = yield select(getProjectUser);
      const workLocations = yield select(sel.getWorkLocations);
      const { batchTemplate = {} } = currentUser;
      const locationTypeId = batchTemplate?.locationTypeId || null;
      const template = workLocations.find(
        template => template.id === locationTypeId,
      );
      const workLoc = workLocations.find(loc => loc.code === 'S');
      if (!!template?.id) {
        allowance.locationTypeId = template.id;
      } else if (!!workLoc?.id) {
        allowance.locationTypeId = workLoc.id;
      } else {
        allowance.locationTypeId = null;
      }
    } else {
      allowance.locationTypeId = allowance.locationType.id;
    }
    delete allowance.locationType;

    if (timecardId === NEW_TC_ID) {
      const formAllowances = yield select(formSelector, 'allowances');
      const allowances = _.cloneDeep(formAllowances);

      const index = allowances.findIndex(
        a => a.worksightId === allowance.worksightId,
      );
      if (index !== -1) {
        allowances[index] = allowance;
      } else {
        allowances.push(allowance);
      }
      yield put(change(FORM_NAME, 'allowances', allowances));
      yield delay(250);
      if (refreshAfter) {
        yield call(saveTimecard, api, debug);
        yield put(hideModal({ dialog: ALLOWANCE_DIALOG }));
      }
    } else {
      const projectId = yield select(currentProject);
      if (isNewAllowance) delete allowance.worksightId;

      const formData = convertToFormData(allowance);

      if (isNewAllowance) {
        //create new allowance
        yield call(api.employeeTimecard.saveTimecardAllowance, {
          projectId,
          timecardId,
          data: formData,
        });
      } else {
        yield call(api.employeeTimecard.updateTimecardAllowance, {
          projectId,
          timecardId,
          worksightId: allowance.worksightId,
          data: formData,
        });
      }

      if (refreshAfter) {
        const savedAllowances = yield call(fetchAllowances, api, debug, {
          timecardId,
        });

        yield put(actions.storeAllowances({ allowances: savedAllowances }));
        yield delay(150);
        yield put(hideModal({ dialog: ALLOWANCE_DIALOG }));
        yield call(checkSavedOnce, api, debug);
      }
    }
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
    yield put(
      actions.setLoading({ variant: 'savingAllowance', loading: false }),
    );
  }
}

export function* saveTimecard(api, debug, params = {}) {
  try {
    yield put(actions.setLoading({ variant: 'saving', loading: true }));

    const id = yield select(sel.getTimecardId);
    const formTimecard = yield select(getFormValues(FORM_NAME));
    let timecard = _.cloneDeep(formTimecard);
    const projectDetails = yield select(getProjectDetails);
    const isCaRegion = projectDetails.region === 'Canada';
    if (isCaRegion) {
      const workLocations = yield select(sel.getWorkLocations);
      timecard = setWorkLocationForCanada(timecard, workLocations);
    }

    const { url } = params;

    // let createTimecardData;
    delete timecard.user;
    delete timecard.createdDate;
    delete timecard.createdBy;
    delete timecard.createdAt;
    convertTimesToMil(timecard, { convert24Plus: true });
    const args = { timecard, url };
    if (id === NEW_TC_ID) {
      yield call(createTimecard, api, debug, args);
    } else {
      yield call(updateExistingTimecard, api, debug, args);
    }

    const isMobile = yield select(sel.getIsMobile);

    if (isMobile) yield put(hideModal({ dialog: MOBILE_DAY_MODAL }));
    yield put(actions.setLoading({ variant: 'saving', loading: false }));
  } catch (e) {
    debug(e);
    const errors = e.data ? camelCase(e.data, { deep: true }) : null;
    yield put(actions.setLoading({ variant: 'saving', loading: false }));
    if (errors && errors.days) {
      yield put(
        showAlert({
          message: 'Timecard validation failed.',
          variant: 'warning',
        }),
      );
    } else {
      yield put(showAlert());
    }

    return errors?.days || { error: true }; //return day error when called from reviews
  }
}

function* createTimecard(api, debug, params) {
  const { timecard } = params;
  let { url } = params;

  const projectId = yield select(currentProject);

  const { dealMemo } = timecard;
  const data = new FormData();
  const allowances = yield select(formSelector, 'allowances');
  const currentUser = yield select(getProjectUser);
  const workLocations = yield select(sel.getWorkLocations);
  const timecardAllowances = allowances?.map((allowance, index) => {
    const { allowanceType, worksightId, ...rest } = allowance;

    const { batchTemplate = {} } = currentUser;
    const locationTypeId = batchTemplate?.locationTypeId || null;
    const template = workLocations.find(
      template => template.id === locationTypeId,
    );
    const workLoc = workLocations.find(loc => loc.code === 'S');
    if (!!template?.id) {
      rest.locationTypeId = template.id;
    } else if (!!workLoc?.id) {
      rest.locationTypeId = workLoc.id;
    } else {
      rest.locationTypeId = null;
    }
    if (allowance.document) {
      const key = `document${index}`;
      data.append(key, allowance.document);
      return {
        ...rest,
        document: key,
      };
    }
    return { ...rest };
  });

  const endsOn = yield select(sel.getEndsOn);
  const startsOn = moment(endsOn).startOf('week').format('YYYY-MM-DDT00:00:00');

  let createTimecardData = {
    ...timecard,
    endsOn,
    startsOn,
    timecardAllowances: timecardAllowances || [],
    action: 'save',
    allowances: [],
    htgDealmemoId: dealMemo.id,
    id: null,
  };

  //This is required to save, but we're not using it in V2
  createTimecardData.editableFields = []; //

  removeInvalidDealDays(createTimecardData);
  removeInactiveDays(createTimecardData);
  removeNoValueFromTimecard(createTimecardData);
  removeEpiObj(createTimecardData);
  for (const key in createTimecardData) {
    if (Object.hasOwnProperty.call(createTimecardData, key)) {
      const element = createTimecardData[key];
      data.append(key, JSON.stringify(element));
    }
  }
  const createdTimecard = yield call(api.employeeTimecard.createTimecard, {
    projectId,
    timecard: data,
  });

  const timecardId = createdTimecard.id;

  const comments = yield select(sel.getComments);
  if (comments.length > 0) {
    const newComments = _.cloneDeep(comments);
    newComments.forEach(comment => {
      delete comment.id;
      delete comment.role;
      comment.date = comment.createdAt;
      delete comment.createdAt;
      delete comment.userName;
    });

    //Preserve order of comments
    for (let i = newComments.length - 1; i >= 0; i--) {
      const comment = newComments[i];
      yield call(api.employeeTimecard.createTimeCardNotes, {
        projectId,
        timecardId,
        data: comment,
      });
    }
  }

  const currentTab = yield getCurrentQuerySearch(debug);

  convertTimesToDec(createdTimecard, { convert24Plus: true });
  yield addRoundingToDeal(api, debug, createdTimecard.dealMemo);
  const episodes = yield select(sel.getEpisodes);
  addEpisodeObj(createdTimecard, episodes);
  yield put(actions.storeTimecard({ timecard: createdTimecard }));
  yield delay(250);
  yield put(reset(FORM_NAME));

  if (!url) {
    url = `/projects/${projectId}/me/timecards`;
    url += `/${createdTimecard.id}${currentTab}`;
  } else {
    url = url.replace(NEW_TC_ID, createdTimecard.id);
  }

  yield put(push(url));
  yield delay(250);
  //avoid init calls when tc is submitting - it will redirect to the timecard list page
  const submittingEmp = yield select(sel.getLoading, 'submittingEmp');
  if (!submittingEmp) {
    yield put(actions.init());
  }
}

function* updateExistingTimecard(api, debug, params) {
  const { timecard, url } = params;

  const projectId = yield select(currentProject);
  const data = _.cloneDeep(timecard);
  data.action = 'save';
  removeNoValueFromTimecard(data);
  removeInvalidDealDays(data);
  removeInactiveDays(data);
  removeEpiObj(data);
  const timecardId = data.id;
  data.allowances = [];

  const savedTimecard = yield call(api.timecards.saveTimecard, {
    projectId,
    timecardId,
    timecard: data,
  });

  const needRefresh = yield call(syncAllowances, api, debug);

  let allowances = [];
  //avoid calling allowances API if there are none
  if (savedTimecard.allowances.length > 0 || needRefresh) {
    allowances = yield call(fetchAllowances, api, debug, { timecardId });
    savedTimecard.allowances = allowances;
  }

  convertTimesToDec(savedTimecard, { convert24Plus: true });
  yield addRoundingToDeal(api, debug, savedTimecard.dealMemo);
  const episodes = yield select(sel.getEpisodes);
  addEpisodeObj(savedTimecard, episodes);

  yield put(actions.storeTimecard({ timecard: savedTimecard }));
  yield delay(100);
  yield put(reset(FORM_NAME));

  yield call(checkSavedOnce, api, debug, { timecard: data, url });
}

export function* checkSavedOnce(api, debug, params = {}) {
  try {
    const savedOnce = yield select(sel.getSavedOnce);
    let { timecard = null, url = null } = params;

    if (!timecard) {
      timecard = yield select(getFormValues(FORM_NAME));
    }

    const previouslyRejected = timecard?.previouslyRejected;
    const tcStatus = timecard?.status;
    const isSubmitting = yield select(sel.getLoading, 'submittingEmp');

    if (
      previouslyRejected &&
      tcStatus === TIMECARD_INCOMPLETE &&
      !savedOnce &&
      !isSubmitting
    ) {
      yield put(
        showModal({ dialog: SUBMIT_REMINDER_MODAL, modalParams: { url } }),
      );
      yield put(actions.setSavedOnce({ savedOnce: true }));
    } else {
      if (url) yield put(push(url));
    }
  } catch (e) {
    debug(e);
    console.error('Error', e);
  }
}

/**
 * Sync allowances with the backend
 * Expects timecard to be saved before calling this function
 */
export function* syncAllowances(api, debug) {
  try {
    const timecardId = yield select(sel.getTimecardId);
    const allowances = yield select(formSelector, 'allowances');

    let needRefresh = false;

    const unsaved = allowances.filter(a => a.unsaved);
    unsaved.forEach(a => delete a.unsaved);

    if (timecardId !== NEW_TC_ID) {
      for (let i = 0; i < unsaved.length; i++) {
        const allowance = unsaved[i];

        if (allowance.toDelete) {
          if (!allowance.worksightId.includes(NEW_ALLO_ID)) {
            //new allowances will be overwritten by fetch afterwards
            yield call(deleteTimecardAllowance, api, debug, {
              worksightId: allowance.worksightId,
              refreshAfter: false,
            });
          }
        } else {
          yield call(saveAllowance, api, debug, {
            data: allowance,
            refreshAfter: false,
          });
          needRefresh = true;
        }
        yield delay(100);
      }
    }
    return needRefresh;
  } catch (e) {
    debug(e);
  } finally {
  }
}

export function* submitTimecard(api, debug, params) {
  try {
    const { electronicSignature } = params;

    yield put(actions.setLoading({ variant: 'submittingEmp', loading: true }));

    const pristine = yield select(isPristine(FORM_NAME));

    const timecard = yield select(getFormValues(FORM_NAME));
    const { status, entryHeaderId, id: timecardId } = timecard;
    let errors = {};
    if (!pristine || timecardId === NEW_TC_ID) {
      db('savingTimecard');
      errors = yield call(saveTimecard, api, debug);
    }
    if (!_.isEmpty(errors)) {
      throw new Error('Save Timecard failed.');
    }
    yield delay(100); // delay after save to update store form values (timecardID)
    const projectId = yield select(currentProject);

    if (status === TIMECARD_INCOMPLETE) {
      yield call(submitEmpTimecard, api, debug, { electronicSignature });
    } else if (status === TIMECARD_PENDING_EMP_REVIEW) {
      yield call(submitDhPaTimecard, api, debug, {
        electronicSignature,
        entryHeaderId,
        timecardId,
      });
    }

    //ensure approval can propagate in BE
    yield delay(1250);

    yield put(
      push(
        `/projects/${projectId}/me/timecards?approvedTimecardId=${timecardId}`,
      ),
    );
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
    yield put(actions.setLoading({ variant: 'submittingEmp', loading: false }));
  }
}
//submit employee created timecard
function* submitEmpTimecard(api, debug, params) {
  const projectId = yield select(currentProject);
  const timecardId = yield select(sel.getTimecardId);
  const { electronicSignature } = params;

  const data = {
    agreeToTerms: true,
    electronicSignature: electronicSignature,
  };
  yield call(api.timecards.approvalFlow, { projectId, timecardId, data });
}

export function* selfReject(api, debug, params) {
  try {
    yield put(actions.setLoading({ variant: 'rejecting', loading: true }));

    const projectId = yield select(currentProject);
    const timecardId = yield select(sel.getTimecardId);
    const timecard = yield select(getFormValues(FORM_NAME));

    const { comment } = params;

    const data = {
      status: 'rejected',
      comment,
      timecardEntryHeaderIds: [timecard.entryHeaderId],
    };
    yield call(api.employeeTimecard.reviewTimecardEmp, {
      projectId,
      timecardId,
      data,
    });
    yield call(fetchTimecard, api, debug, { timecardId });
    yield call(fetchComments, api, debug);
    yield put(hideModal({ dialog: CONFIRM_EDIT_MODAL }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
    yield put(actions.setLoading({ variant: 'rejecting', loading: false }));
  }
}

//submit dh/pa created timecard
function* submitDhPaTimecard(api, debug, params) {
  const projectId = yield select(currentProject);
  const timecardId = yield select(sel.getTimecardId);
  const { electronicSignature, entryHeaderId } = params;

  const data = {
    agreeToTerms: true,
    status: 'approved',
    comment: '',
    electronicSignature,
    timecardEntryHeaderIds: [entryHeaderId],
  };
  yield call(api.employeeTimecard.reviewTimecardEmp, {
    projectId,
    timecardId,
    data,
  });
}

export function* fetchTimecardHistory(api, debug) {
  try {
    yield put(actions.setLoading({ variant: 'history', loading: true }));

    const timecardId = yield select(sel.getTimecardId);
    const projectId = yield select(currentProject);
    const history = yield call(api.timecards.history, {
      projectId,
      timecardId,
    });
    yield put(actions.storeTimecardHistory({ history }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
    yield put(actions.setLoading({ variant: 'history', loading: false }));
  }
}

export function* deleteTimecardFromList(api, debug, params) {
  try {
    yield put(actions.setLoading({ variant: 'delete', loading: true }));
    const { timecardId } = params;

    const projectId = yield select(currentProject);
    yield call(api.employeeTimecard.deleteTimecard, {
      projectId,
      timecardId,
      comment: '',
    });

    const formTimecards = yield select(getTimecards);

    let timecards = _.cloneDeep(formTimecards);
    timecards = timecards.filter(tc => tc.timecardId !== timecardId);

    yield put(storeTimecards({ timecards }));
    yield put(hideModal({ dialog: DEL_TC_FROM_LIST }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
    yield put(actions.setLoading({ variant: 'delete', loading: false }));
  }
}

//Delete from timecard page
export function* deleteTimecard(api, debug, params) {
  try {
    yield put(actions.setLoading({ variant: 'delete', loading: true }));
    const timecardId = yield select(sel.getTimecardId);
    const projectId = yield select(currentProject);
    yield put(actions.setDeletedTimecardId({ timecardId }));
    yield call(api.employeeTimecard.deleteTimecard, {
      projectId,
      timecardId,
      comment: '',
    });

    //delay to allow for the delete to complete
    yield delay(1250);
    yield put(hideModal({ dialog: DELETE_TC_DIALOG }));

    yield put(push(`/projects/${projectId}/me/timecards`));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
    yield put(actions.setLoading({ variant: 'delete', loading: false }));
  }
}

export function* downloadDocument(api, debug, params) {
  try {
    const { token, file } = params;
    const projectId = yield select(currentProject);
    const endpoint = [
      `projects/${projectId}/allowances/${token}/downloadDocument`,
    ].join('');
    const fileName = `${file}`;
    yield call(api.downloader.downloadFromURI, {
      endpoint,
      fileName,
    });
  } catch (e) {
    debug(e);
  }
}

export function* clearDay(api, debug, params) {
  try {
    const { index, returnDay, shouldSyncAllo = true, saveAfter } = params;

    const formDay = yield select(formSelector, `days[${index}]`);
    const initialValues = yield select(sel.getInitialValues, `days[${index}]`);
    let day;
    const { entryHeaderId, id: timecardId } = initialValues;
    const initialDay = initialValues.days[index];
    const referenceDate = moment(initialDay.date).format('YYYY-MM-DD');
    const allComments = yield select(sel.getComments);
    const selDayComments = allComments.filter(
      c =>
        c.type === 'day' &&
        moment(c.referenceDate).isSame(initialDay.date, 'day'),
    );

    //delete day level comment
    if (selDayComments.length > 0) {
      const projectId = yield select(currentProject);
      if (timecardId !== NEW_TC_ID) {
        const timecardList = yield select(sel.getTimecardList);
        //delete comment if timecard is incomplete
        const canDeleteComment = timecardList.some(
          tc =>
            tc.entryHeaderId === entryHeaderId &&
            tc.status.toLowerCase() === TIMECARD_INCOMPLETE,
        );
        if (canDeleteComment) {
          yield call(api.employeeTimecard.deleteDayLevelComments, {
            projectId,
            timecardId: entryHeaderId,
            referenceDate,
          });
          yield call(fetchComments, api, debug);
        }
      } else {
        //remove comment from store - fresh timecard
        const filteredComments = allComments.filter(
          c => c.referenceDate !== initialDay.date,
        );
        yield put(actions.storeComments({ comments: filteredComments }));
      }
    }

    if (!initialDay?.dayType?.id) {
      day = _.cloneDeep(initialDay);
    } else {
      day = _.cloneDeep(formDay);
      Object.keys(day).forEach(key => {
        if (key !== 'date' && key !== 'id') {
          day[key] = null;
        }
      });
    }
    day.date = formDay.date;
    if (returnDay) {
      return day;
    } else {
      yield put(change(FORM_NAME, `days[${index}]`, day));
      yield delay(50);
      if (shouldSyncAllo) yield put(actions.updateDailyAutoAllowances());
      if (saveAfter) yield call(saveTimecard, api, debug);
    }
  } catch (e) {
    debug(e);
  } finally {
  }
}

export function* checkCompatibility(api, debug, params) {
  try {
    yield put(
      actions.setLoading({ variant: 'checkCompatibility', loading: true }),
    );
    yield put(actions.storeCompatibility({ compatible: {} }));

    const { dealMemoId, timecard } = params;
    let { dealMemo, endsOn } = params;

    if (!endsOn) endsOn = yield select(sel.getEndsOn);

    endsOn = endsOn || timecard.endsOn;

    if (!dealMemo) {
      const activeUser = yield select(getProjectUser);
      const projectWorkSightId = yield delayOnValue(
        getCurrentProjectWorkSight,
        { timeout: 5000 },
      );
      const startDate = moment(endsOn).startOf('week').format('YYYY-MM-DD');
      const endDate = moment(endsOn).endOf('week').format('YYYY-MM-DD');

      const dealMemoList = yield call(api.employees.dealMemos, {
        projectId: projectWorkSightId,
        employeeId: activeUser.workSightId,
        startDate,
        endDate,
        withPayroll: true,
      });
      dealMemo = dealMemoList.find(deal => deal.id === dealMemoId);
    }

    const compatible = {
      isCompatible: true,
      dealMemoId: dealMemo.id,
      days: [],
    };
    const startsOn = moment(endsOn).clone().subtract(6, 'days');

    for (let i = 0; i < 7; i++) {
      const day = moment(startsOn).add(i, 'days');

      const dealStart = moment(dealMemo.start);
      const dealEnd = moment(dealMemo.end);

      const isDayValid = day.isBetween(dealStart, dealEnd, 'day', '[]');

      if (!isDayValid) compatible.isCompatible = false;

      compatible.days.push({
        dayType: true,
        workLocation: true,
        occupationCode: true,
        isDayValid,
      });
    }

    const markIncompatible = (index, field) => {
      compatible.isCompatible = false;
      compatible.days[index][field] = false;
    };

    const htgContractId = _.get(dealMemo, 'htgContract.id', '');
    const htgUnionId = _.get(dealMemo, 'htgUnion.id', '');

    const fetchDayTypes = call(api.timecards.searchByTypes, {
      type: 'dayType',
      params: {
        parentValue: htgContractId,
        type: 'dayType',
        pageSize: -1,
      },
    });

    const fetchWorkLocations = call(api.timecards.searchByTypes, {
      type: 'locationType',
      params: { options: { htgContractId, htgUnionId }, pageSize: -1 },
    });

    const occCodesToCheck = [];
    const days = timecard?.days || [];
    days.forEach((day, index) => {
      const occCodeCode = day.occupationCode?.code;
      if (occCodeCode) {
        occCodesToCheck.push(occCodeCode);
      }
    });

    const dbCodeId = yield select(getCurrentProjectDbCodeId);

    const checkOccCodes = call(api.employeeTimecard.checkOccCodes, {
      occupationCodes: occCodesToCheck,
      dbCodeId,
      contractId: htgContractId,
      unionId: htgUnionId,
    });

    let [dayTypes, workLocations, occCodeResults] = yield all([
      fetchDayTypes,
      fetchWorkLocations,
      checkOccCodes,
    ]);

    days.forEach((day, index) => {
      const dayTypeId = day.dayType?.id;
      const workLocationId = day.workLocation?.id;
      const occCodeCode = day.occupationCode?.code;

      if (dayTypeId) {
        const dayType = dayTypes.find(type => type.id === dayTypeId);
        if (!dayType) markIncompatible(index, 'dayType');
      }

      if (workLocationId) {
        const workLocation = workLocations.find(
          loc => loc.id === workLocationId,
        );
        if (!workLocation) markIncompatible(index, 'workLocation');
      }
      if (occCodeCode) {
        const occCode = occCodeResults.find(
          occ => occ.occupationCode === occCodeCode,
        );
        if (!occCode?.isValid) markIncompatible(index, 'occupationCode');
      }
    });

    yield put(actions.storeCompatibility({ compatible }));
    yield delay(250);
  } catch (e) {
    debug(e);
    yield put(showAlert());
    yield put(actions.storeCompatibility({ compatible: null }));
  } finally {
    yield put(
      actions.setLoading({ variant: 'checkCompatibility', loading: false }),
    );
  }
}

export function* deleteTimecardAllowance(api, debug, params) {
  try {
    yield put(
      actions.setLoading({ variant: 'allowanceDelete', loading: true }),
    );
    const { worksightId, refreshAfter = true } = params;

    const projectId = yield select(currentProject);
    const timecardId = yield select(sel.getTimecardId);

    if (timecardId === NEW_TC_ID || worksightId.includes(NEW_ALLO_ID)) {
      const existingAllowances = yield select(formSelector, 'allowances');
      const remainingAllowances = existingAllowances.filter(
        e => e.worksightId !== worksightId,
      );
      if (refreshAfter) {
        yield put(change(FORM_NAME, 'allowances', remainingAllowances));
        yield delay(150);
        yield put(actions.storeAllowances({ allowances: remainingAllowances }));
      } else {
        yield put(change(FORM_NAME, 'allowances', remainingAllowances));
      }
    } else {
      yield call(api.employeeTimecard.deleteTimecardAllowance, {
        projectId,
        timecardId,
        worksightId,
      });

      if (refreshAfter) {
        const savedAllowances = yield call(fetchAllowances, api, debug, {
          timecardId,
        });
        yield put(change(FORM_NAME, 'allowances', savedAllowances));
        yield delay(150);
        yield put(actions.storeAllowances({ allowances: savedAllowances }));
      }
    }

    yield put(hideModal({ dialog: DELETE_TC_ALLOWANCE_DIALOG }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
    yield put(
      actions.setLoading({ variant: 'allowanceDelete', loading: false }),
    );
  }
}

//Get TimecardList
export function* fetchTimecardList(api, debug) {
  try {
    const projectDetails = yield delayOnValueObj(getProjectDetails);
    let { worksightId, dbCode, id } = projectDetails;
    const payload = {
      filters: [
        { field: 'project.id', type: 'key', values: [`${worksightId}`] },
        { field: 'batch.dbCode', type: 'key', values: [`${dbCode}`] },
      ],
    };
    const data = yield call(api.timecards.list, id, payload);
    //const timecards = data?.find(tc => tc.isAllowanceOnly === false );
    let timecardList = camelCase(data, { deep: true });

    yield put(actions.storeTimecardList({ timecardList }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
  }
}

export function* printTimecard(api, debug) {
  try {
    const projectId = yield select(currentProject);
    const timecardId = yield select(sel.getTimecardId);
    const endpoint = `/projects/${projectId}/timecards/${timecardId}/print`;

    const filename = `Timecard-${timecardId}.pdf`;
    const response = yield call(api.downloader.downloadFromURI, {
      endpoint,
      filename,
    });
    if (response?.data?.fileStatus === 'Failed') {
      yield put(
        showAlert({
          message:
            'Some allowance documents cannot be converted to PDF and are not included in the report',
          variant: 'warning',
        }),
      );
    }
  } catch (e) {
    debug(e);
  }
}

export function* changeDealMemo(api, debug, params) {
  try {
    yield put(actions.setLoading({ variant: 'changingDeal', loading: true }));

    const { compatible } = params;

    const { dealMemoId, days, isCompatible } = compatible;
    const dealMemos = yield select(sel.getDealMemos);

    const dealMemo = dealMemos.find(deal => deal.id === dealMemoId);

    if (!dealMemo) {
      throw new Error('Deal Memo not found');
    }

    yield put(change(FORM_NAME, 'dealMemo', dealMemo));
    yield put(change(FORM_NAME, 'htgDealmemoId', dealMemo.id));

    if (!isCompatible) {
      for (let dayIndex = 0; dayIndex < days.length; dayIndex++) {
        const day = days[dayIndex];
        const dayKeys = Object.keys(day);
        for (let j = 0; j < dayKeys.length; j++) {
          const key = dayKeys[j];
          if (key === 'isDayValid' && day[key] === false) {
            yield call(clearDay, api, debug, {
              index: dayIndex,
              shouldSyncAllo: false,
            });
          } else if (key === 'dayType' && day[key] === false) {
            yield put(
              actions.changeDayType({
                newVal: null,
                oldVal: null,
                index: dayIndex,
                shouldSyncAllo: false, //will sync with weekly change below
              }),
            );
          } else if (day[key] === false) {
            yield put(change(FORM_NAME, `days[${dayIndex}].${key}`, null));
          }
        }
      }
    }
    const timecard = yield select(getFormValues(FORM_NAME));
    for (let i = 0; i < timecard.days.length; i++) {
      const tcDay = timecard.days[i];
      //if daytype exists and dealmemo changed - populate selected dealmemo occupation code
      if (tcDay.dayType?.id || tcDay.htgDayTypeId) {
        yield put(
          change(
            FORM_NAME,
            `days[${i}].occupationCode`,
            dealMemo.occupationCode,
          ),
        );
      }
    }

    const timecardId = yield select(sel.getTimecardId);

    //save before updating auto allowances for existing timecards
    if (timecardId !== NEW_TC_ID) {
      yield call(saveTimecard, api, debug);
    }

    yield call(updateWeekAutoAllowances, api, debug, { dealMemo });

    //save after for new timecards
    if (timecardId === NEW_TC_ID) {
      yield call(saveTimecard, api, debug);
    }
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
    yield put(hideModal({ dialog: DEALMEMO_DIALOG }));
    yield put(actions.setLoading({ variant: 'changingDeal', loading: false }));
  }
}

export function* updateWeekAutoAllowances(api, debug, params) {
  const settings = yield select(getSettings);
  const { myTimecardAutoAllowances } = settings;
  if (!myTimecardAutoAllowances) return;

  const { dealMemo } = params;

  const timecardId = yield select(sel.getTimecardId);

  const formAllowances = yield select(formSelector, 'allowances');
  let allowances = _.cloneDeep(formAllowances);

  const allowancesToDelete = allowances.filter(
    a => a.allowanceTypeFlag === 'A',
  );

  for (let i = 0; i < allowancesToDelete.length; i++) {
    const allowance = allowancesToDelete[i];

    yield call(deleteTimecardAllowance, api, debug, {
      worksightId: allowance.worksightId,
      refreshAfter: false,
    });
    yield delay(100);
  }

  const dealMemoAllowances = dealMemo.dealMemoAllowances || [];
  const weeklyAllowances = dealMemoAllowances.filter(a => a.frequency === 'F');

  const allowanceTypes = yield select(sel.getAllowanceTypes);
  const defaultNonTax = yield delayOnValueObj(getDefaultPayCodes);

  for (let i = 0; i < weeklyAllowances.length; i++) {
    const dealAllowance = weeklyAllowances[i];
    const newAllo = makeAllowanceFromDealAllo({
      dealAllowance,
      allowanceTypes,
      defaultNonTax,
      dealMemo,
    });

    if (!_.isEmpty(newAllo)) {
      yield call(saveAllowance, api, debug, {
        data: newAllo,
        refreshAfter: false,
      });
    }
  }

  //Need to refresh before updating daily otherwise save errors out
  if (
    timecardId !== NEW_TC_ID &&
    (weeklyAllowances.length > 0 || allowancesToDelete.length > 0)
  ) {
    yield delay(250); //hold to get the allowances saved
    const savedAllowances = yield call(fetchAllowances, api, debug, {
      timecardId,
    });

    yield put(change(FORM_NAME, 'allowances', savedAllowances));
    yield delay(150);
    yield put(actions.storeAllowances({ allowances: savedAllowances }));
    yield delay(150);
  }

  yield call(updateDailyAutoAllowances, api, debug, {});
  yield delay(250);

  if (timecardId !== NEW_TC_ID) {
    const existingAllowances = yield select(formSelector, 'allowances');
    let needRefresh = false;
    for (let i = 0; i < existingAllowances.length; i++) {
      const exAllo = existingAllowances[i];
      if (exAllo.unsaved || exAllo.worksightId.includes(NEW_ALLO_ID)) {
        needRefresh = true;
        if (exAllo.toDelete) continue;

        yield call(saveAllowance, api, debug, {
          data: exAllo,
          refreshAfter: false,
        });
      }
    }
    if (needRefresh) {
      yield delay(150);
      const savedAllowances = yield call(fetchAllowances, api, debug, {
        timecardId,
      });
      yield put(change(FORM_NAME, 'allowances', savedAllowances));
      yield delay(150);
      yield put(actions.storeAllowances({ allowances: savedAllowances }));
      yield delay(150);
    }
  }
}

export function* changeWeek(api, debug, params) {
  try {
    const { newDate } = params;
    const endsOn = newDate.clone().endOf('week');

    const endDate = endsOn.format('YYYY-MM-DDT00:00:00');
    const weekStarting = endsOn.clone().startOf('week');
    const startDate = weekStarting.format('YYYY-MM-DDT00:00:00');
    const activeUser = yield select(getProjectUser);
    const projectWorkSightId = yield select(getCurrentProjectWorkSight);

    const employeeId = activeUser.workSightId;
    const dealMemosList = yield call(api.employees.dealMemos, {
      projectId: projectWorkSightId,
      employeeId,
      startDate,
      endDate,
      withPayroll: true,
    });

    if (dealMemosList.length === 0) {
      const endsStr = endsOn.format('MM/DD');
      const startsStr = weekStarting.format('MM/DD');
      const weekRange = `${startsStr} - ${endsStr}`;

      yield put(
        showAlert({
          message: `Cannot change work week, no valid deal memos for the week of ${weekRange}.`,
          variant: 'error',
        }),
      );
      return;
    }

    dealMemosList.sort(empDealSort);
    const newDeal = dealMemosList[0];

    const dealStart = moment(newDeal.start);
    const dealEnd = moment(newDeal.end);

    const tcEndsOn = endsOn.format('YYYY-MM-DDT00:00:00');
    yield put(actions.setEndsOn({ endsOn: tcEndsOn }));

    const initial = yield select(sel.getInitialValues);
    const timecard = _.cloneDeep(initial);

    //get current form values
    const formValues = yield select(getFormValues(FORM_NAME));
    const formTimecard = _.cloneDeep(formValues);
    const days = timecard?.days || [];
    const formDays = formTimecard?.days || [];

    for (let i = 0; i < 7; i++) {
      const date = newDate.clone().startOf('week').add(i, 'days');

      if (date.isBetween(dealStart, dealEnd, 'day', '[]') === false) {
        days[i].dayType = null;
        days[i].htgDayTypeId = null;

        formDays[i].dayType = null;
        formDays[i].htgDayTypeId = null;
      }

      days[i].date = date.format('YYYY-MM-DDT00:00:00');
      formDays[i].date = date.format('YYYY-MM-DDT00:00:00');
    }

    const dealMemoAllowances = newDeal?.dealMemoAllowances || [];
    timecard.dealMemo = _.cloneDeep(newDeal) || {};

    const htgContractId = newDeal?.htgContract?.id || null;
    timecard.htgContractId = htgContractId;
    timecard.isExempt = !!newDeal.exempt;
    timecard.allowances = [];

    const settings = yield select(getSettings);
    const { myTimecardAutoAllowances } = settings;
    if (myTimecardAutoAllowances && dealMemoAllowances.length > 0) {
      const weeklyAllowances = dealMemoAllowances.filter(
        a => a.frequency === 'F',
      );
      const allowanceTypes = yield select(sel.getAllowanceTypes);
      const defaultNonTax = yield delayOnValueObj(getDefaultPayCodes);
      const weeklyAutoAllowances = [];
      if (weeklyAllowances.length > 0) {
        for (let i = 0; i < weeklyAllowances.length; i++) {
          const dealAllowance = weeklyAllowances[i];
          const newAllo = makeAllowanceFromDealAllo({
            dealAllowance,
            allowanceTypes,
            defaultNonTax,
            dealMemo: newDeal,
          });

          if (!_.isEmpty(newAllo)) {
            weeklyAutoAllowances.push(newAllo);
          }
        }
      }
      timecard.allowances = weeklyAutoAllowances;
    }

    yield put(actions.storeTimecard({ timecard }));

    yield delay(150);
    //replace changed form values
    yield put(change(FORM_NAME, 'days', formDays));
    yield delay(150);

    yield put(actions.updateDailyAutoAllowances());
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
  }
}

export function* changeDayType(api, debug, params) {
  try {
    const { newVal, oldVal, index, shouldSyncAllo = true } = params;

    const formTimecard = yield select(getFormValues(FORM_NAME));
    const timecard = _.cloneDeep(formTimecard);
    const day = timecard.days[index];

    const columns = yield select(sel.getColumns);
    const workLocations = yield select(sel.getWorkLocations);

    const currentUser = yield select(getProjectUser);
    if (_.isEmpty(newVal)) {
      day.htgDayTypeId = null;
      day.dayType = null;
      day.occupationCode = null;
    } else {
      day.htgDayTypeId = newVal.id;
      day.dayType = newVal;

      day.occupationCode = _.cloneDeep(timecard.dealMemo.occupationCode);

      const allowTimes = doesDayAllowTimes(newVal?.code);
      if (!allowTimes) {
        columns.forEach(column => {
          if (column.type === 'time') {
            day[column.accessor] = null;
          }
        });
      }
      const selectWorkLocation = workLocations?.find(
        l => l.id === currentUser?.batchTemplate?.locationTypeId,
      );
      if (!_.isEmpty(selectWorkLocation)) {
        day.locationType = _.cloneDeep(selectWorkLocation);
        day.htgLocationTypeId = selectWorkLocation.id;
      }
      if (_.isEmpty(oldVal)) {
        day.htgStateId = timecard.htgStateId;
        day.state = _.cloneDeep(timecard.state);
        day.htgCityId = timecard.htgCityId;
        day.city = _.cloneDeep(timecard.city);
        day.htgCountyId = timecard.htgCountyId;
        day.workCounty = _.cloneDeep(timecard.county);
        day.htgSubdivisionId = timecard.htgSubdivisionId;
        day.workSubdivision = _.cloneDeep(timecard.subdivision);
      }
    }
    yield put(change(FORM_NAME, `days[${index}]`, day));

    if (shouldSyncAllo) {
      yield put(actions.updateDailyAutoAllowances({ timecard }));
    }
  } catch (e) {
    debug(e);
  } finally {
  }
}

export function* updateDailyAutoAllowances(api, debug, params = {}) {
  try {
    const settings = yield select(getSettings);
    const { myTimecardAutoAllowances } = settings;

    if (!myTimecardAutoAllowances) return;

    let { timecard } = params;
    if (!timecard) {
      timecard = yield select(getFormValues(FORM_NAME));
    }

    const allowanceTypes = yield select(sel.getAllowanceTypes);
    const defaultNonTax = yield delayOnValueObj(getDefaultPayCodes);

    const dayCountObj = timecard.days.reduce((acc, day) => {
      if (day.htgDayTypeId) {
        if (acc[day.htgDayTypeId]) {
          acc[day.htgDayTypeId] += 1;
        } else {
          acc[day.htgDayTypeId] = 1;
        }
      }
      return acc;
    }, {});

    let existingAllowances = _.cloneDeep(timecard.allowances || []);

    let dealMemoAllowances = timecard.dealMemo?.dealMemoAllowances || [];
    let updatedAllowances = false;
    dealMemoAllowances = dealMemoAllowances.filter(a => a.frequency === 'D');
    dealMemoAllowances = dealMemoAllowances
      .map(dealAllowance =>
        makeAllowanceFromDealAllo({
          dealAllowance,
          allowanceTypes,
          defaultNonTax,
          includeDayTypes: true,
          dealMemo: timecard.dealMemo,
        }),
      )
      .filter(a => !!a); //remove invalid deal allowances

    dealMemoAllowances.forEach(dealAllo => {
      const dayTypes = dealAllo?.dayTypes || [];
      let dayCount = 0;
      dayTypes.forEach(dt => {
        if (dayCountObj[dt.id]) {
          dayCount += dayCountObj[dt.id];
        }
      });

      updatedAllowances = true;
      const existIdx = existingAllowances.findIndex(
        e =>
          e.allowanceTypeFlag === 'A' &&
          e.sequenceNumber === dealAllo.sequenceNumber,
      );
      let newAllo;
      if (existIdx !== -1) {
        const existingAllo = existingAllowances[existIdx];
        newAllo = _.cloneDeep(existingAllo);
      } else {
        newAllo = _.cloneDeep(dealAllo);
        delete newAllo.dayTypes;
        newAllo.worksightId = `${NEW_ALLO_ID}${crypto.randomUUID()}`;
      }

      if (dayCount === 0) {
        newAllo.toDelete = true;
      } else {
        delete newAllo.toDelete;
      }
      newAllo.rate = dealAllo.rate;
      newAllo.units = dayCount;
      newAllo.amount = dealAllo.rate * dayCount;
      newAllo.isEdited = false;
      newAllo.unsaved = true;

      if (existIdx !== -1) {
        existingAllowances[existIdx] = newAllo;
      } else {
        existingAllowances.push(newAllo);
      }
    });
    if (updatedAllowances) {
      //remove any allowances that are not saved yet
      //saved allowance must be removed with server delete call during allowance sync
      existingAllowances = existingAllowances.filter(
        a => !(a.toDelete && a.worksightId.includes(NEW_ALLO_ID)),
      );

      yield put(change(FORM_NAME, 'allowances', existingAllowances));
    }
  } catch (e) {
    debug(e);
  } finally {
  }
}

export function* checkProjectUser(api, debug, params) {
  try {
    let projectUser = yield select(getProjectUser);
    const loadingStatus = yield select(sel.getUserLoadingStatus);
    let isActive =
      projectUser?.hasWorksightAccount && !!projectUser?.department;

    const totalDelay = 5000;
    const delayAmount = 500;

    let count = Math.floor(totalDelay / delayAmount);
    while (!isActive && count > 0) {
      yield delay(500);
      projectUser = yield select(getProjectUser);
      isActive = projectUser?.hasWorksightAccount && !!projectUser?.department;
      count--;
    }

    if (isActive) {
      yield put(actions.setUserLoadingStatus({ userLoadingStatus: 'active' }));
    } else {
      yield put(
        actions.setUserLoadingStatus({ userLoadingStatus: 'inactive' }),
      );
      const projectId = yield select(currentProject);
      if (loadingStatus === 'loading') {
        //retry the user info fetch 1x
        db('Refetching user info');
        yield call(fetchUserInfo, api, projectId);
        yield put(actions.checkProjectUser());
      }
    }

    //check and set value for selector at timecard level
  } catch (e) {
    debug(e);
  } finally {
  }
}

export function* getCurrentQuerySearch(debug) {
  try {
    const location = yield select(getLocation);
    return location.search;
  } catch (e) {
    debug(e);
    return '';
  }
}

function* fetchMilitaryTime(api, debug) {
  try {
    const resp = yield call(api.employeeTimecard.fetchMilitaryTime);
    yield put(
      actions.setUseMilitary({ useMilitary: resp.militarytime_enable }),
    );
  } catch (e) {
    debug(e);
  }
}
function* saveMilitaryTime(api, debug, params) {
  try {
    const { useMilitary } = params;
    const data = {
      militarytime_enable: useMilitary,
    };
    const resp = yield call(api.employeeTimecard.saveMilitaryTime, { data });
    yield put(
      actions.setUseMilitary({ useMilitary: resp.militarytime_enable }),
    );
  } catch (e) {
    debug(e);
  }
}
export default function* digitalEdits({ api, debug }) {
  yield all([
    takeLatest(`${actions.init}`, init, api, debug),
    takeLatest(`${actions.fetchDealMemos}`, fetchDealMemos, api, debug),
    takeLatest(`${actions.fetchSupportData}`, fetchSupportData, api, debug),
    takeLatest(`${actions.fetchDayTypes}`, fetchDayTypes, api, debug),
    takeLatest(
      `${actions.fetchAllowanceTypes}`,
      fetchAllowanceTypes,
      api,
      debug,
    ),
    takeLatest(`${actions.fetchWorkLocations}`, fetchWorkLocations, api, debug),
    takeLatest(`${actions.fetchEpisodes}`, fetchEpisodes, api, debug),
    takeLatest(`${actions.fetchComments}`, fetchComments, api, debug),
    takeLatest(`${actions.saveComment}`, saveComment, api, debug),
    takeLatest(`${actions.copyFromPrevWeek}`, copyFromPrevWeek, api, debug),
    takeLatest(`${actions.parseColumns}`, parseColumns, api, debug),
    takeLatest(`${actions.saveTimecard}`, saveTimecard, api, debug),
    takeLatest(`${actions.submitTimecard}`, submitTimecard, api, debug),
    takeLatest(`${actions.saveAllowance}`, saveAllowance, api, debug),
    takeLatest(`${actions.deleteTimecard}`, deleteTimecard, api, debug),
    takeLatest(
      `${actions.fetchTimecardHistory}`,
      fetchTimecardHistory,
      api,
      debug,
    ),
    takeLatest(`${actions.downloadDocument}`, downloadDocument, api, debug),
    takeLatest(`${actions.clearDay}`, clearDay, api, debug),
    takeLatest(`${actions.checkCompatibility}`, checkCompatibility, api, debug),
    takeLatest(
      `${actions.deleteTimecardAllowance}`,
      deleteTimecardAllowance,
      api,
      debug,
    ),
    takeLatest(`${actions.changeDealMemo}`, changeDealMemo, api, debug),
    takeLatest(`${actions.fetchTimecardList}`, fetchTimecardList, api, debug),
    takeLatest(`${actions.printTimecard}`, printTimecard, api, debug),
    takeLatest(`${actions.selfReject}`, selfReject, api, debug),
    takeLatest(`${actions.changeWeek}`, changeWeek, api, debug),
    takeLatest(`${actions.changeDayType}`, changeDayType, api, debug),
    takeLatest(
      `${actions.updateDailyAutoAllowances}`,
      updateDailyAutoAllowances,
      api,
      debug,
    ),
    takeLatest(`${actions.checkProjectUser}`, checkProjectUser, api, debug),
    takeLatest(
      `${actions.deleteTimecardFromList}`,
      deleteTimecardFromList,
      api,
      debug,
    ),
    takeLatest(`${actions.fetchMilitaryTime}`, fetchMilitaryTime, api, debug),
    takeLatest(`${actions.saveMilitaryTime}`, saveMilitaryTime, api, debug),
  ]);
}
