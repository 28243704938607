import createAction from './createAction';

export const init = createAction('init/digitalEdits');
export const fetchData = createAction('fetchData/digitalEdits');

export const fetchInvoice = createAction('fetch/digitalEdits');
export const updateInvoiceStatus = createAction(
  'updateInvoiceStatus/digitalEdits',
);
export const resubmitInvoice = createAction('resubmitInvoice/digitalEdits');
export const updateInvoice = createAction('updateInvoice/digitalEdits');

export const setLoading = createAction('setLoading/digitalEdits');

export const fetchTimecardSummary = createAction(
  'fetchTimecardSummary/digitalEdits',
);

export const fetchTimecardDistributions = createAction(
  'fetchTimecardDistributions/digitalEdits',
);

export const storeTimecardsInfo = createAction(
  'storeTimecardsInfo/digitalEdits',
);
export const storeTimecardsUpdate = createAction(
  'storeTimecardsUpdate/digitalEdits',
);
export const resetInvoiceAndRecords = createAction(
  'resetInvoiceAndRecords/digitalEdits',
);

export const setCurrentRecordId = createAction(
  'setCurrentTimecardId/digitalEdits',
);
export const setCurrentInvoiceId = createAction(
  'setCurrentInvoiceId/digitalEdits',
);
export const closeInvoiceComments = createAction(
  'closeInvoiceComments/digitalEdits',
);

export const setCommentState = createAction('setCommentState/digitalEdits');
export const saveComment = createAction('saveComment/digitalEdits');
export const fetchComments = createAction('fetchComments/digitalEdits');
export const syncUnsubmittedComment = createAction(
  'syncUnsubmittedComment/digitalEdits',
);

export const setCommentSaveFails = createAction(
  'setCommentSaveFails/digitalEdits',
);

export const storeComments = createAction('storeComments/digitalEdits');

export const setSearchText = createAction('setSearchText/digitalEdits');
export const setSortBy = createAction('setSortBy/digitalEdits');

export const parseFilters = createAction('parseFilters/digitalEdits');
export const storeFilterOptions = createAction(
  'storeFilterOptions/digitalEdits',
);

export const setSearchFilter = createAction('setSearchFilter/digitalEdits');
export const onSelectFilter = createAction('onSelectFilter/digitalEdits');
export const clearAllFilters = createAction('clearAllFilters/digitalEdits');
export const onSelectAllFilters = createAction(
  'onSelectAllFilters/digitalEdits',
);

export const unmount = createAction('unmount/digitalEdits');

export const fetchInvoiceFiles = createAction('fetchInvoiceFiles/digitalEdits');
export const storePendingFiles = createAction('storePendingFiles/digitalEdits');
export const batchPendingFilesForUpload = createAction(
  'batchPendingFilesForUpload/digitalEdits',
);
export const uploadFilesToDB = createAction('uploadFilesToDB/digitalEdits');
export const updateFiles = createAction('updateFiles/digitalEdits');
export const updateFileField = createAction('updateFileField/digitalEdits');
export const deleteFileFromDB = createAction('deleteFileFromDB/digitalEdits');
export const deleteFile = createAction('deleteFile/digitalEdits');
export const downloadFile = createAction('downloadFile/digitalEdits');
export const storeFiles = createAction('storeFiles/digitalEdits');
export const clearAllErrorFiles = createAction(
  'clearAllErrorFiles/digitalEdits',
);

export const setDataCardsView = createAction('setDataCardView/digitalEdits');
export const approveInvoice = createAction('approveInvoice/digitalEdits');
export const updateInProgressUploads = createAction(
  'updateInProgressUploads/digitalEdits',
);

export const toggleHideEmptyCols = createAction(
  'toggleHideEmptyCols/digitalEdits',
);
export const fetchTaxColumnMap = createAction('fetchTaxColumnMap/digitalEdits');
export const storeTaxColumnMap = createAction('storeTaxColumnMap/digitalEdits');

//download edit reports
export const fetchEditReports = createAction(
  'fetch/digitalEdits/digitalEditReports',
);
export const storeEditReports = createAction(
  'store/digitalEdits/digitalEditReports',
);
export const downloadEditReport = createAction(
  'download/digitalEdits/digitalEditReports',
);

export const lockComment = createAction('lockComment/digitalEdits');
export const unlockComment = createAction('unlockComment/digitalEdit');
export const storeCommentLocked = createAction(
  'storeCommentLocked/digitalEdit',
);
export const deleteComment = createAction('deleteComment/digitalEdits');
export const submitComment = createAction('submitComment/digitalEdits');

export const fetchRedirectInfo = createAction('fetchRedirectInfo/digitalEdits');

export const downloadSelectedFiles = createAction(
  'downloadSelected/digitalEdits/digitalEditReports',
);

export const navToStudioPlus = createAction('navToStudioPlus/digitalEdits');
export const downloadExpressForm = createAction(
  'downloadExpressForm/digitalEdits',
);

export const selectAllAdditionalFields = createAction(
  'selectAll/digitalEdits/additionalFields',
);

export const setAcEditRecord = createAction('setAcEditRecord/digitalEdits');
export const fetchEpisodes = createAction('fetchEpisodes/digitalEdits');
export const storeEpisodes = createAction('storeEpisodes/digitalEdits');

export const editAcFields = createAction('editAcFields/digitalEdits');

export const saveACE = createAction('saveACE/digitalEdits');
export const setBatchesOpened = createAction('setBatchesOpened/digitalEdits');
export const saveACECompleted = createAction('saveACECompleted/digitalEdits');
export const saveACEFailed = createAction('saveACEFailed/digitalEdits');

export const storeUnsavedEdits = createAction('storeUnsavedEdits/digitalEdits');
export const storePendingTimecards = createAction(
  'storePendingTimecards/digitalEdits',
);
export const storeErrorHeaderIds = createAction(
  'storeErrorHeaderIds/digitalEdits',
);
export const storeTimeoutHeaderIds = createAction(
  'storeTimeoutHeaderIds/digitalEdits',
);
export const clearRecordHasErrors = createAction(
  'clearRecordHasErrors/digitalEdits',
);

export const statusChangeSuccessful = createAction(
  'statusChangeSuccessful/digitalEdits',
);
export const startACETimer = createAction('startACETimer/digitalEdits');
export const resetACETimer = createAction('resetACETimer/digitalEdits');
export const clearACETimer = createAction('clearACETimer/digitalEdits');

export const updateIsEdited = createAction('updateIsEdited/digitalEdits');
export const deleteCCTimecard = createAction('deleteCCTimecard/digitalEdits');

export const updateLocalOriginal = createAction(
  'updateLocalOriginal/digitalEdits',
);

export const triggerSplitRevert = createAction(
  'triggerSplitRevert/digitalEdits',
);

export const reset = createAction('reset/digitalEdits');
export const setIsInvoiceStatusChanged = createAction(
  'invoice/statusChange/digitalEdits',
);
export const setIsFormDirty = createAction('isFormDirty/digitalEdits');
