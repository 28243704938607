import React from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette }) => ({
  MobileMenuLinkBtn: {
    width: '100%',
  },
  text: {
    width: 180,
    color: palette.text.primary,
    '& > .MuiTypography-root': {
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  icon: {
    minWidth: 0,
    marginRight: 8,
  },
}));

const MobileMenuLinkBtn = props => {
  const classes = useStyles();
  const { icon, text, onClick, id } = props;

  return (
    <ListItem
      id={id}
      className={classes.MobileMenuLinkBtn}
      key={text}
      disablePadding
      onClick={onClick}
    >
      <ListItemButton>
        {icon && <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>}
        <ListItemText className={classes.text} primary={text} />
      </ListItemButton>
    </ListItem>
  );
};

MobileMenuLinkBtn.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func,
};

export default MobileMenuLinkBtn;
