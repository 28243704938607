/* eslint-disable mui-unused-classes/unused-classes */
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

//actions
import { hideAlert } from 'actions/alert';
import { onNavToBatchInWTC } from 'actions/moveTimecards';

//components
import { Snackbar, SnackbarContent } from '@mui/material';

//icons
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

export const getAlert = state => get(state, `alert`, {});

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles(({ palette }) => ({
  success: {
    backgroundColor: palette.success.main,
  },
  error: {
    backgroundColor: palette.error.dark,
  },
  info: {
    backgroundColor: palette.primary.main,
    border: '1px solid white',
  },
  warning: {
    backgroundColor: palette.warning.main,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 8,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  messageContent: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  contentColumn: {
    flexDirection: 'column',
    '& > *': {
      margin: 0,
      paddingBottom: 5,
    },
  },
  wtcLink: {
    textDecoration: 'underline',
    paddingLeft: 10,
    color: palette.misc.link,
    cursor: 'pointer',
  },
}));

const mapState = state => {
  const alert = getAlert(state);
  return {
    message: alert.message || 'Oops, Something went wrong.',
    variant: alert.variant,
    open: alert.open,
    action: alert.action,
    displayTime: alert.displayTime || 5000,
  };
};

const mapDispatch = {
  onClose: hideAlert,
  onNavToBatchInWTC,
};

const SnackBar = props => {
  const classes = useStyles();
  const {
    open,
    message,
    variant,
    action,
    onClose,
    onNavToBatchInWTC,
    displayTime,
  } = props;

  React.useEffect(() => {
    let timeout;
    if (open && variant !== 'error' && displayTime !== -1) {
      timeout = setTimeout(() => {
        onClose();
      }, displayTime);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [open, message, variant, onClose, displayTime]);

  const handleBatchNav = () => {
    onNavToBatchInWTC({ batchWorksightId: action.batchWorksightId });
  };

  const batchLink = (
    <span className={classes.wtcLink} onClick={handleBatchNav}>
      Go to batch
    </span>
  );

  const Icon = variantIcon[variant];
  let messageContent = null;
  if (Array.isArray(message)) {
    const messageContentArray = message.map((content, index) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <ul key={`message-id-${index}`} className={classes.message}>
          {content}
        </ul>
      );
    });
    messageContent = (
      <div className={classes.messageContent}>
        <Icon className={clsx(classes.icon, classes.iconVariant)} />
        <div className={clsx(classes.messageContent, classes.contentColumn)}>
          {messageContentArray}
        </div>
      </div>
    );
  } else {
    messageContent = (
      <span className={classes.message}>
        <Icon className={clsx(classes.icon, classes.iconVariant)} />
        {message}
        {action && action.link ? batchLink : ''}
      </span>
    );
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      ClickAwayListenerProps={{ onClickAway: () => null }} //disable clickAway to close
      onClose={onClose}
    >
      <SnackbarContent
        className={clsx(classes[variant])}
        message={messageContent}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() => onClose()}
            size="large"
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

SnackBar.propTypes = {
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  open: PropTypes.bool,
  action: PropTypes.object,
  onNavToBatchInWTC: PropTypes.func,
  displayTime: PropTypes.number,
};

export default compose(connect(mapState, mapDispatch))(SnackBar);
