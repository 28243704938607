import _ from 'lodash';
// import moment from 'moment';
import {
  EMPLOYEE,
  DH,
  PA,
  UPM,
  IA,
  PROJECT_ADMIN,
} from 'components/props/profiles';

// export const CURRENT_YEAR = moment().format('YYYY');
//TODO do NOT push to PROD/STG - for testing in QA only
export const CURRENT_YEAR = '2024';

export const ORDINALS = [
  'First',
  'Second',
  'Third',
  'Fourth',
  'Fifth',
  'Sixth',
  'Seventh',
  'Eighth',
  'Ninth',
  'Tenth',
];

export const STANDARD = 'standard';

export const WORKFLOW_STANDARD = 'standard';
export const WORKFLOW_PROCESS_AS_REJECTED = 'process_as_rejected';

export const REJECTION_WORKFLOW_MAP = [
  {
    value: WORKFLOW_STANDARD,
    label: 'Standard',
  },
  {
    value: WORKFLOW_PROCESS_AS_REJECTED,
    label: 'Process as Rejected',
  },
];

export const BULK_EDIT_TIMECARD_STATUS = [
  {
    label: 'Incomplete',
    value: '0',
    selected: false,
    index: 0,
  },
  {
    label: 'Draft',
    value: '1',
    selected: false,
    index: 1,
  },
  {
    label: 'Submitted for Approval',
    value: '2',
    selected: false,
    index: 2,
  },
  {
    label: 'Ready for Review',
    value: '3',
    selected: false,
    index: 3,
  },
  {
    label: 'Submitted to C&C',
    value: '4',
    selected: false,
    index: 4,
  },
  {
    label: 'Processed',
    value: '5',
    selected: false,
    index: 5,
  },
  {
    label: 'Paid',
    value: '6',
    selected: false,
    index: 6,
  },
  {
    label: 'Rejected',
    value: '7',
    selected: false,
    index: 7,
  },
];
export const BULK_EDIT_TIMECARD_WEEKDAY = [
  {
    label: 'Sunday',
    value: 'SU',
    selected: false,
    index: 0,
  },
  {
    label: 'Monday',
    value: 'MO',
    selected: false,
    index: 1,
  },
  {
    label: 'Tuesday',
    value: 'TU',
    selected: false,
    index: 2,
  },
  {
    label: 'Wednesday',
    value: 'WE',
    selected: false,
    index: 3,
  },
  {
    label: 'Thursday',
    value: 'TH',
    selected: false,
    index: 4,
  },
  {
    label: 'Friday',
    value: 'FR',
    selected: false,
    index: 5,
  },
  {
    label: 'Saturday',
    value: 'SA',
    selected: false,
    index: 6,
  },
];

//column id of default bulk sort
export const BULK_EDIT_SORT_ORDER = [
  { id: 'department' },
  { id: 'dealMemo' },
  { id: 'occupationCode' },
  { id: 'last' },
  { id: 'first' },
  { id: 'weekDay' },
];

export const BULK_EDIT_DROPDOWN_FIELDS = [
  'episode',
  'dayType',
  'locationType',
  'workSchedule',
];

export const BULK_EDIT_TOO_MANY_GROUPS = ['departments', 'batches'];

export const MEALS = [
  { value: 1, label: 'First Meal' },
  { value: 2, label: 'Second Meal' },
  { value: 3, label: 'Third Meal' },
];

export function labelToStatusMap(label) {
  const lowerCaseLabel = label?.toLowerCase();
  if (lowerCaseLabel.includes('ready for approver')) return 'primary';
  const map = {
    incomplete: 'warning',
    'ready for me': 'warning',
    'ready for dept head': 'primary',
    'ready for payroll acct': 'primary',
    'submitted to c&c': 'primary',
    processing: 'secondary',
    rejected: 'error',
    paid: 'tertiary',
  };
  return map[lowerCaseLabel] || 'primary';
}

export const STATUS_TO_BADGE_MAP = {
  incomplete: 'primary',
  draft: 'primary',
  in_progress: 'warning',
  pending_employee_review: 'warning',
  pending_dh_review: 'warning',
  pending_pa_review: 'warning',
  pending_upm_review: 'warning',
  sent_to_cnc: 'warning',
  rejected: 'primary',
  upm_rejected: 'primary',
  processed: 'secondary',
  paid: 'secondary',
};

export const STATUS_TO_LABEL_MAP = {
  incomplete: 'Incomplete',
  draft: 'Draft',
  pending_employee_review: 'Ready for me',
  in_progress: 'Submitted for Approval',
  pending_dh_review: 'Submitted for Approval',
  pending_pa_review: 'Submitted for Approval',
  pending_upm_review: 'Submitted for Approval',
  sent_to_cnc: 'Submitted for Approval',
  rejected: 'Incomplete',
  upm_rejected: 'Incomplete',
  processed: 'Processed',
  paid: 'Paid',
};

export const LABEL_TO_STAUS_MAP = {
  Incomplete: ['draft', 'Incomplete', 'rejected', 'upm_rejected'],
  'Submitted for Approval': ['in_progress', ''],
  Processed: ['Processed'],
  Paid: ['Paid'],
  'Ready for Review': ['pending_employee_review'],
  'Submitted to C&C': ['sent_to_cnc'],
};

export const CREW_HIRE_STATUS_TO_LABEL_MAP = {
  pending: 'PENDING',
  accepted: 'ACCEPTED',
};

export const TIMECARD_NOTE_STATUS_TO_BADGE_MAP = {
  'timecard-rejected': 'error',
  'timecard-upm_rejected': 'error',
  'timecard-payroll_coordinator_rejected': 'error',
  day: 'primary',
  'day-rerate': 'primary',
  timecard: 'primary',
};

export const TIMECARD_NOTE_STATUS_TO_LABEL_MAP = {
  'timecard-rejected': 'Rejected',
  'timecard-upm_rejected': 'Rejected',
  'timecard-payroll_coordinator_rejected': 'Rejected',
  'day-rerate': 'Rerate',
  timecard: 'General',
};

export const TIMECARD_EDITABLE_NONE = [];

export const TIMECARD_EDITABLE_OPTIONS = [
  'location',
  'meals',
  'times',
  'account-codes',
  'allowances',
  'comments',
  'general-notes',
  'rerate',
];

export const TIMECARD_TYPES_OPTIONS = [
  {
    value: 'mia',
    label: 'Cannot Reach Employee',
  },
  {
    value: 'terminated',
    label: 'Terminated',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

// TO DO: Sweep the site for anymore traces of the old statuses and
//        once confirmed remove the unused statuses from the maps below

// UPM
export const UPM_STATUS_TO_BADGE_MAP = {
  incomplete: 'primary',
  draft: 'primary',
  rejected: 'primary',
  pending_employee_review: 'warning',
  pending_dh_review: 'warning',
  pending_pa_review: 'warning',
  pending_upm_review: 'warning',
  upm_rejected: 'warning',
  sent_to_cnc: 'warning',
  processed: 'secondary',
  paid: 'secondary',
  blank: 'primary',
  'Ready for employee': 'warning',
  'Ready for dept head': 'warning',
  'Ready for payroll acct': 'warning',
  Draft: 'primary',
  'Ready for approver 1': 'warning',
  'Ready for approver 3': 'warning',
  'Ready for approver 2': 'warning',
  'Ready for me': 'warning',
  Incomplete: 'primary',
  Rejected: 'primary',
  Paid: 'secondary',
  Processing: 'secondary',
  'Submitted to C&C': 'secondary',
};

export const UPM_STATUS_TO_LABEL_MAP = {
  incomplete: 'Incomplete',
  draft: 'Draft',
  rejected: 'Incomplete',
  pending_employee_review: 'Submitted for Approval',
  pending_dh_review: 'Submitted for Approval',
  pending_pa_review: 'Submitted for Approval',
  upm_rejected: 'Submitted for Approval',
  pending_upm_review: 'Ready for review', // depends on level
  sent_to_cnc: 'Submitted to C&C',
  processed: 'Processed',
  paid: 'Paid',
  blank: 'Blank',
};

export const UPM_STATUS_TO_ACTION_MAP = {
  pending_upm_review: ['general-notes'],
};

// Payroll Accountant
export const PA_STATUS_TO_BADGE_MAP = {
  incomplete: 'primary',
  draft: 'primary',
  rejected: 'primary',
  pending_employee_review: 'warning',
  pending_dh_review: 'warning',
  pending_pa_review: 'warning',
  pending_upm_review: 'warning',
  upm_rejected: 'warning',
  sent_to_cnc: 'warning',
  processed: 'secondary',
  paid: 'secondary',
  blank: 'primary',
  'Ready for employee': 'warning',
  'Ready for dept head': 'warning',
  'Ready for payroll acct': 'warning',
  Draft: 'primary',
  'Ready for approver 1': 'warning',
  'Ready for approver 3': 'warning',
  'Ready for approver 2': 'warning',
  'Ready for me': 'warning',
  Incomplete: 'primary',
  Rejected: 'primary',
  Paid: 'secondary',
  Processing: 'secondary',
  'Submitted to C&C': 'secondary',
};

export const PA_STATUS_TO_LABEL_MAP = {
  incomplete: 'Incomplete',
  draft: 'Draft',
  rejected: 'Incomplete',
  pending_employee_review: 'Submitted for Approval',
  pending_dh_review: 'Submitted for Approval',
  pending_upm_review: 'Submitted for Approval',
  pending_pa_review: 'Ready for Review',
  upm_rejected: 'Ready for Review',
  sent_to_cnc: 'Submitted to C&C',
  processed: 'Processed',
  paid: 'Paid',
  blank: 'Blank',
};

export const PA_STATUS_TO_ACTION_MAP = {
  incomplete: TIMECARD_EDITABLE_OPTIONS,
  rejected: TIMECARD_EDITABLE_OPTIONS,
  pending_pa_review: [
    'account-codes',
    'rerate',
    'comments',
    'general-notes',
    'allowances',
    'location',
  ],
};

// Department Head
export const DH_STATUS_TO_BADGE_MAP = {
  incomplete: 'primary',
  draft: 'primary',
  rejected: 'primary',
  pending_employee_review: 'warning',
  pending_dh_review: 'warning',
  pending_pa_review: 'warning',
  pending_upm_review: 'warning',
  upm_rejected: 'warning',
  sent_to_cnc: 'warning',
  processed: 'secondary',
  paid: 'secondary',
  blank: 'primary',
  'Ready for employee': 'warning',
  'Ready for dept head': 'warning',
  'Ready for payroll acct': 'warning',
  Draft: 'primary',
  'Ready for approver 1': 'warning',
  'Ready for approver 3': 'warning',
  'Ready for approver 2': 'warning',
  'Ready for me': 'warning',
  Incomplete: 'primary',
  Rejected: 'primary',
  Paid: 'secondary',
  Processing: 'secondary',
  'Submitted to C&C': 'secondary',
};

export const DH_STATUS_TO_LABEL_MAP = {
  incomplete: 'Incomplete',
  draft: 'Draft',
  rejected: 'Incomplete',
  pending_employee_review: 'Submitted for Approval',
  pending_dh_review: 'Ready for Review',
  pending_pa_review: 'Submitted for Approval',
  pending_upm_review: 'Submitted for Approval',
  upm_rejected: 'Submitted for Approval',
  sent_to_cnc: 'Submitted to C&C',
  processed: 'Processed',
  paid: 'Paid',
  blank: 'Blank',
};

export const DH_STATUS_TO_ACTION_MAP = {
  incomplete: TIMECARD_EDITABLE_OPTIONS,
  rejected: TIMECARD_EDITABLE_OPTIONS,
  draft: TIMECARD_EDITABLE_OPTIONS,
  pending_dh_review: [
    'account-codes',
    'rerate',
    'comments',
    'general-notes',
    'allowances',
    'location',
  ],
};

// Employee
//
export const EMPLOYEE_STATUS_TO_BADGE_MAP = {
  incomplete: 'primary',
  rejected: 'primary',
  pending_dh_review: 'warning',
  pending_pa_review: 'warning',
  pending_upm_review: 'warning',
  upm_rejected: 'warning',
  pending_employee_review: 'warning',
  sent_to_cnc: 'warning',
  processed: 'secondary',
  paid: 'secondary',
};

export const EMPLOYEE_STATUS_TO_LABEL_MAP = {
  incomplete: 'Incomplete',
  rejected: 'Incomplete',
  pending_dh_review: 'Submitted for Approval',
  pending_pa_review: 'Submitted for Approval',
  pending_upm_review: 'Submitted for Approval',
  upm_rejected: 'Submitted for Approval',
  pending_employee_review: 'Ready for me',
  sent_to_cnc: 'Submitted to C&C',
  processed: 'Processed',
  paid: 'Paid',
};

export const EMPLOYEE_STATUS_TO_ACTION_MAP = {
  incomplete: TIMECARD_EDITABLE_OPTIONS,
  rejected: TIMECARD_EDITABLE_OPTIONS,
};

export const BY_ROLE = {
  upm: {
    label: UPM_STATUS_TO_LABEL_MAP,
    badge: UPM_STATUS_TO_BADGE_MAP,
    editableFields: UPM_STATUS_TO_ACTION_MAP,
  },
  payroll_accountant: {
    label: PA_STATUS_TO_LABEL_MAP,
    badge: PA_STATUS_TO_BADGE_MAP,
    editableFields: PA_STATUS_TO_ACTION_MAP,
  },
  department_head: {
    label: DH_STATUS_TO_LABEL_MAP,
    badge: DH_STATUS_TO_BADGE_MAP,
    editableFields: DH_STATUS_TO_ACTION_MAP,
  },
  employee: {
    label: EMPLOYEE_STATUS_TO_LABEL_MAP,
    badge: EMPLOYEE_STATUS_TO_BADGE_MAP,
    editableFields: EMPLOYEE_STATUS_TO_ACTION_MAP,
  },
};

export const TIMECARD_PROCESSING = 'processing';
export const TIMECARD_INCOMPLETE = 'incomplete';
export const TIMECARD_DRAFT = 'draft';
export const TIMECARD_REJECTED = 'rejected';
export const TIMECARD_PENDING_EMP_REVIEW = 'pending_employee_review';
export const TIMECARD_READY_FOR_ME = 'Ready for me';

export const TIMECARD_PENDING_STATUS = [
  TIMECARD_PROCESSING,
  TIMECARD_INCOMPLETE,
  TIMECARD_PENDING_EMP_REVIEW,
  TIMECARD_DRAFT,
];

export const WTC_PA_DELETABLE_TIMECARD_STATUSES = [
  'pending_dh_review',
  'pending_pa_review',
  'pending_upm_review',
  'pending_employee_review',
  'incomplete',
  'draft',
];

export const TIMECARD_HISTORY_STATUS = [
  'pending_dh_review',
  'pending_pa_review',
  'pending_upm_review',
  'sent_to_cnc',
  'processed',
  'paid',
];

export const REJECTION_FLOW_STANDARD = 'standard';
export const REJECTION_FLOW_NO_EMPLOYEE_APPROVAL =
  'no_employee_approval_required';
export const REJECTION_FLOW_PROCESS_AS_REJECTED = 'process_as_rejected';

export const WEEK_DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const DEFAULT_WORKABLE_DAYS = 5;
export const DEFAULT_WEEK_START = 'Monday';

export const statusByRole = (role, currentLevel, userLevels) => {
  var options = BY_ROLE[role] || {
    label: STATUS_TO_LABEL_MAP,
    badge: STATUS_TO_BADGE_MAP,
  };

  // if user is UPM, let's check if the currentLevel falls into his
  // level setup (which might be more that one level, even in the
  // same project)
  if (role === 'upm' && currentLevel && userLevels) {
    userLevels = _.map(userLevels, userLevel => userLevel.position);

    if (userLevels.indexOf(currentLevel) === -1) {
      options = JSON.parse(JSON.stringify(options));
      options.label.pending_upm_review = 'Submitted for Approval';
    }
  }

  return options;
};

export const DATE_FORMAT = 'MM-DD-YYYY';

export const LANDING_PAGE_BY_ROLE = (role, projectId) => {
  let url = role === 'admin' ? '/admin/projects' : '/projects';

  if (projectId) {
    switch (role) {
      case EMPLOYEE:
        url = `/projects/${projectId}/me/timecards`;
        break;
      case UPM:
      case DH:
        url = `/projects/${projectId}/review/search-timecards`;
        break;
      case PA:
        url = `/projects/${projectId}/review/search-timecards`;
        break;
      case 'admin':
        url = `/admin/projects/${projectId}/crew-list`;
        break;
      case IA:
        url = `/projects/${projectId}/search-invoices`;
        break;
      case PROJECT_ADMIN:
        url = `/projects/${projectId}/settings`;
        break;
      default:
        break;
    }
  }

  return url;
};
export const REVIEW_TABS_BY_ROLE = (role, projectId) => {
  let tabDefination = [];
  switch (role) {
    case EMPLOYEE:
      break;
    case UPM:
    case DH:
      tabDefination.push({
        key: 'pending',
        caption: 'Pending',
        icon: 'PendingIcon',
        linkTo: `/projects/${projectId}/me/review/batches/pending`,
        defaultActive: false,
      });
      tabDefination.push({
        key: 'ready',
        caption: 'Ready for Review',
        icon: 'ReadyIcon',
        linkTo: `/projects/${projectId}/me/review/batches/ready`,
        defaultActive: true,
      });
      tabDefination.push({
        key: 'history',
        caption: 'History',
        icon: 'HistoryIcon',
        linkTo: `/projects/${projectId}/me/review/batches/history`,
        defaultActive: false,
      });
      break;
    case PA:
      tabDefination.push({
        key: 'open',
        caption: 'Ready for Review',
        icon: 'PendingIcon',
        linkTo: `/projects/${projectId}/me/review/open`,
        defaultActive: true,
      });
      tabDefination.push({
        key: 'complete',
        caption: 'Reviewed',
        icon: 'ReadyIcon',
        linkTo: `/projects/${projectId}/me/review/complete`,
        defaultActive: false,
      });
      break;
    default:
      break;
  }
  return tabDefination;
};

export const DATE_FORMAT_DISPLAY = 'MM-DD-YYYY';
export const DATE_FORMAT_HOUR_MIN = 'MM-DD-YYYY HH:mm';

export const DEFAUL_PAGE_SIZE = 50;

export const HISTORY_ACTION_TO_STATUS = {
  create: 'primary',
  update: 'primary',
  reject: 'error',
  approve: 'secondary',
  submit: 'warning',
  modified: 'primary',
  corrected: 'error',
  resubmitToEmployee: 'error',
  recalled: 'error',
  acctCodesChanged: 'primary',
};

export const HISTORY_ACTION_TO_LABEL_MAP = {
  create: 'Created',
  update: 'Modified',
  reject: 'Rejected',
  approve: 'Approved',
  submit: 'Submitted For Approval',
  modified: 'Modified',
  corrected: 'Corrections Made',
  resubmitToEmployee: 'Resubmitted to Employee',
  recalled: 'Recalled',
  acctCodesChanged: 'Acct Codes Changed',
};

export const TIMECARD_STATUS_INCOMPLETE = 'incomplete';

export const URL_MY_TIMECARD_FINISH = (projectId, id) =>
  `/projects/${projectId}/me/timecardsv1/${id}?finishTimecard=true`;
export const URL_MY_TIMECARD_REVIEW = (projectId, id, approvalFlowId) =>
  `/projects/${projectId}/me/timecardsv1/${id}/approval-flows/${approvalFlowId}/review?finishTimecard=true`;
export const URL_MY_TIMECARD_HISTORY = (projectId, id) =>
  `/projects/${projectId}/me/timecardsv1/${id}/review?fromHistory=true`;

export const DEFAULT_MILITARY_TIME_ENTRY_ROUNDING = '0.10';
export const DEFAULT_TIME_ENTRY_ROUNDING = '6';

export const IDLE_TIMEOUT = 25 * 60; // 25 minutes
export const IDLE_COUNTDOWN = 5 * 60; // 5 minutes
export const BULK_EDIT_PENDING_CALC_TIMEOUT = 1000 * 60 * 14; // 14 minutes
export const BULK_EDIT_PENDING_CALC_IGNORE_TIMEOUT = 1000 * 60; // 1 minute
export const BULK_EDIT_MAX_RESULTS = 150;

//these are the timezone names that are stored in in the Backend. They are different from moment's timezone names
export const BACKEND_TIMEZONES = {
  gmtStandardTime: 'GMT Standard Time',
  hawaiianStandardTime: 'Hawaiian Standard Time',
  alaskanStandardTime: 'Alaskan Standard Time',
  pacificStandardTime: 'Pacific Standard Time',
  usMountainStandardTime: 'US Mountain Standard Time',
  mountainStandardTime: 'Mountain Standard Time',
  centralStandardTime: 'Central Standard Time',
  easternStandardTime: 'Eastern Standard Time',
  usEasternStandardTime: 'US Eastern Standard Time',
};

export const TIMEZONE_OPTIONS = [
  {
    label: 'GMT Greenwich Mean Time',
    value: BACKEND_TIMEZONES.gmtStandardTime,
    moment: 'UTC',
    lbl: 'UTC',
  },
  {
    label: 'HST Hawaii Standard Time. GMT-10:00',
    value: BACKEND_TIMEZONES.hawaiianStandardTime,
    moment: 'US/Hawaii',
    lbl: 'HST',
  },
  {
    label: 'AST Alaska Standard Time. GMT-9:00',
    value: BACKEND_TIMEZONES.alaskanStandardTime,
    moment: 'America/Anchorage',
    lbl: 'AST',
  },
  {
    label: 'PST Pacific Standard Time. GMT-8:00',
    value: BACKEND_TIMEZONES.pacificStandardTime,
    moment: 'US/Pacific',
    lbl: 'PST',
  },
  {
    label: 'PNT Phoenix Standard Time. GMT-7:00',
    value: BACKEND_TIMEZONES.usMountainStandardTime,
    moment: 'America/Phoenix',
    lbl: 'PNT',
  },
  {
    label: 'MST Mountain Standard Time. GMT-7:00',
    value: BACKEND_TIMEZONES.mountainStandardTime,
    moment: 'US/Mountain',
    lbl: 'MST',
  },
  {
    label: 'CST Central Standard Time. GMT-6:00',
    value: BACKEND_TIMEZONES.centralStandardTime,
    moment: 'US/Central',
    lbl: 'CST',
  },
  {
    label: 'EST Eastern Standard Time. GMT-5:00',
    value: BACKEND_TIMEZONES.easternStandardTime,
    moment: 'US/Eastern',
    lbl: 'EST',
  },
  {
    label: 'IET Indiana Eastern Standard Time. GMT-5:00',
    value: BACKEND_TIMEZONES.usEasternStandardTime,
    moment: 'US/East-Indiana',
    lbl: 'IET',
  },
];

export const ALLOWANCE_DOC_MAX_SIZE = 10240000;

export const RESTRICTED_FILE_UPLOAD_TYPE = ['image/webp'];
// To-do: user could still hack file extension to upload
// need to find ways to really check file properly
export const PERMITTED_FILE_UPLOAD_TYPE = [
  'image/jpeg',
  'application/pdf',
  'image/png',
];

//Length account codes are allowed to be when no mask is given
export const ACCOUNT_CODE_MAX_LENGTH = 26;
export const ACCOUNT_CODE_MASK_ALLOWED_CHARACTERS = /^[~.#AaXx-]+$/;

export const NO_BLANK_ALLOWED_FIELDS = [
  'series',
  'location',
  'set',
  'insurance',
  'freeField1',
  'freeField2',
  'freeField3',
  'freeField4',
];

export const BATCH_REVIEW_DEFAULT_PAGE_SIZE = 20;

export const INPUT_FIELD_CHARACTER_LIMIT = {
  emailBody: 2000,
  department: 50,
  comments: 1024,
  templateName: 50,
};

export const REQUIRED_TIME_DAY_TYPES = [
  {
    //we only use code, but leaving the other data in for reference
    code: '1',
    id: '17111001-f785-4029-8062-83fe48d4d979',
    name: 'WORK',
    requiresTimes: true,
    splittable: true,
    type: null,
  },
  {
    code: '6',
    id: '15d32a1a-14c9-4188-b883-423a8392053a',
    name: 'Travel Only',
    requiresTimes: true,
    splittable: true,
    type: null,
  },
  {
    id: '230bb127-4c1f-4e65-80c8-b91d840d375d',
    code: '14',
    name: 'Work/Travel',
    requiresTimes: true,
    splittable: false,
    type: null,
  },
];

export const NON_TIME_DAY_TYPES = [
  {
    id: '08398d88-3c9c-4785-8c1f-e233a675441b',
    code: '4',
    name: 'HNW',
    type: null,
    splittable: true,
    requiresTimes: false,
  },
  {
    id: '3e6528f8-2f00-41d9-b116-0d967dc96be5',
    code: 'DGA6NW-Prep',
    name: 'DGA6NW-Prep',
    type: null,
    splittable: false,
    requiresTimes: false,
  },
  {
    id: '6bfe0c90-bb93-4fe0-acf2-53c9e7ce5e99',
    code: 'DGA6NW-Shoot',
    name: 'DGA6NW-Shoot',
    type: null,
    splittable: false,
    requiresTimes: false,
  },
  {
    id: 'cdb432e8-a48c-412a-b92a-889f77eb94de',
    code: 'DGA7NW-Prep',
    name: 'DGA7NW-Prep',
    type: null,
    splittable: false,
    requiresTimes: false,
  },
  {
    id: 'f59c2291-2f71-4ed3-9f11-96cdaafb7c29',
    code: 'DGA7NW-Shoot',
    name: 'DGA7NW-Shoot',
    type: null,
    splittable: false,
    requiresTimes: false,
  },
  {
    id: '109c9441-6041-4a6f-b9a1-24a829732bda',
    code: 'MIN',
    name: 'Min Call - No times',
    type: null,
    splittable: true,
    requiresTimes: false,
  },
  {
    id: 'c0c9caae-c575-4da9-b69e-8e0046622fc3',
    code: 'HOLD',
    name: 'Hold',
    type: null,
    splittable: true,
    requiresTimes: false,
  },
  {
    id: 'ceb5d66d-7dd2-4806-b4fc-336b204b8ba9',
    code: '11',
    name: 'HOA',
    type: null,
    splittable: true,
    requiresTimes: false,
  },
  // {
  //   id: '165dd849-cd48-408b-8bea-bbcb7e23f2c7',
  //   code: '2',
  //   name: 'Idle',
  //   type: null,
  //   splittable: true,
  //   requiresTimes: false,
  // },
  // {
  //   id: 'c3459cc1-aad0-4e5a-800d-39c7d78d6adf',
  //   code: '3',
  //   name: 'Sick',
  //   type: null,
  //   splittable: true,
  //   requiresTimes: false,
  // },
  // {
  //   id: '5aee359a-2af5-4433-947a-317f4f29b0e3',
  //   code: 'VAC',
  //   name: 'Vacation',
  //   type: null,
  //   splittable: true,
  //   requiresTimes: false,
  // },
  // {
  //   id: '74fc22df-7734-44e9-b780-8f55ca9760c2',
  //   code: 'Bereavement',
  //   name: 'Bereavement',
  //   type: null,
  //   splittable: true,
  //   requiresTimes: false,
  // },
  // {
  //   id: '16a12871-9ada-43bd-b677-a2e4da4abc50',
  //   code: 'JURYDUTY',
  //   name: 'Jury Duty HTG',
  //   type: null,
  //   splittable: true,
  //   requiresTimes: false,
  // },
];
export const US_CURRENCY_TEXT = 'USD';
export const CANADIAN_CURRENCY_TEXT = 'CAN';

export const ENTRY_HEADER_REGEX =
  /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/;

//taken from /HoursPlus.Entity/Enumeration/PayrollCompanyType.cs
export const PAYROLL_COMPANY_CODE_TO_TYPE = {
  CP: 'Union and Non-Affiliate',
  CES: 'Other',
  BT: 'Non-Union',
  CT: 'Union Above-the-Line',
  PS: 'Union',
  TPX: 'Select Union and NU',
  CCAP: 'Union Local 399',
  CD: 'Union Local 33',
  ASNY: 'Union and NU - NY',
  CCC: 'General',
};

export const ACCOUNT_FIELDS_MAP = [
  { name: 'accountCode', dealName: 'wageAccount' },
  { name: 'episode', dealName: 'episode' },
  { name: 'series', dealName: 'series' },
  { name: 'location', dealName: 'location' },
  { name: 'set', dealName: 'set' },
  { name: 'insurance', dealName: 'insurance' },
  { name: 'freeField1', dealName: 'customField1' },
  { name: 'freeField2', dealName: 'customField2' },
  { name: 'freeField3', dealName: 'customField3' },
  { name: 'freeField4', dealName: 'customField4' },
];

export const ProjectTypes = [
  {
    index: 0,
    label: 'Hours+ Enabled',
    selected: false,
    value: 'timePlusEnabled',
  },
];

export const NAVS = [
  {
    label: 'Project Settings',
    path: '/admin/projects/:projectId/settings',
  },
  {
    label: 'Crew List',
    path: '/admin/projects/:projectId/crew-list',
  },
  {
    label: 'Departments & Approvers',
    path: '/admin/projects/:projectId/review-flows',
  },
  {
    label: 'Templates',
    path: '/admin/projects/:projectId/templates',
  },
  {
    label: 'Audit Log',
    path: '/admin/projects/:projectId/audit-logs',
  },
  {
    label: 'My Timecards',
    path: '/projects/:projectId/me/timecards',
  },
  {
    label: 'Daily Timesheets',
    path: '/projects/:projectId/daily-timesheets',
  },
  {
    label: 'Crew Timecards',
    path: '/projects/:projectId/timecards',
  },
  {
    label: 'Timecards',
    path: '/projects/:projectId/review/search-timecards',
  },
  {
    label: 'Bulk Edit',
    path: '/projects/:projectId/review/bulk-edit',
  },
  {
    label: 'Reviews',
    path: '/projects/:projectId/me/review',
  },
  {
    label: 'Invoices',
    path: '/projects/:projectId/search-invoices',
  },
  {
    label: 'Reports',
    path: '/projects/:projectId/reports/onDemand',
  },
  {
    label: 'Crew List',
    path: '/projects/:projectId/crew-list',
  },
];

export const ADJUSTMENT_URL =
  window.location.origin === 'https://hoursplus.castandcrew.com'
    ? 'https://my.castandcrew.com/adjustment-request'
    : 'https://mycnc-web-qa1.dev.aws.castandcrew.com/adjustment-request';
