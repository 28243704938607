import React from 'react';

import { Header, ProjectHeader, MaxWidthLayout } from 'containers/Layout';

//subnav
import { Reviews } from 'containers/Nav/Pages';

//components
const Open = React.lazy(() =>
  import('containers/Employees/Reviews/PayrollAccountant/Open'),
);
const Complete = React.lazy(() =>
  import('containers/Employees/Reviews/PayrollAccountant/Complete'),
);
const Ready = React.lazy(() =>
  import('containers/Employees/Reviews/Reviewer/Ready'),
);
const History = React.lazy(() =>
  import('containers/Employees/Reviews/Reviewer/History'),
);
const Pending = React.lazy(() =>
  import('containers/Employees/Reviews/Reviewer/Pending'),
);

const projectReviewsRoutes = [
  {
    title: 'Reviews',
    path: '/projects/:projectId/me/review/open',
    headers: [Header, ProjectHeader, Reviews],
    layout: MaxWidthLayout,
    component: Open,
    waitForUserToLoad: true,
  },
  {
    title: 'Reviews',
    path: '/projects/:projectId/me/review/complete',
    headers: [Header, ProjectHeader, Reviews],
    layout: MaxWidthLayout,
    component: Complete,
    waitForUserToLoad: true,
  },
  {
    title: 'Reviews',
    path: '/projects/:projectId/me/review/batches/Pending',
    headers: [Header, ProjectHeader, Reviews],
    layout: MaxWidthLayout,
    component: Pending,
    waitForUserToLoad: true,
  },
  {
    title: 'Reviews',
    path: '/projects/:projectId/me/review/batches/History',
    headers: [Header, ProjectHeader, Reviews],
    layout: MaxWidthLayout,
    component: History,
    waitForUserToLoad: true,
  },
  {
    title: 'Reviews',
    path: '/projects/:projectId/me/review/batches/Ready',
    headers: [Header, ProjectHeader, Reviews],
    layout: MaxWidthLayout,
    component: Ready,
    waitForUserToLoad: true,
  },
];

export default projectReviewsRoutes;
