import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Button,
  Checkbox,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  LinearProgress,
  FormControlLabel,
  Box,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ProjectSettingRow from './ProjectSettingRow';
import SortArrows from './ProjectAllowanceSortArrow';

const useStyles = makeStyles(({ palette }) => ({
  button: {
    margin: 20,
  },
  noPadding: {
    padding: 0,
  },
  heading: {
    fontSize: '1.1em',
    fontFamily: 'inherit',
  },
  tableHead: {
    background: palette.misc.tableHeader,
    textTransform: 'uppercase',
    color: palette.text.primary,
    fontWeight: 700,
    '& > *': {
      height: 50,
    },
  },
  center: {
    textAlign: 'center',
  },
  settingsTitle: {
    cursor: 'pointer',
  },
  selectAllLabel: {
    fontWeight: '400',
    fontSize: '14px',
  },
  autoCheckBoxes: {
    display: 'flex',
    padding: '16px 32px',
    flexDirection: 'column',
    color: palette.text.secondary,
  },
  boxes: {
    display: 'flex',
    padding: '0px 32px',
    gap: 16,
  },
  checkboxBox: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const ProjectAllowances = props => {
  const {
    projectAllowancesList,
    sortProjectAllowances,
    selectAll,
    loading,
    settings,
    onUpdate,
    onUpdateProjectAllowance,
    onUpdateIndividual,
  } = props;

  const classes = useStyles();

  const [allVisible, setAllVisible] = useState(false);
  const [allMandatory, setAllMandatory] = useState(false);
  const [filterSelected, setFilterSelected] = useState({
    currentKey: 'description',
    direction: 'asc',
  });

  const setCurrent = (currentKey, direction) => {
    setFilterSelected({
      currentKey,
      direction,
    });
  };

  const setFromLabel = (inputKey, direction, sortBoolean) => {
    const currentDirection =
      inputKey === filterSelected.currentKey && direction === 'asc'
        ? 'desc'
        : 'asc';

    setFilterSelected({
      currentKey: inputKey,
      direction: currentDirection,
    });

    sortProjectAllowances(inputKey, currentDirection, sortBoolean);
  };

  return (
    <div style={{ marginBottom: 30 }}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Allowances</Typography>
        </AccordionSummary>
        <Divider />
        {loading && <LinearProgress />}
        <AccordionDetails className={classes.noPadding}>
          <div style={{ width: '100%' }}>
            <Box className={classes.autoCheckBoxes}>
              <Box sx={{ fontSize: '24px' }}>
                Auto-Populate Allowance From Deal Memo Enabled For
              </Box>
              <div className={classes.boxes}>
                <Box className={classes.checkboxBox}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={settings?.myTimecardAutoAllowances || false}
                        onChange={(_, checked) =>
                          onUpdateIndividual(
                            checked,
                            'myTimecardAutoAllowances',
                          )
                        }
                        color="primary"
                      />
                    }
                    label="My Timecard"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={settings?.crewTimecardAutoAllowances || false}
                        onChange={(_, checked) =>
                          onUpdateIndividual(
                            checked,
                            'crewTimecardAutoAllowances',
                          )
                        }
                        color="primary"
                      />
                    }
                    label="Crew Timecard"
                  />
                </Box>
                <Box className={classes.checkboxBox}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={settings?.dtsTimecardAutoAllowances || false}
                        onChange={(_, checked) =>
                          onUpdateIndividual(
                            checked,
                            'dtsTimecardAutoAllowances',
                          )
                        }
                        color="primary"
                      />
                    }
                    label="Daily Timesheets"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={settings?.bulkEditAutoAllowances || false}
                        onChange={(_, checked) =>
                          onUpdateIndividual(checked, 'bulkEditAutoAllowances')
                        }
                        color="primary"
                      />
                    }
                    label="Bulk Edit"
                  />
                </Box>
              </div>
            </Box>

            <Button
              onClick={() => {
                const saveBtn = document.getElementById(
                  'adminProjectAllowanceSaveSettings',
                );
                saveBtn.scrollIntoView({ behavior: 'smooth' });
              }}
            >
              Jump to save
            </Button>
            <Table>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell>
                    <span
                      className={classes.settingsTitle}
                      onClick={() =>
                        setFromLabel(
                          'description',
                          filterSelected.direction,
                          false,
                        )
                      }
                    >
                      Reimbursement Type
                    </span>
                    <SortArrows
                      sortKey={'description'}
                      sortBoolean={false}
                      sort={sortProjectAllowances}
                      setCurrent={setCurrent}
                      selected={filterSelected}
                    />
                  </TableCell>
                  <TableCell>
                    <span
                      className={classes.settingsTitle}
                      onClick={() =>
                        setFromLabel('code', filterSelected.direction, false)
                      }
                    >
                      Pay Code
                    </span>
                    <SortArrows
                      sortKey={'code'}
                      sortBoolean={false}
                      sort={sortProjectAllowances}
                      setCurrent={setCurrent}
                      selected={filterSelected}
                    />
                  </TableCell>
                  <TableCell className={classes.center}>
                    <span
                      className={classes.settingsTitle}
                      onClick={() =>
                        setFromLabel(
                          'employeeVisible',
                          filterSelected.direction,
                          true,
                        )
                      }
                    >
                      Visible for Crew
                    </span>
                    <SortArrows
                      sortKey={'employeeVisible'}
                      sortBoolean={true}
                      sort={sortProjectAllowances}
                      setCurrent={setCurrent}
                      selected={filterSelected}
                    />
                  </TableCell>
                  <TableCell className={classes.center}>
                    <span
                      className={classes.settingsTitle}
                      onClick={() =>
                        setFromLabel(
                          'employeeMandatory',
                          filterSelected.direction,
                          true,
                        )
                      }
                    >
                      Supporting Doc Required
                    </span>
                    <SortArrows
                      sortKey={'employeeMandatory'}
                      sortBoolean={true}
                      sort={sortProjectAllowances}
                      setCurrent={setCurrent}
                      selected={filterSelected}
                    />
                  </TableCell>
                  <TableCell className={classes.center}>
                    Auto-Apply Day Types
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell className={classes.center}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => {
                            selectAll('allVisible');
                            setAllVisible(!allVisible);
                          }}
                          icon={
                            <CheckBoxOutlineBlankOutlinedIcon color="primary" />
                          }
                          checkedIcon={<CheckBoxOutlinedIcon color="primary" />}
                        />
                      }
                      label={
                        <p className={classes.selectAllLabel}>Select all</p>
                      }
                    />
                  </TableCell>
                  <TableCell className={classes.center}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => {
                            selectAll('allMandatory');
                            setAllMandatory(!allMandatory);
                          }}
                          icon={
                            <CheckBoxOutlineBlankOutlinedIcon color="primary" />
                          }
                          checkedIcon={<CheckBoxOutlinedIcon color="primary" />}
                        />
                      }
                      label={
                        <p className={classes.selectAllLabel}>Select all</p>
                      }
                    />
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {projectAllowancesList.map((row, index) => {
                  return (
                    <ProjectSettingRow
                      key={`${row.id}`}
                      index={index}
                      centerClass={classes.center}
                      onUpdateProjectAllowance={onUpdateProjectAllowance}
                    />
                  );
                })}
              </TableBody>
            </Table>

            {loading && <LinearProgress />}
          </div>
        </AccordionDetails>
        <AccordionActions className={classes.noPadding}>
          <Button
            id="adminProjectAllowanceSaveSettings"
            variant="contained"
            color="primary"
            disabled={loading}
            className={classes.button}
            onClick={onUpdate}
          >
            Save Settings
          </Button>
        </AccordionActions>
      </Accordion>
    </div>
  );
};

ProjectAllowances.propTypes = {
  projectAllowancesList: PropTypes.array.isRequired,
  sortProjectAllowances: PropTypes.func.isRequired,
  selectAll: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpdateProjectAllowance: PropTypes.func.isRequired,
  onUpdateIndividual: PropTypes.func.isRequired,
};

export default ProjectAllowances;
