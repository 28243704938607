import moment from './moment';
import _ from 'lodash';
import {
  WEEK_DAYS,
  DEFAULT_WORKABLE_DAYS,
  DEFAULT_WEEK_START,
  ACCOUNT_CODE_MAX_LENGTH,
  ACCOUNT_CODE_MASK_ALLOWED_CHARACTERS,
  NON_TIME_DAY_TYPES,
  REQUIRED_TIME_DAY_TYPES,
} from 'components/Shared/constants';

import { db } from 'utils/helperFunctions';

export const getCurrentWeekending = weekEndings => {
  const date = _.find(weekEndings, weekEnding => {
    return moment(weekEnding.endsOn).isBetween(moment(), moment().add(1, 'w'));
  });

  return date ? moment(date.endsOn).format('MM-DD-YYYY') : '';
};

export const getTotalHours = (timecard, activeDays = []) => {
  const dayArray = Object.keys(activeDays);
  let dayIndex = 0;
  const totalHours = _.reduce(
    timecard.days,
    (hours, day) => {
      const dayString = dayArray[dayIndex];
      if (activeDays[dayString] === true) hours += calculateDayHours(day);
      dayIndex++;
      return hours;
    },
    0,
  );

  return totalHours ? +parseFloat(totalHours).toFixed(2) : 0;
};

export const calculateDayHours = day => {
  var order = ['call'],
    hours = 0;

  if (day.htgDayTypeId) {
    _.times(3, index => {
      var inKey = `meal${index + 1}In`,
        outKey = `meal${index + 1}Out`;

      if (day[inKey] && day[outKey]) {
        order = order.concat([outKey, inKey]);
      }
    });

    order.push('wrap');

    while (order.length) {
      var fields = order.splice(0, 2);
      if ((day[fields[0]] || day[fields[0]] === 0) && day[fields[1]]) {
        hours += calculateHoursDiff(day[fields[0]], day[fields[1]]);
      }
    }
  }

  return hours;
};

export const calculateHoursDiff = (start, end, format = 'HH:mm') => {
  var hours = 0;

  if ((start || start === 0) && end) {
    hours = end - start;
  }

  // +24 is due to day "overflow"
  // like when in time is 20:00 and out time is 4:00
  return hours;
  // return hours < 0 ? hours + 24 : hours;
};

export const currentWorkWeek = (project, startsOn) => {
  let workWeek = null;

  if (project && project.producerWorkWeeks) {
    startsOn = moment(startsOn);

    workWeek = _.chain(project.producerWorkWeeks)
      .map(week => {
        const effectiveDate = week.effectiveDate
          ? moment(week.effectiveDate)
          : null;
        const expirationDate = week.expirationDate
          ? moment(week.expirationDate)
          : null;

        return {
          weekDay: week.weekDay,
          expirationDate,
          effectiveDate,
        };
      })
      .filter(workWeek => {
        return (
          (!workWeek.effectiveDate || workWeek.effectiveDate <= startsOn) &&
          (!workWeek.expirationDate || workWeek.expirationDate >= startsOn)
        );
      });
  }

  workWeek = _.isObject(workWeek) ? workWeek.weekDay : DEFAULT_WEEK_START;

  return {
    starts: workWeek,
    ends: WEEK_DAYS[
      (WEEK_DAYS.indexOf(workWeek) + DEFAULT_WORKABLE_DAYS - 1) %
        WEEK_DAYS.length
    ],
  };
};

export const formatDate = (date, format = 'MM-DD-YYYY') =>
  moment(date).format(format);

const dealMemoDaysBlocked = timecard => {
  const blockedDays = {};
  if (timecard && timecard.dealMemo) {
    const starts = moment.utc(
      timecard.dealMemo.start || timecard.dealMemo.startsOn,
    );
    const ends = moment.utc(timecard.dealMemo.end || timecard.dealMemo.endsOn);

    _.each(timecard.days, day => {
      const date = moment.utc(day.date);
      blockedDays[formatDate(date)] =
        date.isBefore(starts) || date.isAfter(ends);
    });
  }

  return blockedDays;
};

export const workableDays = workWeek => {
  const daysInWeek = WEEK_DAYS.length,
    workableDays = [];
  let startIndex = WEEK_DAYS.indexOf(workWeek.starts);

  _.times(DEFAULT_WORKABLE_DAYS, () => {
    workableDays.push(startIndex % daysInWeek);
    startIndex += 1;
  });

  return workableDays;
};

export const workableDay = (workWeek, dayDate) => {
  var dayIndex = WEEK_DAYS.indexOf(moment(dayDate).format('dddd'));

  return workableDays(workWeek).indexOf(dayIndex) > -1;
};

export const getActiveBlockedDays = (timecard, project) => {
  if (timecard && project) {
    const activeDays = {};
    const blockedDays = dealMemoDaysBlocked(timecard);
    const workWeek = currentWorkWeek(project, timecard.startsOn);
    const hasDaysData = _.find(timecard.days, day => !!day.htgDayTypeId);

    _.each(timecard.days, day => {
      const date = formatDate(day.date);
      activeDays[date] = hasDaysData
        ? !!day.htgDayTypeId || !!day.isActive
        : workableDay(workWeek, day.date);
      if (blockedDays[date]) {
        activeDays[date] = false;
      }
    });

    return { activeDays, blockedDays };
  }
  return {};
};

export const TIME_LABEL_FORMAT = 'h:mm';
export const FULL_TIME_FORMAT = 'HH:mm';
export const MINUTES_PER_DAY = 1440;
const FULL_TIME_LABEL_FORMAT = TIME_LABEL_FORMAT + ' A';
export const DEFAULT_TIME = '--:-- --';
export const TIME_FIELD_ORDER = [
  'call',
  'ndbIn',
  'meal1Out',
  'meal1In',
  'lastMan1In',
  'ndmOut',
  'ndmIn',
  'meal2Out',
  'meal2In',
  'meal3Out',
  'meal3In',
  'wrap',
];
export const parseGeneratedTime = value => {
  return value ? moment(value, FULL_TIME_FORMAT) : null;
};

export const generateTimes = (
  intervalInMinutes,
  startTime,
  endTime,
  useMilitaryTime,
  value,
  callTime,
) => {
  var times = null,
    selectedTime = value,
    selectedTimeLabel = value,
    existInOption = false,
    insertAt = 0,
    mins = MINUTES_PER_DAY;

  if (!startTime) {
    startTime = moment().startOf('day');
  }

  if (endTime) {
    if (endTime.isBefore(startTime)) {
      endTime.add(24, 'hours');
    }
    mins = endTime.diff(startTime, 'minutes');
  }

  //To check if the value is one of the options
  if (value) {
    existInOption = Number.isInteger(value.split(':')[1] / intervalInMinutes);
  }
  times = _(Math.ceil(mins / intervalInMinutes)).times(function (
    intervalIndex,
  ) {
    var time = startTime
      .floor(intervalInMinutes, 'minutes')
      .clone()
      .add(intervalIndex * intervalInMinutes, 'minutes');
    var timeLabel = useMilitaryTime
      ? time.format(FULL_TIME_FORMAT)
      : time.format(FULL_TIME_LABEL_FORMAT);
    let decimalValue;
    if (intervalIndex === 0) {
      var prevTime = time;
    } else {
      prevTime = startTime
        .floor(intervalInMinutes, 'minutes')
        .clone()
        .add((intervalIndex - 1) * intervalInMinutes, 'minutes');
    }

    if (!existInOption) {
      if (
        selectedTime &&
        moment(selectedTime, 'HH:mm').isBefore(time, 'HH:mm') &&
        moment(selectedTime, 'HH:mm').isAfter(prevTime, 'HH:mm')
      ) {
        selectedTimeLabel = useMilitaryTime
          ? moment(selectedTime, 'HH:mm').format(FULL_TIME_FORMAT)
          : moment(selectedTime, 'HH:mm').format(FULL_TIME_LABEL_FORMAT);
        insertAt = intervalIndex;
      }
    }

    let timeArr = timeLabel.split(':');
    let labelHour = parseInt(timeArr[0], 10);
    let decimalMinutes = timeArr[1] ? parseInt(timeArr[1], 10) : 0;
    decimalValue = labelHour + decimalMinutes / 60;

    if (
      labelHour < startTime.hour() ||
      (callTime && time.hour() < callTime.hour())
    ) {
      let newHour = 24 + labelHour;
      timeLabel = `${newHour}:${timeArr[1]}`;
      decimalValue = newHour + decimalMinutes / 60;
    }

    return {
      label: timeLabel,
      value: time.format(FULL_TIME_FORMAT),
      decimal: decimalValue,
    };
  });

  // we insert at the beginning if value does not exists
  // in options list
  if ((insertAt || !existInOption) && value) {
    times.splice(insertAt, 0, {
      label: selectedTimeLabel,
      value: selectedTime,
    });
  }

  return [{ label: DEFAULT_TIME, value: null }].concat(times);
};

export const doesDayAllowTimes = code => {
  const isNoTimeDayType = NON_TIME_DAY_TYPES.some(d => d.code === code);
  return !isNoTimeDayType;
};

export const doesDayRequireTimes = code => {
  if (code === undefined) return false;

  const dayType = _.find(REQUIRED_TIME_DAY_TYPES, type => type.code === code);
  return !!dayType;
};

export const ACCOUNT_CODES = [
  {
    name: 'accountCode',
    label: 'Account Code',
  },
  {
    name: 'episode',
    label: 'Episode',
  },
  {
    name: 'location',
    label: 'Location',
  },
  {
    name: 'insurance',
    label: 'Insurance',
  },
  {
    name: 'series',
    label: 'Series',
  },
  {
    name: 'set',
    label: 'Set',
  },
  {
    name: 'freeField1',
    label: 'FF1',
  },
  {
    name: 'freeField2',
    label: 'FF2',
  },
  {
    name: 'freeField3',
    label: 'FF3',
  },
  {
    name: 'freeField4',
    label: 'FF4',
  },
];

export const ACCOUNT_CODES_MAP = _(ACCOUNT_CODES).reduce((memo, value) => {
  memo[value.name] = value.label;
  return memo;
}, {});

export const enabledAccountCodes = (settings = {}, role) => {
  return _.chain(ACCOUNT_CODES_MAP)
    .map((_description, name) => {
      var visible = false;

      if (settings[name]) {
        if (role === 'employee') {
          visible = settings[name].employeeVisible;
        } else {
          visible = settings[name].crewVisible;
        }
      }

      return visible ? name : null;
    })
    .compact()
    .value();
};

// Account Code Masking
// Account Masking characters and their meaning.
// `.` - Digits, letter, period (.) or underscore (_)
// `~` - Digits, letter, period (.), underscore (_), or dash (-)
// `#` - Digits only.
// `A` - Letters only.
// `X` - Letters or Digits
// `-` - Represents a dash (-)
const maskMap = {
  '~': /[A-Za-z0-9._-]/,
  '.': /[A-Za-z0-9._]/,
  '#': /[0-9]/,
  A: /[A-Za-z]/,
  a: /[A-Za-z]/,
  X: /[A-Za-z0-9]/,
  x: /[A-Za-z0-9]/,
  '-': /-/,
};

export const validateAccountMask = mask => {
  //allowed characters for mask
  const regex = ACCOUNT_CODE_MASK_ALLOWED_CHARACTERS;

  if (!mask || !regex.test(mask)) {
    return '~'.repeat(ACCOUNT_CODE_MAX_LENGTH);
  }
  return mask;
};

/**
 * Create a masking function given a string mask value.
 * Intended to be use as a normalizing function
 * Invalid masks wil default to all allowed chars of max allowed length as defined
 * in ACCOUNT_CODE_MAX_LENGTH
 * @param {String} mask - valid mask
 * @returns {func(string)} function that takes a string and applies the mask to it
 */
export const makeMaskFunc = mask => {
  mask = validateAccountMask(mask);
  return function (input = [], previousValue) {
    let value = '';

    let maskPtr = 0; //mask Pointer
    let inputPtr = 0; //input pointer

    while (maskPtr < mask.length) {
      const maskChar = mask[maskPtr];
      const char = input[inputPtr];

      if (char === undefined || maskChar === undefined) break;

      //Add in - automatically if not there
      if (maskChar === '-') {
        if (char === '-') {
          inputPtr++;
        }
        maskPtr++;

        value += maskChar;
        continue;
      }

      if (maskMap[maskChar].test(char)) {
        value += char;
        maskPtr++;
      }
      inputPtr++;
    }

    return value;
  };
};

export const preventBlanks = (input, maxLength = -1) => {
  let value = '';
  let inputPtr = 0; //input pointer
  let length = maxLength === -1 ? input.length : maxLength;
  while (inputPtr < length) {
    const char = input[inputPtr];

    if (char === undefined) break;

    if (char === ' ') {
      inputPtr++;
      continue;
    }
    value += char;
    inputPtr++;
  }
  return value;
};

export const autoFill = (start, end) => {
  for (let i = start.length; start.length < end.length; i++) {
    start += '0';
  }
  return start;
};

export const maskAutoFill = (accountMask = '') => {
  return userInput => {
    const masks = (accountMask || '').split('-');
    const input = (userInput || '').split('-');
    const answer = masks
      .map((mask, i) => {
        let value = input[i] || '';
        if (value && value.length < mask.length) {
          return autoFill(value, mask);
        } else if (!value) {
          return autoFill(value, mask);
        } else {
          return value;
        }
      })
      .join('-');
    return answer;
  };
};

export const normalizePhone = (value, previousValue) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return onlyNums + '-';
    }
    if (onlyNums.length === 6) {
      return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3) + '-';
    }
  }

  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3);
  }
  return (
    onlyNums.slice(0, 3) +
    '-' +
    onlyNums.slice(3, 6) +
    '-' +
    onlyNums.slice(6, 10)
  );
};

export const normalizeSSN = (value, previousValue) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');

  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return onlyNums + '-';
    } else if (onlyNums.length === 5) {
      return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 5) + '-';
    } else if (onlyNums.length === 9) {
      return (
        onlyNums.slice(0, 3) +
        '-' +
        onlyNums.slice(3, 5) +
        '-' +
        onlyNums.slice(5, 9)
      );
    }
  }

  if (onlyNums.length <= 3) {
    return onlyNums;
  } else if (onlyNums.length <= 5) {
    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 5);
  } else if (onlyNums.length <= 9) {
    return (
      onlyNums.slice(0, 3) +
      '-' +
      onlyNums.slice(3, 5) +
      '-' +
      onlyNums.slice(5, 9)
    );
  }
};
export const normalizeSIN = (value, previousValue) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');

  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return onlyNums + '-';
    } else if (onlyNums.length === 6) {
      return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-';
    } else if (onlyNums.length === 9) {
      return (
        onlyNums.slice(0, 3) +
        '-' +
        onlyNums.slice(3, 6) +
        '-' +
        onlyNums.slice(6, 9)
      );
    }
  }

  if (onlyNums.length <= 3) {
    return onlyNums;
  } else if (onlyNums.length <= 6) {
    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6);
  } else if (onlyNums.length <= 9) {
    return (
      onlyNums.slice(0, 3) +
      '-' +
      onlyNums.slice(3, 6) +
      '-' +
      onlyNums.slice(6, 9)
    );
  }
};
export const TIMECARD_EDITABLE_NONE = [];

export const TIMECARD_EDITABLE_OPTIONS = [
  'location',
  'meals',
  'times',
  'account-codes',
  'allowances',
  'comments',
  'general-notes',
  'rerate',
];

export const UPM_STATUS_TO_ACTION_MAP = {
  pending_upm_review: ['general-notes'],
};

export const PA_STATUS_TO_ACTION_MAP = {
  incomplete: TIMECARD_EDITABLE_OPTIONS,
  rejected: TIMECARD_EDITABLE_OPTIONS,
  pending_pa_review: [
    'account-codes',
    'rerate',
    'comments',
    'general-notes',
    'allowances',
    'location',
  ],
};

export const DH_STATUS_TO_ACTION_MAP = {
  incomplete: TIMECARD_EDITABLE_OPTIONS,
  rejected: TIMECARD_EDITABLE_OPTIONS,
  draft: TIMECARD_EDITABLE_OPTIONS,
  pending_dh_review: [
    'account-codes',
    'rerate',
    'comments',
    'general-notes',
    'allowances',
    'location',
  ],
};

export const EMPLOYEE_STATUS_TO_ACTION_MAP = {
  incomplete: TIMECARD_EDITABLE_OPTIONS,
  rejected: TIMECARD_EDITABLE_OPTIONS,
  pending_employee_review: TIMECARD_EDITABLE_OPTIONS,
};

export const BY_ROLE = {
  upm: {
    editableFields: UPM_STATUS_TO_ACTION_MAP,
  },
  payroll_accountant: {
    editableFields: PA_STATUS_TO_ACTION_MAP,
  },
  department_head: {
    editableFields: DH_STATUS_TO_ACTION_MAP,
  },
  employee: {
    editableFields: EMPLOYEE_STATUS_TO_ACTION_MAP,
  },
};

export const getEditableFields = function (status, role) {
  if (_.isEmpty(status) || _.isEmpty(role)) return TIMECARD_EDITABLE_NONE;
  const editableByRole = BY_ROLE[role];
  return editableByRole
    ? editableByRole.editableFields[status]
    : TIMECARD_EDITABLE_NONE;
};

export const isFieldEnabled = (editableFields, field) => {
  return !!editableFields && editableFields.indexOf(field) >= 0;
};

export const allEditableFields = () => TIMECARD_EDITABLE_OPTIONS;

export const hourDifference = (start, end) => {
  return moment.duration(end.diff(start)).as('hours');
};

export const timeStringToFloat = time => {
  let hoursMinutes = time.split(/[.:]/);
  let hours = parseInt(hoursMinutes[0], 10);
  let minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
  return {
    hoursMinutes,
    hours,
    minutes,
    theFloatTime: hours + minutes / 60,
  };
};

export const minuteTimeString = time => {
  let hoursMinutes = time.split(/[.:]/);
  return hoursMinutes[1];
};

export const nextDay = (call, time, timeStr, day, nextDayDuration) => {
  const nextDay =
    hourDifference(call, time) > nextDayDuration ||
    hourDifference(call, time) < 0;
  if (nextDay) {
    time.add(1, 'd');
  }

  let dur = moment.duration(time.diff(call)).as('hours');
  let hour = Math.floor(timeStringToFloat(day.call).hours + dur);
  let minute = minuteTimeString(day[`${timeStr}`]);

  return {
    nextDay,
    hour,
    minute,
  };
};

export const convertTimes = day => {
  let dayCallObj = {},
    call,
    midnight,
    altDuration,
    nextDayDuration,
    nextDayTimes = [];

  let meal1In,
    meal1Out,
    meal2In,
    meal2Out,
    meal3In,
    meal3Out,
    meal1Duration = 0,
    meal2Duration = 0,
    meal3Duration = 0;
  if (day.call) {
    dayCallObj = timeStringToFloat(day.call);
    call = moment(day.call, 'HH:mm');
    midnight = moment('24:00', 'HH:mm');
    altDuration = calculateDayHours(day);
    nextDayDuration = moment.duration(midnight.diff(call)).as('hours');

    if (day.meal1In) {
      meal1In = moment(day.meal1In, 'HH:mm');
      const time = nextDay(call, meal1In, 'meal1In', day, nextDayDuration);
      if (time.nextDay) {
        day.meal1TimeIn = `${time.hour}:${time.minute}`;
        nextDayTimes.push('meal1In');
      }
    }
    if (day.meal1Out) {
      meal1Out = moment(day.meal1Out, 'HH:mm');
      const time = nextDay(call, meal1Out, 'meal1Out', day, nextDayDuration);
      if (time.nextDay) {
        day.meal1TimeOut = `${time.hour}:${time.minute}`;
        nextDayTimes.push('meal1Out');
      }
    }

    if (day.meal2In) {
      meal2In = moment(day.meal2In, 'HH:mm');
      const time = nextDay(call, meal2In, 'meal2In', day, nextDayDuration);
      if (time.nextDay) {
        day.meal2TimeIn = `${time.hour}:${time.minute}`;
        nextDayTimes.push('meal2In');
      }
    }
    if (day.meal2Out) {
      meal2Out = moment(day.meal2Out, 'HH:mm');
      const time = nextDay(call, meal2Out, 'meal2Out', day, nextDayDuration);
      if (time.nextDay) {
        day.meal2TimeOut = `${time.hour}:${time.minute}`;
        nextDayTimes.push('meal2Out');
      }
    }
    if (day.meal3In) {
      meal3In = moment(day.meal3In, 'HH:mm');
      const time = nextDay(call, meal3In, 'meal3In', day, nextDayDuration);
      if (time.nextDay) {
        day.meal3TimeIn = `${time.hour}:${time.minute}`;
        nextDayTimes.push('meal3In');
      }
    }
    if (day.meal3Out) {
      meal3Out = moment(day.meal3Out, 'HH:mm');
      const time = nextDay(call, meal3Out, 'meal3Out', day, nextDayDuration);
      if (time.nextDay) {
        day.meal3TimeOut = `${time.hour}:${time.minute}`;
        nextDayTimes.push('meal3Out');
      }
    }
    if (day.meal1In && day.meal1Out) {
      meal1Duration = moment.duration(meal1In.diff(meal1Out)).as('hours');
    }
    if (day.meal2In && day.meal2Out) {
      meal2Duration = moment.duration(meal2In.diff(meal2Out)).as('hours');
    }
    if (day.meal3In && day.meal3Out) {
      meal3Duration = moment.duration(meal3In.diff(meal3Out)).as('hours');
    }

    if (
      altDuration + meal1Duration + meal2Duration + meal3Duration >
      nextDayDuration
    ) {
      day.nextDay = true;
      let minStr;
      if (day.wrap) {
        minStr = minuteTimeString(day.wrap);
      }
      let altHour = Math.floor(
        dayCallObj.hours +
          altDuration +
          meal1Duration +
          meal2Duration +
          meal3Duration,
      );
      day.altTime = `${altHour}:${minStr}`;
    }
  }
  return {
    hours: dayCallObj.hours,
    altDuration,
    nextDayDuration,
    meal1Duration,
    meal2Duration,
    meal3Duration,
    nextDayTimes,
  };
};

export const timecardValidate = (values, formProps) => {
  const errors = { days: [{}, {}, {}, {}, {}, {}, {}], valid: true };
  const week = values.days;

  if (week && !formProps.pristine) {
    for (let x = 0; x <= 6; x++) {
      if (week[x].isActive) {
        let call = week[x].call;
        let wrap = week[x].wrap;
        let nextCall,
          meal1In,
          meal1Out,
          lastMan1In,
          meal2In,
          meal2Out,
          meal3In,
          meal3Out,
          ndbIn,
          ndmOut,
          ndmIn;

        if (x !== 6) {
          nextCall = week[x + 1].call;
        }

        if (week[x].meal1In || week[x].meal1In === 0) {
          meal1In = week[x].meal1In;
        }
        if (week[x].meal1Out || week[x].meal1Out === 0) {
          meal1Out = week[x].meal1Out;
          // if (meal1Out === 0 || meal1Out < 0.5) {
          //   errors.days[x].meal1Out =
          //     'Your meal out time must be greater than 00:30';
          //   errors.valid = false;
          // }
        }
        if (week[x].lastMan1In || week[x].lastMan1In === 0) {
          lastMan1In = week[x].lastMan1In;
        }
        if (week[x].meal2In || week[x].meal2In === 0) {
          meal2In = week[x].meal2In;
        }
        if (week[x].meal2Out || week[x].meal2Out === 0) {
          meal2Out = week[x].meal2Out;
          // if (meal2Out === 0 || meal2Out < 0.5) {
          //   errors.days[x].meal2Out =
          //     'Your meal out time must be greater than 00:30';
          //   errors.valid = false;
          // }
        }
        if (week[x].meal3In || week[x].meal3In === 0) {
          meal3In = week[x].meal3In;
        }
        if (week[x].meal3Out || week[x].meal3Out === 0) {
          meal3Out = week[x].meal3Out;
          // if (meal3Out === 0 || meal3Out < 0.5) {
          //   errors.days[x].meal3Out =
          //     'Your meal out time must be greater than 00:30';
          //   errors.valid = false;
          // }
        }
        if (week[x].ndbIn || week[x].ndbIn === 0) {
          ndbIn = week[x].ndbIn;
        }
        if (week[x].ndmOut || week[x].ndmOut === 0) {
          ndmOut = week[x].ndmOut;
        }
        if (week[x].ndmIn || week[x].ndmIn === 0) {
          ndmIn = week[x].ndmIn;
        }
        // Meal 1 IN and OUT validations
        if (
          (week[x].meal1Out || week[x].meal1Out === 0) &&
          (week[x].call || week[x].call === 0)
        ) {
          if ((week[x].call || week[x].call === 0) && call >= meal1Out) {
            errors.days[x].meal1Out =
              'Your meal out time must be after your call time';
            errors.valid = false;
          }
        }
        if ((week[x].ndbIn || week[x].ndbIn === 0) && week[x].meal1Out) {
          if (ndbIn >= meal1Out) {
            errors.days[x].meal1Out =
              'Your meal out time must be after your NDB in time';
            errors.valid = false;
          }
        }
        if ((week[x].meal1In || week[x].meal1In === 0) && week[x].lastMan1In) {
          if (meal1In > lastMan1In) {
            errors.days[x].lastMan1In =
              'Your Last Man In time must be equal to or after your 1st meal in time';
            errors.valid = false;
          }
        }

        if (week[x].meal1Out === null && week[x].lastMan1In) {
          errors.days[x].meal1Out = 'Meal out time required with "Last Man In"';
          errors.valid = false;
        }

        if (
          (week[x].meal1In || week[x].meal1In === 0) &&
          week[x].meal1Out === null
        ) {
          errors.days[x].meal1Out = 'Meal out time required';
          errors.valid = false;
        }
        if (
          (week[x].meal1In === null && week[x].meal1Out) ||
          week[x].meal1Out === 0
        ) {
          errors.days[x].meal1In = 'Meal in time required';
          errors.valid = false;
        }
        if ((week[x].meal1In || week[x].meal1In === 0) && week[x].meal1Out) {
          if (meal1Out >= meal1In) {
            errors.days[x].meal1In =
              'Your meal in time must be after your meal out time';
            errors.valid = false;
          }
        }
        // Meal 2 IN and OUT validations
        if (
          (week[x].meal2In || week[x].meal2In === 0) &&
          week[x].meal2Out === null
        ) {
          errors.days[x].meal2Out = 'Meal out time required';
          errors.valid = false;
        }
        if (week[x].meal2In === null && week[x].meal2Out) {
          errors.days[x].meal2In = 'Meal in time required';
          errors.valid = false;
        }
        if (week[x].meal2In && week[x].meal2Out) {
          if (meal2Out >= meal2In) {
            errors.days[x].meal2In =
              'Your meal in time must be after your meal out time';
            errors.valid = false;
          }
          if (week[x].call && call >= meal2Out) {
            errors.days[x].meal2Out =
              'Your meal out time must be after your call time';
            errors.valid = false;
          }
          if (week[x].meal1In && meal1In >= meal2Out) {
            errors.days[x].meal2Out =
              'Your meal out time must be after your previous meal in time';
            errors.valid = false;
          }
        }
        // Meal 3 IN and OUT validations
        if (week[x].meal3In && week[x].meal3Out === null) {
          errors.days[x].meal3Out = 'Meal out time required';
          errors.valid = false;
        }
        if (week[x].meal3In === null && week[x].meal3Out) {
          errors.days[x].meal3In = 'Meal in time required';
          errors.valid = false;
        }
        if (week[x].meal3In && week[x].meal3Out) {
          if (meal3Out >= meal3In) {
            errors.days[x].meal3In =
              'Your meal in time must be after your meal out time';
            errors.valid = false;
          }
          if (week[x].call && call >= meal3Out) {
            errors.days[x].meal3Out =
              'Your meal out time must be after your call time';
            errors.valid = false;
          }
          if (week[x].meal2In && meal2In >= meal3Out) {
            errors.days[x].meal3Out =
              'Your meal out time must be after your previous meal in time';
            errors.valid = false;
          }
          if (week[x].meal1In && meal1In >= meal3Out) {
            errors.days[x].meal3Out =
              'Your meal out time must be after your previous meal in time';
            errors.valid = false;
          }
        }
        // NDB and NDM validation
        if (week[x].ndmIn && week[x].ndmOut === null) {
          errors.days[x].ndmOut = 'NDM out time required';
          errors.valid = false;
        }
        if (week[x].ndmOut && week[x].ndmIn === null) {
          errors.days[x].ndmIn = 'NDM in time required';
          errors.valid = false;
        }
        if (week[x].ndmIn && week[x].ndmOut) {
          // if (ndmOut.isSameOrAfter(ndmIn)) {
          if (ndmOut >= ndmIn) {
            errors.days[x].ndmIn =
              'Your NDM in time must be after your NDM out time';
            errors.valid = false;
          }
        }
        if (week[x].ndbIn || (week[x].ndbIn === 0 && week[x].call)) {
          // if (ndmOut.isSameOrAfter(ndmIn)) {
          if (call >= ndbIn) {
            errors.days[x].ndbIn =
              'Your NDB in time must be after your call time';
            errors.valid = false;
          }
        }
        // call === ndBin ( including "0") display the error
        if (week[x].call === week[x].ndbIn) {
          if (week[x].ndbIn !== null && week[x].ndbIn !== undefined) {
            errors.days[x].ndbIn =
              'Your NDB in time must be after your call time';
            errors.valid = false;
          }
        }
        if (week[x].ndmOut && week[x].call) {
          // if (ndmOut.isSameOrAfter(ndmIn)) {
          if (call >= ndmOut) {
            errors.days[x].ndmOut =
              'Your NDM out time must be after your call time';
            errors.valid = false;
          }
        }
        // Call and Wrap validations
        if (
          formProps.isReviewing &&
          !week[x].call &&
          week[x].call !== 0 &&
          !values.isTeamTimecard
        ) {
          //only used when loading draft submitted to employee
          const isExempt = !!(
            values.dealMemo &&
            (values.dealMemo.isExempt || values.dealMemo.exempt)
          );

          const dayTypeCode =
            week[x] && week[x].dayType && week[x].dayType.code;
          if (isExempt === false && doesDayRequireTimes(dayTypeCode)) {
            errors.days[x].call = 'Call time is required';
            errors.valid = false;
          }
        }

        if ((week[x].call || week[x].call === 0) && call >= 24) {
          errors.days[x].call = 'Call time must be under 24 hours.';
          errors.valid = false;
        }
        if ((nextCall || nextCall === 0) && week[x].wrap && wrap >= 24) {
          if (nextCall <= wrap - 24) {
            errors.days[x + 1].call =
              "Call time overlaps with previous day's wrap time.";
            errors.valid = false;
          }
        }
        if (week[x].call !== null && week[x].wrap === null) {
          errors.days[x].wrap = 'Wrap time is required';
          errors.valid = false;
        }
        if (week[x].wrap !== null && week[x].call === null) {
          errors.days[x].call = 'Call time is required';
          errors.valid = false;
        }
        if (
          week[x].wrap &&
          (week[x].meal1In || week[x].meal1In === 0) &&
          meal1In >= wrap
        ) {
          errors.days[x].wrap =
            'Your wrap time must be after your meal 1 in time';
          errors.valid = false;
        }
        if (week[x].wrap && week[x].meal2In && meal2In >= wrap) {
          errors.days[x].wrap =
            'Your wrap time must be after your meal 2 in time';
          errors.valid = false;
        }
        if (week[x].wrap && week[x].meal3In && meal3In >= wrap) {
          errors.days[x].wrap =
            'Your wrap time must be after your meal 3 in time';
          errors.valid = false;
        }
        if (week[x].wrap && week[x].ndmIn && ndmIn >= wrap) {
          errors.days[x].ndmIn =
            'Your ndm in time must be before your wrap time';
          errors.valid = false;
        }
        if ((week[x].call || week[x].call === 0) && week[x].wrap) {
          if (call >= wrap) {
            errors.days[x].wrap = 'Your wrap time must be after your call time';
            errors.valid = false;
          }
        }
        if (wrap === 0) {
          errors.days[x].wrap = 'Wrap time cannot be 0';
          errors.valid = false;
        }
      }
    }
  }
  return errors;
};

const NON_REJECT_DH_FIELDS = [
  'locationType',
  'htgLocationTypeId',
  'dayType',
  'htgDayTypeId',
  'htgStateId',
  'htgCityId',
  'city',
  'htgCountyId',
  'workCounty',
  'htgSubdivisionId',
  'workSubdivision',
  'state',
  'accountCode',
  'episode',
  'location',
  'insurance',
  'series',
  'set',
  'freeField1',
  'freeField2',
  'freeField3',
  'freeField4',
];

//if any fields besides NON_REJECT_DH_FIELDS are modified, prevent save+approve
export const warnForDHReject = (values, formProps) => {
  //Could make this more efficient by only checking the fields that are touched
  //but its likely not needed.
  const initialValues = formProps.initialValues;

  const initialDays = initialValues.days;
  const days = values.days;

  for (let i = 0; i < days.length; i++) {
    const day = days[i];
    const initDay = initialDays[i];
    for (const field in day) {
      if (NON_REJECT_DH_FIELDS.includes(field)) continue;
      if (Object.hasOwnProperty.call(day, field)) {
        const val = day[field];
        const initVal = initDay[field];

        if (val !== initVal) return { needsToReject: true };
      }
    }
  }

  return { needsToReject: false };
};

export const nameConversion = ({ fullName }) => {
  const nameList = fullName.replace(',', '').split(' ');
  return nameList.length === 2
    ? [nameList[1], nameList[0]].join(' ')
    : [nameList[1], nameList[2], nameList[0]].join(' ');
};

export const convertToFormData = (allowance, allowance_type) => {
  let data = new FormData();

  // isDocumentDeleted
  for (let key in allowance) {
    switch (key) {
      case 'defaultAllowanceId':
        data.append('default_allowance_id', allowance.defaultAllowanceId);
        break;
      case 'allowanceType':
        // data.append('allowance_type', allowance_type);
        break;
      default: {
        const value = allowance[key];
        if (value === 'undefined') {
          db(`Saving undefined value on allowance for field ${key}`);
        }
        if (value !== null) {
          data.append(`${key}`, value);
        }
      }
    }
  }

  return data;
};

const unitsNullCheck = value => {
  if (!value || value === '0') {
    return 1;
  } else {
    return value;
  }
};

//only used by old timecard view (DH + Crew Timecard)
export const composeAllowanceV1 = (allowance, allowanceType) => {
  const { amount, hours, rate } = allowance;

  const units = unitsNullCheck(allowance.units);
  let data = {};

  data = {
    rate: allowance.rate || 0,
    frequency: allowance.frequency || 'F',
    units: units,
    allowanceTypeFlag: allowance?.allowanceTypeFlag || 'M',
    amount: amount || 0,
    document: allowance.document || null,
    filename: allowance.filename || allowance.comment,
    htgAllowanceTypeId:
      allowance.htgAllowanceTypeId ||
      allowance.htg_allowance_type_id ||
      (allowance.reason && allowance.reason.id), // WTC local allowance
    isDefaultAllowance: allowance.isDefaultAllowance || false,
    s3path: allowance.s3Path || null,
    token: allowance.token || null,
    combineCheckCode: false,
    allowanceType,
    sequenceNumber: allowance?.sequenceNumber || 0,
    locationTypeId: allowance.locationTypeId || null,
    accountCode: allowance.accountCode || null,
    freeField1: allowance.freeField1 || null,
    freeField2: allowance.freeField2 || null,
    freeField3: allowance.freeField3 || null,
    freeField4: allowance.freeField4 || null,
    location: allowance.location || null,
    series: allowance.series || null,
    set: allowance.set || null,
    insurance: allowance.insurance || null,
  };

  if (allowance.worksightId || allowance.rowId) {
    data.id = allowance.worksightId || allowance.rowId;
    if (
      (!allowance.document &&
        !allowance.filename &&
        !allowance.token &&
        !allowance.s3Path) ||
      (!allowance.comment && allowance.document === null && allowance.rowId)
    ) {
      data.isDocumentDeleted = true;
    }
  }
  if (!amount && hours && rate) {
    data.amount = hours * rate;
  }
  if (allowance.combineCheckCode) data.combineCheckCode = true;
  if (!allowance.reimbursementType) {
    data.reimbursementType = data.htgAllowanceTypeId;
  }
  if (allowance.reimbursementType && !allowance.id) {
    data.reimbursementType = allowance.reimbursementType;
  }
  if (allowance.isDefaultAllowance) {
    data.defaultAllowanceId = allowance.defaultAllowanceId;
  }
  return data;
};

export const convertStringToDecimals = numString => {
  let numArray = numString.split('.');
  let decimals = numArray[1].slice(0, 2);

  if (decimals.length === 1) {
    numArray[1] = decimals + '0';
    return numArray.join('.');
  } else {
    numArray[1] = decimals;
    return numArray.join('.');
  }
};

export const twoDecimalsNoRounding = input => {
  if (!Number(input)) return input;

  let numString = input.toString();

  if (numString.indexOf('.') > 0) {
    return convertStringToDecimals(numString);
  } else {
    return numString + '.00';
  }
};

export function decimalCeil(value) {
  return _.ceil(value, 1);
}

export const validateAllowanceInput = (allowance, mandatoryUpload) => {
  const errors = { valid: true, messages: [] };

  if (_.isEmpty(allowance) || !allowance.htgAllowanceTypeId) {
    errors.messages.push('Please fill Required fields');
    errors.valid = false;
  }

  const hasFile = allowance.filename || allowance.document;

  if (mandatoryUpload && !hasFile) {
    errors.messages.push('Please upload a supporting document');
    errors.valid = false;
  }

  if (allowance.amount) {
    //normalization in onChange keeps this as either a valid number or not

    const num = parseFloat(allowance.amount);
    if (isNaN(num)) {
      errors.messages.push('Amount must be digits and one decimal point only.');
      errors.valid = false;
    }

    if (num < 0) {
      errors.messages.push('Amount cannot be negative');
      errors.valid = false;
    }
  }

  return errors;
};

//returns elapsed time from now in milliseconds from the provided date
export const getElapsedTime = date => {
  const now = new Date();
  return now - date;
};

export const RECALL_BUTTON_TC_STATUS = [
  'pending_upm_review',
  'processed',
  'sent_to_cnc',
];

export const accountsFieldMaxLenDefault = column => {
  if (column === 'insurance') {
    return 2;
  }
  if (column === 'series') {
    return 5;
  } else {
    return 4;
  }
};

const LEN_LIMIT_ACCT_FIELDS = [
  'location',
  'insurance',
  'set',
  'freeField1',
  'freeField2',
  'freeField3',
  'freeField4',
  'series',
];

export const accountsFieldMaxLength = (project, columnId) => {
  if (LEN_LIMIT_ACCT_FIELDS.includes(columnId)) {
    let name = columnId;
    switch (columnId) {
      case 'freeField1':
        name = 'customField1';
        break;
      case 'freeField2':
        name = 'customField2';
        break;
      case 'freeField3':
        name = 'customField3';
        break;
      case 'freeField4':
        name = 'customField4';
        break;
      default:
    }
    return project[`${name}Length`]
      ? project[`${name}Length`]
      : accountsFieldMaxLenDefault(columnId);
  }
  return undefined;
};
export const checkGuaranteedAllowance = employees => {
  const isGuaranteedAllowance = employees.some(employee =>
    employee.dealMemos.some(deal =>
      deal.dealMemoAllowances.some(
        allow => allow.payCode1?.name || allow.payCode2?.name,
      ),
    ),
  );
  return isGuaranteedAllowance;
};

export const isAutoAllowanceCheck = allowanceTypeFlag => {
  return allowanceTypeFlag === 'A' || false;
};

export const getIsValidDealmemo = (dealMemos, details) => {
  return dealMemos.some(dm => details?.some(d => d.dealMemo?.id === dm.id));
};

export const makeNewDHAllowance = (dealAllo, defaultNonTax) => {
  const newAllowance = {
    defaultAllowanceId: '',
    allowanceTypeFlag: 'A',
    frequency: 'D',
    units: 1,
    sequenceNumber: dealAllo.sequenceNumber,
  };

  newAllowance.rate =
    dealAllo.allowancesAmount > 0
      ? dealAllo.allowancesAmount
      : !!dealAllo.rate
      ? dealAllo.rate
      : 0;
  newAllowance.amount = newAllowance.rate;
  newAllowance.sequenceNumber = dealAllo.sequenceNumber;

  const payCodes = [];
  if (!_.isEmpty(dealAllo.payCode1)) {
    payCodes.push(_.cloneDeep(dealAllo.payCode1));
  }
  if (!_.isEmpty(dealAllo.payCode2)) {
    payCodes.push(_.cloneDeep(dealAllo.payCode2));
  }
  let payCode = payCodes[0] || null;

  if (payCodes.length === 2 && defaultNonTax.includes(payCodes[1].code)) {
    payCode = payCodes[1];
  }
  newAllowance.htgAllowanceTypeId = payCode.id;

  return newAllowance;
};
