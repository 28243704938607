import { produce } from 'immer';
import * as actions from 'actions/routeParams';

const initialState = {
  projectId: null,
  timecardId: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.storeProject}`: {
        const cleanProjectId = action.projectId.replace(/[^0-9]/g, '');
        if (action.projectId !== cleanProjectId) {
          throw new Error(
            'Invalid projectId in url. Only numbers are allowed.',
          );
        }
        draft.projectId = action.projectId;
        break;
      }
      case `${actions.storeTimecard}`: {
        draft.timecardId = action.timecardId;
        break;
      }
      default:
    }
  });
