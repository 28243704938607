import React from 'react';

import { Header, ProjectHeader, FullWidthLayout } from 'containers/Layout';

//subNav
import { Reports } from 'containers/Nav/Pages';

//components
const OnDemand = React.lazy(() =>
  import('containers/Employees/Reports/OnDemand'),
);
const Schedule = React.lazy(() =>
  import('containers/Employees/Reports/Schedule'),
);

const projectRoutes = [
  {
    title: 'Project Lander',
    path: '/projects/:projectId/reports/ondemand',
    headers: [Header, ProjectHeader, Reports],
    layout: FullWidthLayout,
    component: OnDemand,
  },
  {
    title: 'Crew List',
    path: '/projects/:projectId/reports/schedule',
    headers: [Header, ProjectHeader, Reports],
    layout: FullWidthLayout,
    component: Schedule,
  },
];

export default projectRoutes;
