import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Paper, Tooltip, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
// import { useResize } from 'utils/customHooks';
import Draggable from 'react-draggable';
import DragHandleIcon from '@mui/icons-material/DragHandle';

import * as actions from 'actions/wtc';
import * as sel from 'selectors/wtc';

import WTCDealMemoModalComp from './WTCDealMemoComp';

const useStyles = makeStyles(({ palette }) => ({
  WTCDealMemoModalV2: {
    zIndex: 1000,
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: '1px solid black',
  },
  titleBar: {
    color: palette.text.secondary,
    fontSize: '1.5rem',
    height: 50,
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'move',
    padding: '20px 24px',
  },
}));

const mapState = state => ({
  modalData: sel.getDealMemoModal(state),
});

const mapDispatch = dispatch => ({
  onCloseModal: () =>
    dispatch(actions.setDealMemoModal({ dealMemoModal: null })),
});

const width = 1145;
const moveTitle = 'Click and drag to move';

const WTCDealMemoModalV2 = props => {
  const classes = useStyles();
  const {
    modalData,
    onCloseModal,
    change,
    onSetFieldVisibility,
    masterRowData,
  } = props;

  // maybe add ability to resize?
  //   const { width, height, enableResize, isResizing } = useResize({
  //     minWidth: 660,
  //     minHeight: 600,
  //   });

  const nodeRef = React.useRef(null);
  const titleRef = React.useRef(null);

  //TODO - these do NOT update on window resize
  // maybe add a listener to update these values on resize completion (debounce?)
  const horBound = React.useMemo(() => Math.floor(window.innerWidth - 400), []);
  const vertBound = React.useMemo(
    () => Math.floor(window.innerHeight - 300),
    [],
  );

  const [title, setTitle] = React.useState(moveTitle);

  if (modalData === null) return null;

  return (
    <Draggable
      bounds={{
        top: -250,
        left: -340,
        right: horBound,
        bottom: vertBound,
      }}
      nodeRef={nodeRef}
      handle=".wtcDealMemoModal"
      defaultPosition={{
        x: 230,
        y: 80,
      }}
      //   disabled={isResizing}
    >
      <Paper
        ref={nodeRef}
        className={classes.WTCDealMemoModalV2}
        sx={{
          // height,
          width,
        }}
        tabIndex={0} //needed for keydown event
        onKeyDown={e => {
          if (e.key === 'Escape') onCloseModal();
        }}
      >
        <Box
          className={clsx('wtcDealMemoModal', classes.titleBar)}
          onMouseDown={() => setTitle('')}
          onMouseUp={() => setTitle(moveTitle)}
        >
          <Box ref={titleRef}>Deal Memo Details</Box>
          <Tooltip title={title}>
            <DragHandleIcon sx={{ flexGrow: 1 }} />
          </Tooltip>
          <Box sx={{ width: titleRef?.current?.clientWidth || '200px' }} />
        </Box>
        <WTCDealMemoModalComp
          change={change}
          onSetFieldVisibility={onSetFieldVisibility}
          masterRowData={masterRowData}
        />
      </Paper>
    </Draggable>
  );
};

WTCDealMemoModalV2.propTypes = {
  modalData: PropTypes.object,
  onCloseModal: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  onSetFieldVisibility: PropTypes.func.isRequired,
};

export default compose(connect(mapState, mapDispatch))(WTCDealMemoModalV2);
