import createAction from './createAction';

// Non Admin Actions.
export const loading = createAction('loading/projects/userInfo');
export const fetch = createAction('fetch/projects/userInfo');
export const store = createAction('store/projects/userInfo');

export const fetchPermissions = createAction('fetch/Permissions');
export const storePermissions = createAction('store/Permissions');

export const fetchWorksightId = createAction('fetch/worksightId');
export const storeWorksightId = createAction('store/worksightId');
