import React, { useState, useMemo, useEffect, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Select from 'react-select';
import { findNextValidInput } from 'utils/reactTableUtils';
import {
  CustomSelectMenu,
  WatchOption,
} from 'components/Shared/ReactTable/reactTableUtils';

const useStyles = makeStyles(theme => ({
  root: {},
  select: {
    marginTop: '-26px',
    width: 200,
    zIndex: 5,
  },
}));

const SelectComp = props => {
  const classes = useStyles();

  const {
    columnId,
    rowIndex,
    closeSelect,
    value,
    updateSelectVal,
    options,
    emptyMsg,
    onInputChange = () => {},
    isLoading = false,
    setTabIndex,
    clientWidth,
    didClick,
    rowsCount,
    containerRef,
  } = props;

  const [localInput, setLocalInput] = useState('');

  const customStyles = useMemo(
    () => ({
      control: provided => ({
        ...provided,
        width: clientWidth,
        minHeight: 10,
        maxHeight: 33,
      }),
      indicatorsContainer: () => ({ display: 'none' }),
    }),
    [clientWidth],
  );

  const getLabel = option => {
    let label;
    switch (columnId) {
      case 'locationType':
      case 'workState':
      case 'workCounty':
      case 'workCountry':
      case 'occupationCode':
        label = option.name ? `${option.code} - ${option.name}` : '';
        break;
      case 'episode':
      case 'workSubdivision':
        label = option.code;
        break;
      case 'combineCheck':
        label = option.value;
        break;
      default:
        label = option.name;
    }
    if (!label) label = <span style={{ opacity: 0 }}>empty option</span>;
    return label;
  };

  const getValue = option => {
    switch (columnId) {
      case 'combineCheck':
        return option.value;

      default:
        return option.id;
    }
  };

  const onChange = newVal => {
    if (columnId === 'combineCheck') newVal = newVal.value;
    updateSelectVal(newVal);
    setMenuIsOpen(false);
  };

  //combine check is stored as a string but needs to be passed in as an obj
  let displayValue;
  if (columnId === 'combineCheck') {
    displayValue = value ? { value: value, label: value } : {};
  } else {
    displayValue = value;
  }

  const [menuIsOpen, setMenuIsOpen] = useState(undefined);
  const [menuPlacement, setMenuPlacement] = useState('bottom');
  const SelectRef = useRef(null);
  useEffect(() => {
    const select = SelectRef?.current?.getBoundingClientRect();
    const table = containerRef?.current?.getBoundingClientRect();
    const SELECT_POS_IN_TABLE = select?.top - table?.top;
    if (SELECT_POS_IN_TABLE / table.height >= 0.6) {
      setMenuPlacement('top');
    } else {
      setMenuPlacement('bottom');
    }
  }, [menuIsOpen, containerRef]);

  const focusedRef = useRef({});

  useEffect(() => {
    // did click should only be true for a spit second on open
    // need to manually close menu
    if (didClick) {
      setMenuIsOpen(true);
    }
  }, [didClick]);

  return (
    <div
      className={classes.root}
      ref={SelectRef}
      onClick={() => {
        if (menuIsOpen === false) setMenuIsOpen(true);
      }}
    >
      <Select
        className={classes.select}
        options={options}
        menuIsOpen={menuIsOpen}
        menuPlacement={menuPlacement}
        autoFocus={true}
        styles={customStyles}
        openMenuOnFocus={false}
        value={displayValue}
        tabSelectsValue={false}
        noOptionsMessage={() => emptyMsg}
        isLoading={isLoading}
        inputValue={localInput}
        getOptionLabel={getLabel}
        getOptionValue={getValue}
        onChange={onChange}
        onMenuOpen={() => setMenuIsOpen(true)}
        onBlur={() => closeSelect()}
        onInputChange={input => {
          setLocalInput(input);
          onInputChange(input);
        }}
        components={{
          Menu: CustomSelectMenu(),
          Option: WatchOption(focusedRef),
        }}
        onKeyDown={e => {
          switch (e.key) {
            case 'ArrowUp':
            case 'ArrowDown':
              if (!menuIsOpen) {
                setMenuIsOpen(true);
              }
              break;
            case 'Delete':
            case 'Backspace':
              if (localInput === '') {
                updateSelectVal({});
                setMenuIsOpen(false);
              }
              break;
            case 'Escape':
              setMenuIsOpen(false);
              setLocalInput('');
              onInputChange('');
              break;
            case 'Tab':
              if (e.shiftKey) {
                setTabIndex(-1);
                setTimeout(() => {
                  setTabIndex(0);
                });
              }
              if (focusedRef.current && menuIsOpen) {
                onChange(focusedRef.current);
              }
              break;
            case 'Enter': {
              e.stopPropagation();
              let nextElement;
              let params = {
                rowIndex,
                columnId,
                direction: e.shiftKey ? 'up' : 'down',
                circulating: true,
                rowsCount,
              };

              nextElement = findNextValidInput(params);
              const [index] = nextElement.id && nextElement.id.split('.');
              if (Number(index) !== rowIndex) {
                nextElement && nextElement.focus();
              }
              break;
            }
            default:
              //Handle single letter cases here
              if (
                columnId !== 'occupationCode' &&
                columnId !== 'dealmemo' &&
                (e.metaKey || e.ctrlKey) &&
                e.shiftKey &&
                e.key.toLowerCase() === 'a'
              ) {
                e.preventDefault();
                e.stopPropagation();

                let newVal = _.isEmpty(focusedRef.current)
                  ? displayValue
                  : _.cloneDeep(focusedRef.current);

                if (columnId === 'combineCheck') newVal = newVal.value;
                updateSelectVal(newVal, { cta: true });
                setLocalInput('');
                onInputChange('');
                setMenuIsOpen(false);
              } else if (!menuIsOpen && /^[a-z0-9 ]$/i.test(e.key)) {
                setMenuIsOpen(true);
              }
              break;
          }
        }}
      />
    </div>
  );
};

SelectComp.propTypes = {
  columnId: PropTypes.string,
  rowIndex: PropTypes.number,
  closeSelect: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  updateSelectVal: PropTypes.func,
  options: PropTypes.array,
  emptyMsg: PropTypes.string,
  onInputChange: PropTypes.func,
  isLoading: PropTypes.bool,
  setTabIndex: PropTypes.func,
  clientWidth: PropTypes.number,
  didClick: PropTypes.bool,
  rowsCount: PropTypes.number,
  containerRef: PropTypes.object,
};

export default SelectComp;
