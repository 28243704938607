import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles(({ palette }) => ({
  badge: {
    height: '100%',
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 8,
    paddingRight: 10,
    borderRadius: 16,
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 6,
    display: 'inline-flex',
    '& .alloFlagBadge-inner': {
      width: 8,
      height: 8,
      position: 'relative',
    },
    '& .alloFlagBadge-dot': {
      width: 6,
      height: 6,
      left: 1,
      top: 1,
      position: 'absolute',
      borderRadius: 9999,
    },
    '& .alloFlagBadge-text': {
      textAlign: 'center',
      fontSize: 14,
      textTransform: 'capitalize',
    },
  },
  manual: {
    background: palette.badge.blue.background,
    border: `1px ${palette.badge.blue.light} solid`,
    '& .alloFlagBadge-dot': {
      background: palette.badge.blue.main,
    },
    '& .alloFlagBadge-text': {
      color: palette.badge.blue.dark,
    },
  },
  deal: {
    background: palette.badge.green.background,
    border: `1px ${palette.badge.green.light} solid`,
    '& .alloFlagBadge-dot': {
      background: palette.badge.green.main,
    },
    '& .alloFlagBadge-text': {
      color: palette.badge.green.dark,
    },
  },
  modified: {
    background: palette.badge.orange.background,
    border: `1px ${palette.badge.orange.light} solid`,
    '& .alloFlagBadge-dot': {
      background: palette.badge.orange.main,
    },
    '& .alloFlagBadge-text': {
      color: palette.badge.orange.dark,
    },
  },
}));

const AlloFlagBadge = props => {
  const classes = useStyles();
  const { flagType, variant = 'empTimecard' } = props;

  const subjects = variant === 'wtc' ? `the employee's` : 'your';
  const subject = variant === 'wtc' ? ` by the employee` : '';

  const content = {
    manual: {
      label: 'Manual',
      tooltip: 'This allowance was manually added during timecard creation.',
    },
    deal: {
      label: 'Deal',
      tooltip: `This allowance was automatically added from ${subjects} deal.`,
    },
    modified: {
      label: 'Modified',
      tooltip: `This allowance was automatically added from ${subjects} deal.  Note: Rate and/or Units have been modified${subject}.`,
    },
  };

  return (
    <Tooltip title={content[flagType].tooltip}>
      <div
        className={clsx(classes.badge, {
          [classes.manual]: flagType === 'manual',
          [classes.deal]: flagType === 'deal',
          [classes.modified]: flagType === 'modified',
        })}
      >
        <div className={'alloFlagBadge-inner'}>
          <div className={'alloFlagBadge-dot'} />
        </div>
        <div className={'alloFlagBadge-text'}>{content[flagType].label}</div>
      </div>
    </Tooltip>
  );
};

AlloFlagBadge.propTypes = {
  flagType: PropTypes.oneOf(['manual', 'deal', 'modified']),
  variant: PropTypes.oneOf(['wtc', 'empTimecard']).isRequired,
};

export default AlloFlagBadge;
