import React from 'react';
import PropTypes from 'prop-types';
import SortArrows from 'components/Shared/SortArrows';
import makeStyles from '@mui/styles/makeStyles';

import { PROJECT_REQ_FIELDS } from 'utils/wtcWeekUtils';

const useStyles = makeStyles(theme => ({
  cell: column => ({
    display: 'flex',
    width: column?.width,
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  title: {
    paddingLeft: 16,
    alignSelf: 'center',
  },
}));

function HeaderCell(props) {
  const { column, currentProject } = props;

  const classes = useStyles(column);

  const isRequiredField = React.useMemo(() => {
    const fieldLabelInProject = PROJECT_REQ_FIELDS[column.id];
    if (fieldLabelInProject) {
      const label = fieldLabelInProject.projectLabel;
      if (currentProject[label] === 'Y' || currentProject[label] === true) {
        return true;
      }
    }
  }, [column.id, currentProject]);

  return (
    <span
      className={classes.cell}
      id={column.id === 'weekDay' ? 'BulkEditFixedBoundary' : column.id}
    >
      <span className={classes.title}>
        {isRequiredField ? '* ' : ''}
        {column.Header}
      </span>
      <SortArrows column={column} />
    </span>
  );
}

HeaderCell.propTypes = {
  column: PropTypes.object.isRequired,
  currentProject: PropTypes.object.isRequired,
};

export default HeaderCell;
