import {
  takeEvery,
  takeLatest,
  call,
  put,
  select,
  all,
  delay,
} from 'redux-saga/effects';
import {
  startSubmit,
  stopSubmit,
  reset,
  getFormInitialValues,
} from 'redux-form';
import camelCase from 'camelcase-keys';
import pluralize from 'pluralize';
import { push } from 'redux-first-history';
// selectors
import { getProject as project } from 'selectors/routeParams';

// actions
import * as actions from './actions';
import { showAlert } from 'actions/alert';
import { hide as hideModal, show as showModal } from 'actions/modalDialog';
import { authenticate } from 'actions/authenticate';
import {
  fetch as fetchUserInfo,
  store as storeUserInfo,
} from 'actions/userInfo';
import { fetch as fetchFeatureFlags } from 'actions/flag';
import { RESET_APP } from 'providers/store/index';

//selectors
import {
  getUser,
  getInviteValues,
  getEditingUsers,
  getUserValues,
  getEditUserValues,
  getLastProject,
  getEditedFields,
} from './selectors';
import { getCurrentUserRole } from 'selectors/session';
import { getCurrentProject } from 'selectors/project';
import { getProjects } from 'selectors/project';

import { LANDING_PAGE_BY_ROLE } from 'components/Shared/constants';
import { errorMsgDecoder } from './CrewListUtils';
import { PROJECT_ADMIN } from 'components/props/profiles';

function* init() {
  const projectId = yield select(project);
  const lastProjectId = yield select(getLastProject);
  if (projectId !== lastProjectId) {
    yield put(actions.storeLastProject({ project: projectId }));
    yield put(actions.resetStatus());
  }
  yield put(actions.fetchProjectUsers({ init: true }));
}

export function* fetchProjectUsers(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const crewResults = yield call(api.crewList.users, {
      projectId,
    });
    const users = crewResults.employeesList;
    const statusCount = crewResults.counts;

    yield put(actions.storeUsers({ users }));
    yield put(actions.storeStatusCount({ statusCount }));
    yield put(actions.storeEditHostCostFlag({ editHostCostFlag: false }));
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    yield put(actions.loading({ loading: false }));
    yield put(actions.storeUsers({ users: [] }));
    debug(e);
    yield put(showAlert());
  }
}

function* impersonate(api, debug) {
  try {
    const projectId = yield select(project);
    const user = yield select(getUser);
    const role = yield select(getCurrentUserRole);
    yield call(api.admin.impersonate, { projectId, user: user.id });

    yield put(RESET_APP());

    yield put(hideModal({ dialog: 'impersonate' }));
    const redirectURI = LANDING_PAGE_BY_ROLE(role, projectId);

    yield put(fetchUserInfo());
    yield put(fetchFeatureFlags());
    yield put(authenticate({ redirectURI: redirectURI }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}
function* removeImpersonation(api, debug) {
  try {
    let projectId = yield select(project);
    const currentProject = yield select(getCurrentProject);
    const altProjectId = currentProject.id;
    const projectList = yield select(getProjects);

    if (projectId === null) {
      projectId = altProjectId;
      if (projectId === undefined && projectList.length > 0) {
        projectId = projectList[0].id;
      }
    }
    yield call(api.admin.removeImpersonation, { projectId });

    //return to root right when remove succeeds to prevent prev page re-rendering for the admin user
    yield put(storeUserInfo({ projectId, activeUser: undefined }));
    yield put(RESET_APP());
    // authenticate and redirect.
    yield put(
      authenticate({ redirectURI: `/admin/projects/${projectId}/crew-list` }),
    );
  } catch (e) {
    debug(e);
    yield put(showAlert({ message: 'Error removing impersonation' }));
    yield put(push('/'));
  }
}

function* sendInvites(api, debug) {
  const formName = 'NonCrewInvite';
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const data = yield select(getInviteValues);
    const invites = { invites: data };
    yield put(startSubmit(formName));
    const res = yield call(api.admin.inviteUsers, { projectId, invites });
    let errors = Object.keys(res.errors);
    if (errors?.length > 0) {
      let decodedErrors = errorMsgDecoder({
        errors: res.errors,
        type: 'invite',
      });
      throw decodedErrors; // error
    }
    // yield put(actions.setInviteStatus({ status: 'S' }));
    yield put(
      showAlert({
        message: `Success! ${pluralize(
          'user',
          res.users.length,
          true,
        )} invited`,
        variant: 'success',
      }),
    );
    yield put(hideModal({ dialog: formName }));
    yield put(reset(formName));
  } catch (e) {
    debug(e);
    yield put(showAlert({ variant: 'error', message: e }));
  } finally {
    yield put(stopSubmit(formName));
    yield delay(1500);
    yield put(hideModal({ dialog: formName }));
    yield put(actions.loading({ loading: false }));
    yield put(actions.fetchProjectUsers());
  }
}

function* deleteInvites(api, debug, params) {
  const formName = 'DeleteCrew';
  try {
    const projectId = yield select(project);
    const usersIds = yield select(getEditingUsers);
    yield put(startSubmit(formName));
    const res = yield call(api.crewList.deleteUser, {
      projectId,
      data: usersIds,
    });
    let errors = Object.keys(res.errors);
    if (errors?.length > 0) {
      let decodedErrors = errorMsgDecoder({
        errors: res.errors,
        type: 'delete',
      });
      throw decodedErrors; // error
    }
    yield put(
      showAlert({
        message: `Success! ${pluralize(
          'user',
          res.users.length,
          true,
        )} deleted`,
        variant: 'success',
      }),
    );
    yield put(hideModal({ dialog: 'DeleteCrew' }));
    yield put(reset(formName));
  } catch (e) {
    debug(e);
    yield put(showAlert({ variant: 'error', message: e }));
  } finally {
    yield put(stopSubmit(formName));
    yield delay(1500);
    yield put(hideModal({ dialog: formName }));
    yield put(actions.loading({ loading: false }));
    yield put(actions.fetchProjectUsers());
  }
}

function* save(api, debug) {
  const formName = 'UserEdit';
  try {
    const projectId = yield select(project);
    const data = yield select(getEditUserValues);

    yield put(startSubmit(formName));

    const invites = { invites: data };
    const res = yield call(api.admin.updateUser, { projectId, invites });
    let errors = res?.errors !== null ? Object.keys(res?.errors) : [];
    if (errors?.length > 0) {
      let decodedErrors = errorMsgDecoder({
        errors: res.errors,
        type: 'update',
      });
      throw decodedErrors; // error
    }
    yield put(
      showAlert({
        message: `Success! ${pluralize('user', 1, true)} updated`,
        variant: 'success',
      }),
    );
    yield put(hideModal({ dialog: 'userEdit' }));
    yield put(reset(formName));
  } catch (e) {
    debug(e);
    yield put(showAlert({ message: e }));
  } finally {
    yield put(stopSubmit(formName));
    yield delay(1500);
    yield put(hideModal({ dialog: 'userEdit' }));
    yield put(actions.fetchProjectUsers());
  }
}
function* confirmEmailChange(api, debug) {
  try {
    const data = yield select(getUserValues);
    const initialData = yield select(getFormInitialValues('UserEdit'));
    if (data.email !== initialData.email) {
      yield put(showModal({ dialog: 'confirmEmailChange' }));
    } else {
      yield call(save, api, debug);
    }
  } catch (e) {
    debug(e);
  }
}

//on edit API
function* fetchSelectedUser(api, debug, param) {
  try {
    const projectId = yield select(project);
    const { email } = param;
    const invites = { invites: [{ email }] };

    const data = yield call(api.crewList.fetchUser, { projectId, invites });
    const editUser = camelCase(data, { deep: true });
    yield put(actions.storeEditingUser({ editUser }));
    yield put(showModal({ dialog: 'userEdit' }));
  } catch (e) {
    yield put(actions.storeEditingUser({ editUser: {} }));
    debug(e);
  }
}
function* exportUsers(api, debug, param) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const userIds = param?.selected || [];
    var msg = yield call(api.admin.exportUsers, {
      projectId,
      userIds,
    });
    yield put(actions.loading({ loading: false }));
    yield put(showAlert({ variant: 'success', message: msg }));
  } catch (e) {
    yield put(showAlert({ variant: 'error', message: e }));
    debug(e);
  }
}
function* saveHotCost(api, debug, param) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const dirtyFields = yield select(getEditedFields);

    const data = param.formValues?.editableCrew;
    let edits = [];
    let editedFields = Object.keys(dirtyFields);
    editedFields.shift();
    let editedRows = [];
    editedFields.forEach(field => {
      let rowNumber = field.substring(
        field.indexOf('[') + 1,
        field.lastIndexOf(']'),
      );
      if (!editedRows.includes(+rowNumber)) editedRows.push(+rowNumber);
    });

    editedRows.forEach(id => {
      edits.push({
        ...data[id].hotCostSettingReport,
        employeeId: data[id].worksightId,
      });
    });

    const hotcosts = edits;
    yield call(api.crewList.saveHotCost, {
      projectId,
      hotcosts,
    });
    const msg = `Success! ${editedRows.length} row saved.`;
    yield put(
      showAlert({
        variant: 'success',
        message: msg,
      }),
    );
    yield delay(1500);
    yield put(actions.fetchProjectUsers());
    yield put(actions.clearEditedFields());
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    yield put(
      showAlert({
        variant: 'error',
        // message: e
      }),
    );
    yield put(actions.loading({ loading: false }));
    debug(e);
  }
}
function* confirmProjectAdminPermission(api, debug, param) {
  try {
    const project = yield select(getCurrentProject);
    const user = yield select(getUser);
    const isProjectAdmin = user.roles.includes(PROJECT_ADMIN) || false;
    const data = {
      projectId: Number(project.id),
      projectName: project.name,
      email: user.email,
      isProjectAdmin: !isProjectAdmin, //If user already exists project admin access remove it, else add it
    };
    yield call(api.admin.confirmProjectAdminPermission, { data });
    yield put(hideModal({ dialog: 'ProjectAdminPermission' }));
    yield put(actions.fetchProjectUsers());
  } catch (e) {
    debug(e);
    yield put(hideModal({ dialog: 'ProjectAdminPermission' }));
    yield put(showAlert());
  }
}
export default function* crewListFlow({ api, debug }) {
  yield all([
    takeLatest(`${actions.init}`, init, api, debug),
    takeEvery(`${actions.impersonate}`, impersonate, api, debug),
    takeEvery(
      `${actions.removeImpersonation}`,
      removeImpersonation,
      api,
      debug,
    ),
    takeEvery(`${actions.fetchProjectUsers}`, fetchProjectUsers, api, debug),
    takeEvery(`${actions.sendInvites}`, sendInvites, api, debug),
    takeEvery(`${actions.deleteInvites}`, deleteInvites, api, debug),
    takeEvery(`${actions.confirmEmailChange}`, confirmEmailChange, api, debug),
    takeEvery(`${actions.save}`, save, api, debug),
    takeEvery(`${actions.fetchSelectedUser}`, fetchSelectedUser, api, debug),
    takeEvery(`${actions.saveHotCost}`, saveHotCost, api, debug),
    takeLatest(`${actions.exportUsers}`, exportUsers, api, debug),
    takeEvery(
      `${actions.confirmProjectAdminPermission}`,
      confirmProjectAdminPermission,
      api,
      debug,
    ),
    // takeEvery(
    //   `${actions.removeImpersonation}`,
    //   removeImpersonation,
    //   api,
    //   debug,
    // ),
  ]);
}
