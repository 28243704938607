import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'utils/helperFunctions';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';

const style = theme => {
  return {
    root: {},
  };
};

const mapStateToProps = state => ({});

class CalculateConfirmModal extends Component {
  componentDidMount() {}

  render() {
    const { onCalc, calculating, ...others } = this.props;

    return (
      <ConfirmModal
        content={
          'This will calculate any timecards that have been modified and saved. This could take several minutes to complete. Do you still want to proceed?'
        }
        title={'Calculate Timecards'}
        buttonText="Yes, calculate"
        cancelText="No, take me back"
        raisedButton
        hasSubmitAction
        onSubmit={onCalc}
        submitting={calculating}
        {...others}
      />
    );
  }
}

export default compose(
  withStyles(style),
  withModalDialog({ dialog: 'ConfirmCalculate', maxWidth: 'sm' }),
  connect(mapStateToProps, null),
)(CalculateConfirmModal);
