import _ from 'lodash';
import { createSelector } from 'reselect';
import moment from 'moment';
import {
  getFormValues,
  formValueSelector,
  getFormSyncErrors,
  isDirty,
} from 'redux-form';
import { db, convertTimesToDec, NEW_TC_ID } from './empTimecardUtils';
import { getStatesV1 } from 'selectors/location';
import { getProject } from 'selectors/routeParams';
import { getColumnSettings } from 'selectors/settings';
import { calculateDayHours } from 'utils/weekUtils';
import { getHistoryStatusAndLabel } from 'selectors/timecard/form';
import { getProjectUser } from 'selectors/session';

import { TIMECARD_INCOMPLETE } from 'components/Shared/constants';
import { getCurrentProject } from 'selectors/project';
import { isRegionCanada } from 'utils/helperFunctions';

export const FORM_NAME = 'empTimecard';

const formSelector = formValueSelector(FORM_NAME);

const getRawLoading = state => _.get(state, 'empTimecard.loading', {});

export const getLoading = (state, flag) => {
  const loading = getRawLoading(state);
  return loading[flag] || false;
};

export const getDealMemoLoading = state => {
  const loading = _.get(state, 'empTimecard.loading', {});
  return loading.dealMemos;
};
export const getCommentsLoading = state => {
  const loading = _.get(state, 'empTimecard.loading', {});
  return loading.comments;
};

export const getSelectedCommentDay = state =>
  _.get(state, 'empTimecard.selectedCommentDay', {});
export const getHistoryLoading = state => {
  const loading = _.get(state, 'empTimecard.loading', {});
  return loading.history;
};
export const getTCDeleteLoading = state => {
  const loading = _.get(state, 'empTimecard.loading', {});
  return loading.delete;
};

export const getRequiredFields = createSelector(
  [getColumnSettings],
  settings => {
    const required = [];

    Object.keys(settings).forEach(fieldName => {
      if (settings[fieldName].employeeMandatory) {
        required.push(fieldName);
      }
    });

    return required;
  },
);

const getTimecard = state => _.get(state, 'empTimecard.timecard', {});

export const getInitialValues = createSelector([getTimecard], timecard => {
  db('INITIALIZE FORM');

  const formValues = _.cloneDeep(timecard);
  if (!formValues || _.isEmpty(formValues)) return {};
  convertTimesToDec(formValues);
  return formValues;
});

// generic timecard selectors
// add here ones that can be moved to a generic timecard feature

export const getDealRounding = state =>
  _.get(state, 'empTimecard.timecard.dealMemo.roundTo', 0);

export const getFieldsPreReq = state =>
  _.get(state, 'empTimecard.fieldPreReq', {});

export const getUseMilitary = state => {
  const useMilitary = _.get(state, 'empTimecard.useMilitary', false);
  return useMilitary;
};

export const getAllowanceAllTypes = state =>
  _.get(state, 'empTimecard.allowanceTypes', {});

export const getAllowanceTypes = createSelector(
  [getAllowanceAllTypes, getProject],
  (allowanceTypes, projectId) => {
    let types = allowanceTypes[projectId] || [];
    types = _.cloneDeep(types);
    types.sort((a, b) => (a.description < b.description ? -1 : 1));
    return types;
  },
);

export const getAllowanceTypeOptions = createSelector(
  [getAllowanceTypes],
  types => {
    return types
      .filter(type => type.employeeVisible)
      .map(type => ({
        id: type.id,
        htgAllowanceTypeId: type.htgAllowanceTypeId,
        name: type.name,
        code: type.code,
        employeeMandatory: type.employeeMandatory,
      }));
  },
);

export const getVisibleAllowances = createSelector(
  [state => formSelector(state, 'allowances')],
  (allowances = []) => {
    return allowances.filter(allowance => !allowance.toDelete);
  },
);

//Are any allowances that require files missing files?
export const anyAllowanceMissingFile = createSelector(
  [getAllowanceTypes, getVisibleAllowances],
  (types = [], allowances = []) => {
    let anyMissing = false;

    allowances.forEach(allowance => {
      const { filename, document, allowanceType } = allowance;
      const type = types.find(t => t.id === allowanceType?.id);
      const hasFile = !!filename || !!document;

      if (type?.employeeMandatory && !hasFile) {
        anyMissing = true;
      }
    });

    return anyMissing;
  },
);

export const getWorkLocations = state =>
  _.get(state, 'empTimecard.workLocations', []);

export const getComments = state => _.get(state, `empTimecard.comments`, []);

export const getIsMobile = state => _.get(state, `empTimecard.isMobile`, false);

// generic form selectors

export const getAllowanceCount = createSelector(
  [getVisibleAllowances],
  (formAllowances = []) => {
    const tcAllowanceCount = formAllowances?.length;

    return Math.max(tcAllowanceCount, 0);
  },
);
export const getCommentCount = state => {
  const comment = _.get(state, `empTimecard.comments`, []);
  return comment.length || 0;
};
const EMPTY_DEAL_OBJ = Object.freeze({});
export const getActiveDealMemo = state =>
  formSelector(state, 'dealMemo') || EMPTY_DEAL_OBJ;

export const getHtgContractId = createSelector([getActiveDealMemo], dealMemo =>
  _.get(dealMemo, 'htgContract.id', ''),
);
export const getHtgUnionId = createSelector([getActiveDealMemo], dealMemo =>
  _.get(dealMemo, 'htgUnion.id', ''),
);
export const getTimecardId = state => formSelector(state, 'id');

export const getTimecardStatus = state => formSelector(state, 'status') || '';

const calcDayHours = state => {
  const days = formSelector(state, 'days');
  const hours = days?.map(day => calculateDayHours(day)) || [];
  return hours;
};

export const getDayHours = state => {
  const hours = calcDayHours(state);
  let displayHours =
    hours.map(h => Number(h % 1 === 0 ? h : h.toFixed(2))) || [];
  return displayHours;
};

// total DISPLAY hours
export const getTotalHours = createSelector(
  [calcDayHours],
  hours => {
    const value = hours.reduce((acc, time) => acc + time, 0);
    return Number(value % 1 === 0 ? value : value.toFixed(2));
  },
  { devModeChecks: { inputStabilityCheck: 'never' } },
);

export const getColumns = state => {
  const columns = _.get(state, `empTimecard.columns`, []);
  return columns;
};

export const getDayTypes = state => {
  const dayTypes = _.get(state, `empTimecard.dayTypes`, []);
  return dayTypes;
};
export const getEpisodes = state => {
  const episodes = _.get(state, `empTimecard.episodes`, []);
  return episodes;
};

export const getStateOptions = createSelector([getStatesV1], states => {
  // remove extra data (cities, countries, specialOptions)
  // They aren't on the state option when timecard loads, so they shouldn't be added here

  return states.map(state => ({
    id: state.id,
    name: state.name,
    code: state.code,
  }));
});

export const getDealMemos = state => _.get(state, 'empTimecard.dealMemos', []);

export const getSelectedDay = state =>
  _.get(state, 'empTimecard.selectedDay', {});
export const getCopyToOtherDays = state =>
  _.get(state, 'empTimecard.copyToOtherDays', []);

const getHistory = state => _.get(state, 'empTimecard.timecardHistory', []);

export const getTimecardHistory = createSelector(
  [getHistory, getTimecardId],
  (history, id) => {
    return getHistoryStatusAndLabel(history, id);
  },
);
export const getNewComment = state =>
  _.get(state, 'empTimecard.newComment', '');

export const getCompatible = state =>
  _.get(state, 'empTimecard.compatible', null);
export const getTimecardList = state =>
  _.get(state, `empTimecard.timecardList`, []);
export const getSelectedTimecard = state =>
  _.get(state, 'empTimecard.timecard', {});

export const getEndsOn = state => _.get(state, 'empTimecard.endsOn', '');

const getIsDealValid = createSelector(
  [getFormValues(FORM_NAME), getEndsOn],
  (timecard, endsOn) => {
    const { dealMemo = {} } = timecard;

    if (_.isEmpty(dealMemo)) {
      return false;
    }

    const dealStart = moment(dealMemo?.start);
    const dealEnd = moment(dealMemo?.end);
    const tcEnd = moment(endsOn);
    const tcStart = tcEnd.clone().startOf('week');

    if (dealStart.isAfter(tcEnd) || dealEnd.isBefore(tcStart)) {
      return false;
    }
    return true;
  },
);

const getAllDaysValidOnDeal = createSelector(
  [getFormValues(FORM_NAME), getEndsOn],
  (timecard, endsOn) => {
    const { dealMemo = {} } = timecard;

    if (_.isEmpty(dealMemo)) {
      return false;
    }

    const dealStart = moment(dealMemo?.start);
    const dealEnd = moment(dealMemo?.end);

    if (Array.isArray(timecard.days) === false) {
      console.error('Timecard days is not an array');
      return false;
    }
    let allValid = true;
    timecard.days.forEach(day => {
      if (!day?.dayType?.id) return;

      const dayDate = moment(day.date);
      if (dayDate.isBefore(dealStart) || dayDate.isAfter(dealEnd)) {
        allValid = false;
      }
    });

    return allValid;
  },
);

export const getIsTcEditable = createSelector(
  [getTimecardStatus, getTimecard, getProjectUser],
  (status, timecard, user) => {
    try {
      const worksightId = user?.workSightId || user?.worksightId;
      const { user: tcUser = {}, id } = timecard;

      const tcUserWorksight = tcUser?.worksightId || tcUser?.workSightId;

      if (id === NEW_TC_ID) return true;
      db(
        'IS TC EDITABLE\n',
        'status\n',
        status,
        'worksightId\n',
        worksightId,
        'tcUserWorksight\n',
        tcUserWorksight,
      );

      const userOwnsTimecard = !!worksightId && worksightId === tcUserWorksight;

      if (status === TIMECARD_INCOMPLETE && userOwnsTimecard) {
        return true;
      }

      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  {
    devModeChecks: {
      inputStabilityCheck: 'never',
      identityFunctionCheck: 'never',
    },
  },
);

export const getAdditionalFields = createSelector(
  [getColumns, getCurrentProject],
  (allColumns, project) => {
    const isCanada = isRegionCanada(project.region);

    let columns = allColumns.filter(
      col =>
        (col.display === 'additional' || col.display === 'show') &&
        !col.secondPair,
    );
    if (isCanada) {
      columns = columns.filter(col => col.accessor !== 'city');
    }

    return columns;
  },
);
export const getVisibleColumns = createSelector(
  [getColumns, getCurrentProject],
  (allColumns, project) => {
    const isCanada = isRegionCanada(project.region);
    let columns = allColumns.filter(
      col => col.display !== 'additional' && col.display !== 'hide',
    );
    if (isCanada) {
      columns = columns.filter(col => col.accessor !== 'city');
    }
    return columns;
  },
);

const getFormErrors = getFormSyncErrors(FORM_NAME);

export const getDisabledBtns = createSelector(
  [
    getRawLoading,
    getIsTcEditable,
    isDirty(FORM_NAME),
    getFormErrors,
    anyAllowanceMissingFile,
    getNewComment,
    getTimecardId,
    state => formSelector(state, 'allowances'),
    getIsDealValid,
    getAllDaysValidOnDeal,
  ],
  (
    loadingObj,
    isTcEditable,
    dirty,
    formErrors,
    missingAlloFile,
    comment,
    timecardId,
    allowances = [],
    isDealValid,
    allDaysValidOnDeal,
  ) => {
    const submittingEmp = loadingObj.submittingEmp;
    const loading = loadingObj.timecard;
    const saving = loadingObj.saving;
    const hasGlobalError = formErrors.global;
    const hasErrors = formErrors.valid === false;
    db('formErrors:', formErrors);

    const submitTooltip = !isDealValid
      ? 'Valid Deal Memo is required'
      : !allDaysValidOnDeal
      ? 'Some day(s) on Timecard are not valid for this deal.  Save timecard to remove invalid days before submission. You may need to make a small change to enable save, then change it back after the invalid days have been removed. '
      : hasGlobalError
      ? formErrors.global
      : hasErrors
      ? 'Cannot submit with errors'
      : missingAlloFile
      ? 'Attach required supporting document to your allowances'
      : !!comment
      ? 'Save or clear comment'
      : '';

    const temp = loading || saving || submittingEmp;

    const forceSaveEnable =
      timecardId === NEW_TC_ID && allowances.length > 0 && !temp;

    const disableSaveSubmit = temp || !isDealValid || !!comment;

    const disableSave =
      (disableSaveSubmit || !isTcEditable || !dirty) && !forceSaveEnable;

    const disableSubmit =
      disableSaveSubmit ||
      hasErrors ||
      missingAlloFile ||
      submittingEmp ||
      !allDaysValidOnDeal;

    const disableTopActions = temp || !isTcEditable;

    return {
      disableOnLoading: temp,
      disableSubmit,
      disableSave,
      disableTopActions, //week picker + copy from prev + deal memo
      disableActions: disableTopActions || !isDealValid, //add allowance + comments
      submitTooltip,
    };
  },
);

export const getSavedOnce = state =>
  _.get(state, 'empTimecard.savedOnce', false);

export const getUserLoadingStatus = state =>
  _.get(state, 'empTimecard.userLoadingStatus');

export const getDeletedTimecardId = state =>
  _.get(state, 'empTimecard.deletedTimecardId', null);

export const getRoundTos = state => _.get(state, 'empTimecard.roundTos', {});

export const getTypeAOptions = createSelector(
  [getAllowanceTypes, getActiveDealMemo],
  (types, dealMemo) => {
    const dealAllowances =
      dealMemo?.dealMemoAllowances?.filter(
        a =>
          (a.frequency === 'F' || a.frequency === 'D') &&
          !_.isEmpty(a.payCode1) &&
          !_.isEmpty(a.payCode2),
      ) || [];

    const typeAOptions = {};

    dealAllowances.forEach(dealAllowance => {
      const sequenceNumber = dealAllowance.sequenceNumber;

      const option1 = types.find(t => t.id === dealAllowance?.payCode1.id);
      const option2 = types.find(t => t.id === dealAllowance?.payCode2.id);

      if (!option1 || !option2) {
        db('Missing a payCode, skipping A type option');
        return;
      }

      typeAOptions[sequenceNumber] = [option1, option2];
    });

    return typeAOptions;
  },
);
