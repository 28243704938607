import _ from 'lodash';
import { createSelector } from 'reselect';

export const getFlags = state => _.get(state, 'flags.list', []);
export const getLoading = state => _.get(state, 'flags.loading', false);

export const isFlagEnabled = (state, name) => {
  const flag = _.find(state.flags.list, flag => flag.name === name);

  return (flag && flag.enabled) || false;
};

/**
 * If one of these is in paycode2 (non taxable) and the we have a value for paycode 1,
 * default to paycode 2
 * @returns
 */
export const getDefaultPayCodes = createSelector(getFlags, flags => {
  const defaultNonTaxableCodes = [
    'EQR', // Box Rental EQR
    'PD1', // Per Diam
    'COMPNON', // Computer Rental
    'PD2', // Housing
  ];
  const defaultPayCodes = [];

  const defaultAllowanceTypes = flags.find(
    flag => flag.id === 'allowance_types',
  );
  if (defaultAllowanceTypes) {
    const enabled = defaultAllowanceTypes.enabled;
    Object.keys(enabled).forEach(key => {
      if (enabled[key] === true || `${enabled[key]}`.toLowerCase() === 'true') {
        defaultPayCodes.push(key);
      }
    });
  } else {
    return defaultNonTaxableCodes;
  }
  return defaultPayCodes;
});
