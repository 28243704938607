import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import clsx from 'clsx';
import {
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TableCell,
  TableRow,
  CircularProgress,
  Hidden,
  Button,
  Tooltip,
  Grid,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDidMount } from 'utils/customHooks';

import ExpandMore from '@mui/icons-material/ExpandMore';

import { FaDownload as DownloadIcon } from 'react-icons/fa';

import { REJECTION_FLOW_STANDARD } from 'components/Shared/constants';
import { DH, UPM } from 'components/props/profiles';
import TimecardSection from './TimecardSection';
import { ErrorMsg } from 'components/Shared/Text';
import { getDownloadingByBatchId } from 'selectors/reviews';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '100%',
    padding: 0,
  },
  column: {
    fontWeight: 700,
    flexBasis: '65%',
    color: palette.getDefaultColor,
    verticalAlign: 'middle',
    paddingTop: 10,
    paddingBottom: 10,
    padding: 0,
  },
  countColumn: {
    fontSize: 16,
    fontWeight: 'regular',
    flexBasis: '30%',
    color: palette.gray.primary,
    textAlign: 'center',
    verticalAlign: 'middle',
    paddingTop: 10,
    paddingBottom: 10,
    padding: 0,
    '& > *': {
      backgroundColor: `${palette.primary.main}10`,
      padding: '7px 11px',
    },
  },
  hasReadyTimecards: {
    '& > *': {
      borderRadius: 5,
      backgroundColor: palette.primary['+7'],
    },
  },
  tableCellDisplay: {
    display: 'table-cell',
  },
  actionColumn: {
    verticalAlign: 'middle',
    display: 'flex',
    flexBasis: '52%',
    justifyContent: 'flex-end',
  },
  expansionContent: {
    boxShadow: `4px 0 0 ${palette.primary.main} inset`,
    backgroundColor: palette.background.paper,
    display: 'block',
    padding: 8,
  },
  downloadIcon: {
    marginRight: 5,
  },
  progress: {
    marginLeft: 8,
    color: `${palette.primary.main}`,
  },
  mobileButtonPadding: {
    paddingTop: 5,
    '& > button': {
      margin: '2% 10%',
    },
  },
  summaryContent: {
    justifyContent: 'space-between',
  },
  avoidWrap: {
    display: 'inline-block',
  },
  downloadProgress: {
    padding: `6px 54px`,
  },
}));

const mapStateToProps = (state, ownProps) => ({
  downloading: getDownloadingByBatchId(state, ownProps?.batch?.details?.id),
});

function BatchRow({
  batch = {},
  projectId,
  downloading,
  onApproveBatch,
  onRejectBatch,
  onViewTimecard,
  onViewApprovalFlow,
  onViewUPMTimecard,
  onSubmitAsRejected,
  onFetchBatchTimecards,
  onDownloadTimecardsReport,
  reviewType,
  role,
  timecardLoadingByBatch,
  displayComment,
  isProcessingApproval,
}) {
  const classes = useStyles();
  const batchDetails = batch.details;
  const buttons = [];

  const [selected, setSelected] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [expanded, setExpanded] = useState(
    !!(batchDetails?.stats?.readyForReview > 0),
  );
  const [orderBy, setSortBy] = useState('employee');
  const [direction, setDirection] = useState('asc');

  const readOnly = !(reviewType === 'ready' || reviewType === 'open');

  const sourceTCs = useMemo(
    () => batch.timecards || batch.approvalFlows || [],
    [batch],
  );

  const timecards = useMemo(() => {
    let timecards = _.sortBy(sourceTCs, record => {
      const timecard = record.timecard || record;
      switch (orderBy) {
        case 'userAccountCode':
          return [
            record.userAccountCode,
            timecard?.user?.lastName?.toLowerCase() || '',
          ];
        case 'employee':
          return timecard?.user?.lastName?.toLowerCase() || '';
        case 'totalHours':
          return timecard?.brokenData?.totalHours?.toFixed(2) || 0;
        case 'totalAllowances':
          return timecard?.brokenData?.totalAllowances?.toFixed(2) || 0;
        case 'totalGrossWage':
          return timecard?.brokenData?.totalGrossWage?.toFixed(2) || 0;
        case 'occupationCode':
          return [
            record?.occupationCode?.code?.toLowerCase(),
            record?.occupationCode?.name?.toLowerCase(),
            timecard?.user?.lastName?.toLowerCase() || '',
          ];
        default:
          return timecard[orderBy];
      }
    });

    if (direction === 'desc') {
      timecards.reverse();
    }
    return timecards;
  }, [sourceTCs, orderBy, direction]);

  useDidMount(() => {
    //Fetch data if batch will open on load
    if (batchDetails?.stats?.readyForReview > 0) {
      onFetchBatchTimecards(batch?.details?.id);
    }
  });

  useEffect(() => {
    //removed selection if timecards change
    setSelected([]);
  }, [sourceTCs]);

  useEffect(() => {
    setSubmitting(false);
  }, [selected]);
  let canSubmitAsRejected = false;

  //Download timecard button tooptip title
  const items = [
    { left: 'Timecards:', right: batchDetails?.totalTimecards },
    {
      left: 'Download: ',
      right: `${batchDetails?.stats?.total} of ${batchDetails?.stats?.total}`,
    },
  ];
  if (batchDetails?.stats?.total !== batchDetails?.totalTimecards) {
    items.splice(1, 0, {
      left: 'My workflow:',
      right: `${batchDetails?.stats?.total} of ${batchDetails?.totalTimecards} `,
    });
  }
  const downloadBtnTooltipTitle = (
    <div>
      {items.map(item => (
        <Grid
          key={item.left}
          container
          spacing={1}
          style={{ whiteSpace: 'nowrap' }}
        >
          <Grid item xs={5}>
            {item.left}
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={5}>
            {item.right}
          </Grid>
        </Grid>
      ))}
    </div>
  );
  const onDownloadBatch = React.useCallback(
    e => {
      e.stopPropagation();

      const tcEntryHeaderIds = timecards.map(tc => tc.entryHeaderId);
      onDownloadTimecardsReport({
        timecards: tcEntryHeaderIds,
        batchId: batchDetails.id,
        htgId: batchDetails.htgId,
        fetchBatch: !expanded && tcEntryHeaderIds.length === 0,
      });
    },
    [timecards, batchDetails, expanded, onDownloadTimecardsReport],
  );

  if (role === UPM && (reviewType === 'ready' || reviewType === 'history')) {
    if (batchDetails.eSigned) {
      buttons.push(
        <Button
          variant="outlined"
          color="primary"
          onClick={onDownloadBatch}
          disabled={downloading}
          key={'verifiedBatch'}
        >
          {downloading ? (
            <CircularProgress
              className={classes.downloadProgress}
              color="primary"
              size={14}
              thickness={6}
            />
          ) : (
            <>
              <DownloadIcon className={classes.downloadIcon} />
              Verified Batch
            </>
          )}
        </Button>,
      );
    }
    if (!batchDetails.eSigned) {
      buttons.push(
        <Tooltip
          arrow
          placement="top"
          key={'downloadBatch'}
          title={downloadBtnTooltipTitle}
        >
          <Button
            variant="outlined"
            color="primary"
            disabled={submitting || downloading}
            onClick={onDownloadBatch}
          >
            {downloading ? (
              <CircularProgress
                className={classes.downloadProgress}
                color="primary"
                size={14}
                thickness={6}
              />
            ) : (
              <>
                <DownloadIcon className={classes.downloadIcon} />
                Download Timecards
              </>
            )}
          </Button>
        </Tooltip>,
      );
    }
  }

  if (role === DH || role === UPM) {
    if (!readOnly) {
      buttons.push(
        <Button
          className="pendo_DH-UPM_bulk_reject"
          variant="outlined"
          onClick={e => {
            e.stopPropagation();
            setSubmitting(true);
            onRejectBatch(batchDetails, selected);
            setSubmitting(true);
          }}
          disabled={(selected.length ? false : true) || submitting}
          key={'reject'}
        >
          Reject
        </Button>,
        <Button
          onClick={e => {
            e.stopPropagation();
            setSubmitting(true);
            onApproveBatch(batchDetails, selected);
            setSubmitting(true);
          }}
          disabled={(selected.length ? false : true) || submitting}
          key={'approve'}
        >
          Approve
        </Button>,
      );
    }
  }

  if (role === DH) {
    if (!_.isEmpty(timecards) && reviewType === 'pending') {
      const firstTC = _.first(timecards);
      canSubmitAsRejected =
        firstTC && firstTC.rejectionWorkflow !== REJECTION_FLOW_STANDARD;
      if (canSubmitAsRejected) {
        buttons.push(
          <Button
            onClick={e => {
              e.stopPropagation();
              onSubmitAsRejected(selected);
            }}
            key={'submitReject'}
            disabled={selected.length === 0 || isProcessingApproval}
          >
            Submit As Rejected
          </Button>,
        );
      }
    }
  }

  function handleSelect(id) {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  }

  function handleSelectAll(checked) {
    if (checked) {
      const newSelected = timecards
        .filter(n => n.status !== 'draft')
        .map(n => n.entryHeaderId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  }

  function handleExpand() {
    if (!expanded) {
      onFetchBatchTimecards(batch?.details?.id);
    }
    setExpanded(!expanded);
  }

  const areTimecardsLoading =
    timecardLoadingByBatch && timecardLoadingByBatch[batchDetails.id];

  const batchError =
    timecardLoadingByBatch && timecardLoadingByBatch[batchDetails.id] === null;

  const showReadyForReview = batchDetails?.stats?.readyForReview > 0;

  if (submitting && !areTimecardsLoading) setSubmitting(false);

  return (
    <TableRow className={classes.root}>
      <TableCell colSpan={3}>
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            classes={{
              content: classes.summaryContent,
            }}
            onClick={handleExpand}
          >
            <div className={classes.column}>
              <span className={classes.avoidWrap}>{batchDetails.htgId}</span>{' '}
              {/* Spans are here to control line breaks */}
              <span className={classes.avoidWrap}>{'-'} </span>{' '}
              <span className={classes.avoidWrap}>{batchDetails.name}</span>
              {areTimecardsLoading && (
                <CircularProgress
                  className={classes.progress}
                  color="primary"
                  size={14}
                  thickness={6}
                />
              )}
              {batchError && (
                <ErrorMsg display={'inline'}>
                  Error Loading. Contact Support.
                </ErrorMsg>
              )}
            </div>
            <div
              className={clsx(classes.countColumn, {
                [classes.hasReadyTimecards]: showReadyForReview,
              })}
            >
              <span className={classes.tableCellDisplay}>
                {batchDetails.stats.total}
              </span>
            </div>
            <Hidden mdDown>
              <div className={classes.actionColumn}>{buttons}</div>
            </Hidden>
          </AccordionSummary>
          <Divider />
          <AccordionDetails classes={{ root: classes.expansionContent }}>
            <TimecardSection
              handleSelect={handleSelect}
              handleSelectAll={handleSelectAll}
              onViewTimecard={onViewTimecard}
              onViewApprovalFlow={onViewApprovalFlow}
              readOnly={readOnly}
              reviewType={reviewType}
              role={role}
              selected={selected}
              timecards={timecards}
              batch={batchDetails}
              timecardLoadingByBatch={timecardLoadingByBatch}
              canSubmitAsRejected={canSubmitAsRejected}
              displayComment={displayComment}
              orderBy={orderBy}
              setSortBy={setSortBy}
              direction={direction}
              setDirection={setDirection}
              enableGross={role === UPM}
            />
            <Hidden mdUp>
              <div className={classes.mobileButtonPadding} align="center">
                {buttons}
              </div>
            </Hidden>
          </AccordionDetails>
        </Accordion>
      </TableCell>
    </TableRow>
  );
}

export default connect(mapStateToProps, null)(BatchRow);
