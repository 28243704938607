import { all, takeEvery, call, put, delay } from 'redux-saga/effects';
import camelCase from 'camelcase-keys';
import { startSubmit, stopSubmit } from 'redux-form';
import { push } from 'redux-first-history';

//actions
import * as actions from 'actions/invitations';
import { showAlert } from 'actions/alert';

export function* fetchInvitations(api, debug, params) {
  try {
    yield put(actions.loadingInvitations({ loading: true }));
    const { token } = params;
    const result = yield call(api.invitations.getInvitation, { token });
    const invitation = camelCase(result, { deep: true });
    yield put(actions.storeInvitations({ invitation }));
    yield put(actions.loadingInvitations({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(
      showAlert({
        message: 'Error fetching invitation, redirecting...',
        variant: 'error',
      }),
    );
    yield put(push('/'));
  }
}

export function* saveInvitations(api, debug, params) {
  const formName = 'Invitations';
  try {
    const data = camelCase(params.values);
    const token = params.token;
    yield put(startSubmit(formName));
    yield call(api.invitations.saveInvitations, data, token);

    //trigger full reload to ensure we get the proper user data after they've been invited successfully
    yield delay(1500);
    yield put(stopSubmit(formName));
    window.location.href = window.location.origin;
  } catch (e) {
    if (e && e.status === 422 && e.data) {
      const errors = e.data ? camelCase(e.data, { deep: true }) : null;
      yield put(stopSubmit(formName, { _error: { ...errors } }));
    } else {
      yield put(stopSubmit(formName));
    }
  }
}

export default function* invitations({ api, debug }) {
  yield all([
    takeEvery(`${actions.fetchInvitations}`, fetchInvitations, api, debug),
    takeEvery(`${actions.saveInvitations}`, saveInvitations, api, debug),
  ]);
}
