import { all, takeLatest, call, put, select, delay } from 'redux-saga/effects';
import { replace } from 'redux-first-history';
//actions
import * as actions from './actions';
import { showAlert } from 'actions/alert';

//selectors
import { getProject } from 'selectors/routeParams';
import { getFilterDbCode, getRouteYear } from 'selectors/project';
import {
  getAllFilters,
  getPageSize,
  getSortByForReq,
  getRemovedSelectedOption,
  getStatusWithRole,
} from './selectors';

//constant + utils
import {
  makeFilterArgFactory,
  STATUS_FILTERS,
  OPEN_COMMENT_PARAM,
} from './searchUtils';
import { delayOnValue } from 'utils/helperFunctions';
import { currentUser } from 'selectors/session';
import { IA } from 'components/props/profiles';

function* init() {
  yield put(actions.fetch({ init: true }));
}

export function* fetch(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(getProject);
    const { init, filterName: activeFilter } = params;

    const activeUser = yield select(currentUser);
    const searchParams = new URLSearchParams(window.location.search);
    const openComment = searchParams.get(OPEN_COMMENT_PARAM);
    let filters = yield select(getAllFilters);

    if (init) {
      if (!!openComment && activeUser.projectRoles.includes(IA)) {
        let statusFilters = yield select(getStatusWithRole);
        const updatedStatus = statusFilters
          .filter(s => s.value !== 'paid')
          .map(s => s.value);

        let invoiceIdFilter = [];

        try {
          //translate invoice guid to invoice code for filter
          const invoice = yield call(api.digitalEdits.fetchInvoice, {
            projectId,
            invoiceId: openComment,
          });

          invoiceIdFilter = [invoice.code];
          if (!invoice.code) throw new Error('No invoice code found');
        } catch (error) {
          console.error('Unable to parse invoice code from openComment');
        }

        yield put(
          actions.setInitialFilters({
            filters: { status: updatedStatus, invoiceId: invoiceIdFilter },
          }),
        );
      } //end openComment check

      yield delay(50); //let the filters update before fetching data

      filters = yield select(getAllFilters);
      filters = yield call(parseUrlFilters, filters);
    }

    const statusFilter = filters.find(f => f.field === 'status');

    const shouldUpdateFilters = activeFilter === 'status' || init;

    const sortBy = yield select(getSortByForReq);

    const makeFilterArgs = makeFilterArgFactory({
      fetchFilter,
      api,
      debug,
      statusFilter,
      projectId,
    });

    // filters to refresh with the current filter arguments
    const names = [
      'invoiceEditsSent',
      'invoiceDescription',
      'invoiceId',
      'invoiceWeekendingDate',
      'invoiceType',
      'futureReleaseDate',
    ];
    if (shouldUpdateFilters) {
      yield all([
        ...names.map(name => call(...makeFilterArgs(name))), //filter calls
        call(fetchData, api, debug, { filters, projectId, sortBy }), //Data Call
        call(fetchFiltersCount, api, debug, { filters, projectId, sortBy }), // badges filter count call
      ]);
    } else {
      yield call(fetchData, api, debug, { filters, projectId, sortBy });
    }

    const removedSelected = yield select(getRemovedSelectedOption);
    if (removedSelected) {
      // The last filter change removed a selected option.
      // Need to re-fetch data with the current filters
      const newFilters = yield select(getAllFilters);
      yield put(
        actions.setRemovedSelectedOption({ removedSelectedOption: false }),
      );
      yield call(fetchData, api, debug, {
        filters: newFilters,
        projectId,
        sortBy,
      });
    }
  } catch (e) {
    debug(e);
    showAlert();
  } finally {
    yield put(actions.loading({ loading: false }));
    yield put(
      actions.setRemovedSelectedOption({ removedSelectedOption: false }),
    );
  }
}

/**
 * Update initial filters based on URL params
 * currently we can only use 1 url param at a time because myCnC strips out additonal params
 * on login redirect.
 *
 * Currently this only Supports the status filter, which is also being set
 * by the open comment param but since can use 1 they shouldn't overlap
 * @returns
 */
function* parseUrlFilters(currentFilters) {
  const searchParams = new URLSearchParams(window.location.search);
  const statusParams = searchParams.get('status');
  if (statusParams) {
    let updatedValues = statusParams.split(',');
    const validFilters = STATUS_FILTERS.map(f => f.value);
    updatedValues = updatedValues.filter(status =>
      validFilters.includes(status),
    );
    const statusFilter = currentFilters.find(f => f.field === 'status');
    statusFilter.values = updatedValues;
    yield put(
      actions.setInitialFilters({ filters: { status: updatedValues } }),
    );

    searchParams.delete('status');

    const newUrl = window.location.pathname + '?' + searchParams.toString();

    yield put(replace(newUrl));
  }

  return currentFilters;
}

function* fetchFilter(api, debug, params) {
  try {
    const projectId = params.projectId;
    const filterName = params.filterName;
    let filters = params.filters.slice();

    yield addDbCodeFilter(filters);

    const filterParams = {
      filters,
      type: filterName,
      page: 0,
      pageSize: filterName === 'invoiceId' ? 2000 : 150,
    };

    const data = yield call(api.searchInvoices.filter, {
      projectId,
      filterParams,
    });
    yield put(actions.storeFilterOptions({ filterName, data }));
  } catch (e) {
    debug(e);
  }
}

function* fetchData(api, debug, params) {
  try {
    const projectId = params.projectId;
    const filters = params.filters.slice();
    const pageSize = yield select(getPageSize);
    const sortBy = params.sortBy;

    yield addDbCodeFilter(filters);

    const dataParams = {
      filters,
      sortBy,
      page: 0,
      pageSize,
    };

    const data = yield call(api.searchInvoices.getData, {
      projectId,
      dataParams,
    });
    const removedSelected = yield select(getRemovedSelectedOption);
    if (!removedSelected) {
      //Only store data if we don't have another data fetch pending because we removed a selected filter option
      yield put(actions.store({ data }));
    }
  } catch (e) {
    debug(e);
    // yield put(showAlert({ message: 'Error updating search data' }));
    yield put(showAlert());
  }
}

function* addDbCodeFilter(filters) {
  const dbCode = yield delayOnValue(getFilterDbCode);

  const dbCodeFilter = {
    field: 'dbCode',
    type: 'key',
    values: [dbCode],
  };

  filters.push(dbCodeFilter);
}

function* fetchFiltersCount(api, debug, params) {
  try {
    const projectId = params.projectId;
    const year = yield delayOnValue(getRouteYear);
    const filterCount = yield call(api.searchInvoices.getFilterBadgesCount, {
      projectId,
      year,
    });
    yield put(actions.fetchFilterBadgesCount({ filterCount }));
  } catch (e) {
    debug(e);
  }
}

export default function* searchInvoices({ api, debug }) {
  yield all([
    takeLatest(`${actions.init}`, init, api, debug),
    takeLatest(`${actions.fetch}`, fetch, api, debug),
  ]);
}
