import React from 'react';
import PropTypes from 'prop-types';
import { Box, Drawer, Paper, ClickAwayListener, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PencilLine from 'components/images/PencilLine';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import MobileMenuLinkBtn from 'containers/Layout/Headers/components/MobileMenuLinkBtn';
import MobileMenuLink from './MobileMenuLink';
import ProjectMobileV2 from 'containers/Layout/Headers/components/ProjectMobileV2';

import clacker from 'assets/icons/clacker.png';

import { ADJUSTMENT_URL } from 'components/Shared/constants';

const useStyles = makeStyles(({ palette }) => ({
  HeaderV2MobileDrawer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  projectIcon: {
    width: '20px',
    height: '20px',
  },
  hoursHelperText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
    '& > div:first-child': {
      fontWeight: 'bold',
    },
    '& > div:last-child': {
      fontSize: 12,
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'italic',
    },
  },
}));

const HeaderV2MobileDrawer = props => {
  const classes = useStyles();
  const { project, open, setOpen, projectsLink, showSubmitAdjustment } = props;

  const projectName = project?.name;

  // swipe to close
  // https://stackoverflow.com/a/70612770/901311
  const [touchStart, setTouchStart] = React.useState(null);
  const [touchEnd, setTouchEnd] = React.useState(null);
  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;
  const onTouchStart = e => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };
  const onTouchMove = e => setTouchEnd(e.targetTouches[0].clientX);
  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    // const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) setOpen(false);
  };

  return (
    <Box className={classes.HeaderV2MobileDrawer}>
      <Drawer open={open}>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100vh',
            }}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 0 }}>
              <Box sx={{ pt: 1 }}>
                <MobileMenuLinkBtn
                  text="My Dashboard"
                  icon={<HomeOutlinedIcon />}
                  to={projectsLink}
                  onClick={() => {
                    const myCnCLink = document.querySelector(
                      '[data-test-id="globalNav-cncLogo-button"]',
                    );
                    if (myCnCLink) {
                      myCnCLink.click();
                    } else {
                      window.location.href = 'https://my.castandcrew.com';
                    }
                  }}
                />
              </Box>
              <Divider />
              <Box className={classes.hoursHelperText}>
                <Box>Hours + </Box>
                <Box>
                  Timekeeping
                  <KeyboardArrowDownIcon sx={{ color: 'text.secondary' }} />
                </Box>
              </Box>
              <Box sx={{}}>
                <MobileMenuLink
                  text="All Projects"
                  icon={
                    <img
                      src={clacker}
                      alt="Project Icon"
                      className={classes.projectIcon}
                    />
                  }
                  to={projectsLink}
                />
              </Box>
              <Divider sx={{ mb: 2 }} />
            </Box>
            <Box sx={{ flex: '1 1 auto', overflow: 'auto', pl: 2 }}>
              {projectName && <ProjectMobileV2 />}
            </Box>
            <Box sx={{ flex: 0 }}>
              <Divider />
              {showSubmitAdjustment && (
                <MobileMenuLinkBtn
                  text="Submit Adjustment"
                  icon={<PencilLine />}
                  onClick={() => {
                    window.open(ADJUSTMENT_URL, '_blank');
                  }}
                />
              )}
              <MobileMenuLink
                text="Settings"
                icon={<SettingsOutlinedIcon />}
                to="/me/profile"
              />
              <MobileMenuLinkBtn
                text="Resource Center"
                icon={<HelpOutlineIcon />}
                id="MyCnC_Resource_Center"
              />
            </Box>
          </Paper>
        </ClickAwayListener>
      </Drawer>
    </Box>
  );
};

HeaderV2MobileDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  showSubmitAdjustment: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired,
  projectsLink: PropTypes.string.isRequired,
};

export default HeaderV2MobileDrawer;
