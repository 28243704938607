import React from 'react';
import { Header, DefaultLayout } from 'containers/Layout';
//subnav
import { ProfileDetails } from 'containers/Nav/Pages';

const Settings = React.lazy(() =>
  import('containers/Employees/Profiles/Preferences'),
);

// me/timecards and me/review routes are in other route files
const meRoutes = [
  {
    path: '/me/preferences',
    redirect: '/me/settings',
  },
  {
    path: '/me/profile',
    redirect: '/me/settings',
  },
  {
    title: 'Settings',
    path: '/me/settings',
    headers: [Header, ProfileDetails],
    layout: DefaultLayout,
    component: Settings,
  },
];

export default meRoutes;
