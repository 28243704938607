import React from 'react';

import {
  Header,
  ProjectHeader,
  FullWidthLayout,
  MaxWidthLayout,
} from 'containers/Layout';

import projectReviewsRoutes from './projectReviewsRoutes';

//components
const ReviewApprovalFlow = React.lazy(() =>
  import('containers/Employees/Reviews/ReviewApprovalFlow'),
);
const ReviewTimecard = React.lazy(() =>
  import('containers/Employees/Reviews/ReviewTimecard'),
);
const Timecard = React.lazy(() =>
  import('containers/Employees/Timecards/Timecard'),
);
const Create = React.lazy(() =>
  import('containers/Employees/Timecards/Create'),
);

// GPT sourced ID to enable lazy loading + find references from the exported value.
//requires come to NOT export default, but instead export a named export called ExportComp
// const Create = React.lazy(() =>
//   import('containers/Employees/Timecards/Create').then(module => ({
//     default: module.ExportComp,
//   })),
// );
//Tried this but had issues on some components for unknown reasons. keeping as a reminder that maybe its possible,
// but tagging with comments for now

const projectLegacyTimecardRoutes = [
  {
    title: 'Review Timecard', //DH review
    path: '/projects/:projectId/reviews/batches/:batchId/approval-flows/:approvalFlowId',
    headers: [Header, ProjectHeader],
    layout: FullWidthLayout,
    component: ReviewApprovalFlow,
    waitForUserToLoad: true,
  },
  {
    title: 'Review Timecards', //DH draft view
    path: '/projects/:projectId/reviews/timecards/:timecardId',
    headers: [Header, ProjectHeader],
    layout: FullWidthLayout,
    component: ReviewTimecard,
    waitForUserToLoad: true,
  },
  {
    title: 'Create Timecard',
    path: '/projects/:projectId/me/timecardsv1/create',
    headers: [Header, ProjectHeader],
    layout: FullWidthLayout,
    component: Create,
    waitForUserToLoad: true,
  },
  {
    title: 'Timecard',
    path: '/projects/:projectId/me/timecardsv1/:timecardId',
    headers: [Header, ProjectHeader],
    layout: MaxWidthLayout,
    component: Timecard,
    waitForUserToLoad: true,
  },
  ...projectReviewsRoutes,
];

export default projectLegacyTimecardRoutes;
