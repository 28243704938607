import React from 'react';
import { compose, isRegionCanada } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import clsx from 'clsx';
import {
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Divider,
  Autocomplete,
  Chip,
  LinearProgress,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';

import {
  TitleBox,
  ContentBox,
  FooterBox,
  HeaderText,
  Button,
  TextField,
  FooterButton,
  StyledInputLabel,
} from 'feature/EmployeeTimecard/Shared/styledComponents';
import CloseIcon from '@mui/icons-material/Close';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckIcon from '@mui/icons-material/Check';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import {
  CREW_MEMBER_TC_FIELDS,
  CREW_MEMBER_DESCRIPTION_MAX_LENGTH,
  HIDE_CANADA_FIELDS,
} from './settingsUtils';

//decorators
import withModalDialog from 'decorators/withModalDialog';

//actions
import * as actions from 'actions/settings';
import { hide as hideModal } from 'actions/modalDialog';

//selectors
import {
  getCrewMemberVisibleFields,
  getSettings,
  getLoading,
  getCrewMemberInitialSettings,
} from 'selectors/settings';
import { getCurrentProject } from 'selectors/project';
import { getProject } from 'selectors/routeParams';

export const CREW_MEMBER_DIALOG = 'CrewMemberTimecardSettings';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  title: {
    fontSize: '22px',
    fontWeight: 'bold',
    [breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  fieldDescription: {
    width: '550px',
    marginBottom: '5px',
    [breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  menuList: {
    height: '500px',
    '& .MuiPaper-root ': {
      width: '550px',
    },
    [breakpoints.down('sm')]: {
      height: '400px',
      '& .MuiPaper-root ': {
        width: '300px',
      },
    },
  },
  menuItem: {
    backgroundColor: palette.gray['+9'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${palette.gray['+10']}`,
    padding: '10px',
  },
  chipsContent: {
    display: 'flex',
    alignItems: 'center',
  },
  chipLabel: {
    backgroundColor: palette.gray['+10'],
    borderRadius: '7px',
    border: `1px solid ${palette.gray['+7']}`,
    marginRight: '25px',
    color: palette.text.primary,
    fontSize: '14px',
    fontWeight: 600,
  },
  autoComplete: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      paddingRight: '10px !important',
    },
    '& .MuiInputBase-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '& .MuiInputBase-input': {
      opacity: 0, // hide the input value
    },
  },
  icon: {
    fontSize: '24px !important',
  },
  listOption: {
    padding: '10px',
    '&:hover': {
      backgroundColor: palette.primary['+9'],
    },
  },
  selectedOption: {
    backgroundColor: palette.primary['+8'],
  },
  dropdownIcon: {
    backgroundColor: palette.gray['+9'],
    borderBottom: `solid 1px ${palette.gray['-5']}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50px',
  },
  placeholderText: {
    fontWeight: '500',
    color: palette.text.primary,
  },
  description: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputBase-input': {
      paddingLeft: '0px',
    },
    '& .MuiInputBase-input:focus': {
      borderBottom: `solid 1px ${palette.primary.main}`,
    },
  },
}));

const mapState = state => ({
  loading: getLoading(state),
  crewMemberVisibleFields: getCrewMemberVisibleFields(state),
  crewInitialSettings: getCrewMemberInitialSettings(state),
  project: getCurrentProject(state),
  settings: getSettings(state),
  projectId: getProject(state),
});

const mapDispatch = dispatch => ({
  handleClose: (settings, projectId, cachedOn) => {
    dispatch(hideModal({ dialog: CREW_MEMBER_DIALOG }));
    //reset settings on cancel button click
    dispatch(actions.store({ settings, projectId, cachedOn }));
  },
  onSelectAdditionalFields: (field, projectId) =>
    dispatch(
      actions.updateCrewMemberVisibleFields({
        key: field.accessor,
        label: field.label,
        projectId,
      }),
    ),
  onUpdateFieldValues: (value, field, prop, projectId) =>
    dispatch(
      actions.updateCrewMemberFieldValues({ projectId, field, prop, value }),
    ),
  onSave: () => dispatch(actions.saveCrewMemberTCFieldsSettings()),
});

const options = ['Additional Fields', 'Always Show'];

const CrewMemberTCSettings = props => {
  const {
    handleClose,
    crewMemberVisibleFields,
    project,
    settings,
    onUpdateFieldValues,
    projectId,
    onSelectAdditionalFields,
    onSave,
    loading,
    crewInitialSettings,
  } = props;
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const isCanada = isRegionCanada(project.region);

  const handleChange = (newValue, field) => {
    //set selected field value to true and other values to false
    const isRequired = newValue === 'Required' ? true : false;
    onUpdateFieldValues(isRequired, field, 'employeeMandatory', projectId);

    const isAlwaysShow = newValue === 'Always Show' ? true : false;
    onUpdateFieldValues(isAlwaysShow, field, 'employeeVisible', projectId);

    const isAdditionalFields = newValue === 'Additional Fields' ? true : false;
    onUpdateFieldValues(
      isAdditionalFields,
      field,
      'employeeAdditionalFields',
      projectId,
    );
  };
  const handleDescription = (e, field) => {
    const val = e.target.value?.replace(
      /[^a-zA-Z0-9\s.,'";:_!@#$%^&*()+=/|\\-]/g,
      '',
    );
    onUpdateFieldValues(val, field, 'employeeDescription', projectId);
  };

  const handleDelete = (field, value) => {
    if (value === 'Required') {
      onUpdateFieldValues(false, field, 'employeeMandatory', projectId);
    } else if (value === 'Always Show') {
      onUpdateFieldValues(false, field, 'employeeVisible', projectId);
    } else if (value === 'Additional Fields') {
      onUpdateFieldValues(false, field, 'employeeAdditionalFields', projectId);
    }
  };

  let visibleFields = Object.keys(crewMemberVisibleFields);
  let additionalFields = Object.keys(CREW_MEMBER_TC_FIELDS).filter(
    field => CREW_MEMBER_TC_FIELDS[field].isAdditionalField,
  );
  if (isCanada) {
    visibleFields = visibleFields.filter(
      field => !HIDE_CANADA_FIELDS.includes(field),
    );
    additionalFields = additionalFields.filter(
      field => !HIDE_CANADA_FIELDS.includes(field),
    );
  }
  const onCloseModal = () => {
    handleClose(crewInitialSettings, projectId, settings?.cachedOn);
  };
  return (
    <Paper className={classes.CrewMemberModal}>
      {loading && <LinearProgress />}
      <TitleBox>
        <HeaderText className={classes.title}>
          Crew Member Timecard Settings
        </HeaderText>
        <IconButton onClick={onCloseModal}>
          <CloseIcon style={{ fontWeight: 'bold' }} />
        </IconButton>
      </TitleBox>
      <ContentBox>
        <Box sx={{ width: '100%' }}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            sx={{ ml: '0px', fontSize: '15px' }}
            ref={anchorRef}
            startIcon={<AddCircleOutlineIcon />}
            endIcon={<KeyboardArrowDownOutlinedIcon className={classes.icon} />}
            onClick={() => setOpen(!open)}
          >
            Add New Field
          </Button>
          <Menu
            className={classes.menuList}
            anchorEl={anchorRef.current}
            open={open}
            onClose={() => setOpen(false)}
          >
            {additionalFields.map(field => {
              const fieldObj = CREW_MEMBER_TC_FIELDS[field];

              return (
                <MenuItem
                  key={fieldObj.label}
                  className={classes.menuItem}
                  onClick={() => onSelectAdditionalFields(fieldObj, projectId)}
                >
                  <Box>{fieldObj.label}</Box>
                  {!!visibleFields.includes(fieldObj.accessor) && (
                    <Box sx={{ color: 'primary.main' }}>
                      <CheckIcon />
                    </Box>
                  )}
                </MenuItem>
              );
            })}
          </Menu>
        </Box>
        {visibleFields.map(field => {
          let dropdownOptions = _.cloneDeep(options);
          const f = settings[field];

          //dropdown options including required for dropdown and checkbox
          if (f?.type !== 'time') {
            dropdownOptions = [...options, 'Required'];
          }

          let icon = null,
            value = null,
            description = null;
          let fieldLabel = crewMemberVisibleFields[field].label;

          if (!_.isEmpty(f)) {
            description = f.employeeDescription;
            //icon
            if (f.type === 'time') {
              icon = <TimerOutlinedIcon />;
            } else if (f.type === 'autocomplete') {
              icon = (
                <span className={classes.dropdownIcon}>
                  {'...'}
                  <ArrowDropDownIcon />{' '}
                </span>
              );
            } else if (f.type === 'checkbox') {
              icon = <CheckBoxOutlinedIcon />;
            } else if (f.type === 'text') {
              icon = <WysiwygOutlinedIcon />;
            }

            //drowndown selected values
            if (f.employeeMandatory) {
              value = 'Required';
            } else if (f.employeeVisible) {
              value = 'Always Show';
            } else if (f.employeeAdditionalFields) {
              value = 'Additional Fields';
            }
            //changing label for state to province for canada region
            if (isCanada && fieldLabel === 'State') {
              fieldLabel = 'Province';
            }
          }
          return (
            <Box key={field} sx={{ width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <StyledInputLabel sx={{ fontSize: '16px' }}>
                  {fieldLabel}
                </StyledInputLabel>
                <Box>{icon}</Box>
              </Box>
              <Box className={classes.fieldDescription}>
                <TextField
                  fullWidth
                  className={classes.description}
                  inputProps={{ maxLength: CREW_MEMBER_DESCRIPTION_MAX_LENGTH }}
                  value={description}
                  onChange={e => handleDescription(e, field)}
                />
              </Box>
              <Autocomplete
                className={classes.autoComplete}
                value={value}
                options={dropdownOptions}
                onChange={(e, newValue) => handleChange(newValue, field)}
                getOptionLabel={option => option}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    className={clsx(classes.listOption, {
                      [classes.selectedOption]: value === option,
                    })}
                  >
                    {option}
                  </li>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <span className={classes.placeholderText}>
                          Select Option
                        </span>
                      ),
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                          <Box className={classes.chipsContent}>
                            {value && (
                              <Chip
                                className={classes.chipLabel}
                                key={value}
                                deleteIcon={<CloseTwoToneIcon />}
                                label={value}
                                onDelete={() => handleDelete(field, value)}
                              />
                            )}
                          </Box>
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          );
        })}
      </ContentBox>
      <Divider />
      <FooterBox
        sx={{
          position: 'sticky',
          bottom: '0',
          backgroundColor: 'background.paper',
        }}
      >
        <Divider />
        <FooterButton variant="outlined" color="primary" onClick={onCloseModal}>
          Cancel
        </FooterButton>
        <FooterButton
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={onSave}
        >
          Save
        </FooterButton>
      </FooterBox>
    </Paper>
  );
};
export default compose(
  withModalDialog({
    dialog: CREW_MEMBER_DIALOG,
    maxWidth: 'md',
    roundedCorners: true,
  }),
  connect(mapState, mapDispatch),
)(CrewMemberTCSettings);
