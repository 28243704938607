import PropTypes from 'prop-types';
import { departmentProps } from './departments';

export const EMPLOYEE = 'employee';
export const DH = 'department_head';
export const PA = 'payroll_accountant';
export const UPM = 'upm';
export const PC = 'payroll_coordinator';
export const IA = 'invoice_approver';
export const PROJECT_ADMIN = 'project_admin';
export const ROLES = [EMPLOYEE, DH, PA, UPM, PC, IA, PROJECT_ADMIN];

export const ROLE_LABELS = {
  [EMPLOYEE]: 'Employee',
  [DH]: 'Department Head',
  [PA]: 'Payroll Accountant',
  [UPM]: 'Approver',
  [PC]: 'Payroll Coordinator',
  [IA]: 'Invoice Approver',
  [PROJECT_ADMIN]: 'Project Admin',
};

export const ROLE_LABELS_WTC_COMMENTS = {
  [EMPLOYEE]: 'Crew',
  [DH]: 'Department Head',
  [PA]: 'Payroll Accountant',
  [UPM]: 'Approver',
};

export const ROLE_MODULES = {
  [EMPLOYEE]: ['My Timecards'],
  [DH]: ['My Timecards', 'Crew Hire List', 'Crew Timecards', 'Reviews'],
  [PA]: ['My Timecards', 'Crew Hire List', 'Crew Timecards', 'Reviews'],
  [UPM]: ['My Timecards', 'Batches', 'Reviews', 'Reports'],
};

export const profileProps = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  middleName: PropTypes.string,
  fullName: PropTypes.string,
  isAdmin: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  onboarded: PropTypes.bool,
  impersonating: PropTypes.bool,
  projectRoles: PropTypes.arrayOf(PropTypes.oneOf(ROLES)),
});

export const userProps = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  email: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  full_name: PropTypes.string,
  middle_name: PropTypes.string,
  worksight_status: PropTypes.string,
  onboarded: PropTypes.bool,
  impersonating: PropTypes.bool,
  role: PropTypes.oneOf(ROLES),
  department: PropTypes.string,
});

export const usersProps = PropTypes.arrayOf(userProps);

// Use it for `projects/:projectId/me` route. gives you active user Props.
//
export const occupationCodeProps = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  description: PropTypes.string,
  code: PropTypes.string,
});

export const dealMemoProps = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  jobDescription: PropTypes.string,
  description: PropTypes.string,
  code: PropTypes.string,
  endsOn: PropTypes.string,
  isExempt: PropTypes.bool,
  isValid: PropTypes.bool,
  occupationId: PropTypes.string,
  productionCompanyId: PropTypes.string,
  startOn: PropTypes.string,
  unionId: PropTypes.string,
  occupationCode: occupationCodeProps,
});

export const dealMemosProps = PropTypes.arrayOf(dealMemoProps);

export const projectUserProps = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  fullName: PropTypes.string,
  middleName: PropTypes.string,
  inviteStatus: PropTypes.string,
  worksightStatus: PropTypes.string,
  role: PropTypes.oneOf(ROLES),
  department: departmentProps,
  dealMemos: dealMemosProps,
});

export const userInfoProps = PropTypes.object;

//Pretty sure this is only used by Dept Heads, please correct if wrong
export const departmentHeadUserProps = PropTypes.shape({
  role: PropTypes.oneOf([DH]).isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  email: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
});

export const ADMIN_ROLES = ['Admin', 'Super Admin', 'Access Admin'];
