import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import clsx from 'clsx';
import { TableCell } from '@mui/material';
import WTCAutoV2 from '../WTCAutoV2';

import { combineCheckOptions } from 'utils/wtcWeekUtils';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette }) => ({
  tableCell: {
    verticalAlign: 'middle',
    padding: 4,
  },
}));

const AutocompleteCell = props => {
  const classes = useStyles();
  const {
    field: { columnId },
    isMasterRow = false,
    member = '',
    wtcDisabled,
    caRegion,
    isPartialDealMemo = false,
    isUnusedDay = false,
    disableCellClass = '',
  } = props;

  // Don't show work location for canadian projects
  if (caRegion && columnId === 'locationType') {
    return '';
  }

  //dropdown specific props
  //TODO - connect this to redux, remove prop drilling
  const {
    workLocations,

    dayTypes,
    loadDayTypes,

    episodes,
    loadEpisodes,
    //locations
    onResetLoc,

    countries,
    onFetchCountries,

    stateOptions,
    loadStatesOnOpen,

    countyOptions,
    loadCountiesOnOpen,

    cityOptions,
    loadCitiesOnOpen,

    subdivisions,
    loadSubdivisions,
    //meta-codes

    occupationCodes,
    loadOccWithDeal,

    workSchedules,
    loadWorkSchedules,

    onChange,
    upmEnabled,
  } = props;

  const name = isMasterRow ? columnId : `${member}.${columnId}`;

  let fieldProps = {};

  switch (columnId) {
    case 'locationType':
      fieldProps = {
        options: workLocations,
      };
      break;
    case 'dayType':
      fieldProps = {
        options: dayTypes,
        async: true,
        loadOptions: loadDayTypes,
        maxWidth: 100,
      };
      break;
    case 'episode':
      fieldProps = {
        options: episodes,
        async: true,
        loadOptions: loadEpisodes,
      };
      break;
    case 'workCountry':
      fieldProps = {
        options: countries,
        onOpen: onFetchCountries,
      };
      break;
    case 'workState':
      fieldProps = {
        options: stateOptions,
        onOpen: loadStatesOnOpen,
        onClose: () => onResetLoc(columnId),
      };
      break;
    case 'workCounty':
      fieldProps = {
        options: countyOptions,
        onOpen: loadCountiesOnOpen,
        onClose: () => onResetLoc(columnId),
      };
      break;
    case 'workCity':
      fieldProps = {
        options: cityOptions,
        onOpen: loadCitiesOnOpen,
        onClose: () => onResetLoc(columnId),
      };
      break;
    case 'workSubdivision':
      fieldProps = {
        options: subdivisions,
        async: true,
        loadOptions: loadSubdivisions,
        onOpen: loadSubdivisions,
      };
      break;
    case 'combineCheck':
      fieldProps = {
        options: combineCheckOptions,
        stringValue: true,
      };
      break;
    case 'occupationCode':
      fieldProps = {
        options: occupationCodes,
        async: true,
        loadOptions: loadOccWithDeal,
      };
      break;
    case 'schedule':
      fieldProps = {
        options: workSchedules,
        async: true,
        loadOptions: loadWorkSchedules,
      };
      break;
    default:
      break;
  }

  const isUnusedDisabled = isUnusedDay && columnId !== 'dayType';

  const upmEditableFields = ['episode'];

  if (upmEditableFields.includes(columnId)) {
    fieldProps.disabled = upmEnabled || isUnusedDisabled || isPartialDealMemo;
  } else {
    fieldProps.disabled = wtcDisabled || isUnusedDisabled || isPartialDealMemo;
  }
  const pendoMasterFields = [
    'locationType',
    'dayType',
    'workCountry',
    'workState',
    'workCounty',
    'workSubdivision',
    'workCity',
    'combineCheck',
  ];
  return (
    <TableCell
      key={name}
      className={clsx(classes.tableCell, {
        [disableCellClass]:
          isPartialDealMemo || (isUnusedDay && columnId !== 'dayType'),
      })}
    >
      <Field
        name={name}
        pendoClass={
          isMasterRow && pendoMasterFields.includes(columnId)
            ? `PENDO_wtc_master_${columnId}`
            : ''
        }
        component={WTCAutoV2}
        onChange={onChange}
        isMasterRow={isMasterRow}
        {...fieldProps}
      />
    </TableCell>
  );
};

AutocompleteCell.propTypes = {
  field: PropTypes.object.isRequired,
  isMasterRow: PropTypes.bool,
  member: PropTypes.string,
  wtcDisabled: PropTypes.bool.isRequired,
  caRegion: PropTypes.bool.isRequired,
  isPartialDealMemo: PropTypes.bool,
  isUnusedDay: PropTypes.bool,
  disableCellClass: PropTypes.string,
  workLocations: PropTypes.array.isRequired,
  dayTypes: PropTypes.array.isRequired,
  loadDayTypes: PropTypes.func.isRequired,
  episodes: PropTypes.array.isRequired,
  loadEpisodes: PropTypes.func.isRequired,
  onResetLoc: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  onFetchCountries: PropTypes.func.isRequired,
  stateOptions: PropTypes.array.isRequired,
  loadStatesOnOpen: PropTypes.func.isRequired,
  countyOptions: PropTypes.array.isRequired,
  loadCountiesOnOpen: PropTypes.func.isRequired,
  cityOptions: PropTypes.array.isRequired,
  loadCitiesOnOpen: PropTypes.func.isRequired,
  subdivisions: PropTypes.array.isRequired,
  loadSubdivisions: PropTypes.func.isRequired,
  occupationCodes: PropTypes.array.isRequired,
  loadOccWithDeal: PropTypes.func.isRequired,
  workSchedules: PropTypes.array.isRequired,
  loadWorkSchedules: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  upmEnabled: PropTypes.bool.isRequired,
};

export default React.memo(AutocompleteCell);
