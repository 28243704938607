import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Field, arrayPush, unregisterField } from 'redux-form';
import {
  Button,
  ClickAwayListener,
  IconButton,
  Grow,
  ListItemIcon,
  MenuList,
  MenuItem,
  Paper,
  Popper,
  Autocomplete,
} from '@mui/material';
import { TextField, Checkbox } from 'components/Shared/redux';
import WTCAutoV2 from './WTCAutoV2';

import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { ACCOUNT_CODE_MAX_LENGTH } from 'components/Shared/constants/index';
import { WTC_FORM_NAME, db } from 'utils/wtcWeekUtils';
import { wtcSplitsFields } from './tableFields';
import { accountsFieldMaxLength } from 'utils/weekUtils';
const useStyles = makeStyles(({ palette, transitions }) => ({
  inputProp: {
    width: 56,
    padding: '8px 4px',
    fontSize: 14,
  },
  inputPropAC: {
    width: 71,
    padding: '8px 4px',
    fontSize: 14,
  },
  deleteIcon: {
    color: palette.primary.main,
    backgroundColor: palette.background.paper,
    width: 21,
    minWidth: 21,
    marginRight: 3,
    borderRadius: 15,
  },
  moreIcon: {
    color: palette.primary.main,
    backgroundColor: palette.background.paper,
    borderRadius: 15,
    '&:hover': {
      backgroundColor: palette.background.paper,
      color: palette.primary.main,
    },
  },
  tableCell: {
    verticalAlign: 'top',
    padding: '7px 4px',
  },
  rowMenu: {
    zIndex: 2,
  },
  applyMenu: {
    backgroundColor: palette.background.paper,
  },
  subMenu: {
    opacity: '0',
    position: 'absolute',
    left: '100%',
    transform: 'scale(0.75, 0.5625)',
    transformOrigin: 'top left',
    transition: `opacity ${transitions.duration.standard}ms ${transitions.easing.easeInOut} 0ms, transform ${transitions.duration.shorter}ms ${transitions.easing.easeInOut} 0ms`, // match Menu transition
    top: '-8px',
    visibility: 'hidden',
    textAlign: 'center',
  },
  menuItem: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'visible',
    position: 'relative',
    '& a': {
      color: palette.common.black,
    },
    fontSize: 14,
    minHeight: 40,
    '&:hover': {
      backgroundColor: palette.action.hover,
      color: palette.primary.main,
    },
  },
  menuItemWIcon: {
    cursor: 'pointer',
    display: 'flex',
    overflow: 'visible',
    position: 'relative',
    '& a': {
      color: palette.common.black,
    },
    fontSize: 14,
    minHeight: 40,
    '&:hover': {
      backgroundColor: palette.action.hover,
      color: palette.primary.main,
    },
  },
  subMenuOpen: {
    transform: 'scale(1, 1) translateZ(0px)',
    visibility: 'visible',
    opacity: '1',
    width: 170,
  },
  applyFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  applyDayButton: {
    borderRadius: 20,
    marginBottom: 8,
    width: 71,
  },
  pasteSplitBtn: {
    marginBottom: 24,
  },
  selected: {
    backgroundColor: palette.primary.main,
    color: palette.background.default,
  },
  splitRow: {
    '&:nth-child(odd)': {
      backgroundColor: palette.primary.table,
    },
  },
  alignCenter: {
    textAlign: 'center',
  },
  wtcTextField: {
    '& .MuiInput-input': {
      fontSize: 14,
    },
    //Used to  remove the space reserved for the autocomplete clearX
    '& .MuiInput-root': {
      paddingRight: '10px !important',
    },
    //Used to hide the autocomplete clearX
    '& .MuiAutocomplete-clearIndicator': {
      display: 'none',
    },
  },
}));

const SplitRow = props => {
  const {
    removeRow,
    index,
    workLocations,
    loadWorkLocations,
    percentBreakdown,
    // paidHours,
    change,
    headerArray,
    splitHourTypes,
    detail,
    tcDates = [],
    getErrorText,
    episodes,
    loadEpisodes,
    wtcDisabled,
    maskingFunc,
    accountCodeMask,
    canEditWorkTimes,
    //
    countries,
    stateOptions,
    countyOptions,
    cityOptions,
    locationFuncs: {
      onFetchCountries,
      onFetchStates,
      onFetchCities,
      onFetchCounties,
      onResetLoc,
    },
    project,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  //where does account code index start?
  //needed for column padding
  const rateIndex = _.findIndex(headerArray, h => h.columnId === 'rate');
  const callTimeIndex = _.findIndex(
    headerArray,
    h => h.columnId === 'callTime',
  );
  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setShowSubMenu(false);
    setOpen(false);
  };
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const formSplit = detail.percentBreakdown[index];

  const [type, setType] = useState('time');

  useEffect(() => {
    if (!_.isEmpty(formSplit)) {
      if (!formSplit.hoursTypeSplit) {
        const defaultTypeSplit = splitHourTypes.find(
          s => s.id === 'SelectAllIdEnabledWhenOptionsStarrable',
        );
        change(`${percentBreakdown}.hoursTypeSplit`, defaultTypeSplit);
      }
    }
  }, [change, formSplit, percentBreakdown, splitHourTypes]);

  useEffect(() => {
    let typeCheck;
    if (!_.isEmpty(formSplit)) {
      if ('percentSplit' in formSplit && formSplit.percentSplit !== null) {
        typeCheck = 'percent';
      } else if ('unitsSplit' in formSplit && formSplit.unitsSplit !== null) {
        typeCheck = 'units';
      } else if (
        'timeOutSplit' in formSplit &&
        formSplit.timeOutSplit !== null
      ) {
        typeCheck = 'time';
      }
      if (typeCheck) setType(typeCheck);
    }
  }, [change, formSplit, percentBreakdown]);

  const splitTypes = useMemo(() => ['percent', 'units', 'time'], []);

  const handleTypeChange = (e, newValue) => {
    unregisterField(WTC_FORM_NAME, `${percentBreakdown}.percentSplit`);
    unregisterField(WTC_FORM_NAME, `${percentBreakdown}.timeOutSplit`);
    unregisterField(WTC_FORM_NAME, `${percentBreakdown}.timeInSplit`);
    unregisterField(WTC_FORM_NAME, `${percentBreakdown}.unitsSplit`);

    setType(newValue);
    const splitData = {};
    switch (newValue) {
      case 'percent':
        splitData.percentSplit = '';
        break;
      case 'units':
        splitData.unitsSplit = '';
        break;
      case 'time':
        splitData.timeOutSplit = '';
        splitData.timeInSplit = '';
        break;

      default:
        break;
    }

    change(percentBreakdown, {
      splitDate: detail.effectiveDate,
      hoursTypeSplit: _.clone(detail.percentBreakdown[index].hoursTypeSplit),
      ...splitData,
    });
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  function handleChangeCountry() {
    change(`${percentBreakdown}.workState`, null);
    change(`${percentBreakdown}.workCity`, null);
    change(`${percentBreakdown}.workCounty`, null);
  }

  function handleChangeState() {
    change(`${percentBreakdown}.workCity`, null);
    change(`${percentBreakdown}.workCounty`, null);
  }

  const loadStatesOnOpen = useCallback(() => {
    const countryId = detail?.percentBreakdown?.[index]?.workCountry?.id || '';
    onFetchStates({ countryId });
  }, [detail?.percentBreakdown, index, onFetchStates]);

  const loadCountiesOnOpen = useCallback(() => {
    const stateId = detail?.percentBreakdown?.[index]?.workState?.id;
    if (stateId) onFetchCounties({ stateId });
  }, [detail?.percentBreakdown, index, onFetchCounties]);

  const loadCitiesOnOpen = useCallback(() => {
    const stateId = detail?.percentBreakdown?.[index]?.workState?.id;
    if (stateId) onFetchCities({ stateId });
  }, [detail?.percentBreakdown, index, onFetchCities]);

  function handleSubMenu() {
    setShowSubMenu(!showSubMenu);
  }

  const splitDates = tcDates.map(tcDate => {
    return {
      date: tcDate,
      isSelected: false,
    };
  });
  const [applySplitOptions, setApplySplitOptions] = useState(splitDates);
  const applyOptions = (tcDates, applySplitOptions) => {
    return tcDates.map(tcDate => {
      let sameDay = false;
      if (tcDate === detail.effectiveDate) {
        sameDay = true;
      }
      const isDaySelected =
        applySplitOptions.find(item => item.date === tcDate)?.isSelected ||
        false;
      return (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => selectDay(tcDate)}
          className={`${classes.applyDayButton} ${
            isDaySelected ? classes.selected : ''
          }`}
          disabled={sameDay}
          key={tcDate}
        >
          {moment(tcDate).format('D MMM')}
        </Button>
      );
    });
  };
  function selectDay(tcDate) {
    setApplySplitOptions(oldValues => {
      const options = _.cloneDeep(oldValues);
      const changeOption = options.find(o => o.date === tcDate);
      changeOption.isSelected = !changeOption.isSelected;
      return options;
    });
  }
  function copySplit() {
    applySplitOptions.forEach(function (item, index) {
      if (item.isSelected) {
        const splitData = { ...formSplit, splitDate: item.date };
        dispatch(
          arrayPush(
            WTC_FORM_NAME,
            `details[${index}].percentBreakdown`,
            splitData,
          ),
        );
      }
    });
    handleClose({});
  }

  const normalTime = value => {
    if (value === null || value === '' || value === undefined) {
      return '';
    }
    value = value.replace(/[^0-9.]/g, ''); // Remove all chars except numbers and .
    // Create an array with sections split by .
    const sections = value.split('.');

    // Remove any leading 0s apart from single 0
    if (sections[0] !== '0' && sections[0] !== '00') {
      sections[0] = sections[0].replace(/^0+/, '');
    } else {
      sections[0] = '0';
    }

    // If number is above 100 return 100
    if (sections[0].length >= 3) {
      if (sections[1]) {
        return sections[0].slice(0, 2) + '.' + sections[1].slice(0, 1);
      }
      return sections[0].slice(0, 2);
    }

    // If numbers exist after first .
    if (sections[1]) {
      // Join first two sections and truncate end section to length 1
      return sections[0] + '.' + sections[1].slice(0, 1);
      // If original value had a decimal place at the end, add it back
    } else if (value.indexOf('.') !== -1) {
      return sections[0] + '.';
      // Otherwise, return only section
    } else {
      return sections[0];
    }
  };

  const displayFields = headerArray.filter(header => {
    return header.visible;
  });

  const getDropDownFieldProps = field => {
    let fieldProps = {
      disabled: wtcDisabled,
    };
    switch (field) {
      case 'hoursTypeSplit':
        fieldProps = {
          ...fieldProps,
          label: 'Hours Type',
          options: splitHourTypes,
          pendoClass: 'PENDO_wtc_hoursType',
        };
        return fieldProps;
      case 'locationType':
        fieldProps = {
          ...fieldProps,
          options: workLocations || [],
          label: '  ',
          async: true,
          loadOptions: loadWorkLocations,
        };
        return fieldProps;
      case 'episode':
        fieldProps = {
          ...fieldProps,
          options: episodes || [],
          async: true,
          loadOptions: loadEpisodes,
          error: getErrorText('episode') !== null,
        };
        return fieldProps;
      case 'workCountry':
        fieldProps = {
          ...fieldProps,
          options: countries || [],
          onChange: handleChangeCountry,
          onOpen: onFetchCountries,
          error: getErrorText('workCountry') !== null,
        };
        return fieldProps;
      case 'workState':
        fieldProps = {
          ...fieldProps,
          options: stateOptions,
          onChange: handleChangeState,
          onOpen: loadStatesOnOpen,
          onClose: () => onResetLoc('workState'),
          error: getErrorText('workState') !== null,
        };
        return fieldProps;
      case 'workCity':
        fieldProps = {
          ...fieldProps,
          options: cityOptions || [],
          onOpen: loadCitiesOnOpen,
          onClose: () => onResetLoc('workCity'),
          error: getErrorText('workCity') !== null,
        };
        return fieldProps;
      case 'workCounty':
        fieldProps = {
          ...fieldProps,
          options: countyOptions || [],
          onOpen: loadCountiesOnOpen,
          onClose: () => onResetLoc('workCounty'),
          error: getErrorText('workCounty') !== null,
        };
        return fieldProps;
      default:
    }
  };

  const callFieldPosition = headerArray.find(
    h => h.columnId === 'callTime',
  )?.position;
  const rateFieldPosition = headerArray.find(
    h => h.columnId === 'rate',
  )?.position;

  const renderTableCell = field => {
    if (!wtcSplitsFields.includes(field.columnId)) {
      if (field === undefined) db('field is undefined');
      if (
        field?.position > callFieldPosition &&
        field?.position < rateFieldPosition
      ) {
        return;
      }
      return <td key={field?.columnId}></td>;
    }

    switch (field.type) {
      case 'buttons':
        if (field.columnId === 'splitButton') {
          return (
            <td className={classes.alignCenter} key={field?.columnId}>
              <IconButton
                size="small"
                ref={anchorRef}
                onClick={handleToggle}
                className={classes.moreIcon}
                disabled={wtcDisabled}
              >
                <MoreVertIcon fontSize="small" />
              </IconButton>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                className={classes.rowMenu}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transfosetrmOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper className={classes.applyMenu}>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem
                            className={classes.menuItem}
                            key={`${percentBreakdown}.split`}
                          >
                            <div onClick={handleSubMenu}>
                              Paste Split Settings To
                            </div>
                            <Paper
                              className={`${classes.subMenu} ${
                                showSubMenu ? classes.subMenuOpen : ''
                              } ${classes.applyMenu}`}
                            >
                              <p>Select Days</p>
                              <MenuList className={classes.applyFlex}>
                                {applyOptions(tcDates, applySplitOptions)}
                              </MenuList>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={copySplit}
                                className={classes.pasteSplitBtn}
                              >
                                Paste Split
                              </Button>
                            </Paper>
                          </MenuItem>
                          <MenuItem
                            onClick={removeRow}
                            key={`${percentBreakdown}.deleteSplit`}
                            className={classes.menuItemWIcon}
                          >
                            <ListItemIcon className={classes.deleteIcon}>
                              <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                            Delete Split
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </td>
          );
        }
        if (field.columnId === 'dayInfo') {
          return (
            <td className={classes.tableCell} key={field?.columnId}>
              {/* This value isn't actually stored in redux for some reason.
        So need to do some work around and use local state for this one. */}
              <Autocomplete
                value={type}
                options={splitTypes}
                renderInput={params => {
                  params.inputProps.style = { textTransform: 'capitalize' };
                  params.inputProps.className = 'PENDO_wtc_splitType';
                  return (
                    <TextField
                      className={clsx(classes.wtcTextField)}
                      label={'Split Type'}
                      placeholder=""
                      {...params}
                    />
                  );
                }}
                disabled={wtcDisabled}
                //capitalize first letter
                getOptionLabel={o => o.charAt(0).toUpperCase() + o.slice(1)}
                onChange={handleTypeChange}
                onKeyDown={e => {
                  if (e.key === 'Backspace') {
                    handleTypeChange(null);
                  }
                }}
              />
            </td>
          );
        }
        break;
      case 'text':
        if (field.columnId === 'callTime') {
          return (
            <td
              key={field?.columnId}
              colSpan={`${
                headerArray
                  .slice(callTimeIndex, rateIndex)
                  .filter(field => field.visible === true).length
              }`}
            >
              {`${type}` === 'percent' && (
                <Field
                  component={TextField}
                  name={`${percentBreakdown}.percentSplit`}
                  type="text"
                  InputProps={{
                    classes: {
                      input: classes.inputProp,
                    },
                    inputProps: {
                      maxLength: '3',
                    },
                  }}
                  variant="outlined"
                  margin="dense"
                  label="%"
                  error={
                    getErrorText(`percentBreakdown[${index}].percentSplit`) !==
                    null
                  }
                  disabled={wtcDisabled}
                />
              )}
              {`${type}` === 'time' && (
                <Field
                  component={TextField}
                  name={`${percentBreakdown}.timeOutSplit`}
                  type="text"
                  variant="outlined"
                  margin="dense"
                  label="Start"
                  className={classes.smallInputLabel}
                  InputProps={{
                    classes: {
                      input: classes.inputProp,
                    },
                  }}
                  error={
                    getErrorText(`percentBreakdown[${index}].timeOutSplit`) !==
                    null
                  }
                  normalize={normalTime}
                  disabled={wtcDisabled}
                />
              )}

              {`${type}` === 'time' && (
                <Field
                  component={TextField}
                  name={`${percentBreakdown}.timeInSplit`}
                  type="text"
                  variant="outlined"
                  margin="dense"
                  label="End"
                  className={classes.smallInputLabel}
                  InputProps={{
                    classes: {
                      input: classes.inputProp,
                    },
                  }}
                  error={
                    getErrorText(`percentBreakdown[${index}].timeInSplit`) !==
                    null
                  }
                  normalize={normalTime}
                  disabled={wtcDisabled}
                />
              )}

              {`${type}` === 'units' && (
                <Field
                  component={TextField}
                  name={`${percentBreakdown}.unitsSplit`}
                  type="text"
                  InputProps={{
                    classes: {
                      input: classes.inputProp,
                    },
                  }}
                  variant="outlined"
                  margin="dense"
                  label="Units"
                  error={
                    getErrorText(`percentBreakdown[${index}].unitsSplit`) !==
                    null
                  }
                  disabled={wtcDisabled}
                />
              )}
            </td>
          );
        }
        return (
          <td key={field.columnId} className={classes.tableCell}>
            <Field
              component={TextField}
              name={`${percentBreakdown}.${field.columnId}`}
              type="text"
              variant="outlined"
              disabled={wtcDisabled}
              InputProps={{
                classes: {
                  input:
                    field.columnId === 'accountCode'
                      ? classes.inputPropAC
                      : classes.inputProp,
                },
                inputProps: {
                  maxLength: accountsFieldMaxLength(project, field.columnId),
                  placeholder:
                    field.columnId === 'accountCode'
                      ? accountCodeMask !== '~'.repeat(ACCOUNT_CODE_MAX_LENGTH)
                        ? accountCodeMask
                        : '' || ''
                      : '',
                },
              }}
              normalize={field.columnId === 'accountCode' ? maskingFunc : null}
            />
          </td>
        );
      case 'checkbox':
        return (
          <td key={field.columnId} className={classes.tableCell}>
            <Field
              component={Checkbox}
              name={`${percentBreakdown}.${field.columnId}`}
              type="checkbox"
              inputprops={{
                classes: {
                  input: classes.inputProp,
                },
              }}
              variant="outlined"
              margin="dense"
              disabled={wtcDisabled}
            />
          </td>
        );
      case 'auto-complete': {
        const name =
          field.columnId === 'dayType' ? 'hoursTypeSplit' : field.columnId;
        return (
          <td key={field.columnId} className={classes.tableCell}>
            <Field
              name={`${percentBreakdown}.${name}`}
              component={WTCAutoV2}
              disabled={wtcDisabled}
              {...getDropDownFieldProps(name)}
            />
          </td>
        );
      }
      case 'read-only':
      default:
        return <td key={field.columnId} />;
    }
  };
  return (
    <tr className={`${classes.splitRow} splitRow`}>
      {canEditWorkTimes && <td />}

      {displayFields.map(renderTableCell)}

      <td style={{ display: 'none' }}>
        <Field
          component="input"
          name={`${percentBreakdown}.splitDate`}
          type="hidden"
          value={detail.effectiveDate}
          disabled={wtcDisabled}
        />
      </td>
    </tr>
  );
};

SplitRow.propTypes = {
  removeRow: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  workLocations: PropTypes.array,
  loadWorkLocations: PropTypes.func,
  percentBreakdown: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  headerArray: PropTypes.array.isRequired,
  splitHourTypes: PropTypes.array.isRequired,
  detail: PropTypes.object.isRequired,
  tcDates: PropTypes.array,
  getErrorText: PropTypes.func.isRequired,
  episodes: PropTypes.array,
  loadEpisodes: PropTypes.func,
  wtcDisabled: PropTypes.bool,
  maskingFunc: PropTypes.func,
  accountCodeMask: PropTypes.string,
  canEditWorkTimes: PropTypes.bool,
  countries: PropTypes.array,
  stateOptions: PropTypes.array,
  countyOptions: PropTypes.array,
  cityOptions: PropTypes.array,
  locationFuncs: PropTypes.object,
  project: PropTypes.object,
};

export default SplitRow;
