import { buildUrl } from '.';

const FETCH_INVOICE_SUMMARY_URL =
  '/projects/:projectId/digitalEdit/:invoiceId/invoiceSummary';
const FETCH_TIMECARD_SUMMARY_URL =
  '/projects/:projectId/digitalEdit/:invoiceId/timecardsSummary';
const FETCH_TIMECARD_DISTRIBUTIONS =
  '/projects/:projectId/digitalEdit/:invoiceId/timecardDistributions';

const UPDATE_INVOICE_STATUS_URL =
  '/projects/:projectId/digitalEdit/:invoiceId/updateInvoice/:invoiceStatus';

const FETCH_COMMENTS = '/projects/:projectId/digitalEdit/:invoiceId/comments';
const ADD_COMMENT_URL = '/projects/:projectId/digitalEdit/addComment';
const UPDATE_COMMENT_URL =
  '/projects/:projectId/digitalEdit/:commentId/updateComment';

const FETCH_FILES_URL = '/projects/:projectId/digitalEdit/:invoiceId/files';
const UPLOAD_FILES_URL = '/projects/:projectId/digitalEdit/upload';
const DELETE_FILE_URL =
  '/projects/:projectId/digitalEdit/:documentId/deleteDocument';

const APPROVE_INVOICE =
  '/projects/:projectId/digitalEdit/:invoiceId/Approve/DM/:deliveryMethod?ApproveInvoice';
const APPROVE_INVOICE_CHECK_DATE =
  '/projects/:projectId/digitalEdit/:invoiceId/Approve/DM/:deliveryMethod/CD/:checkDate?ApproveInvoice';

const DOWNLOAD_EDITS_REPORTS =
  '/projects/:projectId/digitalEdit/:invoiceId/invoiceEditReports';

const FETCH_DATABOX_ORDER = '/projects/:projectId/digitalEdit/getDataboxOrder';

const LOCK_COMMENT = 'projects/:projectId/digitalEdit/:invoiceId/lockComment';
const UNLOCK_COMMENT =
  'projects/:projectId/digitalEdit/:invoiceId/unlockComment';

const DELETE_COMMENT_URL =
  'projects/:projectId/digitalEdit/:invoiceId/comment/:commentId';

const REDIRECT_URL = '/projects/:projectId/digitalEdit/tokens/:token';

const GET_NAV_TO_STUDIO_URL =
  '/projects/:projectId/digitalEdit/:invoiceId/studioPlusLink';

const REOPEN_BATCHES_URL =
  '/projects/:projectId/digitalEdit/:invoiceId/reopenBatch';

const SAVE_ACE_URL = '/projects/:projectId/digitalEdit/:invoiceId/saveACE';
const REFRESH_ACE_URL =
  '/projects/:projectId/digitalEdit/:invoiceId/refreshACE';

const SUBMIT_COMMENT_URL = '/projects/:projectId/digitalEdit/submitComment';

// const randSleep = sleep =>
//   new Promise((resolve, reject) => {
//     const isSleepValid = sleep || sleep === 0;
//     const sleepTime = isSleepValid
//       ? sleep
//       : Math.ceil(Math.random() * 3) * 1000;
//     setTimeout(() => {
//       resolve();
//     }, sleepTime);
//   });

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ clientV2 }) => ({
  async fetchInvoiceFiles({ projectId, invoiceId }) {
    const url = buildUrl(FETCH_FILES_URL, { projectId, invoiceId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },
  async uploadFiles({ projectId, payload, abortController, onProgress }) {
    const url = buildUrl(UPLOAD_FILES_URL, { projectId });
    const rsp = await clientV2.post(url, payload, {
      timeout: 4 * 60 * 1000, //4 min timeout
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
          onProgress(percent);
        }
      },
      signal: abortController.signal,
    });
    return rsp.data;
  },
  async deleteFile({ projectId, documentId }) {
    const url = buildUrl(DELETE_FILE_URL, { projectId, documentId });
    const rsp = await clientV2.delete(url);
    return rsp;
  },
  async fetchInvoice({ projectId, invoiceId }) {
    const url = buildUrl(FETCH_INVOICE_SUMMARY_URL, {
      projectId,
      invoiceId,
    });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },
  async fetchTimecardDistributions({ projectId, invoiceId }) {
    const url = buildUrl(FETCH_TIMECARD_DISTRIBUTIONS, {
      projectId,
      invoiceId,
    });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async fetchTimecardSummary({ projectId, invoiceId }) {
    const url = buildUrl(FETCH_TIMECARD_SUMMARY_URL, {
      projectId,
      invoiceId,
    });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async fetchComments({ projectId, invoiceId }) {
    const url = buildUrl(FETCH_COMMENTS, {
      projectId,
      invoiceId,
    });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async addComment({ projectId, body }) {
    const url = buildUrl(ADD_COMMENT_URL, { projectId });

    body.source = 'Revisions';
    if (!body.containerId) {
      console.error('Add Comment: missing required field: containerId');
    }
    if (!body.commentText) {
      console.error('Add Comment: missing required field: commentText');
    }

    const rsp = await clientV2.post(url, body);
    return rsp.data;
  },
  async updateComment({ projectId, body }) {
    const url = buildUrl(UPDATE_COMMENT_URL, {
      projectId,
      commentId: body.commentId,
    });
    body.source = 'Revisions';
    if (!body.containerId) {
      console.error('Update Comment: missing required field: containerId');
    }
    if (!body.commentText && body.commentText !== '') {
      console.error('Update Comment: missing required field: commentText');
    }

    const rsp = await clientV2.post(url, body);
    return rsp.data;
  },
  async updateInvoice({ projectId, invoiceId, invoiceStatus }) {
    const url = buildUrl(UPDATE_INVOICE_STATUS_URL, {
      projectId,
      invoiceId,
      invoiceStatus,
    });

    const rsp = await clientV2.post(url);
    return rsp.data;
  },
  async approveInvoice({ projectId, invoiceId, deliveryMethod, checkDate }) {
    const url = buildUrl(
      checkDate ? APPROVE_INVOICE_CHECK_DATE : APPROVE_INVOICE,
      { projectId, invoiceId, deliveryMethod, checkDate },
    );

    const rsp = await clientV2.post(url);
    return rsp.data;
  },
  async fetchTaxColumnMap({ projectId }) {
    const url = buildUrl(FETCH_DATABOX_ORDER, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async fetchEditReports({ projectId, invoiceId }) {
    const url = buildUrl(DOWNLOAD_EDITS_REPORTS, { projectId, invoiceId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },
  async lockComment({ projectId, invoiceId }) {
    const url = buildUrl(LOCK_COMMENT, { projectId, invoiceId });
    const rsp = await clientV2.post(url);
    return rsp.data;
  },
  async unlockComment({ projectId, invoiceId }) {
    const url = buildUrl(UNLOCK_COMMENT, { projectId, invoiceId });
    const rsp = await clientV2.post(url);
    return rsp.data;
  },
  async deleteComment({ projectId, invoiceId, commentId }) {
    const url = buildUrl(DELETE_COMMENT_URL, {
      projectId,
      invoiceId,
      commentId,
    });
    const rsp = await clientV2.delete(url);
    return rsp.data;
  },
  async fetchRedirectInfo({ projectId, token }) {
    const url = buildUrl(REDIRECT_URL, { projectId, token });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },
  async getStudioUrl({ projectId, invoiceId }) {
    const url = buildUrl(GET_NAV_TO_STUDIO_URL, { projectId, invoiceId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },
  async reopenBatches({ projectId, invoiceId }) {
    const url = buildUrl(REOPEN_BATCHES_URL, { projectId, invoiceId });
    const rsp = await clientV2.post(url);
    return rsp.data;
  },
  async saveACE({ projectId, invoiceId, timecards }) {
    const url = buildUrl(SAVE_ACE_URL, { projectId, invoiceId });
    const rsp = await clientV2.post(url, { timecards });
    return rsp.data;
  },
  async refreshACE({ projectId, invoiceId, timecardHeaderIds }) {
    const url = buildUrl(REFRESH_ACE_URL, { projectId, invoiceId });
    const rsp = await clientV2.post(url, timecardHeaderIds);
    return rsp.data;
  },
  async submitComment({ projectId, payload }) {
    const url = buildUrl(SUBMIT_COMMENT_URL, {
      projectId,
    });
    const rsp = await clientV2.post(url, payload);
    return rsp.data;
  },
});
