import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { Button, Tooltip, Autocomplete, TextField } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import PropTypes from 'prop-types';

import { BULK_EDIT_MAX_RESULTS } from 'components/Shared/constants';
import ClearConfirmModal from './ClearConfirmModal';
import CalculateConfirmModal from './CalculateConfirmModal';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 80,
    color: palette.gray['+2'],
    backgroundColor: palette.background.paper,
    width: '100%',
    justifyContent: 'flex-start',
  },
  btnContainer: {
    margin: '0 10px',
  },
  btn: {
    width: 113,
    height: 40,
  },
  clearBtn: {
    height: 40,
  },
  backToList: {
    padding: 0,
    marginRight: 35,
  },
  backToListButton: {},
  batchSelect: {
    width: 350,
    margin: '0px 0px 25px 20px',
  },
}));

const getListFromWeekendings = weeks => {
  return _.map(weeks, week => {
    return {
      label: week.id,
      value: week.id,
    };
  });
};

const Header = props => {
  const {
    //--data
    weekEndings,
    weekEnding,
    tcCount,
    //--functions
    clearChanges,
    onBulkLeave,
    onSave,
    onCalc,
    onWeekendingChange,
    showClearChangesModal,
    showCalculateModal,
    //--flags
    saving,
    calculating,
    isDirty,
    tcToBeCalculated,
  } = props;
  const classes = useStyles();
  const weekList = getListFromWeekendings(weekEndings);

  function changeWeekending(weekEnding) {
    onWeekendingChange(weekEnding);
  }

  const [uncalculated, setUncalculated] = useState(false);
  const [saveBtn, setSaveBtn] = useState(false);
  useEffect(() => {
    setSaveBtn(false);
  }, [saving]);
  // let uncalculated = false;
  useEffect(() => {
    if (isDirty === true) {
      setUncalculated(true);
    }
    if (tcToBeCalculated.length === 0 && isDirty === false) {
      setUncalculated(true);
    } else if (tcToBeCalculated.length > 0 && isDirty === false) {
      setUncalculated(false);
    }
  }, [isDirty, tcToBeCalculated?.length]);

  //state calculations
  const saveBtnTxt = saving ? 'Saving' : 'Save';
  const calcBtnTxt = calculating ? 'Calculating' : 'Calculate';

  const overMax = tcCount > BULK_EDIT_MAX_RESULTS;
  //btn logic defined in: HOUR-2955

  const saveBtnDisabled = saving || overMax || !isDirty || saveBtn;

  const calcBtnDisabled = saving || calculating || overMax || uncalculated;
  const clearBtnDisabled = saveBtnDisabled;
  //prevent changing batch with un-saved changes
  const batchSelectDisabled = saving || isDirty;

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.backToList}>
          <Button
            variant="text"
            color="primary"
            className={classes.backToListButton}
            onClick={onBulkLeave}
          >
            <ChevronLeftIcon />
            <span>Back to List</span>
          </Button>
        </div>
        <div className={classes.btnContainer}>
          <Tooltip
            arrow
            title="Save your work. This will not cause timecards to be recalculated."
          >
            <div>
              <Button
                className={classes.btn}
                color={'primary'}
                variant="contained"
                onClick={e => {
                  e.stopPropagation();
                  setSaveBtn(true);
                  onSave();
                }}
                key={'save'}
                disabled={saveBtnDisabled}
              >
                {saveBtnTxt}
              </Button>
            </div>
          </Tooltip>
        </div>
        <div className={classes.btnContainer}>
          <Tooltip
            arrow
            title="Save your work first. If calculating many timecards, this could take a few minutes."
          >
            <div>
              <Button
                className={classes.btn}
                color={'primary'}
                variant="outlined"
                onClick={e => {
                  e.stopPropagation();
                  showCalculateModal();
                }}
                key={'calc'}
                disabled={calcBtnDisabled}
              >
                {calcBtnTxt}
              </Button>
            </div>
          </Tooltip>
        </div>
        <div className={classes.btnContainer}>
          <Button
            className={classes.clearBtn}
            color={'primary'}
            variant="outlined"
            onClick={e => {
              e.stopPropagation();
              showClearChangesModal();
            }}
            key={'BEFetch'}
            disabled={clearBtnDisabled}
          >
            Clear All Changes
          </Button>
        </div>
        <div className={classes.batchSelect}>
          <Autocomplete
            value={weekEnding || null}
            renderInput={params => (
              <TextField label={'Select Week Ending'} {...params} />
            )}
            autoSelect
            openOnFocus
            isOptionEqualToValue={(option, value) => option.value === value}
            options={weekList || []}
            onChange={(e, newValue) => {
              changeWeekending(newValue?.value || '');
            }}
            disabled={batchSelectDisabled}
          />
        </div>
      </div>
      <ClearConfirmModal clearChanges={clearChanges} />
      <CalculateConfirmModal onCalc={onCalc} calculating={calculating} />
    </div>
  );
};

Header.propTypes = {
  batches: PropTypes.array.isRequired,
  weekEndings: PropTypes.array.isRequired,
  weekEnding: PropTypes.string,
  tcCount: PropTypes.number,
  clearChanges: PropTypes.func.isRequired,
  onBulkLeave: PropTypes.func.isRequired,
  onFetch: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCalc: PropTypes.func.isRequired,
  onWeekendingChange: PropTypes.func.isRequired,
  showClearChangesModal: PropTypes.func.isRequired,
  showCalculateModal: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  calculating: PropTypes.bool.isRequired,
  isDirty: PropTypes.bool.isRequired,
  tcToBeCalculated: PropTypes.array.isRequired,
};

export default Header;
