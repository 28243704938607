import './richText.css';

import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import debug from 'debug';

import Text from '@tiptap/extension-text';
import Document from '@tiptap/extension-document';
import TextStyle from '@tiptap/extension-text-style';
import Paragraph from '@tiptap/extension-paragraph';
//text format:
import { Color } from '@tiptap/extension-color';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import Strike from '@tiptap/extension-strike';

//List
import ListItem from '@tiptap/extension-list-item';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';

import Blockquote from '@tiptap/extension-blockquote';
import HardBreak from '@tiptap/extension-hard-break';
import History from '@tiptap/extension-history';

import Placeholder from '@tiptap/extension-placeholder';

import { Extension, textInputRule, textPasteRule } from '@tiptap/core';
import * as DOMPurify from 'dompurify';

import { EditorContent, useEditor } from '@tiptap/react';

import MenuBar from './MenuBar';
import makeStyles from '@mui/styles/makeStyles';

const db = debug('richText');

// eslint-disable-next-line no-control-regex
const UNICODE_REGEX = /[^\u0000-\u00ff]/g;

//styles shared with display only comments as well
export const sharedStyles = {
  paragraph: {
    marginTop: 0,
    marginBottom: 4,
  },
  list: {
    marginBlock: 5,
  },
  listItem: {
    '& $paragraph': {
      margin: 0,
    },
  },
};

const useStyles = makeStyles(({ palette }) => ({
  Tiptap: { display: 'flex', flexDirection: 'column', height: '100%' },
  EditorContent: {
    flex: '1 1 auto',
    overflow: 'auto',
  },
  textEditor: {
    padding: 8,
    minHeight: '90%',
  },
  ...sharedStyles,
}));

export function tryParseJSONObject(jsonString) {
  try {
    var o = JSON.parse(jsonString);

    if (o && typeof o === 'object') {
      return o;
    }
  } catch (e) {}

  return false;
}

export const makeExtensions = ({ classes, placeholder }) => {
  return [
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    TextStyle.configure({ types: [ListItem.name] }),
    Document,
    Paragraph.configure({
      HTMLAttributes: {
        class: classes.paragraph,
      },
    }),
    Text,
    BulletList.configure({
      HTMLAttributes: {
        class: classes.list,
      },
    }),
    OrderedList.configure({
      HTMLAttributes: {
        class: classes.list,
      },
    }),
    ListItem.configure({
      HTMLAttributes: {
        class: classes.listItem,
      },
    }),
    Blockquote,
    HardBreak,
    Bold,
    Italic,
    Underline,
    Strike,
    History,
    Placeholder.configure({
      placeholder,
    }),
  ];
};

const ReplaceUnicode = Extension.create({
  name: 'unicodeReplacer',
  addInputRules() {
    return [textInputRule({ find: UNICODE_REGEX, replace: '' })];
  },
  addPasteRules() {
    return [textPasteRule({ find: UNICODE_REGEX, replace: '' })];
  },
});

/**
 *
 * @param {string} className
 * @param {string} menuBarClassName
 * @param {string} editorClassName
 * @param {string} value
 * - json string of content
 * @param {function} onChange(value, text)
 * - value is json object of content and text is rawText of the editor
 * @param {object} insertText  {text: string, position: 'cursor' | 'end'}
 * - when insertText object changes its text will be inserted at
 *   the given cursor position
 * @param {boolean} editable
 * @param {boolean} disabled
 * @param {string} placeholder
 *
 */

const RichText = props => {
  const {
    className,
    menuBarClassName,
    editorClassName,
    value,
    onChange,
    insertText,
    editable = true,
    disabled = false,
    placeholder,
  } = props;

  const classes = useStyles();

  //comp hooks
  const curPos = React.useRef(0);
  const editor = useEditor(
    {
      editable: editable && !disabled,
      editorProps: {
        attributes: {
          class: clsx(classes.textEditor),
        },
        // handleTextInput: (view, from, to, text) => {
        //   //TODO - implement length limit here
        //   // https://github.com/ueberdosis/tiptap/issues/293#issuecomment-578231063
        //   // console.log(view, from, to, text);
        // },
        // handlePaste: (view, event, slice) => {        },
      },
      extensions: [
        ...makeExtensions({ classes, placeholder }),
        ReplaceUnicode,
        // Extension.create({
        //   name: 'customExtension',

        //   addKeyboardShortcuts() {
        //     return {
        //       'Mod-shift-v': () =>
        //         this.editor
        //           .chain()
        //           .focus()
        //           .insertContent(insertTextRef.current)
        //           .run(),
        //       // 'Mod-shift-b': () => {
        //       //   console.i(this.editor.getAttributes('text'));
        //       //   const prev = curPos.current;
        //       //   this.editor
        //       //     .chain()
        //       //     .focus()
        //       //     .insertContent(insertTextRef.current)
        //       //     .run();
        //       //   const after = curPos.current;
        //       //   console.i('prev: ', prev);
        //       //   console.i('after: ', after);
        //       //   this.editor.commands.setTextSelection({
        //       //     from: prev,
        //       //     to: after,
        //       //   });
        //       //   this.editor.commands.toggleBlockquote();
        //       //   this.editor.commands.setTextSelection(after + 1);
        //       // },
        //     };
        //   },
        // }),
      ],
      onUpdate: ({ editor, transaction }) => {
        onChange(JSON.stringify(editor.getJSON()), transaction.doc.textContent);
      },
      onTransaction: propers => {
        //keep track of current position
        curPos.current = propers.transaction.curSelection.$anchor.pos;
      },
      content: tryParseJSONObject(value) || DOMPurify.sanitize(value),
    },
    [editable, disabled, placeholder],
  );
  const insertTextRef = React.useRef(false);
  React.useEffect(() => {
    insertTextRef.current = true;
    db('insertText changed', insertText);
  }, [insertText]);

  React.useEffect(() => {
    if (editor && insertText?.text && insertTextRef.current) {
      db('Inserting Text', insertText);
      insertTextRef.current = false;
      let position;
      let newText;
      const json = tryParseJSONObject(insertText.text);
      if (json) {
        newText = json;
      } else {
        newText = DOMPurify.sanitize(insertText.text);
      }
      if (insertText.position === 'end') {
        const hasContent = !!editor.view.state.doc.textContent;
        position = insertText.position;
        if (hasContent) {
          editor.chain().focus(position).insertContent('<br /><br />').run();
        }
      } else if (insertText.position !== 'cursor') {
        console.error(
          'Invalid insert position. Expected "end" or "cursor" Found:',
          insertText.position,
        );
      }
      editor
        .chain()
        .focus(position)
        .insertContent(newText)
        .insertContent('<br/>')
        .run();
    }
  }, [editor, insertText]);

  const initRef = useRef(false);

  useEffect(() => {
    initRef.current = false;
  }, [editable, disabled]);

  //clear editor when value becomes undefined (comment deleted)
  useEffect(() => {
    if (value === undefined) {
      editor.commands.setContent('');
    }
  }, [editor, value]);

  useEffect(() => {
    if (editor && !initRef.current && value) {
      const content = tryParseJSONObject(value);
      if (!content) {
        //not a valid json obj - assume it's old text only comment
        const htmlStr = value.replace(/\n/g, '<br/>');
        editor.commands.setContent(DOMPurify.sanitize(htmlStr));
      } else {
        editor.commands.setContent(content);
      }
      initRef.current = true;
    }
  }, [editor, value, editable]);

  return (
    <div className={clsx(classes.Tiptap, className)}>
      {editable && (
        <MenuBar
          className={clsx(classes.MenuBar, menuBarClassName)}
          editor={editor}
          disabled={disabled}
        />
      )}
      <EditorContent
        className={clsx(classes.EditorContent, editorClassName)}
        editor={editor}
      />
    </div>
  );
};

RichText.propTypes = {
  className: PropTypes.string,
  menuBarClassName: PropTypes.string,
  editorClassName: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  insertText: PropTypes.shape({
    text: PropTypes.string.isRequired,
    position: PropTypes.oneOf(['cursor', 'end']).isRequired,
  }),
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default RichText;
