import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { PROJECT_ADMIN } from 'components/props/profiles';
import { truncateStringToFitLength } from 'utils/helperFunctions';

import { Link } from 'react-router-dom';
import { userIsOnlyProjectAdmin } from 'selectors/session';

const useStyles = makeStyles(({ palette }) => ({
  HeaderBtnNav: {},
  selectedBtn: {
    backgroundColor: palette.primary['+3'],
    borderRadius: 7,
  },
  otherBtn: {
    borderRadius: 7,
    color: palette.primary.contrastText,
    backgroundColor: 'transparent',
  },
}));

const HeaderBtnNav = props => {
  const classes = useStyles();
  const { projectsUser, url, project, currentUser } = props;

  const isAdmin = projectsUser.isAdmin;
  const isSuperAdmin = projectsUser.isSuperAdmin;
  const isAccessAdmin = projectsUser.isAccessAdmin;
  const isProjectAdmin = projectsUser?.projectRoles.includes(PROJECT_ADMIN);
  const isProjAdminUrl = /projects\/\d+\/settings/.test(url);

  const navButtons = [];

  const isAnyAdmin = isAdmin || isSuperAdmin || isAccessAdmin;

  let projectNav = null;

  const projectName = truncateStringToFitLength(20, project?.name);

  const isOnlyProjectAdmin = userIsOnlyProjectAdmin(currentUser);

  // show showProjAdminBack only if employee has multiple roles(Emp + Proj Admin / DH + Proj Admin / PA + Proj Admin etc)
  // hide for non-employee and project admin only
  const showProjAdminBack =
    !isOnlyProjectAdmin && isProjectAdmin && isProjAdminUrl;

  if (isAdmin) {
    const isAdminProjects = url.includes('/admin/projects');
    projectNav = (
      <Link to={'/admin/projects'}>
        <Button
          className={clsx({
            [classes.selectedBtn]: isAdminProjects,
            [classes.otherBtn]: !isAdminProjects,
          })}
          startIcon={<HomeOutlinedIcon />}
        >
          Projects
        </Button>
      </Link>
    );
  } else if (!isAnyAdmin) {
    projectNav = (
      <Link to={'/projects'}>
        <Button
          className={classes.selectedBtn}
          startIcon={<HomeOutlinedIcon />}
        >
          Projects
        </Button>
      </Link>
    );
  }

  if (isAdmin) {
    navButtons.push({
      label: 'Users',
      link: '/admin/users-all-projects',
      icon: null,
    });
  }
  if (isSuperAdmin) {
    navButtons.push(
      {
        label: 'Feature Flags',
        link: '/admin/feature-flags',
        icon: null,
      },
      {
        label: 'Super Admin',
        link: '/admin/superadmin',
        icon: null,
      },
    );
  }

  if (isAccessAdmin) {
    navButtons.push({
      label: 'Access Admin',
      link: '/admin/accessadmin',
      icon: null,
    });
  }

  return (
    <Box className={classes.HeaderBtnNav}>
      {projectNav}
      {showProjAdminBack && (
        <Link to={`/projects/${project.id}`}>
          <Button className={clsx(classes.selectedBtn)}>
            {`< Back to ${projectName}`}
          </Button>
        </Link>
      )}
      {navButtons.map(b => (
        <Link key={b.link} to={b.link}>
          <Button
            className={clsx({
              [classes.selectedBtn]: url === b.link,
              [classes.otherBtn]: url !== b.link,
            })}
            startIcon={b.icon}
          >
            {b.label}
          </Button>
        </Link>
      ))}
    </Box>
  );
};

HeaderBtnNav.propTypes = {
  url: PropTypes.string.isRequired,
  projectsUser: PropTypes.object.isRequired,
  project: PropTypes.object,
};

export default HeaderBtnNav;
