import React from 'react';
import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

import { withStyleSheet } from 'shared/theme';
import {
  ModalFooter,
  ModalContent,
  ModalTitle,
} from 'components/Shared/ModalDialog';
import Message from 'components/Message';
import * as timecardCommon from 'selectors/timecard/common';

const style = theme => ({
  root: {
    width: '100%',
    minWidth: 500,
  },
  button: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 8,
    marginRight: 8,

    '&:last-child': {
      marginRight: 4,
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    overflow: 'visible',
  },
  footer: {
    margin: '15px 20px 15px 0px',
  },
  //Mobile only CSS
  [theme.breakpoints.down('lg')]: {
    footer: {
      display: 'flex',
      flexDirection: 'column-reverse',
      margin: '0 !important',
      '& > button': {
        margin: 10,
        width: '75vw',
      },
    },
  },
});

const mapState = (state, ownProps) => ({
  submitting:
    (ownProps && ownProps.submitting) || timecardCommon.isSavingTimecard(state),
});

/**
 * Re-usable Confirm Modal dialog, works with reduxForm.
 */

const ConfirmModal = props => {
  const {
    title = 'Please confirm',
    content,
    submitting = false,
    hasSubmitAction = false,
    buttonHide = false,
    customSubmit = false,
    customOnSubmit = undefined,
    textBtnText = undefined,
    textBtnSubmit = null,
    buttonText = 'Continue',
    cancelText = 'Cancel',
    danger = false,
    classes,
    onModalHide,
    onSubmit,
    submitDisabled = false,
    footerErrorMsg = null,
  } = props;

  const btnColor = danger ? 'error' : 'primary';
  return (
    <div>
      <ModalTitle>{title}</ModalTitle>
      <ModalContent className={classes.content}>{content}</ModalContent>
      <ModalFooter className={classes.footer}>
        <div>
          {footerErrorMsg && <Message error> {footerErrorMsg} </Message>}
        </div>
        <div className={classes.footer}>
          {textBtnText && textBtnSubmit && (
            <Button
              className={classes.button}
              disabled={submitting}
              variant="text"
              onClick={textBtnSubmit}
            >
              {textBtnText}
            </Button>
          )}
          <Button
            className={classes.button}
            disabled={submitting}
            variant={buttonHide ? 'contained' : 'outlined'}
            name="cancel"
            onClick={onModalHide}
          >
            {cancelText}
          </Button>

          {!hasSubmitAction && !buttonHide && (
            <Button
              id="ConfirmModalSubmit" //should only have 1 submit btn
              className={classes.button}
              disabled={submitting || submitDisabled}
              type="submit"
              color={btnColor}
            >
              {buttonText}
            </Button>
          )}
          {hasSubmitAction && !customSubmit && !buttonHide && (
            <Button
              id="ConfirmModalSubmit" //should only have 1 submit btn
              className={classes.button}
              disabled={submitting || submitDisabled}
              onClick={onSubmit}
              color={btnColor}
            >
              {buttonText}
            </Button>
          )}
          {hasSubmitAction && customSubmit && !buttonHide && (
            <Button
              id="ConfirmModalSubmit" //should only have 1 submit btn
              className={classes.button}
              disabled={submitting || submitDisabled}
              onClick={customOnSubmit}
              color={btnColor}
            >
              {buttonText}
            </Button>
          )}
        </div>
      </ModalFooter>
    </div>
  );
};

ConfirmModal.propTypes = {
  title: PropTypes.node,
  content: PropTypes.node.isRequired,
  submitting: PropTypes.bool,
  buttonHide: PropTypes.bool,
  hasSubmitAction: PropTypes.bool,
  customSubmit: PropTypes.bool,
  customOnSubmit: PropTypes.func,
  textBtnText: PropTypes.string,
  textBtnSubmit: PropTypes.func,
  buttonText: PropTypes.string,
  cancelText: PropTypes.string,
  danger: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  onModalHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  submitDisabled: PropTypes.bool,
  footerErrorMsg: PropTypes.string,

  //unused props, leaving here so they can be removed from
  //when used in  <ConfirmModal> comps
  // buttonType: PropTypes.string,
  // raisedButton: PropTypes.bool
};

export default compose(
  connect(mapState),
  withStyleSheet('ConfirmModal', style),
)(ConfirmModal);
