import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TableRow, TableCell, Checkbox } from '@mui/material';
import FieldCheckboxDropDown from 'components/Shared/FieldCheckboxDropDown';
import { getProjectAllowance, getDayTypes } from 'selectors/projectAllowances';

const mapStateToProps = (state, ownProps) => {
  return {
    item: getProjectAllowance(state, ownProps.index),
    dayTypes: getDayTypes(state),
  };
};

function ProjectSettingRow(props) {
  const { item, dayTypes, onUpdateProjectAllowance, centerClass } = props;
  const buildCheckBoxList = (list, checkedList) => {
    return list.map(item => {
      return {
        ...item,
        checked: !!(checkedList && checkedList.find(c => c.id === item.id)),
      };
    });
  };
  return (
    <TableRow key={item.id} hover>
      <TableCell>{item.description}</TableCell>
      <TableCell>{item && item.code}</TableCell>
      <TableCell className={centerClass}>
        <Checkbox
          checked={item && item.employeeVisible}
          color="primary"
          onChange={onUpdateProjectAllowance(item.id, 'employeeVisible')}
        />
      </TableCell>
      <TableCell className={centerClass}>
        <Checkbox
          checked={item && item.employeeMandatory}
          color="primary"
          onChange={onUpdateProjectAllowance(item.id, 'employeeMandatory')}
          disabled={item && !item.employeeVisible}
        />
      </TableCell>
      <TableCell className={centerClass}>
        <FieldCheckboxDropDown
          fieldsList={buildCheckBoxList(dayTypes, item.dayTypes)}
          selectedCount={item?.dayTypes?.length || 0}
          checkedProperty={'checked'}
          onClickField={(field, checked) => {
            const onUpdate = onUpdateProjectAllowance(item.id, 'dayTypes');
            const name = field.id || '';
            onUpdate(name, checked);
          }}
          type={'day types'}
          label=""
          disabled={true}
          dropDownWidth={true}
        />
      </TableCell>
    </TableRow>
  );
}

ProjectSettingRow.propTypes = {
  item: PropTypes.object,
  dayTypes: PropTypes.array,
  onUpdateProjectAllowance: PropTypes.func,
  centerClass: PropTypes.string,
};

export default connect(mapStateToProps, null)(ProjectSettingRow);
