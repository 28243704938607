import React from 'react';

import {
  Header,
  BlankHeader,
  FullWidthLayout,
  DefaultLayout,
  ProjectHeader,
  MaxWidthLayout,
} from 'containers/Layout';

import projectFeatureRoutes from './projectFeatureRoutes';
import projectTimecardRoutes from './projectTimecardRoutes';
import projectReportsRoutes from './projectReportsRoutes';

//components
// import ProjectLander from 'containers/ProjectLander';
const ProjectLander = React.lazy(() => import('containers/ProjectLander'));
const CrewList = React.lazy(() => import('feature/CrewList/CrewList'));
const ProjectAdminWrapper = React.lazy(() =>
  import('feature/ProjectAdmin/ProjectAdminWrapper'),
);

const projectRoutes = [
  {
    title: 'Project Lander',
    path: '/projects/:projectId',
    headers: [BlankHeader],
    layout: DefaultLayout,
    component: ProjectLander,
  },
  {
    title: 'Crew List',
    path: '/projects/:projectId/crew-list',
    headers: [Header, ProjectHeader],
    layout: FullWidthLayout,
    component: CrewList,
  },
  {
    path: '/projects/:projectId/settings',
    redirect: '/projects/:projectId/settings/timecards',
  },
  {
    title: 'Project Admin Timecards',
    path: '/projects/:projectId/settings/timecards',
    headers: [Header, ProjectHeader],
    layout: MaxWidthLayout,
    component: ProjectAdminWrapper,
  },
  ...projectFeatureRoutes,
  ...projectTimecardRoutes,
  ...projectReportsRoutes,
];

export default projectRoutes;
