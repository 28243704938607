import {
  Header,
  MaxWidthLayout,
  ProjectHeader,
  BlankHeader,
} from 'containers/Layout';

//components
import WTC from 'containers/Employees/Reviews/WTC/WTC';
import WrapperWTC from 'containers/Employees/Reviews/WTC/WrapperWTC';

const projectWtcRoutes = [
  {
    title: 'WTC',
    path: '/projects/:projectId/review/:reviewType/:reviewBatchId/wtc',
    headers: [Header, ProjectHeader],
    layout: MaxWidthLayout,
    component: WTC,
    waitForUserToLoad: true,
  },
  {
    title: 'WTC',
    path: '/projects/:projectId/review/:reviewType/wtc',
    headers: [Header, ProjectHeader],
    layout: MaxWidthLayout,
    component: WTC,
    waitForUserToLoad: true,
  },
  {
    title: 'Pop Out WTC',
    path: '/projects/:projectId/popOutWTC/:reviewBatchId',
    headers: [BlankHeader],
    layout: MaxWidthLayout,
    component: WrapperWTC,
    waitForUserToLoad: true,
    noCnCHeader: true,
  },
];

export default projectWtcRoutes;
