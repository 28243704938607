import { buildUrl } from '../api';

const URL_LIST = '/projects/search';
const URL_DEPARTMENTS = '/projects/:projectId/departments';
const URL_PAYROLL = '/projects/:projectId/payroll';
const URL_SETTINGS = '/projects/:projectId/settings';
const URL_TOGGLE_HIDE = '/projects/:projectId/toggle-hide';
const URL_PROJECT = '/projects/:projectId';
const URL_PROJECT_YEAR = '/projects/:projectId/year/:year';
const URL_STATS = '/projects/:projectId/stats';

const URL_ME = '/projects/:projectId/me';
const URL_ALLOWANCES = '/projects/:projectId/allowance_types';
const URL_START = '/projects/:projectId/start';
const URL_PAYROLL_SETTINGS = '/projects/:projectId/settings/payroll';
const URL_PROJECT_USERS = '/projects/:projectId/users/department/:departmentId';
const URL_PROJECT_DEPARTMENTS =
  '/projects/:projectId/departments/:departmentId';
const URL_PERMISSIONS = '/projects/:projectId/me/permissions';

const URL_EMAIL_TEMPLATES = '/projects/:projectId/email_templates';
const URL_EMAIL_TEMPLATE_BY_ID = '/projects/:projectId/email_templates/:id';
const URL_STUDIO_FOLDERS = '/projects/:externalId/studio/folders';

const URL_TIMECARD_TEMPLATES = '/timecardTemplates';

const URL_RECIPIENTS = '/projects/:projectId/notification-recipients';
const URL_REMOVE_RECIPIENTS =
  '/projects/:projectId/notification-recipients/:id';

const URL_EMAIL_PROJECTS_MERGE = '/projects/:projectId/users/email';

const URL_PROJECT_ADMIN = '/projects/:projectId/settings/employeeTimecard';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ clientV2 }) => ({
  async list({ payload }) {
    const rsp = await clientV2.post(URL_LIST, payload);
    return rsp.data;
  },

  //initial project call without year
  async project({ projectId }) {
    if (projectId) {
      const url = buildUrl(URL_PROJECT, { projectId });
      const rsp = await clientV2.get(url);
      return rsp.data;
    }
    return null;
  },

  async projectYear({ projectId, year }) {
    if (projectId) {
      const url = buildUrl(URL_PROJECT_YEAR, { projectId, year });
      const rsp = await clientV2.get(url);
      return rsp.data;
    }
    return null;
  },

  async departments({ projectId }) {
    const url = buildUrl(URL_DEPARTMENTS, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  //this is not being used anywhere currently
  //also this API is not written in .net core
  async payroll({ projectId }) {
    const url = buildUrl(URL_PAYROLL, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async settings({ projectId }) {
    const url = buildUrl(URL_SETTINGS, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async getEmailTemplates({ projectId }) {
    const url = buildUrl(URL_EMAIL_TEMPLATES, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async createEmailTemplate({ projectId, data }) {
    const url = buildUrl(URL_EMAIL_TEMPLATES, { projectId });
    const rep = await clientV2.post(url, data);
    return rep.data;
  },

  async updateEmailTempalte({ projectId, id, data }) {
    const url = buildUrl(URL_EMAIL_TEMPLATE_BY_ID, { projectId, id });
    const rep = await clientV2.put(url, data);
    return rep.data;
  },

  async updateSettings({ projectId, settings }) {
    const url = buildUrl(URL_SETTINGS, { projectId });
    const rsp = await clientV2.put(url, { settings });
    return rsp.data;
  },

  async toggleHide({ projectId }) {
    const url = buildUrl(URL_TOGGLE_HIDE, { projectId });
    const rsp = await clientV2.put(url);
    return rsp.data;
  },

  async stats({ projectId }) {
    const url = buildUrl(URL_STATS, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async userInfo({ projectId }) {
    const url = buildUrl(URL_ME, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  //Project settings Allowances
  async allowances({ projectId }) {
    const url = buildUrl(URL_ALLOWANCES, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  //project settings Allowances
  async updateProjectAllowances({ projectId, data }) {
    const url = buildUrl(URL_ALLOWANCES, { projectId });
    const rsp = await clientV2.put(url, data);
    return rsp.data;
  },

  async validateStartProject({ projectId, project = {} }) {
    const url = buildUrl(URL_START, { projectId });
    const rsp = await clientV2.post(url, project);

    return rsp.data;
  },

  async startProject({ projectId, defaultSettings = {} }) {
    const url = buildUrl(URL_START, { projectId });
    const rsp = await clientV2.put(url, defaultSettings);

    return rsp.data;
  },

  async getPayrollSettings({ projectId }) {
    const url = buildUrl(URL_PAYROLL_SETTINGS, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async projectUsers({ projectId, departmentId }) {
    const url = buildUrl(URL_PROJECT_USERS, { projectId, departmentId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async getPermissions({ projectId }) {
    const url = buildUrl(URL_PERMISSIONS, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async projectDepartment({ projectId, departmentId }) {
    const url = buildUrl(URL_PROJECT_DEPARTMENTS, { projectId, departmentId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async getStudioFolders({ externalId }) {
    const url = buildUrl(URL_STUDIO_FOLDERS, { externalId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async getTimecardTemplates() {
    const rsp = await clientV2.get(URL_TIMECARD_TEMPLATES);
    return rsp.data;
  },

  async notificationRecipients({ projectId }) {
    const url = buildUrl(URL_RECIPIENTS, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async addRecipients({ projectId, recipients }) {
    const url = buildUrl(URL_RECIPIENTS, { projectId });
    const rsp = await clientV2.put(url, recipients);
    return rsp.data;
  },

  async removeRecipient({ projectId, id }) {
    const url = buildUrl(URL_REMOVE_RECIPIENTS, { projectId, id });
    const rsp = await clientV2.delete(url);
    return rsp.data;
  },

  async mergeEmailProjects({ request }) {
    const response = await clientV2.put(URL_EMAIL_PROJECTS_MERGE, request);
    return response.data;
  },
  async fetchProjectAdmin({ projectId }) {
    const url = buildUrl(URL_PROJECT_ADMIN, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },
  async updateProjectAdmin({ projectId, data }) {
    const url = buildUrl(URL_PROJECT_ADMIN, { projectId });
    const rsp = await clientV2.post(url, data);
    return rsp.data;
  },
});
