import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation } from 'react-router-dom';

//actions
import * as actions from 'actions/searchTimecards';
import { setFromURL, setCurrentTimecard } from 'actions/timecards';
import { showAlert } from 'actions/alert';
import { show as showModal } from 'actions/modalDialog';
import { setSelectedFlows } from 'actions/searchTimecards';

//selectors
import {
  getData,
  getTotalCount,
  getLoading,
  getSortBy,
  getSelectAllFlag,
  getSubmitting,
  getDeleting,
} from 'selectors/searchTimecards';
import { currentUser } from 'selectors/session';
import { getPermissions } from 'selectors/userInfo';
import { getProjectDetails } from 'selectors/project';
import { getModalVisible } from 'selectors/modalDialog';

//components
import {
  SearchTable,
  SearchSidebar,
} from 'components/Employees/Reviews/SearchTimecards/';
import SubmitDraftModal from './SubmitDraftModal';
import DeleteSearchModal from './DeleteSearchModal';
import AddCommentModal from 'containers/Employees/Reviews/Reviewer/Modals/AddComment';
import RejectApprovalFlow from './RejectApprovalFlow';

//utils
import { DH, PA, UPM } from 'components/props/profiles';
import { getProjectFromURL } from 'utils/helperFunctions';

const useStyles = makeStyles(({ palette }) => ({
  SearchTimecards: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: palette.background.default,
  },
}));

let currentUserRole;

const mapState = state => ({
  data: getData(state),
  totalCount: getTotalCount(state),
  currentUser: currentUser(state),
  sortBy: getSortBy(state),
  loading: getLoading(state),
  permissions: getPermissions(state),
  selectAllFlag: getSelectAllFlag(state),
  submitting: getSubmitting(state),
  deleting: getDeleting(state),
  dhGrossEnabled: getProjectDetails(state).dhGrossEnabled,
  isModalVisible: getModalVisible(state),
});

const mapDispatch = dispatch => ({
  onRejectBatch: selected => {
    dispatch(setSelectedFlows({ selectedApprovalFlows: selected }));
    dispatch(showModal({ dialog: 'RejectApprovalFlow', maxWidth: 'md' }));
  },
  onApproveBatch: selected => {
    dispatch(
      actions.bulkSubmitFromTimecard({
        comment: '',
        status: 'approved',
        timecardEntryHeaderIds: selected,
      }),
    );
  },
  onInitFetch: () => {
    dispatch(actions.init());
  },
  onSearchFetch: () => dispatch(actions.fetch()), //fetch data only ( to be used on sort change)
  onCleanup: () => dispatch(actions.cleanup()),
  onStoreSort: sortBy => dispatch(actions.storeSort({ sortBy })),
  onClearFilters: () => {
    dispatch(actions.clearFilters());
    dispatch(actions.fetch({ init: true }));
  },
  onLoadMore: () => {
    dispatch(actions.loadMore());
    dispatch(actions.fetch());
  },
  onClickTimecardWithoutPath: ({ timecard }, pathname) => {
    const projectId = getProjectFromURL(pathname);
    let toURL = `/projects/${projectId}`;
    try {
      if (currentUserRole === DH) {
        toURL += '/reviews';
        if (timecard.status === 'Ready for me') {
          toURL += `/batches/${timecard.batchId}/approval-flows/${timecard.approvalFlowId}`;
        } else {
          toURL += `/timecards/${timecard.timecardId}`;
        }
        dispatch(setCurrentTimecard({ timecardId: timecard.timecardId }));
      } else if (currentUserRole === PA || currentUserRole === UPM) {
        toURL += `/review/ready/${timecard.batchWorksightId}/wtc?timecardHeaderId=${timecard.timecardEntryHeaderId}`;
      }
      dispatch(setFromURL({ fromURI: pathname }));
      dispatch(push(toURL));
    } catch (error) {
      console.error('Error creating URL for timecard view');
      dispatch(showAlert());
    }
  },

  onSubmitEmergencyTimecards: () => {
    dispatch(showModal({ dialog: 'emergencyTimecard' }));
  },
  onShowSubmitModal: ({ selectedRows = [], variant }) => {
    const timecards = selectedRows
      .filter(selected => selected.original.status === 'Draft')
      .map(selected => selected.original);

    dispatch(
      showModal({
        dialog: 'SubmitDraftModal',
        modalParams: { timecards, variant },
      }),
    );
  },
  onShowDeleteModal: selectedRows => {
    const timecardIds = selectedRows
      .filter(selected => selected.original.status === 'Draft')
      .map(selected => ({
        timecardEntryHeaderId: selected.original.timecardEntryHeaderId,
      }));
    dispatch(
      showModal({ dialog: 'DeleteSearchModal', modalParams: { timecardIds } }),
    );
  },
  onSetSelectAllFlag: ({ selectAllFlag }) =>
    dispatch(actions.setSelectAllFlag({ selectAllFlag })),
  onOpenTimecards: rows => {
    const timecardHeaderIds = rows.map(r => r.original.timecardEntryHeaderId);
    dispatch(actions.openTimecardsInWTC({ timecardHeaderIds }));
  },
});

const SearchTimecards = props => {
  const classes = useStyles();
  const {
    //data
    data,
    totalCount,
    currentUser,
    sortBy,
    permissions,
    selectAllFlag,
    //loading
    loading,
    submitting,
    deleting,
    isModalVisible,
    //dispatch
    onInitFetch,
    onLoadMore,
    onSearchFetch,
    onCleanup,
    onStoreSort,
    onClearFilters,
    onClickTimecardWithoutPath,
    onShowSubmitModal,
    onSubmitEmergencyTimecards,
    onShowDeleteModal,
    onSetSelectAllFlag,
    dhGrossEnabled,
    onApproveBatch,
    onRejectBatch,
    onOpenTimecards,
  } = props;
  currentUserRole = currentUser.role;

  const [showFilters, setShowFilters] = useState(true);

  const componentDidMountRef = useRef(false);
  const componentWillUnmountRef = useRef(false);

  // Mount Effect
  useEffect(() => {
    if (componentDidMountRef.current === false) {
      onInitFetch();
      componentDidMountRef.current = true;
    }
  }, [onInitFetch]);

  useEffect(() => {
    return () => {
      componentWillUnmountRef.current = true;
    };
  }, []);

  //Unmount Effect
  useEffect(() => {
    return () => {
      if (componentWillUnmountRef.current === true) {
        onCleanup();
      }
    };
  }, [onCleanup]);

  const location = useLocation();
  const pathname = location.pathname;
  const onClickTimecard = React.useCallback(
    args => {
      onClickTimecardWithoutPath(args, pathname);
    },
    [onClickTimecardWithoutPath, pathname],
  );

  const bulkTimecardSubmission = (selectedRows, arg) => {
    let selTimecards = [];
    if (selectedRows.length > 0) {
      for (let i = 0; i < selectedRows.length; i++) {
        let obj = selectedRows[i];
        selTimecards.push(obj.original.timecardEntryHeaderId);
      }
    }
    if (arg === 'approve') {
      onApproveBatch(selTimecards);
    } else {
      onRejectBatch(selTimecards);
    }
  };
  return (
    <div className={classes.SearchTimecards}>
      <SearchSidebar
        onClearFilters={onClearFilters}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
      />
      <SearchTable
        bulkTimecardSubmission={bulkTimecardSubmission}
        data={data}
        totalCount={totalCount}
        sortBy={sortBy}
        onStoreSort={onStoreSort}
        onSearchFetch={onSearchFetch}
        onLoadMore={onLoadMore}
        showFilters={showFilters}
        onClickTimecard={onClickTimecard}
        loading={loading}
        onShowSubmitModal={onShowSubmitModal}
        onSubmitEmergencyTimecards={onSubmitEmergencyTimecards}
        onShowDeleteModal={onShowDeleteModal}
        permissions={permissions}
        currentUserRole={currentUserRole}
        selectAllFlag={selectAllFlag}
        onSetSelectAllFlag={onSetSelectAllFlag}
        submitting={submitting}
        dhGrossEnabled={dhGrossEnabled}
        deleting={deleting}
        isModalVisible={isModalVisible}
        onOpenTimecards={onOpenTimecards}
      />
      <SubmitDraftModal />
      <DeleteSearchModal />
      <AddCommentModal />
      <RejectApprovalFlow />
      {/* Emergency Timecard modal is in SearchTable */}
    </div>
  );
};

SearchTimecards.propTypes = {
  data: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
  sortBy: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  deleting: PropTypes.bool.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  selectAllFlag: PropTypes.bool.isRequired,
  dhGrossEnabled: PropTypes.bool,
  onInitFetch: PropTypes.func.isRequired,
  onSearchFetch: PropTypes.func.isRequired,
  onCleanup: PropTypes.func.isRequired,
  onStoreSort: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onClickTimecardWithoutPath: PropTypes.func.isRequired,
  onShowSubmitModal: PropTypes.func.isRequired,
  onSubmitEmergencyTimecards: PropTypes.func.isRequired,
  onShowDeleteModal: PropTypes.func.isRequired,
  onSetSelectAllFlag: PropTypes.func.isRequired,
  onOpenTimecards: PropTypes.func.isRequired,
  onApproveBatch: PropTypes.func.isRequired,
  onRejectBatch: PropTypes.func.isRequired,
};

export default compose(connect(mapState, mapDispatch))(SearchTimecards);
