import createAction from './createAction';

export const loading = createAction('bulkEdit/loading');
export const saving = createAction('bulkEdit/saving');
export const calculating = createAction('bulkEdit/calculating');
export const searching = createAction('bulkEdit/searching');
export const loadingDropdown = createAction('bulkEdit/dropdown/loading');
export const initLoad = createAction('bulkEdit/initLoad');
export const setInitBatch = createAction('bulkEdit/setInitBatch');
export const clearInitBatchId = createAction('bulkEdit/clearInitBatchId');
export const onSave = createAction('bulkEdit/onSave');

export const fetch = createAction('fetch/bulkEdit/days');
export const store = createAction('store/bulkEdit/days');
export const save = createAction('save/bulkEdit/timecards');
export const calculate = createAction('calculate/bulkEdit/timecards');

export const fetchDropdown = createAction('fetch/bulkEdit/dropdown');
export const preFetchDropdown = createAction('prefetch/bulkEdit/dropdown');

export const storeBatchId = createAction('store/bulkEdit/batchId');

export const fetchWeekEndings = createAction('fetch/bulkEdit/weekEndings');
export const storeWeekEndings = createAction('store/bulkEdit/weekEndings');

export const storeWeekEnding = createAction('store/bulkEdit/weekEnding');

export const setSearchFilter = createAction('setSearch/bulkEdit/filters');
export const onSelect = createAction('dropdownSelect/bulkEdit/filter');

export const clearFilter = createAction('reset/bulkEdit/filters');

export const fetchDepartments = createAction('fetch/bulkEdit/Departments');
export const storeDepartments = createAction('store/bulkEdit/Departments');

export const fetchEmployeeNames = createAction('fetch/bulkEdit/Employees');
export const storeEmployees = createAction('store/bulkEdit/Employees');

export const fetchBatches = createAction('fetch/bulkEdit/Batches');
export const storeBatches = createAction('store/bulkEdit/Batches');

export const fetchAccountCodes = createAction('fetch/bulkEdit/AccountCodes');
export const storeAccountCodes = createAction('store/bulkEdit/AccountCodes');

export const fetchEpisodes = createAction('fetch/bulkEdit/Episodes');
export const storeEpisodes = createAction('store/bulkEdit/Episodes');

export const fetchSets = createAction('fetch/bulkEdit/Sets');
export const storeSets = createAction('store/bulkEdit/Sets');

export const fetchWeekdays = createAction('fetch/bulkEdit/Weekdays');
export const storeWeekdays = createAction('store/bulkEdit/Weekdays');

export const fetchStatuses = createAction('fetch/bulkEdit/Statuses');
export const storeStatuses = createAction('store/bulkEdit/Statuses');

export const storeDropdown = createAction('store/bulkEdit/dropdown');

export const setDirtyFields = createAction('set/bulkEdit/dirtyFields');
export const copyToAllDirtyFields = createAction('clean/bulkEdit/dirtyFields');
export const resetDirtyFields = createAction('reset/bulkEdit/dirtyFields');

export const storeSortOrder = createAction('store/bulkEdit/SortOrder');

export const storeEditedDays = createAction('store/bulkEdit/storeEditedDays');
export const storeEdits = createAction('store/bulkEdit/storeEdits');
export const clearEditedDays = createAction('clear/bulkEdit/storeEditedDays');
export const clearEdits = createAction('clear/bulkEdit/storeEdits');
export const storeTableData = createAction('store/bulkEdit/storeTableData');
export const storeTcBeingSaved = createAction('store/bulkEdit/tcBeingSaved');
export const storeTcToBeCalculated = createAction(
  'store/bulkEdit/tcToBeCalculated',
);
export const storeTcBeingCalculated = createAction(
  'store/bulkEdit/tcBeingCalculated',
);
export const clearTcToCalculate = createAction('clear/bulkEdit/tcToCalculate');
export const setInitLoad = createAction('set/bulkEdit/initLoad');
export const storeSaveResults = createAction('store/bulkEdit/saveResults');
export const clearSaveResults = createAction('clear/bulkEdit/saveResults');
export const storeCalculateResults = createAction(
  'store/bulkEdit/calculateResults',
);
export const clearCalculateResults = createAction(
  'clear/bulkEdit/calculateResults',
);
export const setLastSaveAfterFetch = createAction('set/bulkEdit/lastSave');
export const fetchWECount = createAction('fetch/bulkEdit/WECount');
export const storeWECount = createAction('store/bulkEdit/WECount');
export const storeSearchCount = createAction('store/bulkEdit/SearchCount');
export const storeResultsWE = createAction('store/bulkEdit/ResultsWE');
export const resetFilterSelected = createAction(
  'reset/bulkEdit/filterSelected',
);
export const filterSelected = createAction('set/bulkEdit/filterSelected');
export const fetchSelectedCount = createAction('fetch/bulkEdit/SelectedCount');
export const onSelectFilter = createAction('select/bulkEdit/FilterHandler');
export const onSelectAllFilter = createAction(
  'selectAll/bulkEdit/FilterHandler',
);
export const setProspectiveTimecard = createAction(
  'set/bulkEdit/prospectiveTimecard',
);

export const storeAllowanceTypes = createAction('storeAllowanceTypes/bulkEdit');

export const reset = createAction('reset/bulkEdit');
