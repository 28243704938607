import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import {
  Button,
  Divider,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Switch from 'components/Shared/redux/Switch';
import AutoComplete from 'components/Shared/AutoComplete/AutoComplete';

const useStyles = makeStyles(theme => ({
  content: {
    fontSize: 20,
  },
  label: {
    padding: '5px 10px',
  },
  switch: {
    paddingLeft: 10,
  },
  saveButton: {
    marginTop: 20,
  },
  individualSetting: {
    display: 'flex',
    padding: 8,
    minWidth: 240,
  },
  heading: {
    fontSize: '1.1em',
    fontFamily: 'inherit',
  },
}));

const DtsSettings = props => {
  const classes = useStyles();
  const {
    invalid = false,
    pristine = true,
    submitting = false,
    onFetchTimecardTemplates,
    timecardTemplateOptions,
  } = props;

  return (
    <div style={{ marginTop: 30 }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={onFetchTimecardTemplates}
        >
          <Typography className={classes.heading}>Daily Timesheets</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.noPadding}>
          <div className={classes.content}>
            <section className={classes.individualSetting}>
              <div>
                <Field
                  className={classes.switch}
                  component={Switch}
                  name="dtsSettingEnabled"
                  color="primary"
                />
                Enabled
              </div>
            </section>
            <section className={classes.individualSetting}>
              <span className={classes.label}>Template: </span>
              <AutoComplete
                name={'dtsTemplateId'}
                list={timecardTemplateOptions}
              />
            </section>
          </div>
        </AccordionDetails>
        <Divider />
        <AccordionActions className={classes.noPadding}>
          <Button
            disabled={pristine || submitting || invalid}
            variant="contained"
            color="primary"
            type="submit"
            className={classes.saveButton}
          >
            Save Settings
          </Button>
        </AccordionActions>
      </Accordion>
    </div>
  );
};

DtsSettings.propTypes = {
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  onFetchTimecardTemplates: PropTypes.func.isRequired,
  timecardTemplateOptions: PropTypes.array,
};

export default DtsSettings;
