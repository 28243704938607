import { produce } from 'immer';
import * as actions from 'actions/digitalEdits';
import _ from 'lodash';

import {
  SORT_BYS,
  DATA_CARD_COMPONENTS_CONTROL,
  FILE_COMMENT_CATEGORY_MAP,
  makeRecordIdKey,
  getTcHeadersFromRecordId,
  removeFromCCTimecard,
} from 'feature/DigitalEdits/digitalEditsUtils';

import { db as dbACE } from 'feature/DigitalEdits/AceTable/DataGridTableUtils';

import { getFilteredDropdown } from 'selectors/digitalEdits';
import { setVisibleSelected } from 'components/Employees/Reviews/SearchTimecards/searchUtils';

const initialState = {
  acEditRecord: '',
  batchesOpened: false,
  batchesReopened: false,
  commentLocks: {},
  commentSaveFails: 0,
  commentState: 'closed',
  comments: [],
  currentRecordId: '',
  currentInvoiceId: '',
  dataCardsView: _.cloneDeep(DATA_CARD_COMPONENTS_CONTROL),
  editReports: [],
  episodes: [],
  errorMsg: '',
  errorHeaderIds: [],
  files: {
    uploaded: [], //can be delivery method or invoice files based on category
    submitted: [],
  },
  filters: {
    department: [],
    union: [],
    occCode: [],
    payCode: [],
    recordProp: [
      {
        id: 'Direct Deposit',
        index: 0,
        label: 'Direct Deposit',
        selected: false,
        value: 'directDeposit',
      },
      {
        id: 'Large Payment',
        index: 1,
        label: 'Large Payment',
        selected: false,
        value: 'largePayment',
      },
      {
        id: 'Loan-Out',
        index: 2,
        label: 'Loan-Out',
        selected: false,
        value: 'loanOut',
      },
      {
        id: 'Duplicate Timecard',
        index: 3,
        label: 'Duplicate Timecard',
        selected: false,
        value: 'duplicateTimecard',
      },
      {
        id: 'Edited',
        index: 4,
        label: 'Edited',
        selected: false,
        value: 'isEdited',
      },
      {
        id: 'Error',
        index: 5,
        label: 'Error',
        selected: false,
        value: 'hasErrors',
      },
    ],
  },
  hideEmptyCols: true,
  invoice: {},
  loading: {
    approving: false,
    comments: false,
    details: false,
    editFields: false,
    invoice: false,
    loadingComments: false,
    savingACE: false,
    savingComment: false,
    summary: false,
    uploads: false,
    viewReports: false,
  },
  pendingTasks: {},
  searchText: '',
  searchFilters: {
    department: '',
    occCode: '',
    union: '',
  },
  sortBy: SORT_BYS[0],
  taxColumnMap: {},
  timeoutHeaderIds: [],
  triggerSplitRevert: 0,
  records: {},
  unsavedEdits: {},
  uploadsInProgress: [],
  isInvoiceStatusChanged: false,
  isFormDirty: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    // let timecards, path, recordId, deleteOnComplete;
    switch (action.type) {
      case `${actions.setCurrentRecordId}`: {
        draft.currentRecordId = action.currentRecordId;
        break;
      }
      case `${actions.setCurrentInvoiceId}`: {
        draft.currentInvoiceId = action.currentInvoiceId;
        break;
      }
      case `${actions.setLoading}`: {
        const variant = action.variant;
        draft.loading[variant] = action.loading;
        break;
      }
      case `${actions.updateInvoice}`: {
        draft.invoice = { ...draft.invoice, ...action.invoice };
        break;
      }
      case `${actions.storeTimecardsInfo}`: {
        //This combines both detail and summary calls since we're getting the
        //timecards in 2 pieces, this puts them together regardless of what order they come in
        const timecards = action.timecardsData;
        timecards.forEach(t => {
          const key = makeRecordIdKey(t);
          if (draft.records[key]) {
            for (const field in t) {
              if (Object.hasOwnProperty.call(t, field)) {
                draft.records[key][field] = _.cloneDeep(t[field]);
              }
            }
          } else {
            const newRecord = _.cloneDeep(t);
            const employeeName = t.employeeName || t.employee?.name || '';
            newRecord.firstLetter = employeeName.slice(0, 1).toLowerCase();
            newRecord.employeeName = employeeName;
            newRecord.recordId = key;
            draft.records[key] = newRecord;
          }
        });
        break;
      }
      case `${actions.storeTimecardsUpdate}`: {
        const { timecards: updateTimecards, invoiceAccountCodeSummary } =
          action.data;
        const records = draft.records;
        updateTimecards.forEach(tc => {
          const recordId = makeRecordIdKey(tc);

          const tcRecord = records[recordId];
          if (!tcRecord) {
            throw new Error(`No record found for timecard: ${recordId}`);
          }

          tcRecord.distributions = tc.distributions;
          tcRecord.accountCodeSummary = tc.accountCodeSummary;
          tcRecord.checkSequence = tc.checkSequence;
        });

        if (invoiceAccountCodeSummary) {
          draft.invoice.invoiceAccountCodeSummary = invoiceAccountCodeSummary;
        }
        draft.records = records;
        break;
      }
      case `${actions.resetInvoiceAndRecords}`: {
        draft.records = {};
        draft.invoice = {};
        break;
      }
      case `${actions.setCommentState}`: {
        const commentState = action.commentState;
        if (
          commentState !== 'closed' &&
          commentState !== 'modal' &&
          !commentState?.onCloseInvoiceComments
        ) {
          console.error(
            `Invalid setCommentState value. Must be 'closed' 'modal' or the comment window obj`,
          );
        }

        draft.commentState = commentState;
        break;
      }
      case `${actions.storeComments}`: {
        draft.comments = action.comments;
        break;
      }
      case `${actions.setSearchText}`: {
        draft.searchText = action.searchText;
        break;
      }
      case `${actions.setSortBy}`: {
        draft.sortBy = action.sortBy;
        break;
      }
      case `${actions.setSearchFilter}`: {
        //individual filter option search/filter
        draft.searchFilters[action.filterName] = action.value;
        break;
      }
      case `${actions.onSelectAllFilters}`: {
        const fullList = draft.filters[action.filterName];

        const mockState = { digitalEdits: state };
        const visibleList = getFilteredDropdown(mockState, action.filterName);
        const visibleSelectedCount = visibleList.filter(
          f => f.selected === true,
        ).length;

        let newValue;
        if (visibleSelectedCount === visibleList.length) {
          //unselect all visible
          newValue = false;
        } else {
          //select all visible
          newValue = true;
        }

        setVisibleSelected({ fullList, visibleList, newValue });
        draft.filters[action.filterName] = fullList;
        draft.filtersPristine = false;

        break;
      }
      case `${actions.onSelectFilter}`: {
        const selected =
          draft.filters[action.filterName][action.index].selected;
        draft.filters[action.filterName][action.index].selected = !selected;
        break;
      }
      case `${actions.storeFilterOptions}`: {
        draft.filters[action.filterName] = action.options;
        break;
      }
      case `${actions.clearAllFilters}`: {
        for (const filterName in draft.filters) {
          if (Object.hasOwnProperty.call(draft.filters, filterName)) {
            const filter = draft.filters[filterName];
            filter.forEach(f => (f.selected = false));
          }
        }
        break;
      }
      case `${actions.setCommentSaveFails}`: {
        draft.commentSaveFails = action.count;
        break;
      }
      case `${actions.unmount}`: {
        for (const field in initialState) {
          if (Object.hasOwnProperty.call(initialState, field)) {
            const element = initialState[field];
            draft[field] = _.cloneDeep(element);
          }
        }
        break;
      }
      case `${actions.storeFiles}`: {
        if (action.uploaded) {
          draft.files.uploaded = action.uploaded.sort((a, b) => {
            return b.createdDate.localeCompare(a.createdDate);
          });
        }
        if (action.submitted) {
          draft.files.submitted = action.submitted.sort((a, b) => {
            return b.createdDate.localeCompare(a.createdDate);
          });
        }
        break;
      }

      case `${actions.storePendingFiles}`: {
        draft.files.uploaded = action.pendingFiles.concat(draft.files.uploaded);
        break;
      }
      case `${actions.updateFiles}`: {
        action.pendingFiles.forEach(pendingFile => {
          const fileIndex = draft?.files?.uploaded?.findIndex(
            f => f.uploading && f.fileName === pendingFile.fileName,
          );

          if (fileIndex >= 0) {
            const responseFile = action.responseFiles?.find(
              r =>
                r?.fileName &&
                r.fileName === draft?.files?.uploaded[fileIndex]?.fileName,
            );
            if (responseFile) {
              draft.files.uploaded[fileIndex] = { ...responseFile };
            } else {
              delete draft.files.uploaded[fileIndex].uploading;
              let errorMsg = 'Upload Error, Please Contact Support';
              if (action.action === 'cancel') {
                errorMsg = 'Upload Cancelled';
              }
              draft.files.uploaded[fileIndex].errors?.push(errorMsg);
            }
          }
        });
        break;
      }
      case `${actions.deleteFile}`: {
        const path = action.path || 'uploaded';
        if (action.index >= 0) draft.files[path].splice(action.index, 1);
        else if (action.documentId) {
          const i = draft.files[path].findIndex(
            f => f.documentId === action.documentId,
          );
          draft.files[path].splice(i, 1);
        } else if (action.isApproval) {
          const i = draft.files[path].findIndex(
            f => f.category === FILE_COMMENT_CATEGORY_MAP['InvoiceDelivery'],
          );
          draft.files[path].splice(i, 1);
        }
        break;
      }
      case `${actions.updateFileField}`: {
        const path = action.path || 'uploaded';
        const i = draft.files[path].findIndex(
          f => f.documentId === action.documentId,
        );

        if (i >= 0) {
          if (action.deleteField) {
            delete draft.files[path][i][action.fieldName];
          } else {
            draft.files[path][i][action.fieldName] = action.fieldValue;
          }
        }
        break;
      }
      case `${actions.clearAllErrorFiles}`: {
        draft.files.uploaded = draft.files.uploaded.filter(
          file => !file.errors || (file.errors && file.errors.length === 0),
        );
        break;
      }
      case `${actions.setDataCardsView}`: {
        draft.dataCardsView[action.index].selected =
          !draft.dataCardsView[action.index].selected;
        break;
      }
      case `${actions.updateInProgressUploads}`: {
        let uploadProgress;
        if (action.action === 'add') {
          draft.uploadsInProgress.push(action.uploadProgress);
        } else if (action.action === 'update') {
          uploadProgress = draft.uploadsInProgress.find(
            p => p.uploadId === action.uploadId,
          );
          uploadProgress.percentComplete = action.percentComplete;
        } else if (action.action === 'delete') {
          const uploadProgressIdx = draft.uploadsInProgress.findIndex(
            p => p.uploadId === action.uploadId,
          );
          if (uploadProgressIdx !== -1) {
            draft.uploadsInProgress.splice(uploadProgressIdx, 1);
          }
        }
        break;
      }
      case `${actions.toggleHideEmptyCols}`: {
        draft.hideEmptyCols = !draft.hideEmptyCols;
        break;
      }
      case `${actions.storeTaxColumnMap}`: {
        const taxColObj = {};
        action.taxColumnMap.forEach(taxCol => {
          taxColObj[taxCol.name] = {
            column: Number(taxCol.col),
            position: Number(taxCol.position),
          };
        });
        draft.taxColumnMap = taxColObj;
        break;
      }
      case `${actions.storeEditReports}`: {
        draft.editReports = action.editReports;
        break;
      }
      case `${actions.storeCommentLocked}`: {
        if (action.lockData) {
          draft.commentLocks[action.invoiceId] = action.lockData;
        } else if (action.lockData === '') {
          delete draft.commentLocks[action.invoiceId];
        } else {
          console.error('Invalid storeCommentLocked action');
        }

        break;
      }
      case `${actions.selectAllAdditionalFields}`: {
        for (let i = 0; i < draft.dataCardsView.length; i++) {
          draft.dataCardsView[i].selected = action.checked;
        }
        break;
      }
      case `${actions.setAcEditRecord}`: {
        draft.acEditRecord = action.acEditRecord;
        break;
      }
      case `${actions.storeEpisodes}`: {
        draft.episodes = action.episodes;
        break;
      }
      case `${actions.setBatchesOpened}`: {
        draft.batchesOpened = action.batchesOpened;
        break;
      }
      case `${actions.storeUnsavedEdits}`: {
        const { unsavedEdits } = action;
        const recordId = action.recordId;
        draft.unsavedEdits[recordId] = unsavedEdits;
        break;
      }
      case `${actions.resetACETimer}`: {
        const currentTask = draft.pendingTasks[action.taskId];

        if (currentTask) {
          currentTask.timestamp = new Date().getTime();
        }

        break;
      }

      case `${actions.storePendingTimecards}`: {
        const {
          add,
          remove,
          reset,
          ccToDelete,
          htgTransactionId,
          newSaveCCList,
          timestamp = new Date().getTime(), //param used for testing
        } = action;
        const recordId = action.recordId;
        //only action should be set
        //throw error if more than one is set or none are set
        const definedParams = [add, remove, reset, ccToDelete].filter(
          param => param !== undefined,
        );
        if (definedParams.length !== 1) {
          throw new Error('Exactly one parameter must be defined.');
        }

        // dbACE(
        //   'storePendingTimecards ',
        //   '\nadd',
        //   add,
        //   '\nremove',
        //   remove,
        //   '\nreset',
        //   reset,
        //   '\nccToDelete',
        //   ccToDelete,
        //   htgTransactionId,
        // );

        if (add) {
          //assert add is an array
          if (!Array.isArray(add)) {
            throw new Error('Invalid  action. Add must be an array');
          }

          let linkedRecordIds = [];
          let timecardEntryHeaderIds = [...add];
          let addedHeader = true;
          const allRecordIds = Object.keys(draft.records);
          //get all related timecard headers
          while (addedHeader) {
            let newHeaders = [];
            addedHeader = false;
            for (let i = 0; i < timecardEntryHeaderIds.length; i++) {
              const timecardEntryHeaderId = timecardEntryHeaderIds[i];
              for (let j = 0; j < allRecordIds.length; j++) {
                const recordId = allRecordIds[j];
                if (recordId.includes(timecardEntryHeaderId)) {
                  linkedRecordIds.push(recordId);
                  const headerIds = getTcHeadersFromRecordId(recordId);
                  for (let k = 0; k < headerIds.length; k++) {
                    const headerId = headerIds[k];
                    if (!timecardEntryHeaderIds.includes(headerId)) {
                      newHeaders.push(headerId);
                      addedHeader = true;
                    }
                  }
                }
              }
            }

            timecardEntryHeaderIds = Array.from(
              new Set(newHeaders.concat(timecardEntryHeaderIds)),
            );
          }

          linkedRecordIds = Array.from(new Set(linkedRecordIds));

          const pendingTC = {};

          linkedRecordIds.forEach(recordId => {
            const record = draft.records[recordId];
            record.distributions.forEach(d => {
              const { timecardEntryHeaderId, ccTimecardNumber } = d;
              const pendingArray = pendingTC[timecardEntryHeaderId];
              if (pendingArray) {
                if (!pendingArray.includes(ccTimecardNumber)) {
                  pendingArray.push(ccTimecardNumber);
                }
              } else {
                pendingTC[timecardEntryHeaderId] = [ccTimecardNumber];
              }
            });
          });
          const taskId = htgTransactionId;
          draft.pendingTasks[taskId] = {
            timecards: pendingTC,
            timestamp,
            recordId,
            deleteOnComplete: [],
            newSaveCCList,
          };
        } else if (remove) {
          dbACE('Remove', remove);

          Object.keys(draft.pendingTasks).forEach(taskId => {
            const pendingTimecardsRemove = draft.pendingTasks[taskId].timecards;
            Object.keys(remove).forEach(key => {
              if (pendingTimecardsRemove[key]) {
                pendingTimecardsRemove[key] = pendingTimecardsRemove[
                  key
                ].filter(tc => !remove[key].includes(tc));
                draft.pendingTasks[taskId].timestamp = timestamp;
              } else {
                dbACE('TimecardHeader not found in pendingTimecards', key);
              }
            });
          });
        } else if (reset) {
          dbACE('Reset', reset);
          Object.keys(draft.pendingTasks).forEach(taskId => {
            const resetPendingTimecards = draft.pendingTasks[taskId].timecards;
            delete resetPendingTimecards[reset];
            if (_.isEmpty(resetPendingTimecards)) {
              delete draft.pendingTasks[taskId];
            }
          });
        } else if (ccToDelete) {
          const task = draft.pendingTasks[htgTransactionId];

          if (task) {
            task.deleteOnComplete.push(ccToDelete);
          }
        }

        // dbACE('storePendingTimecards ');
        // dbACE('Pending Tasks: after');
        // Object.keys(draft.pendingTasks).forEach(taskId => {
        //   dbACE(taskId);
        //   dbACE('TimeStamp', JSON.stringify(draft.pendingTasks[taskId].timestamp));
        //   dbACE(JSON.stringify(draft.pendingTasks[taskId].timecards));
        //   dbACE('-----------------');
        // });

        break;
      }
      case `${actions.storeErrorHeaderIds}`: {
        if (action.add) {
          draft.errorHeaderIds = Array.from(
            new Set([...draft.errorHeaderIds, ...action.add]),
          );
        } else if (action.remove) {
          draft.errorHeaderIds = draft.errorHeaderIds.filter(
            rec => action.remove.includes(rec) === false,
          );
        }
        break;
      }
      case `${actions.storeTimeoutHeaderIds}`: {
        if (action.add) {
          draft.timeoutHeaderIds = Array.from(
            new Set([...draft.timeoutHeaderIds, ...action.add]),
          );
        } else if (action.remove) {
          draft.timeoutHeaderIds = draft.timeoutHeaderIds.filter(
            rec => action.remove.includes(rec) === false,
          );
        }
        break;
      }
      case `${actions.updateIsEdited}`: {
        const allRecords = draft.records;
        const { timecardEntryHeaderId } = action;

        Object.keys(allRecords).forEach(recordId => {
          if (recordId.includes(timecardEntryHeaderId)) {
            allRecords[recordId].isEdited = true;
          }
        });

        break;
      }
      case `${actions.reset}`: {
        if (Object.keys(draft).length !== Object.keys(initialState).length) {
          console.warn(
            'Length of draft and initialState are not equal\n',
            'draft',
            Object.keys(draft),
            '\ninitialState',
            Object.keys(initialState),
          );
        }
        for (const key in initialState) {
          if (Object.hasOwnProperty.call(initialState, key)) {
            draft[key] = initialState[key];
          }
        }
        break;
      }
      case `${actions.clearRecordHasErrors}`: {
        draft.records[action.recordId].hasErrors = false;
        break;
      }
      case `${actions.triggerSplitRevert}`: {
        draft.triggerSplitRevert = action.timecardEntryHeaderId;
        break;
      }
      case `${actions.deleteCCTimecard}`:
        {
          const deleteOnComplete = action.deleteOnComplete;
          deleteOnComplete.forEach(ccTimecardNumber => {
            const ccStr = `${ccTimecardNumber}`;

            const draftRecords = draft.records;
            let recordToUpdate = null;

            const recordKeys = Object.keys(draftRecords);
            for (let i = 0; i < recordKeys.length; i++) {
              const recordId = recordKeys[i];
              if (
                recordId.includes(ccStr) &&
                draftRecords[recordId].ccTimecardNumber.includes(ccStr)
              ) {
                recordToUpdate = draftRecords[recordId];
                recordToUpdate.ccTimecardNumber = removeFromCCTimecard(
                  recordToUpdate.ccTimecardNumber,
                  ccStr,
                );
                recordToUpdate.payrollTimecardNumber =
                  recordToUpdate.payrollTimecardNumber.filter(
                    cc => `${cc}` !== ccStr,
                  );

                recordToUpdate.distributions =
                  recordToUpdate.distributions.filter(
                    d => `${d.ccTimecardNumber}` !== ccStr,
                  );

                const newId = makeRecordIdKey(recordToUpdate);
                recordToUpdate.recordId = newId;
                draft.records[newId] = recordToUpdate;

                const unsavedEdit = draft.unsavedEdits[recordId];
                draft.unsavedEdits[newId] = unsavedEdit;

                delete draft.records[recordId];
                delete draft.unsavedEdits[recordId];

                break;
              }
            }
          });
        }
        break;
      case `${actions.setIsInvoiceStatusChanged}`: {
        draft.isInvoiceStatusChanged = action.isInvoiceStatusChanged;
        break;
      }
      case `${actions.setIsFormDirty}`: {
        draft.isFormDirty = action.isFormDirty;
        break;
      }
      default:
    }
  });
