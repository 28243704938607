import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

import SortArrows from 'components/Shared/SortArrows';

import {
  // DEFAULT_SORT,
  STYLING,
} from 'components/Employees/Reviews/SearchTimecards/searchUtils';
import { handleSortBy } from 'utils/reactTableUtils';

const useStyles = makeStyles(({ palette }) => ({
  root: {},
  tableHeaderCell: {
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
    position: 'sticky',
    top: 0,
    zIndex: 10,
    backgroundColor: palette.misc.tableHeader,
    textAlign: 'left',
    padding: STYLING.cellPadding,
    height: 16,
    color: palette.text.secondary,
    fontWeight: '500',
    fontStyle: 'normal',
  },
  tableHeaderRow: {},
  cell: {
    cursor: 'pointer',
  },

  endColumn: {
    width: '100%',
  },
  noPadding: {
    padding: 0,
  },
}));

const noSortColumns = [
  'comments',
  'currentApprovers',
  'emergencyType',
  'selection',
  'daysEntered',
];

const HeaderRow = props => {
  const { headerGroups, setSortBy } = props;
  const classes = useStyles();
  return (
    <thead className={classes.root}>
      {headerGroups.map(headerGroup => {
        const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
        return (
          <tr
            className={classes.tableHeaderRow}
            key={key}
            {...headerGroupProps}
          >
            {headerGroup.headers.map(column => {
              const isSortable = !noSortColumns.includes(column.id);
              const { key, ...columnProps } = column.getHeaderProps();

              return (
                <th
                  key={key}
                  {...columnProps}
                  onClick={() => {
                    if (isSortable) handleSortBy(column, setSortBy, []);
                  }}
                  className={clsx(classes.tableHeaderCell, {
                    [classes.endColumn]: column.id === 'comments',
                    [classes.noPadding]:
                      column.id === 'selection' ||
                      column.id === 'emergencyType',
                  })}
                >
                  {column.id === 'selection'
                    ? column.render('Header')
                    : column.render(props => (
                        <span
                          className={clsx({ [classes.cell]: isSortable })}
                          id={column.id}
                        >
                          <span className={classes.title}>{column.Header}</span>
                          {isSortable && <SortArrows column={column} />}
                        </span>
                      ))}
                </th>
              );
            })}
          </tr>
        );
      })}
    </thead>
  );
};

HeaderRow.propTypes = {
  headerGroups: PropTypes.array.isRequired,
  setSortBy: PropTypes.func.isRequired,
};

export default HeaderRow;
