import moment from './moment';
import _ from 'lodash';
import debug from 'debug';
import TimeValidator from 'utils/TimeValidator';
import {
  lowerCaseLastManIn,
  removeNoValueObj,
  isRegionCanada,
  makeMiniDeal,
} from 'utils/helperFunctions';
import { ACCOUNT_FIELDS_MAP } from 'components/Shared/constants';

export const db = debug('wtc');

export const WTC_FORM_NAME = 'weeklyTimecard';
export const WTC_LAYOUT_STANDARD_TEMPLATE = 'H+ WTC Basic Template';
export const WTC_TC_DELETE_FORM_NAME = 'DeleteTimecardWTC';

export const RESUBMIT_WORKFLOW = 'resubmitToEmployee';

// Returns type double of hours worked in a day minus meals
// similar functionality to calculateDayHours in weekUtils
export const dayHoursCalc = day => {
  if (!day.dayType?.id) return 0.0;
  let hoursWorked;
  if (day.wrapTime === undefined || day.callTime === undefined) {
    hoursWorked = 0.0;
  } else {
    const dayTotal = day.wrapTime - day.callTime;
    const meal1 = day.meal1Out ? day.meal1In - day.meal1Out : 0;
    const meal2 = day.meal2Out ? day.meal2In - day.meal2Out : 0;
    const meal3 = day.meal3Out ? day.meal3In - day.meal3Out : 0;
    hoursWorked = dayTotal - meal1 - meal2 - meal3;
  }
  return Math.max(hoursWorked, 0.0);
};

export const cckList = [
  { value: 'Y', name: 'Y' },
  { value: '1', name: '1' },
  { value: '2', name: '2' },
  { value: '3', name: '3' },
  { value: '4', name: '4' },
  { value: '5', name: '5' },
  { value: '6', name: '6' },
  { value: '7', name: '7' },
  { value: '8', name: '8' },
  { value: '9', name: '9' },
];

export const combineCheckOptions = [
  'Y',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
];

export const WORK_TIME_FIELDS = [
  'callTime',
  'meal1Out',
  'meal1In',
  'meal2Out',
  'meal2In',
  'meal3Out',
  'meal3In',
  'wrapTime',
];

export const convertPaidHours = (data, dayOfWeekIdxs = []) => {
  let paidHours;

  if (data && data.details && dayOfWeekIdxs.length > 0) {
    const tableData =
      data &&
      data.details &&
      dayOfWeekIdxs.map(date => {
        return data.details[date];
      });

    const existingHeaders = new Set();

    tableData.forEach(day => {
      for (const header in day) {
        if (day.hasOwnProperty(header)) {
          const dayInfo = day[header];
          if (dayInfo) {
            existingHeaders.add(header);
          }
        }
      }
    });

    const filteredHeaders =
      data &&
      data.headers &&
      data.headers.filter(header => existingHeaders.has(header));

    const headerObjArray = filteredHeaders.map(field => {
      return {
        columnId: field,
        label: field,
        group: 'calc',
        type: 'read-only',
        visible: true,
      };
    }, []);
    paidHours = {
      ...data,
      filteredHeaders,
      headerObjArray,
      tableData,
    };
  } else {
    paidHours = {
      details: [{}],
      headers: [],
      filteredHeaders: [],
      headerObjArray: [],
      tableData: [],
    };
  }

  return paidHours;
};

//shared style classes

export const rightShadow = {
  boxShadow: '6px 1px 5px -1px #CCCCCC',
};

const WTC_TIME_FIELDS = [
  ...WORK_TIME_FIELDS,
  'ndmIn',
  'ndmOut',
  'ndbIn',
  'ndbOut',
  'lastMan1In',
];

const WTC_NUM_FIELDS = [...WTC_TIME_FIELDS, 'rate', 'hours'];

const formatNumValues = obj => {
  WTC_NUM_FIELDS.forEach(field => {
    if (obj[field] || obj[field] === '') {
      let numVal = parseFloat(obj[field]);
      if (isNaN(numVal)) numVal = null;
      obj[field] = numVal;
    }
  });
};

export function prepTimecardForSave(saveTimecardData, { hasWorkTimeChanges }) {
  saveTimecardData.hasWorkTimeChanges = hasWorkTimeChanges;

  if (saveTimecardData.details && Array.isArray(saveTimecardData.details)) {
    saveTimecardData.details = saveTimecardData.details.filter(
      d => d.unusedDay !== true,
    );

    //remove days without dealMemo
    saveTimecardData.details = saveTimecardData.details.filter(
      d => d.isPartialDealMemo !== true,
    );

    const length = saveTimecardData.details.length;

    for (let i = 0; i < length; i++) {
      //removes breakdown array element from timecard details if its empty
      const day = saveTimecardData.details[i];
      delete day.unusedDay;
      //modify percent breakdown data before sending to backend API
      if (day?.percentBreakdown?.length >= 0) {
        const percentBreakdownLength = day.percentBreakdown.length;
        for (let j = 0; j < percentBreakdownLength; j++) {
          const split = day.percentBreakdown[j];
          const percentBreakdownControlsProperty = 'percentBreakdownControls';
          if (
            !saveTimecardData.hasOwnProperty(percentBreakdownControlsProperty)
          ) {
            saveTimecardData.percentBreakdownControls = {};
          }

          if (split.unitsSplit) {
            split.percentSplit = null;
            split.timeInSplit = null;
            split.timeOutSplit = null;
            saveTimecardData.percentBreakdownControls.unitsSplitCheck = true;
          }
          if (split.timeInSplit) {
            split.timeInSplit = parseFloat(split.timeInSplit);
            split.timeOutSplit = parseFloat(split.timeOutSplit);
            split.percentSplit = null;
            split.unitsSplit = null;
            saveTimecardData.percentBreakdownControls.timeSplitCheck = true;
          }
          if (split.percentSplit) {
            split.unitsSplit = null;
            split.timeInSplit = null;
            split.timeOutSplit = null;
            saveTimecardData.percentBreakdownControls.percentSplitCheck = true;
            saveTimecardData.percentBreakdownControls.roundingPrecision =
              '10ths';
          }
          const onProductionProperty = 'onProduction';
          if (!split.hasOwnProperty(onProductionProperty)) {
            split.onProduction = false;
          }
          split.position = i;
          const newDate = moment(split.splitDate).format('YYYY-MM-DD');
          split.splitDate = newDate;
          delete split.splitType;
          if (split.workState) {
            delete split.workState.cities;
            delete split.workState.counties;
          }
          if (!split.workCounty) delete split.workCounty;
          if (!split.workCity) delete split.workCity;

          removeNoValueObj(split);
        }
      }

      //update num fields for WTC weekdays

      formatNumValues(saveTimecardData.details[i]);

      removeNoValueObj(day);
    }
  }

  if (saveTimecardData.allowances && saveTimecardData.allowances.length > 0) {
    saveTimecardData.allowances.forEach(allowance => {
      removeNoValueObj(allowance);
      formatNumValues(allowance);
    });
  }

  //Whole Timecard actions

  removeNoValueObj(saveTimecardData);

  //update number fields for wtc top level AKA Master Row
  formatNumValues(saveTimecardData);
}

export const PROJECT_REQ_FIELDS = {
  accountCode: { projectLabel: 'accountCodeRequired' },
  dateWorked: { projectLabel: 'dateWorkedRequired' },
  episode: { projectLabel: 'episodeRequired' },
  series: { projectLabel: 'seriesRequired' },
  insurance: { projectLabel: 'insuranceRequired' },
  location: { projectLabel: 'locationRequired' },
  set: { projectLabel: 'setRequired' },
  freeField1: { projectLabel: 'customField1Required' },
  freeField2: { projectLabel: 'customField2Required' },
  freeField3: { projectLabel: 'customField3Required' },
  freeField4: { projectLabel: 'customField4Required' },
};

export function handleChangeDealMemoWTCTimecard(
  newDealId,
  {
    change,
    timecard,
    details,
    dealMemos,
    allowances,
    onFetchAndSetScaleRate,
    onShowRoundingMsg,
    onSetFieldVisibility,
    onUpdateAutoAllowances,
    masterRowData,
  },
) {
  //Change top level timecard info
  handleChangeDealMemoWTCTimecardTop(newDealId, {
    dealMemos,
    timecard,
    change,
    onSetFieldVisibility,
  });

  //change info for each day
  for (let index = 0; index < details.length; index++) {
    const detail = details[index];
    const member = `details[${index}]`;
    handleChangeDealMemosWTCDay(newDealId, {
      change,
      detail,
      member,
      dealMemos,
      onFetchAndSetScaleRate,
      onShowRoundingMsg,
      onSetFieldVisibility,
    });
  }

  //change each allowance
  for (let index = 0; index < allowances.length; index++) {
    handleChangeDealmemosWTCAllowance(newDealId, {
      dealMemos,
      change,
      allowanceName: `allowances[${index}]`,
      allowanceData: allowances[index],
      masterRowData,
    });
  }

  //update auto allowances
  onUpdateAutoAllowances();
}

function handleChangeDealMemoWTCTimecardTop(
  newDealId,
  { dealMemos, timecard, change, onSetFieldVisibility },
) {
  const dealMemo = dealMemos.find(dealMemo => dealMemo.id === newDealId);

  if (!_.isEmpty(timecard) && !_.isEmpty(dealMemo)) {
    if (dealMemo.occupationCode) {
      const occupationCode = {
        id: dealMemo.occupationCode.id,
        code: dealMemo.occupationCode.code,
        name: dealMemo.occupationCode.name,
      };

      change(`occupationCode`, occupationCode);
    }

    if (dealMemo.workSchedule) {
      const workSchedule = {
        id: dealMemo.workSchedule.id,
        code: dealMemo.workSchedule.code,
        name: dealMemo.workSchedule.name,
      };

      change(`schedule`, workSchedule);
    }
  }

  ACCOUNT_FIELDS_MAP.forEach(field => {
    const { name, dealName } = field;
    if (dealMemo[dealName]) {
      change(name, dealMemo[dealName]);
      onSetFieldVisibility(name);
    }
  });

  change('rate', '');

  change(`dealMemo`, dealMemo ? dealMemo : null);
}

export function handleChangeDealMemosWTCDay(
  newDealId,
  {
    dealMemos,
    member,
    detail,
    change,
    onFetchAndSetScaleRate = undefined,
    onShowRoundingMsg = () => {},
    onSetFieldVisibility,
  },
) {
  var guarantee;

  const dealMemo = dealMemos.find(dealMemo => dealMemo.id === newDealId);
  change(`${member}.dealMemo`, dealMemo ? dealMemo : null);

  if (dealMemo) {
    if (detail.isPartialDealMemo) {
      change(`${member}.isPartialDealMemo`, undefined);
    }

    //Timecard days row
    ACCOUNT_FIELDS_MAP.forEach(field => {
      const { name, dealName } = field;
      if (dealMemo[dealName]) {
        change(`${member}.${name}`, dealMemo[dealName]);
        onSetFieldVisibility(name);
      }
    });
  }

  if (!_.isEmpty(detail) && newDealId) {
    if (!_.isEmpty(dealMemo)) {
      if (dealMemo.guarantees && dealMemo.guarantees.length > 0) {
        if (detail.locationType && detail.locationType.code) {
          guarantee = dealMemo.guarantees.find(g => {
            const gCode = g.onLocation && g.onLocation.code.toUpperCase();
            const workCode =
              detail.locationType.code === 'D'
                ? 'D'
                : detail.locationType.code === 'DS'
                ? 'D'
                : detail.locationType.code;
            return gCode === workCode;
          });
        }
        if (!guarantee) {
          guarantee = dealMemo.guarantees.find(
            g =>
              g.onLocation &&
              g.onLocation.code &&
              g.onLocation.code.toUpperCase() === 'S',
          );
        }

        if (
          onFetchAndSetScaleRate &&
          guarantee &&
          guarantee.payAtScale &&
          (guarantee.payAtScale === 'C' || guarantee.payAtScale === 'Y')
        ) {
          onFetchAndSetScaleRate({ member, payAtScale: guarantee.payAtScale });
        } else if (guarantee && guarantee.rate) {
          change(`${member}.rate`, guarantee.rate);
        } else {
          change(`${member}.rate`, '');
        }
      } else {
        change(`${member}.rate`, '');
      }
      if (dealMemo.occupationCode) {
        const occupationCode = {
          id: dealMemo.occupationCode.id,
          code: dealMemo.occupationCode.code,
          name: dealMemo.occupationCode.name,
        };
        change(`${member}.occupationCode`, occupationCode);
      }
      if (dealMemo.workSchedule) {
        const workSchedule = {
          id: dealMemo.workSchedule.id,
          code: dealMemo.workSchedule.code,
          name: dealMemo.workSchedule.name,
        };
        change(`${member}.schedule`, workSchedule);
      }

      //Update Rounding
      const oldDealId = detail?.dealMemo?.id;
      const oldDealMemo = dealMemos.find(dealMemo => dealMemo.id === oldDealId);
      if (dealMemo?.roundTo && oldDealMemo?.roundTo !== dealMemo.roundTo) {
        onShowRoundingMsg();
        const tv = new TimeValidator(dealMemo.roundTo);
        const TIME_FIELDS = [
          'callTime',
          'ndbOut',
          'ndbIn',
          'meal1Out',
          'meal1In',
          'lastMan1In',
          'ndmOut',
          'ndmIn',
          'meal2Out',
          'meal2In',
          'meal3Out',
          'meal3In',
          'wrapTime',
        ];
        TIME_FIELDS.forEach(fieldName => {
          if (detail[fieldName]) {
            const roundedVal = tv.parse(`${detail[fieldName]}`);
            change(`${member}.${fieldName}`, roundedVal);
          }
        });
      }
    }
  }
}

export function handleChangeDealmemosWTCAllowance(
  newDealId,
  { dealMemos, allowanceName, change, allowanceData, masterRowData = {} },
) {
  const dealMemo = dealMemos.find(dealMemo => dealMemo.id === newDealId) || {};

  if (!_.isEmpty(dealMemo) && newDealId) {
    if (!_.isEmpty(dealMemo.occupationCode)) {
      const occupationCode = {
        id: dealMemo.occupationCode.id,
        code: dealMemo.occupationCode.code,
        name: dealMemo.occupationCode.name,
      };
      change(`${allowanceName}.occupationCode`, occupationCode);
    }
    let dealAllowObj = {};
    if (dealMemo?.dealMemoAllowances?.length > 0) {
      dealAllowObj =
        dealMemo?.dealMemoAllowances.find(allowance => {
          return (
            allowance.payCode1?.id === allowanceData.reason?.id ||
            allowance.payCode2?.id === allowanceData.reason?.id
          );
        }) || {};
    }
    change(`${allowanceName}.dealMemo`, dealMemo ? dealMemo : null);
    if (!_.isEmpty(dealMemo)) {
      ACCOUNT_FIELDS_MAP.forEach(field => {
        const { name, dealName } = field;
        const value = getValue(
          dealAllowObj,
          dealMemo,
          masterRowData,
          allowanceData,
          name,
          dealName,
        );
        change(`${allowanceName}.${name}`, value);
        // no need to set field visibility here, handled in dealmemo day level.
      });
    }
  } else {
    throw new Error('No new deal memo found');
  }
}

const getValue = (
  dealAllowObj = {},
  dealMemo = {},
  masterRowData = {},
  allowanceData = {},
  name,
  dealName,
) => {
  /* Account code fields should check below order for value
    1. DealMemoAllowance  
    2. DealMemo, 
    3. Master row
    4. Allowance data - (if already have data from previous deal memo don't override)
    5. null
  */
  // account code special case
  if (dealName === 'wageAccount' && dealAllowObj?.account) {
    return dealAllowObj.account;
  } else if (dealAllowObj[dealName]) {
    return dealAllowObj[dealName];
  }

  if (dealMemo[dealName]) {
    return dealMemo[dealName];
  }

  if (masterRowData[name]) {
    return masterRowData[name];
  }

  if (allowanceData[name]) {
    return allowanceData[name];
  }
  return null; // Default return value
};

const exemptList = [
  'onLocation',
  'start',
  'accountCode',
  'end',
  'gross',
  'hours',
  'days',
  'type',
  'category',
];
//Helper function for handleChangeWorkLocationsDay
function applyGuarantee({ guarantee, change, member }) {
  for (let key in guarantee) {
    if (exemptList.indexOf(key) >= 0) continue;
    if (key === 'rate' && !guarantee[key]) {
      change(`${member}.${key}`, '');
      continue;
    }
    if (guarantee[key]) {
      change(
        `${member}.${key.replace('customField', 'freeField')}`,
        guarantee[key],
      );
    }
  }
}

export function handleChangeWorkLocationsDay(
  newValue,
  { detail, dealMemos, change, member, onFetchAndSetScaleRate },
) {
  const result = newValue;

  const check = detail?.dealMemo?.code;
  const currentDealMemo = dealMemos.find(dealMemo => dealMemo.code === check);

  if (!currentDealMemo) {
    console.warn('No dealMemo found on timecard day:', detail);
  }

  if (!_.isEmpty(currentDealMemo) && currentDealMemo.guarantees.length > 0) {
    const theGuarantee = getGuarBasedOnWorkLoc(currentDealMemo, result);

    if (theGuarantee) {
      applyGuarantee({ guarantee: theGuarantee, change, member });
      if (
        theGuarantee.payAtScale &&
        (theGuarantee.payAtScale === 'C' || theGuarantee.payAtScale === 'Y') &&
        onFetchAndSetScaleRate
      ) {
        onFetchAndSetScaleRate({ member, payAtScale: theGuarantee.payAtScale });
      }
    }
  } else {
    // for deal memo with 0 guarantee
    change(`${member}.rate`, '');
  }
}

//used in DTS as well, but need wtcUtil functions so leaving here.
export const getGuarBasedOnWorkLoc = (dealMemo, workLoc) => {
  const currentGuarantee = getGuarFromDeal(dealMemo, workLoc && workLoc.code);
  const defaultStudio = getGuarFromDeal(dealMemo, 'S');
  const defaultDistant = getGuarFromDeal(dealMemo, 'D');
  let theGuarantee = null;
  if (!_.isEmpty(currentGuarantee)) {
    theGuarantee = currentGuarantee;
  } else if (!_.isEmpty(defaultDistant) && workLoc && workLoc.code === 'DS') {
    theGuarantee = defaultDistant;
  } else if (!_.isEmpty(defaultStudio)) {
    theGuarantee = defaultStudio;
  }
  return theGuarantee;
};

const getGuarFromDeal = (dealMemo, code) =>
  dealMemo &&
  dealMemo.guarantees &&
  dealMemo.guarantees.find(
    guarantee =>
      guarantee && guarantee.onLocation && guarantee.onLocation.code === code,
  );

const fillMissingDetails = timecard => {
  const endDate = moment(timecard.weekEndingDate);
  const startDate = endDate.clone().subtract(6, 'days');
  const details = _.cloneDeep(timecard.details);
  details.sort((a, b) => moment(a.effectiveDate).diff(moment(b.effectiveDate)));

  // Create a map to easily check existing days
  const existingDays = new Map();
  details.forEach(day => {
    existingDays.set(moment(day.effectiveDate).format('YYYY-MM-DD'), day);
  });

  // Iterate through the week starting from the startDate
  const filledDetails = [];
  for (let i = 0; i < 7; i++) {
    const currentDay = moment(startDate).add(i, 'days').format('YYYY-MM-DD');
    if (existingDays.has(currentDay)) {
      // If the day exists in the details, add it to the result
      filledDetails.push(existingDays.get(currentDay));
    } else {
      // If the day is missing, create a placeholder
      filledDetails.push({
        effectiveDate: currentDay,
        dayType: null,
        unusedDay: true,
        dealMemo: _.cloneDeep(timecard.dealMemo),
        employee: _.cloneDeep(timecard.dealMemo.employee),
      });
    }
  }
  return filledDetails;
};

export function addAdditionalTimecardInfo(timecard, rounding) {
  if (timecard && timecard.details) lowerCaseLastManIn(timecard.details);

  timecard.details = fillMissingDetails(timecard);

  if (rounding) {
    addRoundingToDeal(timecard.dealMemo, rounding);
    timecard.details.forEach(day => addRoundingToDeal(day.dealMemo, rounding));
  }

  if (!timecard.allowances) {
    db('Timecard missing allowances.  Adding empty array');
    timecard.allowances = [];
  }

  //add tempId to splits for unique key, id is NOT saved in DB
  timecard.details.forEach(day => {
    const splits = day.percentBreakdown || [];
    if (splits.length) {
      splits.forEach(s => (s.splitId = crypto.randomUUID()));
    }
  });
}

export function addRoundingToDeal(deal, rounding) {
  if (!rounding[deal.id]) {
    throw new Error(`No rounding found for deal ${deal.id}`);
  }
  deal.roundTo = rounding[deal.id];
}

export function getActiveDealMemoIds(timecard) {
  const dealMemoIds = new Set();

  if (timecard?.dealMemo?.id) dealMemoIds.add(timecard.dealMemo.id);

  timecard.details.forEach(day => {
    if (day?.dealMemo?.id) dealMemoIds.add(day.dealMemo.id);
  });

  return Array.from(dealMemoIds);
}

export const getPayCodeFromDealMemoAllowance = (payCodeId, dealMemo) => {
  const dealMemoAllowance =
    dealMemo.dealMemoAllowance || dealMemo.dealMemoAllowances;

  if (
    dealMemoAllowance === undefined ||
    dealMemoAllowance.length === 0 ||
    !payCodeId
  ) {
    return;
  }

  let result = _.find(dealMemoAllowance, a => {
    if (a.payCode1 || a.payCode2) {
      return a?.payCode1?.id === payCodeId || a?.payCode2?.id === payCodeId;
    }
    return false;
  });

  return result;
};

//often the autoComp use an emptyOption that has fields
// so it doesn't get seen as an empty object
// Counting as empty if id is undefined/null/empty-string
// false is used to hide checkboxes visibility
export const fieldHasValue = input => {
  if (input === undefined || input === null || input === false) return false;

  if (
    _.isObject(input) &&
    (_.isEmpty(input) ||
      input.id === undefined ||
      input.id === '' ||
      input.id === null)
  ) {
    return false;
  }

  return true;
};

export const makeScaleKey = ({ dealMemoId, weekEndingDate, payAtScale }) =>
  `scale-${weekEndingDate}-${dealMemoId}-${payAtScale}`;

export const isDistantDay = day => {
  const locationType = day.locationType;

  if (locationType?.code === 'D' || locationType?.code === 'DS') {
    return true;
  } else {
    return false;
  }
};

export const getRateFromLocal = params => {
  let scaleKey;
  try {
    scaleKey = makeScaleKey(params);
    const result = sessionStorage.getItem(scaleKey);

    if (result === null || result === 'fetching') return result;

    const parsedResult = JSON.parse(result);

    const obj = {
      distant: +parsedResult.distant,
      studio: +parsedResult.studio,
      studioGross: +parsedResult.studioGross,
      distantGross: +parsedResult.distantGross,
    };

    // parsing item from sessionStorage
    // validate obj
    if (
      Object.keys(obj).length !== 4 ||
      obj.distant === undefined ||
      obj.studio === undefined ||
      obj.studioGross === undefined ||
      obj.distantGross === undefined ||
      Number.isNaN(obj.distant) ||
      Number.isNaN(obj.studio) ||
      Number.isNaN(obj.studioGross) ||
      Number.isNaN(obj.distantGross)
    ) {
      throw new Error('Invalid scale rate obj');
    }

    return obj;
  } catch (e) {
    console.error('sessionStorage lookup failed:', scaleKey);
    console.error(e);
    return null;
  }
};

export const getStudioAndDistant = data => {
  let studio = 0;
  let distant = 0;
  let studioGross = 0;
  let distantGross = 0;

  if (data.length === 1) {
    const result = data[0];
    studio = result.rate;
    distant = studio;
  } else if (data.length === 2) {
    let result = data.find(d => d.onLocation === 'S');
    if (result) {
      studio = result.rate;
      studioGross = result.htgWeeklyRate || result.htgDailyRate;
    }
    result = data.find(d => d.onLocation === 'D');
    if (result) {
      distant = result.rate;
      //Assumes only one of these will be non-zero - otherwise we'd need to check the source guarantee for weekly / daily
      distantGross = result.htgWeeklyRate || result.htgDailyRate;
    }
  } else {
    throw new Error('Invalid scale rate response. Must be 1 or 2 obj array');
  }

  return { studio, distant, studioGross, distantGross };
};

export const hasAutoCoding = tc => {
  let hasIt = false;
  if ('defaultAccountOverride' in tc && tc.defaultAccountOverride) {
    return true;
  }
  if (tc.details) {
    tc.details.forEach(day => {
      if ('defaultAccountOverride' in day && day.defaultAccountOverride) {
        hasIt = true;
      }
    });
  }
  return hasIt;
};

export const validateSourceBatch = batch => {
  if (!batch || _.isEmpty(batch)) return false;

  const schema = {
    id: 'number',
    name: 'string',
    endsOn: 'string',
    invoiceId: 'string',
    worksightId: 'string',
    htgBatchNumber: 'number',
  };

  for (const fieldPath in schema) {
    if (Object.hasOwnProperty.call(schema, fieldPath)) {
      const value = _.get(batch, fieldPath);
      const expectedType = schema[fieldPath];
      if (typeof value !== expectedType) {
        return false;
      }
    }
  }
  return true;
};

export const SORT_BYS = [
  {
    id: 0,
    field: 'employee',
    label: 'Name (asc)',
    sortOrder: 'ascSort',
  },
  {
    id: 1,
    field: 'employee',
    label: 'Name (desc)',
    sortOrder: 'decSort',
  },
  {
    id: 2,
    field: 'department',
    label: 'Department (asc)',
    sortOrder: 'ascSort',
  },
  {
    id: 3,
    field: 'department',
    label: 'Department (desc)',
    sortOrder: 'decSort',
  },
];

export const assertIsString = str => {
  if (typeof str !== 'string') {
    db(
      `Expected string, found type: ${typeof str}. Converting to empty string`,
    );
    str = '';
  }
  return str;
};

export const makeTextFilter = textFilter => {
  let textSearchFilter = assertIsString(textFilter).toLowerCase();

  const func = timecard => {
    if (!textSearchFilter) return true;

    let { employee = '', employeeSSN = '', occupationName = '' } = timecard;

    employee = assertIsString(employee);
    employeeSSN = assertIsString(employeeSSN);
    occupationName = assertIsString(occupationName);

    const lastSsnDigits = employeeSSN?.replace(/[X-]/g, '') || '';

    return (
      employee.toLowerCase().indexOf(textSearchFilter) >= 0 ||
      lastSsnDigits.indexOf(textSearchFilter) >= 0 ||
      occupationName.toLowerCase().indexOf(textSearchFilter) >= 0
    );
  };
  return func;
};

export const makeTimecardsFilter = activeFilters => {
  const { department, status } = activeFilters;

  const func = timecard => {
    const { departmentId: deptId, status: timecardStatus } = timecard;

    const hasDept = !department.length || department.includes(deptId);

    const hasStatus = !status.length || status.includes(timecardStatus);

    return hasDept && hasStatus;
  };
  return func;
};

export const validateTimecard = (timecard, drawerTimecards) => {
  const makeError = field => ({
    completed: false,
    created: Date.now(),
    field,
    level: 'Error',
    text: `This timecard does not have a valid ${field}. Contact support`,
    user: 'wtcValidation',
  });

  if (Array.isArray(timecard.errors) === false) {
    console.warn('Timecard.errors not array as expected.');
    timecard.errors = [];
  }

  if (!timecard.batch || !timecard.batch.id) {
    timecard.errors.push(makeError('Batch'));
  }
  if (!timecard.dealMemo || !timecard.dealMemo.id) {
    timecard.errors.push(makeError('Deal memo'));
  }
  if (!timecard.weekEndingDate) {
    timecard.errors.push(makeError('Week ending'));
  }

  const drawerTimecard = drawerTimecards.find(
    tc => tc.timecardEntryHeaderId === timecard.timecardEntryHeaderId,
  );

  if (!drawerTimecard || !drawerTimecard.department) {
    timecard.errors.push(makeError('Department'));
  }

  if (!timecard.employee || !timecard.employee.code) {
    const field = isRegionCanada(localStorage.getItem('region'))
      ? 'SIN'
      : 'SSN';
    timecard.errors.push(makeError(field));
  }
};
const getFieldName = str => {
  return str.split('.')[1];
};
const getFocusDay = str => {
  const matches = str.match(/\[(.*)\]/);
  return Number(matches[1]);
};

export const checkParent = (parent, child) => {
  if (parent === null) return false;
  return parent !== child && parent.contains(child);
};

export const downHandler = (e, count, str, mstRow) => {
  let day, fieldName, x, nuName, nextname;
  e.preventDefault();
  e.stopPropagation();

  if (e.target.type === 'checkbox') {
    if (checkParent(mstRow, e.target)) {
      if (e.shiftKey) {
        return;
      }
      nextname = `${str}[0].${e.target.name}`;
      x = document.getElementsByName(nextname);
      x[0].select();
    } else {
      day = getFocusDay(e.target.name);
      fieldName = getFieldName(e.target.name);
      if (e.shiftKey) {
        if (day === 0) {
          if (!mstRow) {
            return;
          }
          x = document.getElementsByName(fieldName);
          x[0].select();
        } else if (day > 0) {
          nuName = `${str}[${day - 1}].${fieldName}`;
          x = document.getElementsByName(nuName);
          x[0].select();
        }
      } else {
        if (day + 1 === count) {
          return;
        }
        nuName = `${str}[${day + 1}].${fieldName}`;
        x = document.getElementsByName(nuName);
        x[0].select();
      }
    }
  } else if (e.target.type === 'text') {
    if (e.target.name === '') {
      if (checkParent(mstRow, e.target)) {
        if (e.shiftKey) {
          return;
        }
        fieldName = e.target.id.split('auto-complete-for-')[1];
        nuName = `auto-complete-for-${str}[0].${fieldName}`;
        x = document.getElementById(nuName);
        x.select();
      } else {
        day = getFocusDay(e.target.id);
        if (e.shiftKey) {
          if (day === 0) {
            if (!mstRow) {
              return;
            }
            fieldName = e.target.id.split('].')[1];
            nuName = `auto-complete-for-${fieldName}`;
            x = document.getElementById(nuName);
            x.select();
          } else if (day > 0) {
            fieldName = e.target.id.split('].')[1];
            nuName = `auto-complete-for-${str}[${day - 1}].${fieldName}`;
            x = document.getElementById(nuName);
            x.select();
          }
        } else {
          if (day + 1 === count) {
            return;
          }
          fieldName = e.target.id.split('].')[1];
          nuName = `auto-complete-for-${str}[${day + 1}].${fieldName}`;
          x = document.getElementById(nuName);
          x.select();
        }
      }
    } else {
      if (checkParent(mstRow, e.target)) {
        if (e.shiftKey) {
          return;
        }
        nextname = `${str}[0].${e.target.name}`;
        x = document.getElementsByName(nextname);
        x[0].select();
      } else {
        day = getFocusDay(e.target.name);
        fieldName = getFieldName(e.target.name);
        if (e.shiftKey) {
          if (day === 0) {
            if (!mstRow) {
              return;
            }
            x = document.getElementsByName(fieldName);
            x[0].select();
          } else if (day > 0) {
            nuName = `${str}[${day - 1}].${fieldName}`;
            x = document.getElementsByName(nuName);
            x[0].select();
          }
        } else {
          if (day + 1 === count) {
            return;
          }
          nuName = `${str}[${day + 1}].${fieldName}`;
          x = document.getElementsByName(nuName);
          x[0].select();
        }
      }
    }
  }
};

export const calcTimecardTimeChanges = (prevTimecard, nextTimecard) => {
  const changedTimes = {};

  const prevDates = prevTimecard.details
    .filter(d => d.unusedDay !== true && d.dealMemo !== null)
    .map(d => moment(d.effectiveDate).format());

  const nextDates = nextTimecard.details
    .filter(d => d.unusedDay !== true && d.dealMemo !== null)
    .map(d => moment(d.effectiveDate).format());

  prevDates.forEach(date => {
    if (nextDates.includes(date) === false) {
      changedTimes[date] = 'removed';
    }
  });

  nextDates.forEach(date => {
    if (prevDates.includes(date) === false) {
      changedTimes[date] = 'added';
    } else {
      getTimeDiffs(changedTimes, date, prevTimecard, nextTimecard);
    }
  });
  return changedTimes;
};

const getTimeDiffs = (changedTimes, date, prevTimecard, nextTimecard) => {
  const prev = prevTimecard.details.find(
    d => moment(d.effectiveDate).format() === date,
  );

  const next = nextTimecard.details.find(
    d => moment(d.effectiveDate).format() === date,
  );

  WORK_TIME_FIELDS.forEach(fieldName => {
    const prevVal = prev[fieldName];
    const nextVal = next[fieldName];
    if (prevVal !== nextVal) {
      if (!changedTimes[date]) changedTimes[date] = {};
      if (!changedTimes[date][fieldName]) changedTimes[date][fieldName] = {};

      changedTimes[date][fieldName] = { prevVal, nextVal };
    }
  });
};

export const generateComment = (changedTimes, tableFields) => {
  const comments = [];
  const dates = Object.keys(changedTimes).sort((a, b) => {
    if (a < b) return -1;
    return 1;
  });

  dates.forEach(date => {
    const changedEntry = changedTimes[date];
    const dayOfWeek = moment(date).format('dddd');
    if (typeof changedEntry === 'string') {
      let str = `${dayOfWeek} ${changedEntry}`;
      comments.push(str);
    } else {
      for (const fieldName in changedEntry) {
        if (Object.hasOwnProperty.call(changedEntry, fieldName)) {
          const changes = changedEntry[fieldName];
          const fieldLabel = tableFields[fieldName]?.label || fieldName;
          let str = `${dayOfWeek} ${fieldLabel} `;

          const { prevVal, nextVal } = changes;

          if ((prevVal || prevVal === 0) && (nextVal || nextVal === 0)) {
            str += `changed from ${prevVal.toFixed(1)} to ${nextVal.toFixed(
              1,
            )}.`;
          } else if (!prevVal && prevVal !== 0) {
            str += `added as ${nextVal.toFixed(1)}.`;
          } else if (!nextVal && nextVal !== 0) {
            str += 'removed.';
          }
          comments.push(str);
        }
      }
    }
  });

  let comment = '';
  comments.forEach(line => (comment += `${line}\n`));

  return comment;
};

export const getFieldType = (type, key) => {
  //different - types form HTG resp
  // ['text', 'checkbox', 'auto-complete', 'float', 'time', 'label', 'mask']
  switch (type) {
    case 'checkbox':
      return 'checkbox';
    case 'auto-complete':
      return 'auto-complete';
    case 'text':
    case 'time':
    case 'float':
    case 'mask':
    case 'label':
      if (key === 'combineCheck') return 'auto-complete';
      return 'text';
    default:
  }
};

export const sortWTCFieldsByPosition = obj => {
  const res = {};
  const order = Object.keys(obj).sort(
    (a, b) => obj[a]?.position - obj[b]?.position,
  );
  order.forEach(key => {
    res[key] = obj[key];
  });
  return res;
};

export const createNewWTCAllowance = (
  masterRowData,
  dealMemos,
  locationTypeId,
  workLocations = [],
  dealAllo = {},
) => {
  const newAllowance = {
    tempId: crypto.randomUUID(), //used to ID new allowances before and after save in WTC
  };
  const countryId = _.get(masterRowData, 'workCountry.id', '');
  const stateId = _.get(masterRowData, 'workState.id', '');
  const cityId = _.get(masterRowData, 'workCity.id', '');
  const countyId = _.get(masterRowData, 'workCounty.id', '');
  const subdivisionId = _.get(masterRowData, 'workSubdivision.id', '');

  if (countryId) {
    newAllowance.workCountry = _.cloneDeep(masterRowData.workCountry);
  }

  if (stateId) {
    newAllowance.workState = _.cloneDeep(masterRowData.workState);
  }
  if (cityId) {
    newAllowance.workCity = _.cloneDeep(masterRowData.workCity);
  }
  if (countyId) {
    newAllowance.workCounty = _.cloneDeep(masterRowData.workCounty);
  }
  if (subdivisionId) {
    newAllowance.workSubdivision = _.cloneDeep(masterRowData.workSubdivision);
  }

  const locationType = _.get(masterRowData, 'locationType.id', '');
  const episode = _.get(masterRowData, 'episode.id', '');
  const cck = masterRowData?.combineCheck;
  const accountCode = dealAllo?.account || masterRowData?.accountCode;
  const series = dealAllo?.series || masterRowData?.series;
  const set = dealAllo?.set || masterRowData?.set;
  const freeField1 = dealAllo?.customField1 || masterRowData?.freeField1;
  const freeField2 = dealAllo?.customField2 || masterRowData?.freeField2;
  const freeField3 = dealAllo?.customField3 || masterRowData?.freeField3;
  const freeField4 = dealAllo?.customField4 || masterRowData?.freeField4;
  const location = dealAllo?.location || masterRowData?.location;
  const insurance = dealAllo?.insurance || masterRowData?.insurance;

  /*set allowance locationType based on the below priorities
    1.	Master Row
    2.	Batch Template
    3.	Default to Studio
    4. If no Studio in response set to Empty.
  */

  const batchTemplate = workLocations.find(temp => temp.id === locationTypeId);
  const workLoc = workLocations.find(loc => loc.code === 'S');
  if (locationType) {
    newAllowance.locationType = _.cloneDeep(masterRowData.locationType);
  } else if (!!batchTemplate?.id) {
    newAllowance.locationType = batchTemplate;
  } else if (!!workLoc?.id) {
    newAllowance.locationType = workLoc;
  } else {
    newAllowance.locationType = null;
  }

  if (episode) newAllowance.episode = _.cloneDeep(masterRowData.episode);
  if (cck) newAllowance.combineCheck = masterRowData.combineCheck;
  if (accountCode) newAllowance.accountCode = accountCode;
  if (location) newAllowance.location = location;
  if (insurance) newAllowance.insurance = insurance;
  if (series) newAllowance.series = series;
  if (set) newAllowance.set = set;
  if (freeField1 || freeField1 === 0) {
    newAllowance.freeField1 = freeField1;
  }
  if (freeField2 || freeField2 === 0) {
    newAllowance.freeField2 = freeField2;
  }
  if (freeField3 || freeField3 === 0) {
    newAllowance.freeField3 = freeField3;
  }
  if (freeField4 || freeField4 === 0) {
    newAllowance.freeField4 = freeField4;
  }

  if (masterRowData?.dealMemo?.id) {
    const dealMemoId = masterRowData.dealMemo.id;

    const dealMemo = dealMemos.find(dealMemo => dealMemo.id === dealMemoId);

    if (!_.isEmpty(dealMemo) && dealMemoId) {
      if (!_.isEmpty(dealMemo.occupationCode)) {
        const occupationCode = {
          id: dealMemo.occupationCode.id,
          code: dealMemo.occupationCode.code,
          name: dealMemo.occupationCode.name,
        };
        newAllowance.occupationCode = occupationCode;
      }

      newAllowance.dealMemo = makeMiniDeal({ dealMemo });
    }
  }
  newAllowance.allowanceTypeFlag = 'M';
  newAllowance.frequency = 'F';
  newAllowance.hours = 1;

  return newAllowance;
};

export const getAutoWTCAllowances = ({
  existingAllowances,
  dealMemos,
  timecard,
  allowanceTypes,
  defaultNonTax,
  locationTypeId,
  workLocations,
  dayOnly,
}) => {
  const dealMemo = dealMemos.find(
    dealMemo => dealMemo.id === timecard.dealMemo.id,
  );

  let dealMemoAllowances = dealMemo?.dealMemoAllowances || [];
  const newAutoAllowances = [];
  dealMemoAllowances = dealMemoAllowances.filter(a => a.payCode1 || a.payCode2);

  //update dayCountObj for only used days
  const effectiveDays = timecard.details.filter(day => !day.unusedDay);
  const dayCountObj = effectiveDays?.reduce((acc, day) => {
    if (day.dayType?.id) {
      if (acc[day.dayType.id]) {
        acc[day.dayType.id] += 1;
      } else {
        acc[day.dayType.id] = 1;
      }
    }
    return acc;
  }, {});

  dealMemoAllowances.forEach(dealAllo =>
    processAutoAllowance(dealAllo, {
      timecard,
      dayCountObj,
      existingAllowances,
      dayOnly,
      dealMemo,
      locationTypeId,
      workLocations,
      defaultNonTax,
      allowanceTypes,
      newAutoAllowances,
    }),
  );

  existingAllowances.push(...newAutoAllowances);
  const allowances = existingAllowances.filter(a => {
    if (a.allowanceTypeFlag !== 'A') {
      return true;
    }
    const inCurrentDeal = dealMemoAllowances.find(
      d => d.sequenceNumber === a.sequenceNumber,
    );

    return !!inCurrentDeal;
  });

  return allowances;
};

//shared logic with bulkEdit autoAllowance
export const processAutoAllowance = (
  dealAllo,
  {
    timecard,
    dayCountObj,
    existingAllowances,
    dayOnly,
    dealMemo,
    locationTypeId,
    workLocations,
    defaultNonTax,
    allowanceTypes,
    newAutoAllowances,
  },
) => {
  let existingIndex = -1;
  const frequency = dealAllo.frequency;

  if (dayOnly && frequency === 'F') return;

  existingIndex = existingAllowances.findIndex(
    existing => existing.sequenceNumber === dealAllo.sequenceNumber,
  );
  let newAllowance;
  if (existingIndex !== -1) {
    newAllowance = _.cloneDeep(existingAllowances[existingIndex]);
  } else {
    newAllowance = createNewWTCAllowance(
      timecard,
      [dealMemo],
      locationTypeId,
      workLocations,
      dealAllo,
    );

    newAllowance.allowanceTypeFlag = 'A';
    newAllowance.frequency = frequency;
    newAllowance.sequenceNumber = dealAllo.sequenceNumber;
    newAllowance.hours = 1;
    newAllowance.rate =
      dealAllo.allowancesAmount > 0
        ? dealAllo.allowancesAmount
        : !!dealAllo.rate
        ? dealAllo.rate
        : 0;
    newAllowance.amount = newAllowance.rate;

    const payCodes = [];
    if (!_.isEmpty(dealAllo.payCode1)) {
      payCodes.push(_.cloneDeep(dealAllo.payCode1));
    }
    if (!_.isEmpty(dealAllo.payCode2)) {
      payCodes.push(_.cloneDeep(dealAllo.payCode2));
    }
    let payCode = payCodes[0] || null;

    if (payCodes.length === 2 && defaultNonTax.includes(payCodes[1].code)) {
      payCode = payCodes[1];
    }
    newAllowance.reason = payCode;
  }

  const allowanceType = allowanceTypes.find(
    type => type.id === newAllowance.reason.id,
  );

  if (!allowanceType) {
    throw new Error(
      `Invalid Type for deal memo allowance: ${newAllowance?.reason?.id} not found`,
    );
  }

  if (frequency === 'D') {
    const dayTypes = allowanceType?.dayTypes || [];
    let dayCount = 0;
    dayTypes.forEach(dt => {
      if (dayCountObj[dt.id]) {
        dayCount += dayCountObj[dt.id];
      }
    });
    newAllowance.hours = dayCount;
  }

  let amount = Number(Number(newAllowance.rate) * newAllowance.hours);
  if (Number.isNaN(amount)) amount = 0;
  amount = amount.toFixed(2);

  newAllowance.amount = amount;

  if (newAllowance.hours !== 0) {
    if (existingIndex !== -1) {
      existingAllowances[existingIndex] = newAllowance;
    } else {
      newAutoAllowances.push(newAllowance);
    }
  } else {
    //if hours is 0, remove the allowance
    if (existingIndex !== -1) {
      existingAllowances.splice(existingIndex, 1);
    }
  }
};
