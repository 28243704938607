import { produce } from 'immer';
import * as actions from 'actions/modalDialog';

const initialState = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.show}`: {
        draft[action.dialog] = {
          isRendered: true,
          projectId: action.projectId,
          modalParams: action.modalParams,
        };
        break;
      }
      case `${actions.hide}`: {
        draft[action.dialog] = { isRendered: false };
        break;
      }
      case `${actions.hideAll}`: {
        for (const dialog in draft) {
          if (Object.hasOwnProperty.call(draft, dialog)) {
            draft[dialog] = { isRendered: false };
          }
        }
        break;
      }

      case `${actions.initialize}`: {
        // do not init twice
        if (!draft[action.dialog]) {
          draft[action.dialog] = { isRendered: false };
        }
        break;
      }

      case `${actions.remove}`: {
        delete draft[action.dialog];
        break;
      }

      default:
    }
  });
