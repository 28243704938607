import { Badge, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import EditIcon from '@mui/icons-material/Edit';

const useStyles = makeStyles(theme => ({
  editIcon: {
    position: 'relative',
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    overflow: 'hidden',
    top: '15px',
    right: '5px',
    zIndex: '2',
  },
  editIconAfter: {
    top: '21px',
    left: '1px',
    width: '9px',
    height: '10px',
    content: '""',
    zIndex: '1',
    position: 'absolute',
    borderRadius: '100% 0% 0%  50%',
    backgroundColor: 'white',
  },
}));

const PencilPerson = props => {
  const { color = 'primary' } = props;
  const classes = useStyles();
  return (
    <Badge
      badgeContent={
        <Box>
          <EditIcon className={classes.editIcon} color={color} />
          <Box className={classes.editIconAfter} />
        </Box>
      }
    >
      <PersonIcon sx={{ zIndex: 0 }} color={color} />
    </Badge>
  );
};

PencilPerson.propTypes = {
  color: PropTypes.string,
};

export default PencilPerson;
