import { produce } from 'immer';
import * as actions from 'actions/alert';

const initialState = {
  message: null,
  open: false,
  variant: 'warning',
  action: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.showAlert}`: {
        draft.message = action.message;
        draft.open = true;
        draft.variant = action.variant || 'warning';
        draft.action = action.action || null;
        draft.displayTime = action.displayTime;
        break;
      }

      case `${actions.hideAlert}`: {
        draft.open = false;
        break;
      }

      default:
    }
  });
