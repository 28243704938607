import React from 'react';
import {
  Header,
  FullWidthLayout,
  MaxWidthLayout,
  DefaultLayout,
} from 'containers/Layout';

//subNav
import { Projects as ProjectsNav } from 'containers/Nav/Pages';

//routes
import adminRoutes from './adminRoutes';
import meRoutes from './meRoutes';
import projectRoutes from './projectRoutes';

import AppSwitcher from 'containers/AppSwitcher';
import validate from './validateRoutes';
//components
const ProjectList = React.lazy(() => import('containers/Employees/Projects'));
const AllowanceDocument = React.lazy(() =>
  import('containers/Employees/AllowanceDocument'),
);
const Invitations = React.lazy(() =>
  import('containers/Employees/Profiles/Invitations'),
);
const NotFound = React.lazy(() => import('components/NotFound'));
const ActionRouteComp = React.lazy(() => import('components/ActionRouteComp'));

const routes = [
  {
    title: 'Root',
    path: '/',
    headers: [Header],
    layout: DefaultLayout,
    component: AppSwitcher,
  },
  ...adminRoutes,
  ...meRoutes,
  {
    title: 'Projects',
    path: '/projects',
    headers: [Header, ProjectsNav],
    layout: FullWidthLayout,
    component: ProjectList,
  },
  ...projectRoutes,
  {
    title: 'Allowance Document',
    path: '/allowances/:token',
    headers: [],
    layout: DefaultLayout,
    component: AllowanceDocument,
  },
  {
    title: 'Invitation',
    path: '/invitations/:token',
    headers: [],
    layout: MaxWidthLayout,
    component: Invitations,
  },
  {
    title: 'Remove Impersonate',
    path: '/removeImpersonate',
    headers: [],
    layout: DefaultLayout,
    component: ActionRouteComp,
  },
  {
    title: 'Logout',
    path: '/logout',
    headers: [],
    layout: DefaultLayout,
    component: ActionRouteComp,
  },
  {
    title: 'Not Found',
    path: '*',
    headers: [Header],
    layout: MaxWidthLayout,
    component: NotFound,
  },
];

validate(routes);

export default routes;
