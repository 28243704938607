/* eslint-disable react/prop-types */
import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { Field } from 'redux-form';
import {
  Button,
  TableRow,
  TableCell,
  Typography,
  IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  FormatListNumbered as AccountCodesBtn,
  ChatBubbleOutlined,
  FileCopy,
} from '@mui/icons-material';
import { AutoComplete } from 'components/Shared/AutoComplete';
import {
  formatDate,
  calculateDayHours,
  doesDayAllowTimes,
  isFieldEnabled,
  TIME_FIELD_ORDER,
} from 'utils/weekUtils';
import TimeValidator from 'utils/TimeValidator';
import { NON_TIME_DAY_TYPES } from 'components/Shared/constants/index';
import { TimeCombo } from 'components/Shared/TimeCombo';
import AccountCodes from '../AccountCodes';
import Note from '../Note';
// import { formatDateUTC } from 'utils/formatDate';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    overflow: 'visible',
    marginBottom: 20,
  },
  tableCell: {
    paddingRight: 5,
    verticalAlign: 'middle',
    minWidth: 110,
  },
  dayTypesEmptyError: {
    minWidth: 220,
  },
  firstCell: {
    verticalAlign: 'top',
    paddingRight: 5,
    position: 'sticky',
    left: 0,
    zIndex: 1,
    backgroundColor: palette.background.paper,
  },
  hoursCell: {
    paddingRight: 5,
    verticalAlign: 'middle',
    color: palette.primary.main,
    fontWeight: 700,
    fontSize: 16,
    textAlign: 'center',
  },
  dropShadow: {
    boxShadow: '3px 1px 3px gray',
  },
  noBorder: {
    border: 0,
  },
  evenRow: {
    backgroundColor: palette.primary.table,
  },
  hasError: {
    paddingBottom: 35,
  },
  btnCopy: {
    fontWeight: 700,
    fontSize: 10,
    padding: '2px 8px',
    width: 'max-content',
    '&:hover': {
      backgroundColor: palette.primary['+6'],
      color: palette.common.white,
    },
  },
  iconCopy: {
    fontSize: 14,
    paddingRight: 2,
  },
}));

function WeekDay(props) {
  const {
    colLength,
    day,
    dayTypes: incomingDayTypes = [],
    editableFields,
    even,
    dayError,
    firstDay,
    meals,
    member,
    onCopyToAll,
    showAccountCodes = false,
    stateOptions,
    cityOptions,
    timecard,
    viewOnly = false,
    onSaveNote,
    isTeamTimecard = false,
    dropShadow,
    loadDayTypes,
    change,
    workLocations = [],
    setNoteEmpty,
    showLastMan1In,
    isUserDeleted,
    onFetchStates,
    onFetchCities,
    onResetLoc,
    stateLabel,
    isCityVisible,
    caRegion,
    showNdm,
    showNdb,
    index,
    project,
    onUpdateDailyAutoAllowances,
    isUpdatingAutoAllow,
  } = props;

  const dayTypes = incomingDayTypes.slice();

  const classes = useStyles();
  const [toggleAccountCodes, toggleAccountCodeSection] = useState(false);
  const [toggleComments, toggleCommentSection] = useState(false);

  const editableTimes = isFieldEnabled(editableFields, 'times');
  const editableLocation = isFieldEnabled(editableFields, 'location');
  const editableAccountCodes = isFieldEnabled(editableFields, 'account-codes');

  const dayTypeCode = day?.dayType?.code;
  const allowsTimes = doesDayAllowTimes(dayTypeCode);

  const isPaid = timecard?.status === 'processed';

  const timeValidator = new TimeValidator(
    timecard?.teamDealMemo?.roundTo ?? timecard?.dealMemo?.roundTo,
  );

  const disableTimes =
    viewOnly || !editableTimes || !allowsTimes || !day.htgDayTypeId;

  const disableAccountCodes = !editableAccountCodes;
  const mealRows = [];

  const disableDayTypesMessage =
    'There are no day types that are applicable to all selected employees.\nRemove some employees and try again.';

  //populate on dayType selection
  const dayTypeChanged = useRef(false);
  const skipInitialRender = useRef(false);
  useEffect(() => {
    if (skipInitialRender.current) {
      dayTypeChanged.current = true;
    }
    skipInitialRender.current = true;
  }, [dayTypeCode]);

  useEffect(() => {
    if (dayTypeChanged.current) {
      if (!!dayTypeCode && !day.htgStateId && !day.htgCityId) {
        if (timecard.htgStateId && timecard.state) {
          change(`${member}.htgStateId`, timecard.htgStateId);
          change(`${member}.state`, timecard.state);
          change(`${member}.htgCityId`, timecard.htgCityId);
          change(`${member}.city`, timecard.city);
          change(`${member}.htgCountyId`, timecard.htgCountyId);
          change(`${member}.workCounty`, timecard.county);
          change(`${member}.htgSubdivisionId`, timecard.htgSubdivisionId);
          change(`${member}.workSubdivision`, timecard.subdivision);
        }
      }
      if (!isUpdatingAutoAllow && !isTeamTimecard) {
        onUpdateDailyAutoAllowances(timecard);
      }

      dayTypeChanged.current = false;
    }
  }, [
    change,
    day.htgCityId,
    day.htgStateId,
    dayTypeCode,
    isUpdatingAutoAllow,
    member,
    onUpdateDailyAutoAllowances,
    timecard,
    isTeamTimecard,
  ]);

  const hasError = !_.isEmpty(dayError);

  const showAccountCodeSection =
    showAccountCodes || toggleAccountCodes || hasError;

  let rowSpan = 1;
  rowSpan = showAccountCodeSection ? rowSpan + 1 : rowSpan;
  rowSpan = toggleComments ? rowSpan + 1 : rowSpan;
  // CSS
  const rowClass = clsx(classes.root, { [classes.evenRow]: even });
  const firstCell = clsx(classes.firstCell, {
    [classes.evenRow]: even,
    [classes.dropShadow]: dropShadow,
  });
  const firstRowCell = clsx(classes.tableCell, {
    [classes.noBorder]: showAccountCodeSection || toggleComments,
    [classes.hasError]: hasError,
  });
  const hoursCellClx = clsx(classes.hoursCell, {
    [classes.noBorder]: showAccountCodeSection || toggleComments,
  });
  const commentsCell = clsx(classes.tableCell, {
    [classes.noBorder]: showAccountCodeSection,
  });

  const getErrorText = field => {
    return dayError && dayError[field] ? _.startCase(dayError[field]) : null;
  };

  if (dayTypes.length === 0) dayTypes.push({});

  const isDayTypeEmpty =
    dayTypes &&
    dayTypes.length === 1 &&
    _.isEmpty(dayTypes[0]) &&
    isTeamTimecard === true;

  // calculate total day Hours with rounded feature
  let totalDayHours = calculateDayHours(day);
  if (totalDayHours) {
    totalDayHours = +parseFloat(totalDayHours).toFixed(2);
  } else if (totalDayHours !== 0) {
    totalDayHours = '--';
  }
  if (showNdb) {
    const inKey = 'ndbIn';
    const inLabel = caRegion ? 'General Call' : 'NDB In';

    //ndb
    mealRows.push(
      <TableCell key={inKey} className={firstRowCell}>
        <TimeCombo
          name={`${member}.${inKey}`}
          placeholder={`${inLabel}`}
          disabled={disableTimes}
          value={day[`${inKey}`]}
          viewOnly={viewOnly}
          helperText={getErrorText(inKey)}
          error={getErrorText(inKey) !== null}
          change={change}
          timeValidator={timeValidator}
        />
      </TableCell>,
    );
  }

  _.times(meals || 1, index => {
    const outKey = `meal${index + 1}Out`;
    const outLabel = `Meal${index + 1} Out`;
    const inKey = `meal${index + 1}In`;
    const inLabel = `Meal${index + 1} In`;

    mealRows.push(
      <TableCell key={outKey} className={firstRowCell}>
        <TimeCombo
          name={`${member}.${outKey}`}
          placeholder={`${outLabel}`}
          disabled={disableTimes}
          value={day[`${outKey}`]}
          viewOnly={viewOnly}
          helperText={getErrorText(outKey)}
          error={getErrorText(outKey) !== null}
          change={change}
          timeValidator={timeValidator}
        />
      </TableCell>,
    );

    mealRows.push(
      <TableCell key={inKey} className={firstRowCell}>
        <TimeCombo
          name={`${member}.${inKey}`}
          placeholder={`${inLabel}`}
          disabled={disableTimes}
          value={day[`${inKey}`]}
          viewOnly={viewOnly}
          helperText={getErrorText(inKey)}
          error={getErrorText(inKey) !== null}
          change={change}
          timeValidator={timeValidator}
        />
      </TableCell>,
    );

    if (showLastMan1In && index === 0) {
      mealRows.push(
        <TableCell key={'LMInKey'} className={firstRowCell}>
          <TimeCombo
            name={`${member}.lastMan1In`}
            placeholder={`Last Man In`}
            disabled={disableTimes}
            value={day[`lastMan1In`]}
            viewOnly={viewOnly}
            helperText={getErrorText('lastMan1In')}
            error={getErrorText('lastMan1In') !== null}
            change={change}
            timeValidator={timeValidator}
          />
        </TableCell>,
      );
    }

    if (showNdm && index === 0) {
      const outKey = `ndmOut`;
      const outLabel = `NDM Out`;
      const inKey = `ndmIn`;
      const inLabel = `NDM In`;

      mealRows.push(
        <TableCell key={outKey} className={firstRowCell}>
          <TimeCombo
            name={`${member}.${outKey}`}
            placeholder={`${outLabel}`}
            disabled={disableTimes}
            value={day[`${outKey}`]}
            viewOnly={viewOnly}
            helperText={getErrorText(outKey)}
            error={getErrorText(outKey) !== null}
            change={change}
            timeValidator={timeValidator}
          />
        </TableCell>,
      );

      mealRows.push(
        <TableCell key={inKey} className={firstRowCell}>
          <TimeCombo
            name={`${member}.${inKey}`}
            placeholder={`${inLabel}`}
            disabled={disableTimes}
            value={day[`${inKey}`]}
            viewOnly={viewOnly}
            helperText={getErrorText(inKey)}
            error={getErrorText(inKey) !== null}
            change={change}
            timeValidator={timeValidator}
          />
        </TableCell>,
      );
    }
  });

  function handleChangeDayTypes(id) {
    const result = dayTypes.find(d => d.id === id);

    change(`${member}.dayType.id`, !result ? '' : result.id);
    change(`${member}.dayType.code`, !result ? '' : result.code);
    change(`${member}.dayType.name`, !result ? '' : result.name);

    const isNoTimeDayType = NON_TIME_DAY_TYPES.find(d => d.id === id);

    if (!!isNoTimeDayType) {
      for (let i = 0; i <= TIME_FIELD_ORDER.length; i++) {
        change(`${member}.${TIME_FIELD_ORDER[i]}`, null);
      }
    }
  }

  function handleChangeWorkLocation(id) {
    const result = workLocations.find(d => d.id === id);
    change(`${member}.locationType.id`, !result ? null : result.id);
    change(`${member}.locationType.code`, !result ? null : result.code);
    change(`${member}.locationType.name`, !result ? null : result.name);
  }

  function handleChangeState(newValue) {
    change(`${member}.htgStateId`, newValue && newValue.id);
    change(`${member}.htgCityId`, null);
    change(`${member}.city`, null);
    change(`${member}.htgCountyId`, null);
    change(`${member}.workCounty`, null);
    change(`${member}.htgSubdivisionId`, null);
    change(`${member}.workSubdivision`, null);
  }

  function handleChangeCity(newValue) {
    change(`${member}.htgCityId`, newValue && newValue.id);
  }

  function loadStatesOnOpen() {
    const countryId = day?.htgCountryId;
    onFetchStates({ countryId });
  }

  function loadCitiesOnOpen() {
    const stateId = day?.htgStateId;
    if (stateId) onFetchCities({ stateId });
  }

  return (
    <React.Fragment>
      <TableRow
        id="timecardRow"
        className={`PENDO_timecard_day_${index} ${rowClass}`}
        key={`${day.date}-1`}
      >
        <TableCell className={firstCell} rowSpan={rowSpan}>
          <Field
            component="input"
            name={`${member}.id`}
            type="hidden"
            value={day.id}
          />
          <Typography variant="subtitle2" color="inherit">
            <b>{formatDate(day.date, 'D MMMM')}</b>
          </Typography>
          <Typography variant="subtitle2">
            {formatDate(day.date, 'dddd')}
          </Typography>
          {firstDay && !viewOnly && (
            <Button
              variant="outlined"
              color="primary"
              className={classes.btnCopy}
              disabled={isUpdatingAutoAllow}
              size="small"
              onClick={() => onCopyToAll(day.date)}
            >
              <FileCopy className={classes.iconCopy} /> Copy to all days
            </Button>
          )}
        </TableCell>
        {!caRegion && (
          <TableCell className={firstRowCell}>
            {viewOnly && (
              <Typography>
                {(day.locationType && day.locationType.name) || ''}
              </Typography>
            )}
            {!viewOnly && (
              <AutoComplete
                list={workLocations}
                name={`${member}.htgLocationTypeId`}
                placeholder="Work Location"
                fieldLabel="name"
                fieldValue="id"
                helperText={getErrorText('htgLocationTypeId')}
                error={getErrorText('htgLocationTypeId') !== null}
                disabled={!editableLocation}
                required
                onChange={handleChangeWorkLocation}
              />
            )}
          </TableCell>
        )}
        <TableCell
          className={clsx(firstRowCell, {
            [classes.dayTypesEmptyError]: isDayTypeEmpty,
          })}
        >
          {viewOnly && (
            <Typography>{(day.dayType && day.dayType.name) || ''}</Typography>
          )}
          <div>
            {!viewOnly && (
              <AutoComplete
                list={dayTypes}
                name={`${member}.htgDayTypeId`}
                placeholder="Day Type"
                fieldLabel="name"
                fieldValue="id"
                helperText={
                  getErrorText('htgDayTypeId') || disableDayTypesMessage
                }
                error={getErrorText('htgDayTypeId') !== null || isDayTypeEmpty}
                disabled={viewOnly || !editableTimes || isUpdatingAutoAllow}
                required
                async
                loadOptions={loadDayTypes}
                onChange={handleChangeDayTypes}
              />
            )}
          </div>
        </TableCell>
        <TableCell className={firstRowCell}>
          {viewOnly && (
            <Typography>{(day.state && day.state.name) || ''}</Typography>
          )}
          {!viewOnly && (
            <AutoComplete
              list={stateOptions}
              name={`${member}.state`}
              placeholder={stateLabel}
              fieldLabel="name"
              fieldValue="id"
              helperText={getErrorText('htgStateId')}
              error={getErrorText('htgStateId') !== null}
              required
              defaultSort
              objectSelect
              onChange={handleChangeState}
              onMenuOpen={loadStatesOnOpen}
              onMenuClose={() => onResetLoc('workState')}
              showLoadingMsg
            />
          )}
        </TableCell>
        {isCityVisible && (
          <TableCell className={firstRowCell}>
            {viewOnly && (
              <Typography>{(day.city && day.city.name) || ''}</Typography>
            )}
            {!viewOnly && (
              <AutoComplete
                list={cityOptions}
                name={`${member}.city`}
                placeholder="Work City"
                fieldLabel="name"
                fieldValue="id"
                helperText={getErrorText('htgCityId')}
                error={getErrorText('htgCityId') !== null}
                required
                defaultSort
                objectSelect
                onMenuOpen={loadCitiesOnOpen}
                onChange={handleChangeCity}
                onMenuClose={() => onResetLoc('workCity')}
                showLoadingMsg
              />
            )}
          </TableCell>
        )}
        <TableCell className={firstRowCell}>
          <TimeCombo
            name={`${member}.call`}
            placeholder="Call"
            disabled={disableTimes}
            value={day.call}
            viewOnly={viewOnly}
            helperText={getErrorText('call')}
            error={getErrorText('call') !== null}
            change={change}
            timeValidator={timeValidator}
          />
        </TableCell>
        {mealRows}
        <TableCell className={firstRowCell}>
          <TimeCombo
            name={`${member}.wrap`}
            placeholder="Wrap"
            disabled={disableTimes}
            value={day.wrap}
            viewOnly={viewOnly}
            helperText={getErrorText('wrap')}
            error={getErrorText('wrap') !== null}
            change={change}
            timeValidator={timeValidator}
          />
        </TableCell>
        <TableCell className={hoursCellClx}>{totalDayHours}</TableCell>
        <TableCell className={firstRowCell} align="center">
          {!isTeamTimecard && !isUserDeleted && !isPaid && (
            <IconButton
              aria-label="Show Comments"
              onClick={() => toggleCommentSection(!toggleComments)}
              size="large"
            >
              <ChatBubbleOutlined />
            </IconButton>
          )}

          {!showAccountCodes && (
            <IconButton
              aria-label="Show Account Codes"
              onClick={() => toggleAccountCodeSection(!toggleAccountCodes)}
              size="large"
            >
              <AccountCodesBtn />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      {toggleComments && (
        <TableRow key={`${day.date}-2`} className={rowClass}>
          <TableCell className={commentsCell} colSpan={4} />
          <TableCell className={commentsCell} colSpan={colLength - 7}>
            <Note
              label='Enter Day Note. For Example: "I had an NDB at 6am"'
              name={`note-${day.date}`}
              onSave={val => onSaveNote(val, 'day', timecard.id, day.date)}
              setNoteEmpty={setNoteEmpty}
            />
          </TableCell>
          <TableCell className={commentsCell} colSpan={2} />
        </TableRow>
      )}
      {showAccountCodeSection && (
        <TableRow className={rowClass}>
          <TableCell colSpan={colLength}>
            <AccountCodes
              member={member}
              timecard={timecard}
              rowClass={rowClass}
              disabled={disableTimes}
              viewOnly={viewOnly}
              disableAccountCodes={disableAccountCodes}
              dayError={dayError}
              project={project}
              {...props}
            />
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

export default WeekDay;
