import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import withModalDialog from 'decorators/withModalDialog';
import {
  Button,
  ContentBox,
  TitleBox,
  FooterBox,
} from 'feature/EmployeeTimecard/Shared/styledComponents';

//icons
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Box,
  LinearProgress,
  CircularProgress,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
} from '@mui/material';

//actions
import * as actions from '../actions';
import { hide as hideModal } from 'actions/modalDialog';

//selectors
import * as sel from '../selectors';
import moment from 'moment';
import DealMemoCard from './DealMemoCard';

import {
  EMP_TC_FIELD_LABEL_MAP as fieldMap,
  NEW_TC_ID,
} from 'feature/EmployeeTimecard/empTimecardUtils';
import { useDidMount, useWillUnmount } from 'utils/customHooks';

import { DEALMEMO_DIALOG } from '../Modals/modalNames';
import { empDealSort } from 'utils/helperFunctions';

const selector = formValueSelector(sel.FORM_NAME);

const mapState = state => {
  return {
    dealMemos: sel.getDealMemos(state),
    loading: sel.getDealMemoLoading(state),
    checking: sel.getLoading(state, 'checkCompatibility'),
    compatible: sel.getCompatible(state),
    dealMemo: selector(state, 'dealMemo'),
    endsOn: sel.getEndsOn(state),
    timecardId: selector(state, 'id'),
    changingDeal: sel.getLoading(state, 'changingDeal'),
  };
};

const mapDispatch = dispatch => {
  return {
    onFetchDealMemos: () => dispatch(actions.fetchDealMemos()),
    onHideModal: () => dispatch(hideModal({ dialog: DEALMEMO_DIALOG })),
    onCheckCompatibility: ({ dealMemo, timecard }) =>
      dispatch(actions.checkCompatibility({ dealMemo, timecard })),
    onClearCompatibility: () =>
      dispatch(actions.storeCompatibility({ compatible: null })),
    onChangeDealMemo: ({ compatible }) => {
      dispatch(actions.changeDealMemo({ compatible }));
    },
  };
};

const dayOfWeek = integer => {
  return moment().day(integer).format('dddd');
};

const EmployeeDealMemoModal = props => {
  const {
    onFetchDealMemos,
    dealMemos,
    loading,
    onHideModal,
    onCheckCompatibility,
    checking,
    compatible,
    onClearCompatibility,
    onChangeDealMemo,
    dealMemo,
    tcStatus,
    timecardId,
    changingDeal,
  } = props;

  const { id: currentDealMemoId } = dealMemo || { id: null };
  const currentDealMemo = dealMemos.find(dm => dm.id === currentDealMemoId);

  const currentPayrollId = currentDealMemo?.payrollCompany?.id;

  useDidMount(() => {
    onFetchDealMemos();
  });

  useWillUnmount(() => {
    onClearCompatibility();
  });

  const ref = React.useRef();
  const [innerHeight, setInnerHeight] = React.useState(50);
  const parentHeight = ref.current?.parentElement.clientHeight;
  React.useEffect(() => {
    setInnerHeight(h => Math.max(h, parentHeight));
  }, [parentHeight]);

  //sorted deal memos
  const list = React.useMemo(() => {
    const deals = _.cloneDeep(dealMemos);
    deals.sort(empDealSort);
    return deals;
  }, [dealMemos]);

  return (
    <Box
      ref={ref}
      sx={{
        padding: '10px',
        height: innerHeight,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
      }}
    >
      {loading && <LinearProgress sx={{ height: '4px', width: '300px' }} />}
      {checking && (
        <Box
          sx={{
            position: 'absolute',
            height: '97%',
            width: '97%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ backgroundColor: 'white', borderRadius: '25px', p: 2 }}>
            <TitleBox>Checking Deal Compatibility</TitleBox>
            <ContentBox
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </ContentBox>
          </Box>
        </Box>
      )}
      {!loading && (!compatible || checking) && (
        <Box sx={{ flex: 8, height: innerHeight - 50, overflowY: 'auto' }}>
          <TitleBox>
            <Box />
            <Box sx={{ fontWeight: 600, fontSize: '20px' }}>{'Deals'}</Box>
            <Box>
              <ClearIcon onClick={onHideModal} style={{ cursor: 'pointer' }} />
            </Box>
          </TitleBox>
          <ContentBox>
            {list?.map((deal, index) => {
              const isCurrent = deal.id === currentDealMemoId;
              const payrollCompanyId = deal.payrollCompany?.id;
              const isDiffInvoice =
                currentPayrollId &&
                payrollCompanyId &&
                currentPayrollId !== payrollCompanyId &&
                !isCurrent;

              const isDisabled =
                (isDiffInvoice && timecardId !== NEW_TC_ID) || checking;

              return (
                <DealMemoCard
                  key={deal.id}
                  deal={deal}
                  isCurrent={isCurrent}
                  isDisabled={isDisabled}
                  isDiffInvoice={isDiffInvoice}
                  index={index}
                  onCheckCompatibility={onCheckCompatibility}
                  tcStatus={tcStatus}
                />
              );
            })}
            {dealMemos.length === 0 && <Box>No deals</Box>}
          </ContentBox>
        </Box>
      )}
      {compatible && !checking && (
        <Box
          sx={{
            flex: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1,
          }}
        >
          {_.isEmpty(compatible) === false && (
            <Box>
              {compatible.isCompatible ? (
                <Box>
                  {changingDeal ? (
                    <>
                      <TitleBox>Changing Deal</TitleBox>
                      <ContentBox
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <CircularProgress
                          sx={{
                            color: 'success.main',
                            height: '72px',
                            width: '72px',
                          }}
                        />
                      </ContentBox>
                    </>
                  ) : (
                    <>
                      {' '}
                      <TitleBox>Deal is compatible</TitleBox>
                      <ContentBox
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <CheckCircleOutlineIcon
                          sx={{
                            color: 'success.main',
                            height: '72px',
                            width: '72px',
                          }}
                        />
                      </ContentBox>
                    </>
                  )}
                </Box>
              ) : (
                <Box>
                  <TitleBox>Deal Memo Conflict</TitleBox>
                  <ContentBox>
                    <Box>
                      The new deal memo conflicts with some of your entered
                      values.
                    </Box>
                    <Box>Changing deal memos will clear these fields:</Box>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Day</TableCell>
                          <TableCell>Field</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {compatible.days.map((day, index) => {
                          if (
                            Object.keys(day).some(field => !day[field]) ===
                            false
                          ) {
                            return null;
                          }
                          let inCompatibleDisplay;

                          if (day.isDayValid === false) {
                            inCompatibleDisplay = (
                              <Box>
                                Entire day - deal memo not valid for day
                              </Box>
                            );
                          } else {
                            inCompatibleDisplay = Object.keys(day)
                              .filter(field => !day[field])
                              .map(field => (
                                <Box key={field}>
                                  {fieldMap[field] || field}
                                </Box>
                              ));
                          }

                          return (
                            // eslint-disable-next-line react/no-array-index-key
                            <TableRow key={index}>
                              <TableCell>{dayOfWeek(index)}</TableCell>
                              <TableCell>{inCompatibleDisplay}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </ContentBox>
                </Box>
              )}
            </Box>
          )}
          <FooterBox>
            <Button
              variant="outlined"
              onClick={onClearCompatibility}
              disabled={changingDeal}
            >
              Cancel
            </Button>
            <Button
              disabled={checking || changingDeal}
              onClick={() => {
                onChangeDealMemo({ compatible });
              }}
            >
              Change Deal
            </Button>
          </FooterBox>
        </Box>
      )}
    </Box>
  );
};

EmployeeDealMemoModal.propTypes = {
  dealMemos: PropTypes.array,
  loading: PropTypes.bool,
  onFetchDealMemos: PropTypes.func,
  onHideModal: PropTypes.func,
  onCheckCompatibility: PropTypes.func,
  checking: PropTypes.bool,
  compatible: PropTypes.object,
  onClearCompatibility: PropTypes.func,
  onChangeDealMemo: PropTypes.func,
  dealMemo: PropTypes.object,
  tcStatus: PropTypes.string,
  timecardId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([NEW_TC_ID]),
  ]),
  changingDeal: PropTypes.bool,
};

export default compose(
  withModalDialog({
    dialog: DEALMEMO_DIALOG,
    maxWidth: 'sm',
    roundedCorners: true,
    clickAwayClose: true,
  }),
  connect(mapState, mapDispatch),
)(EmployeeDealMemoModal);
