/* eslint-disable react/prop-types */
import React from 'react';
import _ from 'lodash';
import {
  Divider,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableRow,
  TableCell,
  LinearProgress,
  Table,
  TableBody,
  Paper,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NoteIcon from '@mui/icons-material/InsertComment';
import makeStyles from '@mui/styles/makeStyles';
//utils
import {
  formatDateTimezone,
  getAbbreviationFromBackendTimezoneName,
} from 'utils/formatDate';
import { isFieldEnabled } from 'utils/weekUtils';
//components
import { DATE_FORMAT_HOUR_MIN } from 'components/Shared/constants';
import { Badge } from 'components/Shared/Text';
import Note from './Note';
import TimecardHistory from './TimecardHistory';
import { renderApprovers } from './TimecardApprovers';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  root: {
    marginTop: 25,
    marginBottom: 75,
  },
  content: {
    padding: 20,
    overflow: 'auto',
  },
  noPadding: {
    padding: 0,
    '& > *': {
      width: '100%',
      marginBottom: 0,
      marginTop: 0,
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > *': {
      width: '100%',
      marginBottom: 0,
      marginTop: 0,
      paddingTop: 5,
      paddingBottom: 5,
      border: 0,
    },
  },
  mobileRow: {
    whiteSpace: 'nowrap',
    '& > *': {
      width: '100%',
      marginBottom: 0,
      marginTop: 0,
      paddingTop: 5,
      paddingBottom: 5,
      border: 0,
    },
  },
  icon: {
    verticalAlign: 'middle',
    paddingRight: 10,
  },
  blankSlate: {
    fontSize: '2.14285714em',
    background: palette.background.paper,
    color: palette.action.disabled,
    fontWeight: 700,
    fontStyle: 'italic',
    marginTop: 2,
    padding: 10,
    textAlign: 'center',
  },
  subHeading: {
    fontWeight: 500,
  },
  [breakpoints.down('lg')]: {
    content: { padding: 10 },
    tableCellAdjust: {
      padding: '5px 16px',
    },
  },
}));

function NotesAndHistory({
  isViewOnly,
  loading,
  onSaveNote,
  timecard,
  hideNotes,
  isNotesProcessing,
  localNotes,
  setNoteEmpty,
  projectSettings,
}) {
  const classes = useStyles();
  const { notes, history, editableFields, status, id } = timecard;

  const [expanded, setExpanded] = React.useState('notesPanel');
  const screenOrientation = _.get(window, 'screen.availWidth', 0);
  const handlePanelChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  let notesToShow;

  if (!Number(id)) {
    notesToShow = localNotes;
  } else {
    notesToShow = notes;
  }

  function renderNotes() {
    let noteRows = null;
    const rowStyle = screenOrientation > 1024 ? classes.row : classes.mobileRow;

    if (notesToShow && notesToShow.length <= 0) {
      noteRows = (
        <TableRow>
          <TableCell colSpan={7} className={classes.blankSlate}>
            {'No Notes'}
          </TableCell>
        </TableRow>
      );
    } else {
      if (!Number(id)) {
        noteRows = _.map(notesToShow, (note, index) => (
          <TableRow className={rowStyle} key={`${index}-note`}>
            <TableCell className={classes.tableCellAdjust}>
              <Typography color="inherit">{note.comment}</Typography>
            </TableCell>
            <TableCell className={classes.tableCellAdjust}>
              <Typography color="inherit">
                {/* from laue: this used to show note.referenceDate, but reference date is just a date, not a time.
                in order to display with timezone info */}
                {formatDateTimezone(
                  note.createdAt,
                  DATE_FORMAT_HOUR_MIN,
                  projectSettings.timeZone,
                )}
                <b>{` (${getAbbreviationFromBackendTimezoneName(
                  projectSettings.timeZone,
                )})`}</b>
              </Typography>
            </TableCell>
            <TableCell className={classes.tableCellAdjust}>
              {/* <Badge status='warning' uppercase>
                not saved
              </Badge> */}
            </TableCell>
          </TableRow>
        ));
      } else {
        noteRows = notesToShow.map(note => {
          return (
            <TableRow className={rowStyle} key={note.id}>
              <TableCell className={classes.tableCellAdjust}>
                <Typography color="inherit">{note.comment}</Typography>
              </TableCell>
              <TableCell className={classes.tableCellAdjust}>
                <Typography color="inherit">
                  {formatDateTimezone(
                    note.createdAt,
                    DATE_FORMAT_HOUR_MIN,
                    projectSettings.timeZone,
                  )}
                  <b>{` (${getAbbreviationFromBackendTimezoneName(
                    projectSettings.timeZone,
                  )})`}</b>
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCellAdjust}>
                <Badge status={'primary'} uppercase>
                  {/* Using this as a placeholder since the .NET comments don't have type which determines what the label was. */}
                  Comment
                </Badge>
              </TableCell>
            </TableRow>
          );
        });
      }
    }

    return (
      <div className={classes.content}>
        <Typography variant="subtitle1" className={classes.subHeading}>
          Timecard Notes
        </Typography>
        <Table>
          <TableBody>{noteRows}</TableBody>
        </Table>
      </div>
    );
  }

  return (
    <Paper square elevation={0} className={classes.root}>
      {!hideNotes && (
        <Accordion
          expanded={expanded === 'notesPanel'}
          onChange={handlePanelChange('notesPanel')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <NoteIcon color="primary" className={classes.icon} />
            <Typography color="primary">NOTES & HISTORY</Typography>
          </AccordionSummary>
          {isNotesProcessing && <LinearProgress />}
          <Divider />
          <AccordionDetails className={classes.noPadding}>
            <div className={classes.content}>
              {loading && <LinearProgress />}
              {isFieldEnabled(editableFields, 'general-notes') &&
                !isViewOnly && (
                  <Note
                    label='Enter Timecard Note. For Example: "I had an NDB every day this week"'
                    onSave={val => onSaveNote(val, 'timecard', id)}
                    style={{ padding: 10 }}
                    setNoteEmpty={setNoteEmpty}
                  />
                )}
              {renderNotes()}
              <TimecardHistory
                history={history}
                projectSettings={projectSettings}
              />
              {status !== 'sent_to_cnc' && renderApprovers(history)}
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </Paper>
  );
}

export default NotesAndHistory;
