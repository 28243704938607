import { original, produce } from 'immer';
import * as actions from 'actions/timecards';
import _ from 'lodash';

import { TIME_FIELD_ORDER } from 'utils/weekUtils';
import { empDealSort } from 'utils/helperFunctions';

const initialState = {
  loading: false,
  enableESignature: false,
  savingTimecard: false,
  reload: false,
  loadingTeamTimecard: false,
  processingNotes: false,
  processingAllowances: false,
  isCopyPrevious: false,
  loadingDetails: false,
  loadingInitialize: false,
  list: [],
  weekendings: [],
  dayTypes: [],
  episodes: [],
  workLocations: [],
  loadingTimecardDetails: {},
  details: {},
  notes: {},
  allowanceInput: {},
  tcAllowances: {
    'fresh-0': [],
  },
  allowancesHistory: {
    'fresh-0': [],
  },
  history: {},
  fromURI: '',
  currentTimecard: null,
  currentDetails: {},
  recentlyAddedComments: {},
  timecardAllowances: {},
  teamTimecard: {},
  localNotes: [],
  employeeAllowancePayCodes: [],
  employees: [],
  selectedEmployee: null,
  comment: {},
  groupRounding: [],
  rounding: [],
  copyFromPrevRoundFlag: false,
  weekendingStats: [],
  weekendingTimecards: [],
  updatingAutoAllowances: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.initTimecard}`: {
        draft.processingNotes = false;
        draft.processingAllowances = false;
        draft.fromURI = '';
        draft.enableESignature = false;
        draft.reload = false;
        break;
      }
      case `${actions.loading}`: {
        draft.loading = action.loading;
        break;
      }

      case `${actions.store}`: {
        draft.list = action.timecards;
        break;
      }

      case `${actions.storeWeekendings}`: {
        draft.weekendings = action.weekendings;
        break;
      }

      case `${actions.storeWeekendingStats}`: {
        draft.weekendingStats = action.weekendingStats;
        break;
      }

      case `${actions.storeWeekendingTimecards}`: {
        draft.weekendingTimecards = action.weekendingTimecards;
        break;
      }

      case `${actions.storeDetails}`: {
        draft.details[action.timecard.id] = _.cloneDeep(action.timecard);
        draft.loadingTimecardDetails[action.timecard.id] = false;
        break;
      }
      case `${actions.storeCurrentDetails}`: {
        draft.currentDetails = _.cloneDeep(action.timecard);
        draft.loadingTimecardDetails[action.timecard.id] = false;
        break;
      }

      case `${actions.loadingDetails}`: {
        draft.loadingDetails = action.loading;
        break;
      }

      case `${actions.clearTimecardDetails}`: {
        const timecardId = action.timecardId;

        if (
          timecardId &&
          draft.details[timecardId] &&
          draft.details[timecardId].days
        ) {
          TIME_FIELD_ORDER.forEach(field => {
            draft.details[timecardId].days.forEach(day => {
              if (day[field] || day[field] === 0) {
                day[field] = null;
              }
            });
          });
        }
        break;
      }

      case `${actions.loadingInitialize}`: {
        draft.loadingInitialize = action.loading;
        break;
      }

      case `${actions.storeDayTypes}`: {
        draft.dayTypes = action.dayTypes;
        break;
      }

      case `${actions.storeEpisodes}`: {
        draft.episodes = action.episodes;
        break;
      }

      case `${actions.storeWorkLocations}`: {
        draft.workLocations = action.workLocations;
        break;
      }

      case `${actions.storeTimecardNotes}`: {
        draft.notes[action.timecardId] = action.notes;
        break;
      }

      case `${actions.storeTimecardHistory}`: {
        draft.history[action.timecardId] = action.history;
        break;
      }

      case `${actions.setFromURL}`: {
        draft.fromURI = action.fromURI;
        localStorage.setItem('fromURI', action.fromURI);
        break;
      }

      case `${actions.setCurrentTimecard}`: {
        draft.currentTimecard = action.timecardId;
        break;
      }
      case `${actions.clearCurrentTimecard}`: {
        draft.currentTimecard = '';
        break;
      }
      case `${actions.addRecentComment}`: {
        const newObj = _.clone(draft.recentlyAddedComments);
        newObj[action.timecardEntryHeaderId] = true;
        draft.recentlyAddedComments = newObj;
        break;
      }

      case `${actions.enableESignature}`: {
        draft.enableESignature = action.enableESignature;
        break;
      }

      case `${actions.savingTimecard}`: {
        draft.savingTimecard = action.savingTimecard;
        break;
      }

      case `${actions.reloadTimecard}`: {
        draft.reload = action.reload;
        break;
      }

      //Timecard Allowances
      case `${actions.storeTCAllowances}`: {
        draft.tcAllowances[action.timecardId] = action.tcAllowances;
        draft.allowancesHistory[action.timecardId] = action.tcAllowances;
        break;
      }

      case `${actions.storeTimecardAllowances}`: {
        draft.timecardAllowances = action.timecardAllowances || {};
        break;
      }

      case `${actions.storeEmployeeAllowancePayCodes}`: {
        draft.employeeAllowancePayCodes =
          action.employeeAllowancePayCodes || {};
        break;
      }

      case `${actions.storeTeamTimecardUser}`: {
        const timecards = _.cloneDeep(action.selectedUsers || []);
        timecards.forEach(timecard => {
          if (!timecard.dealMemos) return;
          timecard.dealMemos.sort(empDealSort);
          if (timecard.user.dealMemos) {
            timecard.user.dealMemos.sort(empDealSort);
          }
        });

        draft.teamTimecard.users = timecards;
        break;
      }

      case `${actions.updateTeamTimecardUser}`: {
        {
          const users = action.users;
          const index = users.findIndex(user => user.key === action.key);
          users.splice(index, 1);
          draft.teamTimecard.users = users || {};
        }
        break;
      }

      case `${actions.loadingTeamTimecard}`: {
        draft.loadingTeamTimecard = action.loading;
        break;
      }

      case `${actions.storeTeamTimecard}`: {
        draft.teamTimecard['fresh-0'] = action.teamTimecard;
        break;
      }

      case `${actions.removeLocalEmployee}`: {
        {
          const teamTimecard = draft.teamTimecard['fresh-0'];
          const users = teamTimecard.users.filter(
            usr => usr.userId !== action.userId,
          );
          teamTimecard.users = users;
          draft.teamTimecard['fresh-0'] = teamTimecard;
        }
        break;
      }

      case `${actions.processingNotes}`: {
        draft.processingNotes = action.loading;
        break;
      }

      case `${actions.processingAllowances}`: {
        draft.processingAllowances = action.loading;
        break;
      }
      case `${actions.setFlagCopyPrevious}`: {
        draft.isCopyPrevious = action.flag;
        break;
      }
      case `${actions.storeLocalTCNote}`: {
        {
          const notes = [...draft.localNotes];
          notes.push(action.note);
          draft.localNotes = notes;
        }

        break;
      }
      case `${actions.removeLocalTCNote}`: {
        // draft.localNotes = action.loading;
        break;
      }
      case `${actions.storeLocalTCAllowance}`: {
        if (Number.isInteger(action.index)) {
          draft.tcAllowances['fresh-0'][action.index] = _.cloneDeep(
            action.allowance,
          );
          draft.allowancesHistory['fresh-0'][action.index] = _.cloneDeep(
            action.allowance,
          );
        } else {
          draft.tcAllowances['fresh-0'].push(_.cloneDeep(action.allowance));
          draft.allowancesHistory['fresh-0'].push(
            _.cloneDeep(action.allowance),
          );
        }
        break;
      }
      case `${actions.removeLocalTimecardAllowance}`: {
        const copy = _.cloneDeep(draft.tcAllowances['fresh-0']);
        copy.splice(action.index, 1);

        draft.tcAllowances['fresh-0'] = copy;
        draft.allowancesHistory['fresh-0'] = copy;
        break;
      }
      case `${actions.clearLocalTimecardAllowance}`: {
        draft.tcAllowances['fresh-0'] = [];
        draft.allowancesHistory['fresh-0'] = [];
        break;
      }
      case `${actions.clearLocalTCNotes}`: {
        draft.localNotes = [];
        break;
      }

      case `${actions.storeEmployees}`: {
        draft.employees = action.employees;
        break;
      }

      case `${actions.resetAllowanceInput}`: {
        if (Number.isInteger(action.index)) {
          draft.tcAllowances[action.timecardId][action.index] = original(
            draft.allowancesHistory[action.timecardId][action.index],
          );
        } else {
          draft.allowanceInput = {};
        }
        break;
      }

      case `${actions.selectAllowanceType}`: {
        const allowanceType = action.allowanceType;
        let allowance = {};

        allowance.allowanceType = _.cloneDeep(allowanceType);
        allowance.htgAllowanceTypeId =
          (allowanceType &&
            (allowanceType.htgAllowanceTypeId || allowanceType.value)) ||
          allowanceType.id;
        allowance.isDefaultAllowance = false;
        allowance.defaultAllowanceId = '';

        if (allowanceType.isDefaultAllowance) {
          allowance.amount = allowanceType.amount;
          allowance.token = allowanceType.token;
          allowance.filename = allowanceType.filename;
          allowance.isDefaultAllowance = true;
          allowance.defaultAllowanceId = allowanceType.id;
          allowance.s3Path = allowanceType.s3Path;
        }

        if (Number.isInteger(action.index)) {
          draft.tcAllowances[action.timecardId][action.index] = {
            ...original(draft.tcAllowances[action.timecardId][action.index]),
            ...allowance,
          };
        } else {
          draft.allowanceInput = {
            ...original(draft.allowanceInput),
            ...allowance,
          };
        }

        break;
      }
      case `${actions.selectAllowanceAmount}`: {
        // new allowance (in input fields) will have no index
        if (Number.isInteger(action.index)) {
          draft.tcAllowances[action.timecardId][action.index].amount =
            action.amount;
        } else {
          draft.allowanceInput.amount = action.amount;
        }
        break;
      }
      case `${actions.selectAllowanceRate}`: {
        // new allowance (in input fields) will have no index
        if (Number.isInteger(action.index)) {
          draft.tcAllowances[action.timecardId][action.index].rate =
            action.rate;
        } else {
          draft.allowanceInput.rate = action.rate;
        }
        break;
      }
      case `${actions.selectAllowanceUnits}`: {
        // new allowance (in input fields) will have no index
        if (Number.isInteger(action.index)) {
          draft.tcAllowances[action.timecardId][action.index].units =
            action.units;
        } else {
          draft.allowanceInput.units = action.units;
        }
        break;
      }
      case `${actions.selectAllowanceCombineCheckCode}`: {
        if (Number.isInteger(action.index)) {
          draft.tcAllowances[action.timecardId][action.index].combineCheckCode =
            action.combineCheckCode;
        } else {
          draft.allowanceInput.combineCheckCode = action.combineCheckCode;
        }
        break;
      }
      case `${actions.uploadAllowanceDocument}`: {
        let document = {};
        if (action.document === null) {
          document = {
            document: null,
            filename: null,
            token: null,
            s3Path: null,
          };
        } else {
          document = {
            document: action.document,
            filename: action.document.name,
          };
        }

        if (Number.isInteger(action.index)) {
          draft.tcAllowances[action.timecardId][action.index] = {
            ...original(draft.tcAllowances[action.timecardId][action.index]),
            ...document,
          };
        } else {
          draft.allowanceInput = {
            ...original(draft.allowanceInput),
            ...document,
          };
        }
        break;
      }
      case `${actions.setEditComment}`: {
        draft.comment = action.comment;
        break;
      }
      case `${actions.clearEditComment}`: {
        draft.comment = {};
        break;
      }
      case `${actions.storeGroupRounding}`: {
        draft.groupRounding = action.groupRounding;
        break;
      }
      case `${actions.setCopyFromPrevRoundFlag}`: {
        draft.copyFromPrevRoundFlag = action.copyFromPrevRoundFlag;
        break;
      }
      case `${actions.setUpdatingAutoAllowances}`: {
        draft.updatingAutoAllowances = action.updatingAutoAllowances;
        break;
      }
      case `${actions.reset}`: {
        if (Object.keys(draft).length !== Object.keys(initialState).length) {
          console.warn(
            'Length of draft and initialState are not equal\n',
            'draft',
            Object.keys(draft),
            '\ninitialState',
            Object.keys(initialState),
          );
        }
        for (const key in initialState) {
          if (Object.hasOwnProperty.call(initialState, key)) {
            draft[key] = initialState[key];
          }
        }
        break;
      }
      default:
    }
  });
