import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

// actions
import { fetch as fetchUserInfo } from 'actions/userInfo';
import { storeProject } from 'actions/routeParams';
import { fetch as fetchSettings } from 'actions/settings';
import { fetchDetails } from 'actions/project';

import { useDidMount } from 'utils/customHooks';

const mapDispatchToProps = dispatch => ({
  onFetchUserInfo: projectId => {
    dispatch(storeProject({ projectId }));
    dispatch(fetchUserInfo());
  },
  onFetchProject: currentPath => {
    dispatch(fetchDetails({ currentPath }));
  },
  onFetchSettings: () => dispatch(fetchSettings()),
});

// Sets projectId from URL and makes initial userInfo call
// Usually projectId is set in ProjectHeader

const BlankHeader = ({ onFetchUserInfo, onFetchProject, onFetchSettings }) => {
  const { projectId } = useParams();

  useDidMount(() => {
    onFetchUserInfo(projectId);
    onFetchProject();
    onFetchSettings();
  });

  return null;
};

export default connect(null, mapDispatchToProps)(BlankHeader);
