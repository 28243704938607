// Planning to use it with both admin as well as normal user.
import createAction from '../../actions/createAction';

export const loading = createAction('crewList/loading');
export const init = createAction('crewList/fetch/init');
export const storeUsers = createAction('crewList/store/crew');
export const fetchProjectUsers = createAction('crewList/fetch/project/crew');

export const saving = createAction('crewList/saving/crew');
export const inviting = createAction('crewList/inviting/crew');

export const fetch = createAction('crewList/fetch/crew');
export const editing = createAction('crewList/editing/crew');
export const editingUsers = createAction('crewList/editing/crews');
export const save = createAction('crewList/save/crew');

export const storeStatusCount = createAction(
  'crewList/store/crew/statusCounts',
);

export const sendInvites = createAction('crewList/send/usersInvites');
export const deleteInvites = createAction('crewList/delete/users');
export const resendInvites = createAction('crewList/resend/userInvites');

export const impersonate = createAction('crewList/impersonate');
export const removeImpersonation = createAction('crewList/removeImpersonation');

export const resetStatus = createAction('crewList/resetStatus');
export const selectStatus = createAction('crewList/onSelectStatus');
export const selectAllStatus = createAction('crewList/onSelectAllStatus');

export const storeDealMemoId = createAction('crewList/storeDealMemoId');
export const removeDealMemoId = createAction('crewList/removeDealMemoId');

export const storeDealMemoCount = createAction('crewList/storeDealMemoCount');
export const removeDealMemoCount = createAction('crewList/removeDealMemoCount');

export const storeSort = createAction('crewList/storeSort');

export const confirmEmailChange = createAction(
  'crewList/save/confirmEmailChange/user',
);
export const fetchSelectedUser = createAction('crewList/fetch/user');
export const storeEditingUser = createAction('crewList/store/user');
export const exportUsers = createAction('crewList/export/users');

export const clearUserList = createAction('crewList/clear/userList');
export const clearFilters = createAction('crewList/clear/Filters');

export const storeLastProject = createAction('crewList/store/lastProject');
export const saveHotCost = createAction('crewList/save/hotCosts');

export const storeEditHostCostFlag = createAction(
  'crewList/store/editHostCostFlag',
);

export const storeUpdatedHiddenColumns = createAction(
  'crewList/store/storeUpdatedHiddenColumns',
);
export const storeEditedFields = createAction(
  'crewList/store/EditedHotCostFields',
);
export const clearEditedFields = createAction(
  'crewList/clear/EditedHotCostFields',
);
export const confirmProjectAdminPermission = createAction(
  'crewList/confirmProjectAdminPermission',
);
