import React from 'react';

import { Header, FullWidthLayout, ProjectHeader } from 'containers/Layout';

//sub nav

import { CrewTimecardList as CrewTimecardSubNav } from 'containers/Nav/Pages';
import { CrewTimecardWeek } from 'containers/Nav/Pages';

//components
const CrewTimecardList = React.lazy(() =>
  import('containers/Employees/CrewTimecard/List'),
);
const CreateCrewTimecard = React.lazy(() =>
  import('containers/Employees/CrewTimecard/Create'),
);
const CrewTimecard = React.lazy(() =>
  import('containers/Employees/CrewTimecard/TeamTimecard'),
);

const projectCrewTimecardRoutes = [
  {
    title: 'Crew Timecard List',
    path: '/projects/:projectId/timecards',
    headers: [Header, ProjectHeader, CrewTimecardSubNav],
    layout: FullWidthLayout,
    component: CrewTimecardList,
  },
  {
    title: 'Create Crew Timecard',
    path: '/projects/:projectId/timecards/week-ending/:weekEndingdate/department/:departmentId',
    headers: [Header, ProjectHeader, CrewTimecardWeek],
    layout: FullWidthLayout,
    component: CreateCrewTimecard,
  },
  {
    title: 'Crew Timecard',
    path: '/projects/:projectId/timecards/week-ending/:weekEnding/department/:departmentId/team/:id',
    headers: [Header, ProjectHeader, CrewTimecardWeek],
    layout: FullWidthLayout,
    component: CrewTimecard,
  },
];

export default projectCrewTimecardRoutes;
