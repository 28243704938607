import React from 'react';

import { Header, ProjectHeader, FullWidthLayout } from 'containers/Layout';

import projectCrewTimecardRoutes from './projectCrewTimecardRoutes';
import projectLegacyTimecardRoutes from './projectLegacyTimecardRoutes';

//components
import TimecardHistoryView from 'containers/Employees/Timecards/TimecardHistoryView';
import Timecard from 'feature/EmployeeTimecard/Timecard';
const NavTo = React.lazy(() => import('containers/Employees/Timecards/NavTo'));

const projectTimecardRoutes = [
  {
    title: 'My Timecards',
    path: '/projects/:projectId/me/timecards',
    headers: [Header, ProjectHeader],
    layout: FullWidthLayout,
    component: TimecardHistoryView,
    waitForUserToLoad: true,
  },
  {
    title: 'Timecard',
    path: '/projects/:projectId/me/timecards/:timecardId',
    headers: [Header, ProjectHeader],
    layout: FullWidthLayout,
    component: Timecard,
    waitForUserToLoad: true,
  },
  {
    path: '/projects/:projectId/navto/timecard',
    headers: [Header, ProjectHeader],
    layout: FullWidthLayout,
    component: NavTo,
  },
  ...projectCrewTimecardRoutes,
  ...projectLegacyTimecardRoutes,
];

export default projectTimecardRoutes;
