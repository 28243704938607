import { DH, UPM, PA, PROJECT_ADMIN } from 'components/props/profiles';
import { hasReviewerRole } from 'selectors/session';

export const blockUrls = {
  nonReviewerBlock: [
    '/projects/:projectId/crew-list',
    '/projects/:projectId/timecards/week-ending/:weekEndingdate/department/:departmentId',
    '/projects/:projectId/timecards/week-ending/:weekEnding/department/:departmentId/team/:id', // crew timecard
    '/projects/:projectId/timecards', // crew timecard list
    '/projects/:projectId/reports/ondemand',
    '/projects/:projectId/reports/schedule',
    '/projects/:projectId/me/review/open', //pa
    '/projects/:projectId/me/review/complete', //pa
    '/projects/:projectId/me/review/batches/Pending', //  DH + UPM
    '/projects/:projectId/me/review/batches/History', //  DH + UPM
    '/projects/:projectId/me/review/batches/Ready', //    DH + UPM
    '/projects/:projectId/reviews/timecards/:timecardId', // DH view
    '/projects/:projectId/reviews/batches/:batchId/approval-flows/:approvalFlowId', // DH review
    '/projects/:projectId/review/:reviewType/:reviewBatchId/wtc',
    '/projects/:projectId/review/:reviewType/wtc', // multi-batch WTC
    '/projects/:projectId/popOutWTC/:reviewBatchId',
    '/projects/:projectId/review/bulk-edit',
    '/projects/:projectId/daily-timesheets',
    '/projects/:projectId/review/search-timecards',
    '/projects/:projectId/search-invoices',
  ],
  invoiceApproverUrls: [
    '/projects/:projectId/search-invoices',
    '/projects/:projectId/digital-edits/:invoiceId',
    '/projects/:projectId/invoice-comments/:invoiceId',
    '/projects/:projectId/digitalEdit/tokens/:token',
  ],
  dhBlockUrls: [
    '/projects/:projectId/review/:reviewType/:reviewBatchId/wtc',
    '/projects/:projectId/review/bulk-edit',
    '/projects/:projectId/popOutWTC/:reviewBatchId',
    '/projects/:projectId/me/review/open', //pa
    '/projects/:projectId/me/review/complete', //pa
    '/projects/:projectId/review/:reviewType/wtc', // multi-batch WTC
    '/projects/:projectId/digital-edits/:invoiceId',
    '/projects/:projectId/invoice-comments/:invoiceId',
    '/projects/:projectId/digitalEdit/tokens/:token',
  ],
  upmBlockUrls: [
    '/projects/:projectId/review/bulk-edit',
    '/projects/:projectId/me/review/open', //pa
    '/projects/:projectId/me/review/complete', //pa
    '/projects/:projectId/digital-edits/:invoiceId',
    '/projects/:projectId/invoice-comments/:invoiceId',
    '/projects/:projectId/digitalEdit/tokens/:token',
  ],
  paBlockUrls: [
    '/projects/:projectId/me/review/batches/Pending', //  DH + UPM
    '/projects/:projectId/me/review/batches/History', //  DH + UPM
    '/projects/:projectId/me/review/batches/Ready', //    DH + UPM
    '/projects/:projectId/digital-edits/:invoiceId',
    '/projects/:projectId/invoice-comments/:invoiceId',
    '/projects/:projectId/digitalEdit/tokens/:token',
  ],
  myTimecardUrls: [
    '/projects/:projectId/me/timecardsv1/create',
    '/projects/:projectId/me/timecardsv1/:timecardId',
    '/projects/:projectId/me/timecards/:timecardId',
  ],
  // temp landing page for non-employee not in the project
  // should have their own "you're not an employee on this project page" but this is prev behavior so will keep it for now
  projectAdminBlockUrls: ['/projects/:projectId/me/timecards'],
};

export const checkShouldRedirect = ({ path, userRole, isIA, hasWorksight }) => {
  const isReviewer = hasReviewerRole(userRole);

  let blockList = [];

  if (!hasWorksight) {
    blockList.push(...blockUrls.myTimecardUrls);
    if (userRole === PROJECT_ADMIN || isIA || isReviewer) {
      blockList.push(...blockUrls.projectAdminBlockUrls);
    }
  }

  if (!isReviewer) {
    blockList.push(...blockUrls.nonReviewerBlock);
    blockList.push(...blockUrls.invoiceApproverUrls); // removed for IA users below
  }

  if (isReviewer && userRole === DH) {
    blockList.push(...blockUrls.dhBlockUrls);
    blockList.push(...blockUrls.invoiceApproverUrls); // removed for IA users below
  }
  if (isReviewer && userRole === UPM) {
    blockList.push(...blockUrls.upmBlockUrls);
  }
  if (isReviewer && userRole === PA) {
    blockList.push(...blockUrls.paBlockUrls);
  }

  if (isIA) {
    blockList = blockList.filter(
      url => blockUrls.invoiceApproverUrls.includes(url) === false,
    );
  }

  return blockList.includes(path);
};
