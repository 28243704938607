import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isRendered as isModalOpen } from 'selectors/modalDialog';
import { Modal } from 'components/Shared/ModalDialog';
import * as actions from 'actions/modalDialog';

//options passed in on the 'withModalDialog' HOC
const WithModalDialog = ({
  dialog,
  maxWidth = 'sm',
  skipUnmount = false,
  noEscClose = false,
  clickAwayClose = false,
  roundedCorners = false,
  sx = {},
}) => {
  const mapStateToProps = state => ({
    isRendered: isModalOpen(state, dialog),
  });

  const mapDispatchToProps = dispatch => ({
    onModalInit: () => dispatch(actions.initialize({ dialog })),
    onModalShow: () => dispatch(actions.show({ dialog })),
    onModalHide: () => dispatch(actions.hide({ dialog })),
    onModalDestroy: () => dispatch(actions.remove({ dialog })),
  });

  return BaseComponent => {
    class ComponentWithModal extends Component {
      static displayName = `withModalDialog(${BaseComponent.displayName})`;

      static propTypes = {
        onModalInit: PropTypes.func.isRequired,
        onModalDestroy: PropTypes.func.isRequired,
        isRendered: PropTypes.bool.isRequired,
        showImmediately: PropTypes.bool,
        onModalHide: PropTypes.func.isRequired,
      };

      componentDidMount() {
        this.props.onModalInit(dialog);
      }

      componentWillUnmount() {
        if (!skipUnmount) this.props.onModalDestroy(dialog);
      }

      //Props passed in from the component
      render() {
        const {
          isRendered,
          onModalHide,
          customOnModalHide,
          showImmediately = false,
        } = this.props;

        const onRequestClose = customOnModalHide
          ? customOnModalHide
          : onModalHide;

        return (
          <Modal
            isRendered={showImmediately ? true : isRendered}
            onRequestClose={onRequestClose}
            maxWidth={maxWidth}
            noEscClose={noEscClose}
            clickAwayClose={clickAwayClose}
            roundedCorners={roundedCorners}
            sx={sx}
          >
            <BaseComponent {...this.props} />
          </Modal>
        );
      }
    }

    return connect(mapStateToProps, mapDispatchToProps)(ComponentWithModal);
  };
};

export default WithModalDialog;
