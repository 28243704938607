import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from 'decorators/withRouter';
import { compose } from 'utils/helperFunctions';
// components
import { PageHeader } from 'components/Nav';
// decorators
import withSearchFilter from 'decorators/withSearchFilter';

const mapState = (state, { match, history }) => ({
  location: history.location,
});

class CrewTimecardWeekPageHeader extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {};

  render() {
    return <PageHeader title="Crew Timecards" {...this.props} />;
  }
}

export default compose(
  withRouter(
    connect(
      mapState,
      null,
    )(withSearchFilter('crew-weekending-timecard')(CrewTimecardWeekPageHeader)),
  ),
);
