// Planning to use it with both admin as well as normal user.
import createAction from './createAction';

export const loading = createAction('loading/projects');
export const fetch = createAction('fetch/projects');
export const store = createAction('store/projects');
export const fetchDepartments = createAction('fetch/projects/departments');
export const storeDepartments = createAction('store/projects/departments');
export const fetchStats = createAction('fetch/projects/stats');
export const storeStats = createAction('store/projects/stats');
export const fetchDetails = createAction('fetch/projects/details');
export const storeDetails = createAction('store/projects/details');
export const toggleHide = createAction('toggle/projects/hide');
export const startProject = createAction('start/project');
export const validateStartProject = createAction('start/project/validate');
export const validatingStart = createAction('start/project/validating');
export const startProjectResult = createAction('start/project/result');
export const fetchProjectUsers = createAction('fetch/project/users');
export const storeProjectUsers = createAction('store/project/users');
export const fetchProjectDepartments = createAction('fetch/project/department');
export const storeProjectDepartments = createAction('store/project/department');
export const loadingProjectUsers = createAction('loading/project/users');
export const fetchSiblings = createAction('fetch/siblings');
export const storeSiblings = createAction('store/siblings');

export const setViewingYear = createAction('viewingYear/projects');
export const switchYear = createAction('switchYear/projects');
export const navigateTo = createAction('userRole/landingScreen');

export const storeProjectsCount = createAction('store/projects/count');
export const storeSearchText = createAction('store/projects/searchtext');
export const fetchPaginationProjects = createAction(
  'projects/pagination/filter',
);
export const fetchProjectSearch = createAction('projects/search/filter');
export const projectSorting = createAction('projects/search/sorting');

export const onSelect = createAction('projects/selectFilters');
export const storeFilterOptions = createAction('projects/storeFilterOptions');
export const fetchFilteredProjects = createAction('projects/fetchfilter');

export const clearProjectData = createAction('clear/project/data');

export const fetchProjectAdmin = createAction('fetch/project/admin');
export const storeProjectAdminData = createAction('store/project/admin');
export const updateProjectAdmin = createAction('update/project/admin');
