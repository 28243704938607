import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  TableBody,
  TableCell,
  TableRow,
  Radio,
  CircularProgress,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import {
  dmStudioScaleRatesFields,
  dmDistantScaleRatesFields,
} from 'components/Shared/constants/DMTableFields';

import { rightShadow } from 'utils/wtcWeekUtils';

const useStyles = makeStyles(theme => ({
  rowHeader: {
    position: 'sticky',
    left: -1,
    border: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    zIndex: 1,
  },
  scrolling: {
    ...rightShadow,
  },
  whiteRow: {
    backgroundColor: 'white',
  },

  blueRow: {
    backgroundColor: theme.palette.primary.table,
  },
  radio: {
    marginRight: 7,
    padding: 0,
  },
  selectedRow: {
    backgroundColor: theme.palette.primary['+8'],
  },
  tableRow: {
    minHeight: 28,
    whiteSpace: 'nowrap',
    '& > *': {
      padding: '4px 8px',
      border: `1px solid ${theme.palette.primary['+7']}`,
    },
  },
  disabled: {
    color: theme.palette.gray['+7'],
    backgroundColor: theme.palette.gray['+9'],
  },
  //Mobile only CSS
  [theme.breakpoints.down('lg')]: {
    rowHeader: {
      whiteSpace: 'normal',
    },
    tableCell: {
      fontSize: 11,
    },
  },
}));

//Default render function
const renderText = (item, dataLoc) => {
  const str = _.get(item, dataLoc);
  return str ? str.toString() : '';
};

const SelectTableRow = ({
  list,
  fields,
  selectedIndex,
  setSelectedIndex,
  isScrolling,
  readOnly,
  dmScaleRatesLoading,
}) => {
  const classes = useStyles();
  const renderCell = ({
    field,
    index,
    fields,
    listItem,
    itemIndex,
    isDistantScaleRateRow,
    isStudioScaleRateRow,
  }) => {
    let classList = clsx({
      [classes.rowHeader]: index === 0,
      [classes.scrolling]: isScrolling,
      [classes.selectedRow]: itemIndex === selectedIndex,
      [classes.blueRow]: itemIndex % 2 === 0,
      [classes.whiteRow]: itemIndex % 2 === 1,
      [classes.tableCell]: true,
      [classes.disabled]: listItem.isDisabled,
    });

    const renderFunc = field.renderFunc ? field.renderFunc : renderText;

    const scaleLoading =
      dmScaleRatesLoading &&
      ((dmStudioScaleRatesFields.includes(field.label) &&
        isStudioScaleRateRow) ||
        (dmDistantScaleRatesFields.includes(field.label) &&
          isDistantScaleRateRow));

    const content = (
      <div>
        {index === 0 && !readOnly && (
          <Radio
            className={classes.radio}
            checked={itemIndex === selectedIndex}
            autoFocus={itemIndex === selectedIndex}
            disabled={listItem.isDisabled}
          />
        )}
        {scaleLoading ? (
          <CircularProgress size={20} />
        ) : (
          renderFunc(listItem, field.dataLoc)
        )}
      </div>
    );

    return (
      <TableCell className={classList} key={index}>
        {content}
      </TableCell>
    );
  };

  const renderRow = (listItem, itemIndex) => {
    const dScaleField = fields.find(
      f => f.label === 'Scale' && f.dataLoc?.code === 'D',
    );
    const dScaleValue = dScaleField?.renderFunc(listItem, dScaleField.dataLoc);
    const isDistantScaleRateRow = dScaleValue === 'Y' || dScaleValue === 'C';

    const sScaleField = fields.find(
      f => f.label === 'Scale' && f.dataLoc?.code === 'S',
    );
    const sScaleValue = sScaleField?.renderFunc(listItem, sScaleField.dataLoc);
    const isStudioScaleRateRow = sScaleValue === 'Y' || sScaleValue === 'C';

    const row = fields.map((field, index, fields) =>
      renderCell({
        field,
        index,
        fields,
        listItem,
        itemIndex,
        isDistantScaleRateRow,
        isStudioScaleRateRow,
      }),
    );

    const isDisabled = listItem.isDisabled;

    return (
      <TableRow
        key={itemIndex}
        className={classes.tableRow}
        onClick={() => {
          if (!readOnly && !isDisabled) setSelectedIndex(itemIndex);
        }}
      >
        {row}
      </TableRow>
    );
  };

  const rows = list.map(renderRow);

  return <TableBody>{rows}</TableBody>;
};

SelectTableRow.propTypes = {
  list: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  setSelectedIndex: PropTypes.func.isRequired,
  isScrolling: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  dmScaleRatesLoading: PropTypes.bool.isRequired,
};

export default SelectTableRow;
