import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';

// @mui
import { Toolbar, Box, IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SegmentIcon from '@mui/icons-material/Segment';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PencilLine from 'components/images/PencilLine';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

//selector
import {
  getSessionUser,
  getPromptOnLeavePage,
  currentUser,
} from 'selectors/session';
import { getProjectDetails } from 'selectors/project';

//action
import { fetch as fetchUserInfo } from 'actions/userInfo';

//components
import Brand from 'components/Nav/Brand';
import ImpersonateAlert from 'containers/Layout/Headers/components/ImpersonateAlert';
import HeaderV2MobileDrawer from 'containers/Layout/Headers/components/HeaderV2MobileDrawer.js';
import HeaderBtnNav from 'containers/Layout/Headers/components/HeaderBtnNav';

//utils
import { useDidMount, useIsMobile } from 'utils/customHooks';
import { ADJUSTMENT_URL } from 'components/Shared/constants';
import { PA, UPM } from 'components/props/profiles';

const useStyles = makeStyles(({ palette }) => ({
  HeaderV2: {
    background: `linear-gradient(0deg, ${palette.misc.headerBottom} 0%, ${palette.misc.headerTop} 100%)`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconButton: {
    padding: '3px',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: palette.primary['+3'],
    },
    '& > .MuiSvgIcon-root': {
      color: palette.primary.contrastText,
    },
  },
  activeNavButton: {
    backgroundColor: palette.primary['+3'],
  },
}));

const mapState = state => ({
  project: getProjectDetails(state),
  projectsUser: getSessionUser(state),
  promptOnLeavePage: getPromptOnLeavePage(state),
  currentUser: currentUser(state),
});

const mapDispatch = dispatch => ({
  onFetchUser: () => {
    dispatch(fetchUserInfo());
  },
});

const HeaderV2 = props => {
  const classes = useStyles();
  const { project, onFetchUser, projectsUser, promptOnLeavePage, currentUser } =
    props;
  const { url } = useRouteMatch();

  const userRoles = projectsUser?.projectRoles || [];
  const showSubmitAdjustment =
    userRoles.includes(PA) || userRoles.includes(UPM);

  const isInvoiceApproverOnly = projectsUser?.isInvoiceApproverOnly;
  const isAdmin = projectsUser?.isAdmin;
  const projectsLink = isAdmin ? '/admin/projects' : '/projects';
  const inSettings =
    url === '/me/profile' ||
    url === '/me/settings' ||
    url === '/me/preferences';

  const isMobile = useIsMobile();
  const [open, setOpen] = React.useState(false);

  useDidMount(() => {
    onFetchUser();
  });

  const shouldPromptOnLeavePage = React.useCallback(
    e => {
      if (promptOnLeavePage) {
        // Cancel the event and show alert that the unsaved changes would be lost
        e.preventDefault();
        e.returnValue = '';
      }
    },
    [promptOnLeavePage],
  );

  React.useEffect(() => {
    window.addEventListener('beforeunload', shouldPromptOnLeavePage);
    return () => {
      window.removeEventListener('beforeunload', shouldPromptOnLeavePage);
    };
  }, [shouldPromptOnLeavePage]);

  return (
    <Toolbar className={classes.HeaderV2}>
      {isMobile ? (
        <IconButton onClick={() => setOpen(true)}>
          <SegmentIcon
            sx={{
              transform: 'rotate(180deg) scaleX(1.4) scaleY(-1.4)',
              color: 'primary.contrastText',
            }}
          />
        </IconButton>
      ) : (
        <HeaderBtnNav
          url={url}
          projectsUser={projectsUser}
          project={project}
          currentUser={currentUser}
        />
      )}
      <ImpersonateAlert />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {!isMobile && !isAdmin && (
          <Box sx={{ display: 'flex', gap: 1, mr: 2 }}>
            {showSubmitAdjustment && (
              <Tooltip title="Submit Adjustment">
                <IconButton
                  className={clsx(classes.iconButton)}
                  onClick={() => {
                    window.open(ADJUSTMENT_URL, '_blank');
                  }}
                >
                  <PencilLine color="white" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Settings">
              <Link to="/me/profile">
                <IconButton
                  className={clsx(classes.iconButton, {
                    [classes.activeNavButton]: inSettings,
                  })}
                >
                  <SettingsOutlinedIcon />
                </IconButton>
              </Link>
            </Tooltip>
            <Tooltip title="Resource Center">
              <span>
                <IconButton
                  id="MyCnC_Resource_Center"
                  className={classes.iconButton}
                >
                  <HelpOutlineIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        )}
        <Brand isInvoiceApproverOnly={isInvoiceApproverOnly} />
        {isMobile && (
          <HeaderV2MobileDrawer
            project={project}
            projectsLink={projectsLink}
            open={open}
            setOpen={setOpen}
            showSubmitAdjustment={showSubmitAdjustment}
          />
        )}
      </Box>
    </Toolbar>
  );
};

HeaderV2.propTypes = {
  project: PropTypes.object.isRequired,
  projectsUser: PropTypes.object.isRequired,
  onFetchUser: PropTypes.func.isRequired,
  promptOnLeavePage: PropTypes.bool.isRequired,
};

HeaderV2.displayName = 'HeaderV2';

export default compose(connect(mapState, mapDispatch))(HeaderV2);
