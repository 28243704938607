import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import clsx from 'clsx';
import { TableRow, TableCell } from '@mui/material';
import { useDispatch } from 'react-redux';
import { formValueSelector } from 'redux-form';

//components

import makeStyles from '@mui/styles/makeStyles';
import TextCell from './WTCGridCells/TextCell';
import CheckboxCell from './WTCGridCells/CheckboxCell';
import AutocompleteCell from './WTCGridCells/AutocompleteCell';
import { toggleTableFields } from 'actions/wtc';

//selectors
import { getCanEditWorkTimes, getIsDraft } from 'selectors/wtc';
//utils
import { preventBlanks, doesDayAllowTimes } from 'utils/weekUtils';
import TimeValidator from 'utils/TimeValidator';
import {
  handleChangeWorkLocationsDay,
  WTC_FORM_NAME,
  WORK_TIME_FIELDS,
} from 'utils/wtcWeekUtils';
import { onBlurNumber } from 'utils/helperFunctions';

const useStyles = makeStyles(({ palette }) => ({
  tableCell: {
    verticalAlign: 'middle',
    padding: '4px',
  },
  timeCell: {
    margin: 'auto',
    borderRadius: 3,
    width: 45,
    height: 30,
    backgroundColor: `${palette.primary.main}10`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    height: 60,
  },
  stickyCell: {
    zIndex: 1,
    position: 'sticky',
    backgroundColor: palette.background.default,
  },
  leftBorder: {
    borderLeft: `5px solid ${palette.background.default}`,
  },
}));

const selector = formValueSelector(WTC_FORM_NAME);

const mapState = state => ({
  canEditWorkTimes: getCanEditWorkTimes(state),
  details: selector(state, 'details'),
  isDraft: getIsDraft(state),
});

const mapDispatch = {};

function MasterRow(props) {
  const {
    allowancesCount,
    workSchedules,
    loadWorkSchedules,
    loadDayTypes,
    occupationCodes,
    loadOccupationCodes,
    dealMemos,
    workLocations,
    episodes,
    loadEpisodes,
    dayTypes,
    tableFields,
    change,
    blur,
    wtcDisabled,
    upmEnabled,
    details,
    settings,
    maskingFunc,
    accountCodeMask,
    onFetchAndSetScaleRate,
    //
    workingWorkState,
    masterRowData,
    countries,
    stateOptions,
    countyOptions,
    cityOptions,
    subdivisions,
    locationFuncs: {
      onFetchCountries,
      onFetchStates,
      onFetchCities,
      onFetchCounties,
      onFetchSubdivisions,
      onResetLoc,
    },
    caRegion,
    //mapped props:
    canEditWorkTimes,
    isDraft,
    project,
    headers,
  } = props;

  const validDays = details
    .map((d, i) => (d.unusedDay || !d.dealMemo ? null : i))
    .filter(d => d !== null);

  const classes = useStyles();

  const dispatch = useDispatch();

  const loadStatesOnOpen = useCallback(() => {
    const countryId = masterRowData?.workCountry?.id || '';
    onFetchStates({ countryId });
  }, [masterRowData?.workCountry?.id, onFetchStates]);

  const loadCountiesOnOpen = useCallback(() => {
    const stateId = workingWorkState?.id || '';
    if (stateId) onFetchCounties({ stateId });
  }, [onFetchCounties, workingWorkState?.id]);

  const loadCitiesOnOpen = useCallback(() => {
    const stateId = workingWorkState?.id || '';
    if (stateId) onFetchCities({ stateId });
  }, [onFetchCities, workingWorkState?.id]);

  const loadSubdivisions = useCallback(
    arg => {
      if (typeof arg === 'string') {
        onFetchSubdivisions({ search: arg });
      } else {
        //onOpen calls with an event obj
        onFetchSubdivisions();
      }
    },
    [onFetchSubdivisions],
  );

  function loadWithDealMemo(callback) {
    // parentValue need to be dbCode ID
    const dbCodeId = _.get(masterRowData, 'project.dbCode.id', '');
    const htgContractId = _.get(masterRowData, 'dealMemo.contract.id', '');
    const pensionUnionId = _.get(masterRowData, 'dealMemo.pensionUnion.id', '');

    return function (searchText) {
      const params = {
        search: searchText,
        dbCodeId,
        htgContractId,
        pensionUnionId,
      };
      callback(params);
    };
  }

  //
  // cascading change functions
  //
  const handleChangeWorkLocations = newValue => {
    validDays.forEach(i => {
      change(`details[${i}].locationType`, newValue);
      change('rate', '');
      handleChangeWorkLocationsDay(newValue, {
        detail: details[i],
        dealMemos,
        change,
        member: `details[${i}]`,
        onFetchAndSetScaleRate,
      });
    });

    for (let i = 0; i < allowancesCount; i++) {
      change(`allowances[${i}].locationType`, newValue);
    }
  };

  const handleChangeEpisode = newValue => {
    change('series', newValue?.series || '');
    change('location', newValue?.location || '');

    validDays.forEach(i => {
      change(`details[${i}].episode`, newValue);
      change(`details[${i}].series`, newValue?.series || '');
      change(`details[${i}].location`, newValue?.location || '');
    });
    for (let i = 0; i < allowancesCount; i++) {
      change(`allowances[${i}].episode`, newValue);
      change(`allowances[${i}].series`, newValue?.series || '');
      change(`allowances[${i}].location`, newValue?.location || '');
    }
  };

  function handleChangeCountry(e, newValue, prev, name) {
    change(`workState`, null);
    change(`workCounty`, null);
    change(`workCity`, null);

    validDays.forEach(i => {
      change(`details[${i}].workCountry`, newValue ? newValue : null);
      change(`details[${i}].workState`, null);
      change(`details[${i}].workCounty`, null);
      change(`details[${i}].workCity`, null);
    });

    for (let i = 0; i < allowancesCount; i++) {
      change(`allowances[${i}].workCountry`, newValue ? newValue : null);
      change(`allowances[${i}].workState`, null);
      change(`allowances[${i}].workCounty`, null);
      change(`allowances[${i}].workCity`, null);
    }
  }

  function handleChangeState(e, newValue, prev, name) {
    if (
      newValue?.specialOptions?.includes('C') &&
      !tableFields.workCounty.visible
    ) {
      dispatch(
        toggleTableFields({
          columnId: tableFields.workCounty.columnId,
          visible: true,
        }),
      );
    }

    change(`workCounty`, null);
    change(`workCity`, null);

    validDays.forEach(i => {
      change(`details[${i}].workState`, newValue ? newValue : null);
      change(`details[${i}].workCounty`, null);
      change(`details[${i}].workCity`, null);
    });

    for (let i = 0; i < allowancesCount; i++) {
      change(`allowances[${i}].workState`, newValue ? newValue : null);
      change(`allowances[${i}].workCounty`, null);
      change(`allowances[${i}].workCity`, null);
    }

    //clear workSubdivision if new state doesn't support it
    if (newValue?.specialOptions && !newValue.specialOptions.includes('U')) {
      change('workSubdivision', null);

      validDays.forEach(i => {
        change(`details[${i}].workSubdivision`, null);
      });

      for (let i = 0; i < allowancesCount; i++) {
        if (i < allowancesCount) {
          change(`allowances[${i}].workSubdivision`, null);
        }
      }
    }
  }

  // Generic onChange for autocomplete and checkbox fields

  function masterRowOnChange(e, newValue, prev, name) {
    switch (name) {
      //Cascading changes
      case 'locationType':
        handleChangeWorkLocations(e, newValue, prev, name);
        return;
      case 'episode':
        handleChangeEpisode(e, newValue, prev, name);
        return;
      case 'workCountry':
        handleChangeCountry(e, newValue, prev, name);
        return;
      case 'workState':
        handleChangeState(e, newValue, prev, name);
        return;

      default:
        break;
    }

    //TODO - does this need more checks?
    let dayToChange = validDays;
    if (name === 'dayType' && canEditWorkTimes) {
      dayToChange = [0, 1, 2, 3, 4, 5, 6];
    }

    dayToChange.forEach(i => {
      const hasDeal = !!details[i]?.dealMemo;
      if (hasDeal) change(`details[${i}].${name}`, newValue);
    });

    const allowanceFields = [
      'combineCheck',
      'occupationCode',
      'schedule',
      'workCounty',
      'workCity',
      'workSubdivision',
    ];
    if (allowanceFields.includes(name)) {
      for (let i = 0; i < allowancesCount; i++) {
        change(`allowances[${i}].${name}`, newValue);
      }
    }
  }

  /** User by time and rate field blur funcs */
  function textFieldOnChange(e, newValue, prev, name) {
    switch (name) {
      case 'accountCode':
        newValue = maskingFunc(newValue);
        break;
      case 'location':
      case 'insurance':
      case 'series':
      case 'set':
      case 'freeField1':
      case 'freeField2':
      case 'freeField3':
      case 'freeField4':
        newValue = preventBlanks(newValue);
        break;
      default:
        break;
    }

    validDays.forEach(i => {
      if (WORK_TIME_FIELDS.includes(name)) {
        const dayType = details[i]?.dayType;
        const allowsTimes = doesDayAllowTimes(dayType?.code);

        if (!allowsTimes) return;
      }

      change(`details[${i}].${name}`, newValue);
    });

    //fields in grid and allowances
    const allowanceFields = [
      'location',
      'accountCode',
      'insurance',
      'series',
      'set',
      'freeField1',
      'freeField2',
      'freeField3',
      'freeField4',
    ];

    if (allowanceFields.indexOf(name) >= 0) {
      for (let i = 0; i < allowancesCount; i++) {
        change(`allowances[${i}].${name}`, newValue);
      }
    }
  }

  const timeFieldBlur = (e, newValue, prev, name) => {
    e.preventDefault();

    const rounded = applyRounding(newValue);

    // skip blur when tabbing and not changing value
    // initial value could be null and rounded will be undefined
    if (rounded === prev || (!rounded && !rounded === !prev)) {
      return;
    }

    blur(name, rounded);
    textFieldOnChange(e, rounded, prev, name);
  };

  const rateFieldBlur = (e, newValue, prev, name) => {
    e.preventDefault();
    const newVal = onBlurNumber(newValue, 4, 0);

    // skip blur when tabbing and not changing value
    // initial value could be null and newVal will be emptyString
    if (newVal === prev || (!newVal && !newVal === !prev)) {
      return;
    }

    blur(name, newVal);
    textFieldOnChange(e, newVal, prev, `rate`);
  };

  /**
   *
   * @param {*} value
   * @returns - undefined for invalid values
   */
  const applyRounding = value => {
    const tv = new TimeValidator(masterRowData?.dealMemo?.roundTo || 0.1);
    return tv.parse(value);
  };

  const dropdownProps = {
    workLocations,

    dayTypes,
    loadDayTypes,

    episodes,
    loadEpisodes,
    //locations
    onResetLoc,

    countries,
    onFetchCountries,

    stateOptions,
    loadStatesOnOpen,

    countyOptions,
    loadCountiesOnOpen,

    cityOptions,
    loadCitiesOnOpen,

    subdivisions,
    loadSubdivisions,

    //meta-codes

    occupationCodes,
    loadOccWithDeal: loadWithDealMemo(loadOccupationCodes),

    workSchedules,
    loadWorkSchedules,

    onChange: masterRowOnChange,
  };

  const renderTableCell = field => {
    const cellProps = {
      isMasterRow: true,
      field,
      wtcDisabled,
      caRegion,
    };

    switch (field.type) {
      case 'text':
        if (field.columnId === 'hoursWorked') {
          return (
            <TableCell key={field?.columnId} className={classes.tableCell}>
              <div className={classes.timeCell}></div>
            </TableCell>
          );
        }
        return (
          <TextCell
            key={field?.columnId}
            {...cellProps}
            onChange={textFieldOnChange}
            timeFieldBlur={timeFieldBlur}
            scaleLoading={{}}
            rateFieldBlur={rateFieldBlur}
            accountCodeMask={accountCodeMask}
            maskingFunc={maskingFunc}
            upmEnabled={upmEnabled}
            canEditWorkTimes={canEditWorkTimes}
            wtcEditable={settings?.wtcEditable}
            isDraft={isDraft}
            project={project}
          />
        );
      case 'checkbox':
        return (
          <CheckboxCell
            key={field?.columnId}
            {...cellProps}
            onChange={masterRowOnChange}
          />
        );
      case 'auto-complete':
        if (field.columnId === 'dealMemo') {
          return <TableCell key={field?.columnId} />;
        }
        return (
          <AutocompleteCell
            key={field?.columnId}
            {...cellProps}
            {...dropdownProps}
            upmEnabled={upmEnabled}
          />
        );
      case 'read-only':
        return (
          <TableCell
            className={clsx(classes.tableCell, {
              [classes.leftBorder]: field.columnId === '1x',
            })}
            key={`paidHourMaster-${field.columnId}`}
          >
            <div className={classes.timeCell} />
          </TableCell>
        );
      case 'empty':
      default:
        //placeholder - shouldn't be hit
        // console.warn('Unexpected field:', field?.columnId);
        return <TableCell key={field?.columnId} />;
    }
  };

  const displayFields = headers.filter(header => header.visible);
  return (
    <TableRow className={`${classes.row} masterRow`} key={`MR-1`}>
      {canEditWorkTimes && (
        <TableCell className={classes.stickyCell} sx={{ left: 0 }} />
      )}
      {displayFields.map(renderTableCell)}
    </TableRow>
  );
}

MasterRow.propTypes = {
  allowancesCount: PropTypes.number.isRequired,
  workSchedules: PropTypes.array.isRequired,
  loadWorkSchedules: PropTypes.func.isRequired,
  loadDayTypes: PropTypes.func.isRequired,
  occupationCodes: PropTypes.array.isRequired,
  loadOccupationCodes: PropTypes.func.isRequired,
  dealMemos: PropTypes.array.isRequired,
  workLocations: PropTypes.array.isRequired,
  episodes: PropTypes.array.isRequired,
  loadEpisodes: PropTypes.func.isRequired,
  dayTypes: PropTypes.array.isRequired,
  tableFields: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  blur: PropTypes.func.isRequired,
  wtcDisabled: PropTypes.bool.isRequired,
  upmEnabled: PropTypes.bool.isRequired,
  details: PropTypes.array.isRequired,
  settings: PropTypes.object.isRequired,
  maskingFunc: PropTypes.func.isRequired,
  accountCodeMask: PropTypes.string.isRequired,
  onFetchAndSetScaleRate: PropTypes.func.isRequired,
  workingWorkState: PropTypes.object.isRequired,
  masterRowData: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  stateOptions: PropTypes.array.isRequired,
  countyOptions: PropTypes.array.isRequired,
  cityOptions: PropTypes.array.isRequired,
  subdivisions: PropTypes.array.isRequired,
  locationFuncs: PropTypes.object.isRequired,
  caRegion: PropTypes.bool.isRequired,
  canEditWorkTimes: PropTypes.bool.isRequired,
  isDraft: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
};

export default connect(mapState, mapDispatch)(MasterRow);
