/* eslint-disable react/prop-types */
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import BackIcon from '@mui/icons-material/KeyboardBackspace';
import {
  Toolbar,
  AppBar,
  Button,
  Grid,
  LinearProgress,
  Hidden,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
  },

  buttons: { margin: 8 },
  iconButton: {
    marginRight: 10,
    backgroundColor: palette.background.default,
    borderRadius: 12,
  },
  actionsRight: {
    textAlign: 'right',
    [breakpoints.down('md')]: {
      '& button': {
        padding: '6px 8px',
        marginRight: 8,
      },
    },
  },
  deletePosition: {
    textAlign: 'right',
    [breakpoints.down('md')]: {
      textAlign: 'left',
      padding: 0,
    },
  },
  gutters: {
    paddingLeft: 8,
    paddingRight: 8,
  },
}));

export default function BottomAppBar({
  buttons,
  canDeleteTimecard = false,
  loading,
  onDeleteTimecard,
  onTakeMeBack,
  submitting,
  canBackTimecard = true,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const deleteText = mobile ? 'Delete' : 'Delete Timecard';

  return (
    <AppBar position="fixed" color="default" className={classes.appBar}>
      {submitting && <LinearProgress />}
      <Toolbar
        classes={{
          gutters: classes.gutters,
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Hidden smDown>
            {canBackTimecard && (
              <Grid item sm={4} md={3}>
                <Button
                  variant="text"
                  color="primary"
                  className={classes.buttons}
                  onClick={onTakeMeBack}
                  disabled={loading}
                >
                  <BackIcon className={classes.iconButton} />
                  {'Back'}
                </Button>
              </Grid>
            )}
          </Hidden>

          {canDeleteTimecard && (
            <Grid
              item
              xs={3}
              sm={2}
              md={canBackTimecard ? 3 : 6}
              className={classes.deletePosition}
            >
              <Button
                variant="text"
                color="primary"
                disabled={loading}
                onClick={onDeleteTimecard}
              >
                <DeleteIcon className={classes.iconButton} />
                {deleteText}
              </Button>
            </Grid>
          )}
          <Grid
            item
            xs={canDeleteTimecard ? 9 : 12}
            sm={6}
            className={classes.actionsRight}
          >
            {buttons}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
