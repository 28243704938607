import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { connect } from 'react-redux';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';
// actions
import * as actions from 'actions/wtc';
import { hide as hideModal, show as showModal } from 'actions/modalDialog';
//selectors
import { getProspectiveTimecard } from 'selectors/wtc';
import { reset } from 'redux-form';
import { WTC_FORM_NAME } from 'utils/wtcWeekUtils';

const mapState = state => ({
  prospectiveTimecard: getProspectiveTimecard(state),
});

const mapDispatch = dispatch => ({
  onLeaveTimecard: prospectiveTimecard => {
    dispatch(reset(WTC_FORM_NAME));
    dispatch(hideModal({ dialog: 'TakeMeBack' }));

    if (prospectiveTimecard.actionType === 'loadTimecard') {
      const timecardEntryHeaderId = prospectiveTimecard.timecardEntryHeaderId;
      dispatch(actions.loadTimecard({ timecardEntryHeaderId }));
    } else if (prospectiveTimecard.actionType === 'navToBatchWTC') {
      dispatch(actions.navToBatchWTC(prospectiveTimecard));
    } else if (prospectiveTimecard.actionType === 'closeWindow') {
      window.close();
    } else if (prospectiveTimecard.actionType === 'moveTimecard') {
      dispatch(
        showModal({
          dialog: 'moveTimecardsToBatch',
          modalParams: { sourceBatch: prospectiveTimecard.batch },
        }),
      );
    } else {
      dispatch(actions.navBackFromWTC());
    }
  },
  onModalHide: () => {
    dispatch(hideModal({ dialog: 'TakeMeBack' }));
  },
});

const TakeMeBack = props => {
  const { prospectiveTimecard, onLeaveTimecard, onModalHide } = props;

  const title = <div>{'Unsaved Changes'}</div>;

  const content = (
    <div>
      <Typography color="inherit">
        {'Your new changes will be lost.'} <br />
        {'Are you sure you want to leave this page?'}
      </Typography>
    </div>
  );

  return (
    <ConfirmModal
      title={title}
      content={content}
      buttonText="Leave this Page"
      buttonType="default"
      cancelText="Stay on this Page"
      hasSubmitAction={true}
      onModalHide={onModalHide}
      onSubmit={() => onLeaveTimecard(prospectiveTimecard)}
    />
  );
};

TakeMeBack.propTypes = {
  prospectiveTimecard: PropTypes.object.isRequired,
  onLeaveTimecard: PropTypes.func.isRequired,
  onModalHide: PropTypes.func.isRequired,
};

export default withModalDialog({
  dialog: 'TakeMeBack',
})(connect(mapState, mapDispatch)(TakeMeBack));
