import React, { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Popover, Divider, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import withWTCMenuFilter from './withWTCMenuFilter';
import FilterDropdown from 'components/Shared/ReactTable/FilterDropdown';

const useStyles = makeStyles(theme => ({
  FiltersComp: {},
  filterList: {
    width: 250,
    maxHeight: '65vh',
  },
  filterSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filterTitle: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 700,
    padding: 10,
    lineHeight: '18px',
    letterSpacing: '1.15px',
  },
  divider: {
    margin: '0px 10px',
    height: 2,
    backgroundColor: theme.palette.primary.main,
  },
}));

const FilterComp = ({ anchorElRef, isOpen, setIsOpen }) => {
  const classes = useStyles();
  const DepartmentFilter = withWTCMenuFilter({
    filterName: 'department',
  })(FilterDropdown);

  const StatusFilter = withWTCMenuFilter({
    filterName: 'status',
  })(FilterDropdown);

  const [expandAll, setExpandAll] = useState(undefined);
  const checkExpand = filterName => {
    if (expandAll === undefined) {
      if (filterName === 'status') return true;
      return false;
    } else {
      return expandAll;
    }
  };
  const toggleExpandAll = () => {
    setExpandAll(val => (val === undefined ? true : !val));
  };

  useEffect(() => {
    if (isOpen === false) {
      setExpandAll(undefined);
    }
  }, [isOpen]);

  return (
    <Popover
      anchorEl={anchorElRef.current}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className={classes.FiltersComp}
    >
      <div className={classes.filterList}>
        <section className={classes.filterSection}>
          <span className={classes.filterTitle}>Filter by</span>
          <Button onClick={toggleExpandAll}>
            {expandAll === true ? 'Collapse All' : 'Expand All'}
          </Button>
        </section>
        <Divider className={classes.divider} />

        <DepartmentFilter
          canSelectAll
          enableSearch
          label={`Department`}
          expand={checkExpand('department')}
        />
        <StatusFilter
          canSelectAll
          enableSearch
          label={`Status`}
          expand={checkExpand('status')}
        />
      </div>
    </Popover>
  );
};

FilterComp.propTypes = {
  anchorElRef: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default memo(FilterComp, (prev, next) => prev.isOpen === next.isOpen);
