import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

import Cell from './Cell';

const useStyles = makeStyles(({ palette }) => ({
  Row: {
    zIndex: 0,
    height: 30,
    borderBottom: `1px solid ${palette.primary['+7']}`,
    '&:hover': {
      '& > td': {
        //need to apply to cell so sticky Bg works
        backgroundColor: palette.gray['+8'],
      },
    },
  },
  odd: {
    '& > td': {
      //need to apply to cell so sticky Bg works
      backgroundColor: palette.gray['+9'],
    },
  },
  even: {
    '& > td': {
      //need to apply to cell so sticky Bg works
      backgroundColor: palette.primary['+9'],
    },
  },
}));

const Row = props => {
  const classes = useStyles();

  const {
    prepareRow,
    row,
    rowIndex,
    // onClickTimecard,
  } = props;

  const [
    rowClicked,
    // setRowClicked
  ] = useState(false);

  prepareRow(row);
  const { key, rowProps } = row.getRowProps();
  return (
    <tr
      key={key}
      {...rowProps}
      className={clsx(classes.Row, {
        [classes.odd]: rowIndex % 2 === 0 && !rowClicked,
        [classes.even]: rowIndex % 2 === 1 && !rowClicked,
        [classes.selected]: rowClicked,
      })}
      // onClick={e => {
      //   setRowClicked(true);
      //   setTimeout(() => {
      //     //setTimeout lets the selected be visible
      //     onClickTimecard({ timecard: row.original });
      //   }, 0);
      // }}
    >
      {row.cells.map(cell => (
        <Cell cell={cell} key={cell.column.id} />
      ))}
    </tr>
  );
};

Row.propTypes = {
  prepareRow: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
};

export default memo(Row, (prevProps, nextProps) => {
  try {
    const prevVal =
      JSON.stringify(prevProps.row.values) +
      JSON.stringify(prevProps.hiddenColCount) +
      `${prevProps.rowIndex}`;

    const nextVal =
      JSON.stringify(nextProps.row.values) +
      JSON.stringify(nextProps.hiddenColCount) +
      `${nextProps.rowIndex}`;

    return prevVal === nextVal;
  } catch (error) {
    return false;
  }
});

// export default Row;
