import axios from 'axios';

import settings from 'constants/config/apiServer';
import OktaClient from 'providers/oktaClient';
import configureAdmin from './admin';
import configureBulkEdit from './bulkEdit';
import configureDepartments from './departments';
import configureEmployees from './employees';
import configureFlags from './flags';
import configureDigitalEdits from './digitalEdits';
import configureLocations from './locations';
import configureProjects from './projects';
import configureSession from './session';
import configureSearchTimecards from './searchTimecards';
import configureSearchInvoices from './searchInvoices';
import configureReviewFlows from './reviewFlows';
import configureTimecards from './timecards';
import configureProfileDetails from './profileDetails';
import configureReports from './reports';
import configureDownloader from './downloader';
import configureReviews from './reviews';
import configureInvitations from './invitations';
import configureUsers from './users';
import configureWTC from './wtc';
import configureDts from './dts';
import getNotificationsApi from './notifications';
import configureTemplates from './invoiceTemplate';
import configureCrewList from './crewList';
import configureMoveTimecards from './moveTimecards';
import configureEmployeeTimecardApi from 'feature/EmployeeTimecard/api';

import './axiosdebug';

const TIMEOUT = 120000;

const PARAM_VALIDATION = {
  projectId: /\d+/,
  timecardId: /\d+/,
};

const validateParam = (url, name, params) => {
  if (PARAM_VALIDATION[name]) {
    const regex = PARAM_VALIDATION[name];
    if (regex.test(params[name]) === false) {
      console.error(`Invalid API parameter: ${url} ${name} ${params[name]}`);
      throw new Error(`Invalid API parameter: ${url} ${name} ${params[name]}`);
    }
  }
};

export const buildUrl = (url, params) => {
  let parsedUrl = url;
  const names = Object.keys(params);

  names.forEach(name => {
    const search = new RegExp(`:${name}`, 'g');
    validateParam(url, name, params);
    parsedUrl = parsedUrl.replace(search, params[name]);
  });

  return parsedUrl;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  let getAuthToken = OktaClient.accessToken;
  const clientV2 = axios.create({
    timeout: TIMEOUT,
    baseURL: process.env.HOURSPLUS_API_URL || settings.apiUrl,
    withCredentials: true,
  });

  const onRequestClient = async config => {
    if (config) {
      const token = await getAuthToken();
      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = 'bearer ' + token;
      }
    }

    return config;
  };

  function errorResponseHandler(error) {
    // check for errorHandle config
    if (
      error.config?.hasOwnProperty('errorHandle') &&
      error.config.errorHandle === false
    ) {
      //TODO - check if this is needed anymore. Think its left over from Ruby BE.
      return Promise.reject(error);
    }
    // if has response show the error
    if (error.response) {
      return Promise.reject(error.response);
    }
    if (error.message === 'canceled') {
      return Promise.reject(error);
    }
  }

  clientV2.interceptors.response.use(
    response => response,
    errorResponseHandler,
  );

  clientV2.interceptors.request.use(onRequestClient);

  const admin = configureAdmin({ clientV2 });
  const bulkEdit = configureBulkEdit({ clientV2 });

  const departments = configureDepartments({ clientV2 });
  const employees = configureEmployees({ clientV2 });
  const flags = configureFlags({ clientV2 });
  const locations = configureLocations({ clientV2 });
  const projects = configureProjects({ clientV2 });
  const reviewFlows = configureReviewFlows({ clientV2 });
  const session = configureSession({ clientV2 });
  const searchTimecards = configureSearchTimecards({ clientV2 });
  const searchInvoices = configureSearchInvoices({ clientV2 });
  const timecards = configureTimecards({ clientV2 });
  const profileDetails = configureProfileDetails({ clientV2 });
  const reports = configureReports({ clientV2 });
  const downloader = configureDownloader({ clientV2 });
  const reviews = configureReviews({ clientV2 });
  const invitations = configureInvitations({ clientV2 });
  const users = configureUsers({ clientV2 });
  const wtc = configureWTC({ clientV2 });
  const dts = configureDts({ clientV2 });
  const notifications = getNotificationsApi({
    endpoint:
      settings.notifications_api.baseUrl + settings.notifications_api.timecard,
    tokenProvider: session.getAuthTicket,
  });
  const invoiceTemplate = configureTemplates({ clientV2 });
  const crewList = configureCrewList({ clientV2 });
  const moveTimecards = configureMoveTimecards({ clientV2 });
  const digitalEdits = configureDigitalEdits({ clientV2 });
  const employeeTimecard = configureEmployeeTimecardApi({ clientV2 });

  return {
    setAuthTokenProvider(provider) {
      getAuthToken = provider;
    },
    admin,
    bulkEdit,
    departments,
    employees,
    flags,
    locations,
    projects,
    reviewFlows,
    session,
    searchInvoices,
    searchTimecards,
    timecards,
    profileDetails,
    reports,
    downloader,
    reviews,
    invitations,
    users,
    wtc,
    dts,
    notifications,
    invoiceTemplate,
    crewList,
    moveTimecards,
    digitalEdits,
    employeeTimecard,
  };
};
