import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'utils/helperFunctions';
import { Router, Switch, Route } from 'react-router-dom';

import withAuthProtection from 'decorators/withAuthProtection';
// import Routes from './Routes';
import AppReady from 'containers/RoutingSupport/AppReady';
import RedirectCheck from 'containers/RoutingSupport/RedirectCheck';
import QuickRedirect from 'containers/RoutingSupport/QuickRedirect';
import MyCnCHeaderWrapper from 'containers/Layout/Headers/MyCnCHeaderWrapper';

import routes from 'components/Routes/routes';

const mappedRoutes = routes.map(r => {
  if (r.redirect) {
    return (
      <Route
        exact
        path={r.path}
        key={r.path}
        render={() => <QuickRedirect path={r.path} redirect={r.redirect} />}
      />
    );
  }
  return (
    <Route
      exact
      path={r.path}
      key={r.path}
      render={() => (
        <RedirectCheck
          path={r.path}
          headers={r.headers}
          layout={r.layout}
          component={r.component}
          title={r.title}
          noCnCHeader={r.noCnCHeader}
          waitForUserToLoad={r.waitForUserToLoad}
        />
      )}
    />
  );
});

const App = ({ history }) => (
  <AppReady>
    <MyCnCHeaderWrapper />
    <Router history={history}>
      <Switch>{mappedRoutes}</Switch>
    </Router>
  </AppReady>
);

App.propTypes = {
  history: PropTypes.object.isRequired,
};

export default compose(withAuthProtection())(App);
