import debug from 'debug';
import _ from 'lodash';
import TimeValidator from 'utils/TimeValidator';
import { doesDayRequireTimes } from 'utils/weekUtils';
import moment from 'moment';
import { getEmpDealMemoFields } from 'components/Shared/constants/DMTableFields';

export const db = debug('empTimecard');

// constants
// Update ProjectAdminPreview when this is updated
export const COLUMNS = [
  {
    label: 'Date',
    accessor: 'date',
    type: 'special',
    width: 50,
    editable: false,
    display: 'always',
  },
  {
    label: 'Work Zone',
    accessor: 'locationType',
    width: 115,
    type: 'autocomplete',
    editable: true,
    display: 'hide',
  },
  {
    label: 'Day Type',
    accessor: 'dayType',
    width: 115,
    type: 'autocomplete',
    editable: true,
    display: 'always',
  },
  {
    label: 'Start Travel',
    accessor: 'startTravel',
    width: 85,
    type: 'time',
    editable: true,
    display: 'hide',
  },
  {
    label: 'Call',
    accessor: 'call',
    width: 85,
    type: 'time',
    editable: true,
    display: 'always',
  },
  {
    label: 'NDB In',
    accessor: 'ndbIn',
    width: 85,
    type: 'time',
    editable: true,
    display: 'hide',
  },

  {
    label: 'Meal 1 Out',
    accessor: 'meal1Out',
    width: 85,
    type: 'time',
    editable: true,
    flexField: true,
    pairName: 'meal1In',
    pairLabel: 'Meal 1',
  },
  {
    label: 'Meal 1 In',
    accessor: 'meal1In',
    width: 85,
    type: 'time',
    editable: true,
    flexField: true,
    secondPair: true,
  },
  {
    label: 'Last Man In',
    accessor: 'lastMan1In',
    width: 85,
    type: 'time',
    editable: true,
    flexField: false,
    display: 'hide',
  },
  {
    label: 'NDM Out',
    accessor: 'ndmOut',
    width: 85,
    type: 'time',
    editable: true,
    flexField: true,
    pairName: 'ndmIn',
    pairLabel: 'NDM',
    display: 'hide',
  },
  {
    label: 'NDM In',
    accessor: 'ndmIn',
    width: 85,
    type: 'time',
    editable: true,
    flexField: true,
    secondPair: true,
    display: 'hide',
  },
  {
    label: 'Meal 2 Out',
    accessor: 'meal2Out',
    width: 85,
    type: 'time',
    editable: true,
    flexField: true,
    display: 'additional',
    pairName: 'meal2In',
    pairLabel: 'Meal 2',
  },
  {
    label: 'Meal 2 In',
    accessor: 'meal2In',
    width: 85,
    type: 'time',
    editable: true,
    flexField: true,
    display: 'additional',
    secondPair: true,
  },
  {
    label: 'Meal 3 Out',
    accessor: 'meal3Out',
    width: 85,
    type: 'time',
    editable: true,
    flexField: true,
    display: 'hide',
    pairName: 'meal3In',
    pairLabel: 'Meal 3',
  },
  {
    label: 'Meal 3 In',
    accessor: 'meal3In',
    width: 85,
    type: 'time',
    editable: true,
    flexField: true,
    display: 'hide',
    secondPair: true,
  },
  {
    label: 'Wrap',
    accessor: 'wrap',
    width: 85,
    type: 'time',
    editable: true,
    display: 'always',
  },
  {
    label: 'Travel End',
    accessor: 'travelEnd',
    width: 85,
    type: 'time',
    editable: true,
    display: 'hide',
  },

  {
    label: 'Country',
    accessor: 'country',
    width: 120,
    type: 'autocomplete',
    editable: true,
    flexField: false,
    display: 'additional',
  },
  {
    label: 'State',
    accessor: 'state',
    width: 120,
    type: 'autocomplete',
    editable: true,
    flexField: true,
    display: 'show',
  },
  {
    label: 'County',
    accessor: 'county',
    width: 120,
    type: 'autocomplete',
    editable: true,
    flexField: true,
    display: 'hide',
  },
  {
    label: 'City',
    accessor: 'city',
    width: 120,
    type: 'autocomplete',
    editable: true,
    flexField: true,
    display: 'show',
  },
  {
    label: 'PSD',
    accessor: 'workSubdivision',
    width: 120,
    type: 'autocomplete',
    editable: true,
    flexField: true,
    display: 'hide',
  },
  {
    label: 'Account Code',
    accessor: 'accountCode',
    width: 80,
    type: 'text',
    editable: true,
    flexField: false,
    display: 'hide',
  },
  {
    label: 'Episode',
    accessor: 'episode',
    width: 120,
    type: 'autocomplete',
    editable: true,
    flexField: false,
    display: 'show',
  },
  {
    label: 'Series',
    accessor: 'series',
    width: 80,
    type: 'text',
    editable: true,
    flexField: false,
    display: 'additional',
  },
  {
    label: 'Location',
    accessor: 'location',
    width: 80,
    type: 'text',
    editable: true,
    flexField: false,
    display: 'additional',
  },
  {
    label: 'Set',
    accessor: 'set',
    width: 80,
    type: 'text',
    editable: true,
    flexField: false,
    display: 'hide',
  },
  {
    label: 'Insurance',
    accessor: 'insurance',
    width: 80,
    type: 'text',
    editable: true,
    flexField: false,
    display: 'hide',
  },
  {
    label: 'FF1',
    accessor: 'freeField1',
    width: 80,
    type: 'text',
    editable: true,
    flexField: false,
    display: 'hide',
  },
  {
    label: 'FF2',
    accessor: 'freeField2',
    width: 80,
    type: 'text',
    editable: true,
    flexField: false,
    display: 'hide',
  },
  {
    label: 'FF3',
    accessor: 'freeField3',
    width: 80,
    type: 'text',
    editable: true,
    flexField: false,
    display: 'hide',
  },
  {
    label: 'FF4',
    accessor: 'freeField4',
    width: 80,
    type: 'text',
    editable: true,
    flexField: false,
    display: 'hide',
  },
  {
    label: 'Work Hrs',
    accessor: 'workedHours',
    width: 50,
    type: 'special',
    editable: false,
    display: 'always',
  },
  {
    label: '',
    accessor: 'actions',
    width: 50,
    type: 'special',
    editable: false,
    display: 'always',
  },
];

export const DEAL_MEMO_FIELDS = [];

export const ALL_TIME_FIELDS = COLUMNS.filter(col => col.type === 'time').map(
  col => col.accessor,
);

export const EMP_TC_FIELD_LABEL_MAP = {
  accountCode: 'Account Code',
  episode: 'Episode',
  location: 'Location',
  insurance: 'Insurance',
  series: 'Series',
  set: 'Set',
  freeField1: 'Free Field 1',
  freeField2: 'Free Field 2',
  freeField3: 'Free Field 3',
  freeField4: 'Free Field 4',
  date: 'Date',
  locationType: 'Work Zone',
  dayType: 'Day Type',
  call: 'Call',
  ndbIn: 'NDB In',
  meal1Out: 'Meal 1 Out',
  meal1In: 'Meal 1 In',
  lastMan1In: 'Last Man In',
  ndmOut: 'NDM Out',
  ndmIn: 'NDM In',
  meal2Out: 'Meal 2 Out',
  meal2In: 'Meal 2 In',
  meal3Out: 'Meal 3 Out',
  meal3In: 'Meal 3 In',
  wrap: 'Wrap',
  startTravel: 'Start Travel',
  travelEnd: 'Travel End',
  country: 'Country',
  state: 'State',
  county: 'County',
  city: 'City',
  occupation: 'Occ Code',
  occupationCode: 'Occupation Code',
  psd: 'PSD',
  workedHours: 'Work Hrs',
};

export const EMP_ADDITIONAL_FIELD_DESC_MAP = {
  'NDB In': 'NDB In - Non-Deductible Breakfast In',
  NDM: 'NDM - Non-Deductible Meal',
  'Occ Code': 'Occupation',
  'NDM Out': 'NDM Out - Non-Deductible Meal Out',
  'NDM In': 'NDM In - Non-Deductible Meal In',
};
export const NEW_TC_ID = 'fresh-0';
export const NEW_ALLO_ID = 'NEW-allo';

function reverseKeysAndValues(obj) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    acc[value] = key;
    return acc;
  }, {});
}

export const TAB_NAV_MAP = {
  comments: 2,
  allowances: 1,
  timecard: 0,
  history: 3,
};
export const TAB_NAV_MAP_REVERSE = reverseKeysAndValues(TAB_NAV_MAP);

//util functions

export const convertTimesToMil = (timecard, options = {}) => {
  const tv = new TimeValidator(null, { noRounding: true });

  const days = timecard.days;
  days.forEach(day => {
    ALL_TIME_FIELDS.forEach(field => {
      if (day[field] || day[field] === 0) {
        let parsedTime = tv.parse(`${day[field]}`);
        day[field] = tv.parseFloatToMilitary(parsedTime);
      }
    });
  });
};

export const convertTimesToDec = (timecard, options = {}) => {
  const tv = new TimeValidator(null, { noRounding: true });
  const days = timecard.days;
  days.forEach(day => {
    ALL_TIME_FIELDS.forEach(field => {
      if (day[field] || day[field] === 0) {
        day[field] = tv.parse(`${day[field]}`);
      }
    });
  });
};

export const validate = (values, formProps) => {
  const errors = { days: [{}, {}, {}, {}, {}, {}, {}], valid: true };

  const { requiredFields } = formProps;
  let anyDayActive = false;
  const week = values.days;
  if (week) {
    for (let x = 0; x <= 6; x++) {
      if (!!week[x].htgDayTypeId) {
        anyDayActive = true;
        //TODO when do we validate?
        let call = week[x].call;
        let wrap = week[x].wrap;
        let nextCall,
          meal1In,
          meal1Out,
          lastMan1In,
          meal2In,
          meal2Out,
          meal3In,
          meal3Out,
          ndbIn,
          ndmOut,
          ndmIn;

        if (x !== 6) {
          nextCall = week[x + 1].call;
        }

        if (week[x].meal1In || week[x].meal1In === 0) {
          meal1In = week[x].meal1In;
        }
        if (week[x].meal1Out || week[x].meal1Out === 0) {
          meal1Out = week[x].meal1Out;
          // if (meal1Out === 0 || meal1Out < 0.5) {
          //   errors.days[x].meal1Out =
          //     'Your meal out time must be greater than 00:30';
          //   errors.valid = false;
          // }
        }
        if (week[x].lastMan1In || week[x].lastMan1In === 0) {
          lastMan1In = week[x].lastMan1In;
        }
        if (week[x].meal2In || week[x].meal2In === 0) {
          meal2In = week[x].meal2In;
        }
        if (week[x].meal2Out || week[x].meal2Out === 0) {
          meal2Out = week[x].meal2Out;
          // if (meal2Out === 0 || meal2Out < 0.5) {
          //   errors.days[x].meal2Out =
          //     'Your meal out time must be greater than 00:30';
          //   errors.valid = false;
          // }
        }
        if (week[x].meal3In || week[x].meal3In === 0) {
          meal3In = week[x].meal3In;
        }
        if (week[x].meal3Out || week[x].meal3Out === 0) {
          meal3Out = week[x].meal3Out;
          // if (meal3Out === 0 || meal3Out < 0.5) {
          //   errors.days[x].meal3Out =
          //     'Your meal out time must be greater than 00:30';
          //   errors.valid = false;
          // }
        }
        if (week[x].ndbIn || week[x].ndbIn === 0) {
          ndbIn = week[x].ndbIn;
        }
        if (week[x].ndmOut || week[x].ndmOut === 0) {
          ndmOut = week[x].ndmOut;
        }
        if (week[x].ndmIn || week[x].ndmIn === 0) {
          ndmIn = week[x].ndmIn;
        }
        // Meal 1 IN and OUT validations
        if (
          (week[x].meal1Out || week[x].meal1Out === 0) &&
          (week[x].call || week[x].call === 0)
        ) {
          if ((week[x].call || week[x].call === 0) && call >= meal1Out) {
            errors.days[x].meal1Out =
              'Your meal out time must be after your call time';
            errors.valid = false;
          }
        }
        if ((week[x].ndbIn || week[x].ndbIn === 0) && week[x].meal1Out) {
          if (ndbIn >= meal1Out) {
            errors.days[x].meal1Out =
              'Your meal out time must be after your NDB in time';
            errors.valid = false;
          }
        }
        if ((week[x].meal1In || week[x].meal1In === 0) && week[x].lastMan1In) {
          if (meal1In > lastMan1In) {
            errors.days[x].lastMan1In =
              'Your Last Man In time must be equal to or after your 1st meal in time';
            errors.valid = false;
          }
        }

        if (week[x].meal1Out === null && week[x].lastMan1In) {
          errors.days[x].meal1Out = 'Meal out time required with "Last Man In"';
          errors.valid = false;
        }

        if (
          (week[x].meal1In || week[x].meal1In === 0) &&
          week[x].meal1Out === null
        ) {
          errors.days[x].meal1Out = 'Meal out time required';
          errors.valid = false;
        }
        if (
          (week[x].meal1In === null && week[x].meal1Out) ||
          week[x].meal1Out === 0
        ) {
          errors.days[x].meal1In = 'Meal in time required';
          errors.valid = false;
        }
        if ((week[x].meal1In || week[x].meal1In === 0) && week[x].meal1Out) {
          if (meal1Out >= meal1In) {
            errors.days[x].meal1In =
              'Your meal in time must be after your meal out time';
            errors.valid = false;
          }
        }
        // Meal 2 IN and OUT validations
        if (
          (week[x].meal2In || week[x].meal2In === 0) &&
          week[x].meal2Out === null
        ) {
          errors.days[x].meal2Out = 'Meal out time required';
          errors.valid = false;
        }
        if (week[x].meal2In === null && week[x].meal2Out) {
          errors.days[x].meal2In = 'Meal in time required';
          errors.valid = false;
        }
        if (week[x].meal2In && week[x].meal2Out) {
          if (meal2Out >= meal2In) {
            errors.days[x].meal2In =
              'Your meal in time must be after your meal out time';
            errors.valid = false;
          }
          if (week[x].call && call >= meal2Out) {
            errors.days[x].meal2Out =
              'Your meal out time must be after your call time';
            errors.valid = false;
          }
          if (week[x].meal1In && meal1In >= meal2Out) {
            errors.days[x].meal2Out =
              'Your meal out time must be after your previous meal in time';
            errors.valid = false;
          }
        }
        // Meal 3 IN and OUT validations
        if (week[x].meal3In && week[x].meal3Out === null) {
          errors.days[x].meal3Out = 'Meal out time required';
          errors.valid = false;
        }
        if (week[x].meal3In === null && week[x].meal3Out) {
          errors.days[x].meal3In = 'Meal in time required';
          errors.valid = false;
        }
        if (week[x].meal3In && week[x].meal3Out) {
          if (meal3Out >= meal3In) {
            errors.days[x].meal3In =
              'Your meal in time must be after your meal out time';
            errors.valid = false;
          }
          if (week[x].call && call >= meal3Out) {
            errors.days[x].meal3Out =
              'Your meal out time must be after your call time';
            errors.valid = false;
          }
          if (week[x].meal2In && meal2In >= meal3Out) {
            errors.days[x].meal3Out =
              'Your meal out time must be after your previous meal in time';
            errors.valid = false;
          }
          if (week[x].meal1In && meal1In >= meal3Out) {
            errors.days[x].meal3Out =
              'Your meal out time must be after your previous meal in time';
            errors.valid = false;
          }
        }
        // NDB and NDM validation
        if (week[x].ndmIn && week[x].ndmOut === null) {
          errors.days[x].ndmOut = 'NDM out time required';
          errors.valid = false;
        }
        if (week[x].ndmOut && week[x].ndmIn === null) {
          errors.days[x].ndmIn = 'NDM in time required';
          errors.valid = false;
        }
        if (week[x].ndmIn && week[x].ndmOut) {
          // if (ndmOut.isSameOrAfter(ndmIn)) {
          if (ndmOut >= ndmIn) {
            errors.days[x].ndmIn =
              'Your NDM in time must be after your NDM out time';
            errors.valid = false;
          }
        }
        if (week[x].ndbIn || (week[x].ndbIn === 0 && week[x].call)) {
          // if (ndmOut.isSameOrAfter(ndmIn)) {
          if (call >= ndbIn) {
            errors.days[x].ndbIn =
              'Your NDB in time must be after your call time';
            errors.valid = false;
          }
        }
        // call === ndBin ( including "0") display the error
        if (week[x].call === week[x].ndbIn) {
          if (week[x].ndbIn !== null && week[x].ndbIn !== undefined) {
            errors.days[x].ndbIn =
              'Your NDB in time must be after your call time';
            errors.valid = false;
          }
        }
        if (week[x].ndmOut && week[x].call) {
          // if (ndmOut.isSameOrAfter(ndmIn)) {
          if (call >= ndmOut) {
            errors.days[x].ndmOut =
              'Your NDM out time must be after your call time';
            errors.valid = false;
          }
        }
        // Call and Wrap validations
        if (!week[x].call && week[x].call !== 0) {
          const isExempt = !!values.dealMemo?.exempt;
          const dayTypeCode =
            week[x] && week[x].dayType && week[x].dayType.code;
          if (isExempt === false && doesDayRequireTimes(dayTypeCode)) {
            errors.days[x].call = 'Call time is required';
            errors.valid = false;
          }
        }

        if (week[x].call && call >= 24) {
          errors.days[x].call = 'Call time must be under 24 hours.';
          errors.valid = false;
        }
        if (week[x].wrap && wrap >= 48) {
          errors.days[x].wrap = 'Wrap time must be under 48 hours.';
          errors.valid = false;
        }
        if ((nextCall || nextCall === 0) && week[x].wrap && wrap >= 24) {
          if (nextCall <= wrap - 24) {
            errors.days[x + 1].call =
              "Call time overlaps with previous day's wrap time.";
            errors.valid = false;
          }
        }
        if (week[x].call !== null && week[x].wrap === null) {
          errors.days[x].wrap = 'Wrap time is required';
          errors.valid = false;
        }
        if (week[x].wrap !== null && week[x].call === null) {
          errors.days[x].call = 'Call time is required';
          errors.valid = false;
        }
        if (
          week[x].wrap &&
          (week[x].meal1In || week[x].meal1In === 0) &&
          meal1In >= wrap
        ) {
          errors.days[x].wrap =
            'Your wrap time must be after your meal 1 in time';
          errors.valid = false;
        }
        if (week[x].wrap && week[x].meal2In && meal2In >= wrap) {
          errors.days[x].wrap =
            'Your wrap time must be after your meal 2 in time';
          errors.valid = false;
        }
        if (week[x].wrap && week[x].meal3In && meal3In >= wrap) {
          errors.days[x].wrap =
            'Your wrap time must be after your meal 3 in time';
          errors.valid = false;
        }
        if (week[x].wrap && week[x].ndmIn && ndmIn >= wrap) {
          errors.days[x].ndmIn =
            'Your ndm in time must be before your wrap time';
          errors.valid = false;
        }
        if (week[x].call && week[x].wrap) {
          if (call >= wrap) {
            errors.days[x].wrap = 'Your wrap time must be after your call time';
            errors.valid = false;
          }
        }
        if (wrap === 0) {
          errors.days[x].wrap = 'Wrap time cannot be 0';
          errors.valid = false;
        }
        //travel validations

        if (
          (week[x].startTravel || week[x].startTravel === 0) &&
          (week[x].call || week[x].call === 0)
        ) {
          if (week[x].startTravel >= week[x].call) {
            errors.days[x].startTravel =
              'Your start travel time must be before your call time';
            errors.valid = false;
          }
        }

        if (week[x].travelEnd && week[x].wrap) {
          if (week[x].travelEnd <= week[x].wrap) {
            errors.days[x].travelEnd =
              'Your travel end time must be after your wrap time';
            errors.valid = false;
          }
        }

        //Dynamic Validations:
        requiredFields.forEach(fieldName => {
          if (!week[x][fieldName]) {
            errors.days[x][fieldName] = `${
              EMP_TC_FIELD_LABEL_MAP[fieldName] || fieldName
            } is required`;
            errors.valid = false;
          }
        });
      }
    }
  }
  let anyAllowance = false;

  if (Array.isArray(values.allowances) && values.allowances.length > 0) {
    anyAllowance = true;
  }

  if (!anyDayActive && !anyAllowance) {
    errors.valid = false;
    errors.global = 'At least one day or allowance is required';
  }

  return errors;
};

export const composeAllowanceV2 = allowance => {
  const { allowanceType, file } = allowance;

  const payload = {
    rate: allowance.rate || 0,
    htgAllowanceTypeId: allowanceType.id,
    isDefaultAllowance: allowance.isDefaultAllowance || false,
    s3path: allowance.s3Path || null,
    token: allowance.token || null,
    filename: allowance.filename || null,
    combineCheckCode: allowance.combineCheckCode || false, //aka live check
    reimbursementType: allowanceType.id,
    allowanceType: allowance.allowanceType,
    units: allowance.units,
    frequency: allowance.frequency,
    allowanceTypeFlag: allowance.allowanceTypeFlag,
    amount: allowance.amount || 0,
    worksightId: allowance.worksightId,
    sequenceNumber: allowance.sequenceNumber || 0,
    locationType: allowance.locationType || null,
    accountCode: allowance.accountCode || null,
    freeField1: allowance.freeField1 || null,
    freeField2: allowance.freeField2 || null,
    freeField3: allowance.freeField3 || null,
    freeField4: allowance.freeField4 || null,
    location: allowance.location || null,
    series: allowance.series || null,
    set: allowance.set || null,
    insurance: allowance.insurance || null,
  };

  if (
    !allowance.document &&
    !allowance.filename &&
    !allowance.token &&
    !allowance.s3Path &&
    !allowance.file?.name
  ) {
    payload.isDocumentDeleted = true;
  }

  if (file) {
    payload.document = file; //document will be the binary file that is sent to the server
    payload.filename = file.token;
  }

  return payload;
};

export const getTCDaysOptions = (selDate, timecard, activeDeal) => {
  // filter out the selected date and dates outside of the deal
  const filtered = timecard.days.filter(
    item =>
      !(
        moment(item.date).isBefore(activeDeal.start) ||
        moment(item.date).isAfter(activeDeal.end)
      ),
  );
  const options =
    filtered.map(tc => {
      const { date } = tc;
      const label = moment(date).format('dddd MMM DD');
      return { label: label, value: date, id: tc.id || null };
    }) || [];
  return options;
};

const TRIPLES = {
  'Hourly Rate': ['Studio Hourly Rate', 'Distant Hourly Rate', 'Epi/Pgm Rate'],
  'Guaranteed Days': [
    'Studio Guar Days',
    'Distant Guar Days',
    'Epi/Pgm Guar Days',
  ],
  'Guaranteed Hours': [
    'Studio Guar Hours',
    'Distant Guar Hours',
    'Epi/Pgm Guar Hours',
  ],
};

const addTripleField = (name, dataFields) => {
  const labels = TRIPLES[name];

  const studio = dataFields.find(field => field.label === labels[0]);
  const distant = dataFields.find(field => field.label === labels[1]);
  const other = dataFields.find(field => field.label === labels[2]);

  let values = [studio?.value, distant?.value, other?.value];

  // Filter out the falsy values
  let truthyValues = values.filter(Boolean);

  // Check if all the truthy values are the same
  let allSame =
    truthyValues.length === 0 ||
    truthyValues.every(val => val === truthyValues[0]);

  let value;

  if (!allSame) {
    const valArray = [];
    if (studio) {
      valArray.push(<span key={'studioVal'}>{`${studio.value} Stu`}</span>);
    }
    if (distant) {
      if (valArray.length > 0) valArray.push(<span key="div1">/</span>);
      valArray.push(<span key={'disVal'}>{`${distant.value} Dis`}</span>);
    }
    if (other) {
      if (valArray.length > 0) valArray.push(<span key="div2">/</span>);
      valArray.push(<span key={'othVal'}>{`${other.value} Other`}</span>);
    }
    value = valArray;
  } else {
    value = studio?.value || distant?.value || other?.value;
  }
  if (!value) return undefined;

  return { label: name, value };
};

export const getDealMemoDisplay = (dealMemo, types) => {
  const subFields = getEmpDealMemoFields();
  const deal = _.cloneDeep(dealMemo);

  deal.dealMemoAllowances = deal.dealMemoAllowances || [];
  deal.dealMemoAllowances = deal.dealMemoAllowances.filter(a => {
    const payCode = a.payCode1 || a.payCode2 || {};
    const exists = types.find(type => type.id === payCode.id);
    if (!exists) db('Allowance not found in types,suppressing', payCode);
    return !!exists;
  });

  const dataFields = subFields
    .map(field => {
      const value = field.renderFunc(deal, field.dataLoc);
      return {
        label: field.label,
        value: value,
      };
    })
    .filter(field => field.value && field.value !== '0');

  const loanOut = dataFields.find(field => field.label === 'Loan-Out');

  const dmId = _.get(deal, 'code', '');
  const name = _.get(deal, 'occupationCode.name', '');
  const occCode = _.get(deal, 'occupationCode.code', '');

  const dealDisplay = {
    loanOut: loanOut?.value,
    title: `${dmId} - ${name} - ${occCode}`,
    fields: [],
  };

  let triVal = addTripleField('Hourly Rate', dataFields);
  if (triVal) dealDisplay.fields.push(triVal);

  triVal = addTripleField('Guaranteed Days', dataFields);
  if (triVal) dealDisplay.fields.push(triVal);

  triVal = addTripleField('Guaranteed Hours', dataFields);
  if (triVal) dealDisplay.fields.push(triVal);

  const unionName = dataFields.find(field => field.label === 'Pension Union');
  if (unionName?.value?.toLowerCase() !== 'non-union') {
    dealDisplay.fields.push({ label: 'Union', value: unionName?.value });
    const scale = dataFields.find(field => field.label === 'Scale');
    if (scale) {
      dealDisplay.fields.push(scale);
    }
  }

  const effectiveDate = dataFields.find(
    field => field.label === 'Effective Date',
  );
  if (effectiveDate) {
    const dateVal = moment(effectiveDate.value);
    if (dateVal.isValid()) effectiveDate.value = dateVal.format('MM/DD/YYYY');
    dealDisplay.fields.push(effectiveDate);
  }

  const expirationDate = dataFields.find(
    field => field.label === 'Expiration Date',
  );
  if (expirationDate) {
    const dateVal = moment(expirationDate.value);
    if (dateVal.isValid()) {
      expirationDate.value = dateVal.format('MM/DD/YYYY');
      if (expirationDate.value !== '12/31/9999') {
        dealDisplay.fields.push(expirationDate);
      }
    }
  }

  const dealAllowances = dataFields.find(field => field.label === 'Allowances');
  if (dealAllowances) {
    dealDisplay.fields.push(dealAllowances);
  }

  return dealDisplay;
};

const copyTcExcludedFields = COLUMNS.filter(col => col.type === 'time').map(
  col => col.accessor,
);
export const checkCopyToAllMatch = timecard => {
  let matched = null;
  const days = timecard.days;
  if (days.length > 1) {
    const filteredDays = days.filter(day => day.htgDayTypeId);
    const firstDay = filteredDays[0];
    filteredDays.forEach((day, index) => {
      if (index >= 1) {
        Object.keys(day).forEach(key => {
          if (
            (matched == null || matched) &&
            copyTcExcludedFields.includes(key)
          ) {
            //converting data to string to compare
            const x = JSON.stringify(firstDay[key]);
            const y = JSON.stringify(day[key]);
            matched = x === y ? true : false;
          }
        });
      }
    });
  }
  return matched;
};

export const makeAllowanceFromDealAllo = ({
  dealAllowance,
  allowanceTypes,
  defaultNonTax,
  includeDayTypes = false,
  dealMemo,
}) => {
  const payCodes = [];
  if (!_.isEmpty(dealAllowance.payCode1)) {
    payCodes.push(_.cloneDeep(dealAllowance.payCode1));
  }
  if (!_.isEmpty(dealAllowance.payCode2)) {
    payCodes.push(_.cloneDeep(dealAllowance.payCode2));
  }

  let payCode = payCodes[0] || null;

  if (payCodes.length === 2 && defaultNonTax.includes(payCodes[1].code)) {
    payCode = payCodes[1];
  }

  if (!_.isEmpty(payCode)) {
    const randId = crypto.randomUUID();
    let amount = undefined;
    if (dealAllowance.allowancesAmount > 0) {
      amount = dealAllowance.allowancesAmount;
    } else if (!!dealAllowance.rate) {
      amount = dealAllowance.rate;
    } else {
      amount = 0;
    }

    let allowance = {
      id: `auto-allowance-${randId}`,
      allowanceType: {
        code: payCode.code,
        id: payCode.id,
        name: payCode.name,
      },
      units: 1,
      allowanceTypeFlag: 'A',
      frequency: dealAllowance.frequency,
      rate: amount,
      amount: amount,
      htgAllowanceTypeId: payCode.id,
      isDefaultAllowance: false,
      reimbursementType: payCode.id,
      freeField1: dealAllowance?.customField1 || dealMemo?.customField1 || null,
      freeField2: dealAllowance?.customField2 || dealMemo?.customField2 || null,
      freeField3: dealAllowance?.customField3 || dealMemo?.customField3 || null,
      freeField4: dealAllowance?.customField4 || dealMemo?.customField4 || null,
      location: dealAllowance?.location || dealMemo?.location || null,
      series: dealAllowance?.series || dealMemo?.series || null,
      set: dealAllowance?.set || dealMemo?.set || null,
      insurance: dealAllowance?.insurance || dealMemo?.insurance || null,
      workState: dealAllowance?.workState,
      workCity: dealAllowance?.workCity,
      worksightId: `${NEW_ALLO_ID}-${randId}`,
      sequenceNumber: dealAllowance?.sequenceNumber,
      accountCode: dealAllowance?.account || dealMemo?.wageAccount || null,
    };
    const allowanceType = allowanceTypes.find(allow => allow.id === payCode.id);
    if (!_.isEmpty(allowanceType)) {
      allowance = {
        ...allowance,
        isDefaultAllowance: allowanceType.isDefaultAllowance,
        employeeMandatory: allowanceType.employeeMandatory,
        employeeVisible: allowanceType.employeeVisible,
      };
      if (includeDayTypes) {
        allowance.dayTypes = allowanceType.dayTypes;
      }
    } else {
      //do not populate if we don't know the type of allowance
      db("Hiding allowance because we don't know the type");
      return null;
    }
    return allowance;
  }
  return null;
};
export const getMissedFileAllowanceId = (allowances, allowanceTypes) => {
  const allowanceWithoutReqFile = allowances.find(
    allowance =>
      !allowance.filename &&
      allowanceTypes.some(
        type =>
          type.id === allowance.htgAllowanceTypeId && type.employeeMandatory,
      ),
  );
  const worksightId = allowanceWithoutReqFile?.worksightId;
  return worksightId;
};
