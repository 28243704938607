import React from 'react';

import { Header, FullWidthLayout, DefaultLayout } from 'containers/Layout';

//sub nav
import { Projects as ProjectsNav } from 'containers/Nav/Pages';

import adminProjectRoutes from './adminProjectRoutes';
//lazy loaded in src\components\Routes\adminRoutes.js
//components
const Projects = React.lazy(() => import('containers/Admin/Projects'));
const SuperAdmin = React.lazy(() => import('containers/Admin/SuperAdmin'));
const Flags = React.lazy(() => import('containers/Admin/FeatureFlags'));
const AccessAdmin = React.lazy(() => import('containers/Admin/AccessAdmin'));
const UsersAllProjects = React.lazy(() =>
  import('containers/Admin/UsersAllProjects'),
);

const adminRoutes = [
  {
    title: 'Super Admin',
    path: '/admin/superadmin',
    headers: [Header],
    layout: DefaultLayout,
    component: SuperAdmin,
  },
  {
    title: 'Admin Projects',
    path: '/admin/accessadmin',
    headers: [Header],
    layout: DefaultLayout,
    component: AccessAdmin,
  },
  {
    title: 'Feature Flags',
    path: '/admin/feature-flags',
    headers: [Header],
    layout: DefaultLayout,
    component: Flags,
  },
  {
    title: 'User All Projects',
    path: '/admin/users-all-projects',
    headers: [Header],
    layout: DefaultLayout,
    component: UsersAllProjects,
  },
  {
    title: 'Admin Projects',
    path: '/admin/projects',
    headers: [Header, ProjectsNav],
    layout: FullWidthLayout,
    component: Projects,
  },
  ...adminProjectRoutes,
];

export default adminRoutes;
