import React, { useEffect, useState, useCallback } from 'react';
import clsx from 'clsx';
import _, { debounce } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import makeStyles from '@mui/styles/makeStyles';
import SelectComp from './SelectComp';

//selectors
import {
  getDayTypeOptions,
  getLocationTypes,
  getOccCodeOptions,
  getSoloOptionsLoading,
  getEffectiveDate,
} from 'selectors/dts';
import {
  getStatesV1,
  getCitiesV1,
  getCountiesV1,
  getSubdivisionsV1,
} from 'selectors/location';

//actions
import * as actions from 'actions/dts';
import {
  storeStatesV1,
  storeCitiesV1,
  storeCountiesV1,
  storeSubdivisionsV1,
} from 'actions/location';

import { getOptionLoading } from 'feature/DTS/dtsUtils';
import { useWillUnmount } from 'utils/customHooks';

const useStyles = makeStyles(theme => ({
  root: {
    transition: 'all 0.4s ',
  },
  hide: {
    transform: 'scale(.01)',
    transformOrigin: 'left',
  },
}));

const mapState = state => {
  return {
    dayTypes: getDayTypeOptions(state),
    locationTypes: getLocationTypes(state),
    occCodes: getOccCodeOptions(state),

    // dealMemos: getDealMemoOptions(state),
    loadingObj: getSoloOptionsLoading(state),
    effectiveDate: getEffectiveDate(state),
    //
    states: getStatesV1(state),
    cities: getCitiesV1(state),
    counties: getCountiesV1(state),
    workSubs: getSubdivisionsV1(state),
  };
};

// DO NOT add a second parameter to dispatch function. Causes functions to re-render and trigger useEffects unexpectedly
const mapDispatch = dispatch => ({
  onFetchOptions: ({ columnId, original, search, effectiveDate }) => {
    dispatch(actions.fetchSoloOptions({ columnId, original, search }));
    if (columnId === 'locationType') {
      const { employeeId } = original;

      dispatch(
        actions.fetchDealMemos({
          employeeId,
          effectiveDate: effectiveDate.format('YYYY-MM-DDT00:00:00'),
        }),
      );
    }
  },
  onClearOptions: ({ columnId }) => {
    switch (columnId) {
      case 'workState':
        dispatch(storeStatesV1({ states: [] }));
        break;
      case 'workCity':
        dispatch(storeCitiesV1({ cities: [] }));
        break;
      case 'workCounty':
        dispatch(storeCountiesV1({ counties: [] }));
        break;
      case 'workSubdivision':
        dispatch(storeSubdivisionsV1({ subdivisions: [] }));
        break;
      case 'locationType':
        dispatch(actions.storeDealMemos({ data: [] }));
      // eslint-disable-next-line no-fallthrough
      default:
        dispatch(actions.clearSoloOptions({ columnId }));
        break;
    }
  },
});

const SoloSelect = props => {
  const { columnId, original } = props;
  const classes = useStyles();
  //option props
  const {
    effectiveDate,
    dayTypes,
    locationTypes,
    occCodes,
    workSubs,
    states,
    cities,
    counties,
    loadingObj,
    onFetchOptions,
    onClearOptions,
  } = props;

  const [options, setOptions] = useState([]);

  const [search, setSearch] = useState('');

  const [emptyMsg, setEmptyMsg] = useState(
    search === '' ? 'No options' : ' No matches',
  );
  useEffect(() => {
    search === '' ? setEmptyMsg('No options') : setEmptyMsg(' No matches');
  }, [search]);
  const isLoading = getOptionLoading(columnId, loadingObj);

  const fetchOptions = useCallback(() => {
    onFetchOptions({
      columnId,
      original: _.cloneDeep(original),
      search,
      effectiveDate,
    });
  }, [columnId, original, search, effectiveDate, onFetchOptions]);

  useEffect(() => {
    let options;
    switch (columnId) {
      case 'workState':
        options = states;
        break;
      case 'workCounty':
        options = counties;
        break;
      case 'workCity':
        options = cities;
        break;
      case 'dayType': {
        const dayTypesWithBlank = dayTypes.slice();
        if (dayTypesWithBlank.length > 0) {
          //add empty option to enable removal of dayType
          dayTypesWithBlank.unshift({});
        }
        options = dayTypesWithBlank;
        break;
      }
      case 'locationType':
        options = locationTypes;
        break;
      case 'occupationCode':
        options = occCodes;
        break;
      case 'workSubdivision':
        options = workSubs;

        break;

      default:
        console.warn('ColumnId not recognized. ', columnId);
        options = [];
        break;
    }
    setOptions(options);
  }, [
    columnId,
    states,
    counties,
    cities,
    dayTypes,
    locationTypes,
    occCodes,
    workSubs,
  ]);

  useWillUnmount(() => {
    onClearOptions({ columnId });
  });

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  const [didMount, setDidMount] = useState(false); //used to change CSS for intro animation
  useEffect(() => {
    if (!didMount) {
      setDidMount(true);
    }
  }, [didMount]);

  const onInputChange =
    columnId === 'occupationCode' || columnId === 'workSubdivision'
      ? debounce(newValue => setSearch(newValue), 400, {
          maxWait: 800,
        })
      : () => {};

  return (
    <div
      className={clsx(classes.root, {
        [classes.hide]: !didMount,
      })}
      style={{ marginTop: 0 }}
    >
      <SelectComp
        {...props}
        options={options}
        emptyMsg={emptyMsg}
        onInputChange={onInputChange}
        isLoading={isLoading}
      />
    </div>
  );
};

export default compose(connect(mapState, mapDispatch))(SoloSelect);
