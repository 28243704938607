import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette }) => ({
  upArrow: {
    marginBottom: '-4px',
    marginLeft: '8px',
    fontSize: '20',
  },
  downArrow: {
    marginTop: '-5px',
    marginLeft: '8px',
    fontSize: '20',
  },
  primaryColor: {
    color: palette.primary.main,
  },
  selectOption: {
    color: palette.action.disabledBackground,
  },
  arrowContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  arrow: {
    '&& :hover': {
      color: palette.primary.main,
    },
    color: palette.action.disabled,
    padding: 0,
  },
}));

const ProjectAllowanceSortArrow = props => {
  const classes = useStyles();
  const { sort, sortKey, sortBoolean, setCurrent, selected } = props;
  const { currentKey, direction } = selected;

  return (
    <span className={classes.arrowContainer}>
      <ArrowDropUp
        onClick={e => {
          e.stopPropagation();
          sort(sortKey, 'asc', sortBoolean);
          setCurrent(sortKey, 'asc');
        }}
        className={clsx({
          [classes.upArrow]: true,
          [classes.arrow]: currentKey !== sortKey,
          [classes.primaryColor]: currentKey === sortKey && direction === 'asc',
          [classes.selectOption]: currentKey === sortKey && direction !== 'asc',
        })}
      />

      <ArrowDropDown
        onClick={e => {
          e.stopPropagation();
          sort(sortKey, 'desc', sortBoolean);
          setCurrent(sortKey, 'desc');
        }}
        className={clsx({
          [classes.downArrow]: true,
          [classes.arrow]: currentKey !== sortKey,
          [classes.primaryColor]:
            currentKey === sortKey && direction === 'desc',
          [classes.selectOption]:
            currentKey === sortKey && direction !== 'desc',
        })}
      />
    </span>
  );
};

ProjectAllowanceSortArrow.propTypes = {
  sort: PropTypes.func.isRequired,
  sortKey: PropTypes.string.isRequired,
  sortBoolean: PropTypes.bool.isRequired,
  setCurrent: PropTypes.func.isRequired,
  selected: PropTypes.object.isRequired,
};

export default ProjectAllowanceSortArrow;
