/* eslint-disable mui-unused-classes/unused-classes */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import { getFilteredDropdown } from 'selectors/searchTimecards';
import { currentUser } from 'selectors/session';

import { fetch, onSelect, onSelectAll } from 'actions/searchTimecards';
import { statusClass, moveReadyForMe } from 'utils/helperFunctions';

const useStyles = makeStyles(({ palette }) => {
  return {
    styleRow: {
      marginLeft: '0px !important',
      marginRight: '0px !important',
      padding: '5px !important',
      fontSize: '12px',
      fontStyle: 'normal',
      lineHeight: '16px',
      letterSpacing: '0.3199999928474426px',
      textAlign: 'center',
      display: 'flex',
      flexWrap: 'wrap',
    },
    styleContent: {
      paddingLeft: '15px !important',
      paddingRight: '15px !important',
      cursor: 'pointer',
      padding: '5px',
      borderRadius: '8px',
      marginLeft: '10px !important',
      marginRight: '10px !important',
      display: 'inline-block',
      marginTop: '5px !important',
      marginBottom: '5px !important',
    },

    //programmatic classes
    invoiceSelected: {
      background: `${palette.background.default}`,
      border: `solid 1px ${palette.background.default}`,
    },
    redBtn: {
      background: palette.button.lightRed.background,
      border: `solid 1px ${palette.button.lightRed.background}`,
      color: palette.button.lightRed.color,
    },
    orangeBtn: {
      background: palette.button.orange.background,
      border: `solid 1px ${palette.button.orange.background}`,
      color: palette.button.orange.color,
    },
    yellowBtn: {
      background: palette.button.yellow.background,
      border: `solid 1px ${palette.button.yellow.background}`,
      color: palette.button.yellow.color,
    },
    grayBtn: {
      background: palette.button.gray.background,
      border: `solid 1px ${palette.button.gray.background}`,
      color: palette.button.gray.color,
    },
    greenBtn: {
      background: palette.button.lightGreen.background,
      border: `solid 1px ${palette.button.lightGreen.background}`,
      color: palette.button.lightGreen.color,
    },
    primaryBtn: {
      background: palette.button.primary.background,
      border: `solid 1px ${palette.button.primary.background}`,
      color: palette.button.primary.color,
    },
    deselect: {
      background: palette.action.disabledBackground,
      color: palette.action.disabled,
    },
  };
});
const SearchTableFilter = props => {
  const classes = useStyles();
  const user = useSelector(currentUser);
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const options = useSelector(state => getFilteredDropdown(state, 'status'));
  const dispatch = useDispatch();
  const [filteredOptions, setFilteredOptions] = useState([]);
  useEffect(() => {
    if (user && user.role === 'upm' && options.length > 1) {
      setFilteredOptions(moveReadyForMe(options, user.level));
    } else {
      setFilteredOptions(options);
    }
  }, [options, user]);

  const [allSelected, setAllSelected] = useState(false);

  const allStatusFilterHandler = () => {
    setAllSelected(!allSelected);
    dispatch(onSelectAll({ filterName: 'status' }));
    dispatch(fetch({ filterName: 'status' }));
  };
  const statusFilterHandler = index => {
    dispatch(onSelect({ index, filterName: 'status' }));
    dispatch(fetch({ filterName: 'status' }));
  };

  const [tcTotal, setTcTotal] = useState(0);

  const totalTimecards = tcArray => {
    return tcArray.reduce(function (previousValue, currentValue) {
      return previousValue + currentValue.count;
    }, 0);
  };

  useEffect(() => {
    setTcTotal(totalTimecards(options));
    if (options.every(x => x.selected === true) && options.length > 1) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [options]);

  const buttons = filteredOptions.map(option => (
    <div
      className={clsx(classes.styleContent, `pendo_${option.value}`, {
        [classes[statusClass(option.value)]]: option.selected,
        [classes.deselect]: !option.selected,
      })}
      onClick={() => statusFilterHandler(option.index)}
      key={option.value}
    >
      {option.label} ({option.count})
    </div>
  ));

  return (
    <div className={classes.styleRow}>
      {!isNaN(tcTotal) && (
        <main>
          <div
            className={clsx(classes.styleContent, 'pendo_all_timecards', {
              [classes.deselect]: !allSelected,
              [classes.primaryBtn]: allSelected,
            })}
            onClick={allStatusFilterHandler}
          >
            All Timecards ({tcTotal})
          </div>
          {buttons}
        </main>
      )}
    </div>
  );
};
export default SearchTableFilter;
