import { createSelector } from 'reselect';
import { getProject } from './routeParams';
import _ from 'lodash';

import {
  DH,
  PA,
  UPM,
  IA,
  EMPLOYEE,
  PROJECT_ADMIN,
} from 'components/props/profiles';

export const getProjUsers = state => _.get(state, 'userInfo.activeUser', {});

export const getSessionUser = state => _.get(state, 'session.currentUser', {});

export const authenticated = state => _.get(state, 'auth.authenticated', false);

export const redirectUrl = state => _.get(state, 'session.redirectUrl', '');

export const token = state => _.get(state, 'auth.authenticated', null);

export const getProjectUser = createSelector(
  [getProject, getProjUsers],
  (projectId, users) => {
    return users[projectId] || {};
  },
);
export const userDeleted = createSelector([getProjectUser], projectUser => {
  if (projectUser) return projectUser.deletedAt !== null;
  else return false;
});

export const isUserInfoLoaded = createSelector([getProjUsers], activeUser => {
  const result = !(_.isEmpty(activeUser) || activeUser === null);
  return result;
});

export const getLoading = state => _.get(state, 'userInfo.loading', false);

export const userInfoById = projectId => state =>
  (state.userInfo.activeUser && state.userInfo.activeUser[projectId]) || {};

export const emailSent = state => state.profile.emailSent || false;

//TODO rename this to getCurrentUser
export const currentUser = createSelector(
  [getProject, getSessionUser, getProjUsers],
  (projectId, loggedInUser, activeUsers) => {
    if (projectId && activeUsers && activeUsers[projectId]) {
      return activeUsers[projectId];
    }
    let role = loggedInUser.isAdmin ? 'admin' : 'employee';

    if ((loggedInUser.projectRoles || []).length > 0) {
      role = loggedInUser.projectRoles[0];
    }

    return {
      ...loggedInUser,
      role,
    };
  },
);

export const hasReviewerRole = role => {
  const reviewerRoles = [DH, PA, UPM];
  return _.indexOf(reviewerRoles, role) > -1;
};
export const roleIsPA = role => {
  return role === PA;
};
export const roleIsDH = role => {
  return role === DH;
};
export const roleIsUPM = role => {
  return role === UPM;
};

export const userIsOnlyIA = user => {
  //TODO - see if this can be simplified with the new isInvoiceApproverOnly on root /me call
  return (
    user?.projectRoles.includes(IA) &&
    user.workSightId === null &&
    user.role === EMPLOYEE
  );
};
export const userIsOnlyProjectAdmin = user => {
  // only project admin role conditions
  // user should not have workSightId - if workSightId is present then user is employee
  // user should have project admin role
  return (
    user?.projectRoles.includes(PROJECT_ADMIN) &&
    !user?.projectRoles.includes(IA) &&
    !user.workSightId &&
    user.role === EMPLOYEE
  );
};

export const hasIARole = (projectRoles = []) => {
  return projectRoles.includes(IA);
};

export const getLimitedIAAccess = createSelector([currentUser], activeUser => {
  const projectRoles = activeUser?.projectRoles || [];
  const role = activeUser?.role || '';
  const isOnlyIA = userIsOnlyIA(activeUser);
  return (
    isOnlyIA || (hasIARole(projectRoles) && (role === DH || role === EMPLOYEE))
  );
});

export const hasCrewTimeCardRoles = role => {
  const crewTimeCardRoles = [DH, PA];
  return _.indexOf(crewTimeCardRoles, role) > -1;
};

export const getCurrentUserRole = createSelector(
  [currentUser, getProject, getProjUsers],
  (user, projectId, activeUser) => {
    const isInvoiceApprover = userIsOnlyIA(activeUser[projectId]);
    const isOnlyProjectAdmin = userIsOnlyProjectAdmin(user);
    if (isInvoiceApprover) {
      return 'invoice_approver';
    } else if (isOnlyProjectAdmin) {
      return 'project_admin';
    }
    //admin check from currentUser selector
    else if (user?.role === 'admin') {
      return user.role;
    }
    //role check from activeUser - current project role
    //TODO - this is not loaded when enhanced route first loads so its causing PA users to register as employees and redirect to timecard view page
    else {
      return activeUser[projectId]?.role || 'employee';
    }
  },
);

export const getHasRoleIA = createSelector(
  [getProject, getProjUsers],
  (projectId, activeUser) => {
    const activeProject = activeUser[projectId];
    return activeProject?.projectRoles?.includes(IA) || false;
  },
);

export const getPromptOnLeavePage = state =>
  _.get(state, 'session.promptOnLeavePage', false);

export const activeUserProjectRoles = createSelector(
  [getProject, getProjUsers],
  (projectId, activeUser) => {
    return activeUser[projectId]?.projectRoles || [];
  },
);
