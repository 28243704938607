import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFlagsReady } from 'selectors/appReady';
import { fetch as fetchFeatureFlags } from 'actions/flag';

import Loader from 'components/Loader';

/**
 * There is an authReady state, but its being handled by the withAuthProtection decorator
 * For now AppReady is just if we've made the feature flags call.
 */

const mapState = state => ({
  appReady: getFlagsReady(state),
});

//DO NOT ADD PARAMS TO THIS DISPATCH FUNCTION
const mapDispatch = {
  //THIS NEEDS TO REMAIN STABLE TO PREVENT THE ENTIRE APP FROM RE-RENDERING
  onFetchFeatureFlags: () => fetchFeatureFlags(),
};

const AppReady = ({ appReady = false, onFetchFeatureFlags, children }) => {
  React.useEffect(() => {
    onFetchFeatureFlags();
  }, [onFetchFeatureFlags]);

  if (!appReady) return <Loader fullScreen={true} />;
  return children;
};

AppReady.propTypes = {
  appReady: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onFetchFeatureFlags: PropTypes.func.isRequired,
};

export default connect(mapState, mapDispatch)(AppReady);
