import { produce } from 'immer';
import _ from 'lodash';
import * as actions from './actions';
import {
  mergeCounts,
  STATUS_LIST,
  ALWAYS_HIDDEN_COLUMNS,
  HOT_COST_COLUMNS,
} from './CrewListUtils';

export const initialState = {
  loading: false,
  users: [],
  editing: {},
  editingUsers: [],
  statusCount: [],
  editUser: {},
  filters: {
    status: STATUS_LIST,
  },
  sort: {
    id: null,
    desc: false,
  },
  dealMemoId: '',
  lastProject: null,
  dealMemoCount: 0,
  tableColumns: [],
  editHostCostFlag: false,
  hiddenColumns: [...ALWAYS_HIDDEN_COLUMNS, ...HOT_COST_COLUMNS],
  editedHotCostFields: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.loading}`: {
        draft.loading = action.loading;
        break;
      }
      case `${actions.storeUsers}`: {
        draft.users = _.sortBy(action.users, ['fullName']);
        break;
      }
      case `${actions.editing}`: {
        let user = draft.users.find(u => u.id === action.userId) || {};
        draft.editing = user;
        break;
      }
      case `${actions.editingUsers}`: {
        draft.editingUsers = action.users;
        break;
      }
      case `${actions.storeStatusCount}`: {
        draft.filters.status = mergeCounts([
          ...state.filters.status,
          ...action.statusCount.filter(stat => stat.key !== 'pending'),
        ]);
        break;
      }
      case `${actions.selectStatus}`: {
        let selected = !draft.filters[action.filterName][action.index].selected;
        draft.filters[action.filterName][action.index].selected = selected;
        break;
      }
      case `${actions.selectAllStatus}`: {
        const fullList = state.filters[action.filterName];
        if (fullList.filter(status => status.selected === false).length > 0) {
          draft.filters[action.filterName] = fullList.map(status => ({
            ...status,
            selected: true,
          }));
        } else {
          draft.filters[action.filterName] = fullList.map(status => ({
            ...status,
            selected: false,
          }));
        }
        break;
      }
      case `${actions.storeDealMemoId}`: {
        draft.dealMemoId = action.dealMemoId;
        break;
      }
      case `${actions.removeDealMemoId}`: {
        draft.dealMemoId = '';
        break;
      }
      case `${actions.storeDealMemoCount}`: {
        draft.dealMemoCount = action.dealMemoCount;
        break;
      }
      case `${actions.removeDealMemoCount}`: {
        draft.dealMemoCount = 0;
        break;
      }
      case `${actions.storeSort}`: {
        draft.sort = action.sort;
        break;
      }
      case `${actions.storeEditingUser}`: {
        draft.editUser = action.editUser;
        break;
      }
      case `${actions.clearUserList}`: {
        draft.users = [];
        break;
      }
      case `${actions.storeLastProject}`: {
        draft.lastProject = action.project;
        break;
      }
      case `${actions.resetStatus}`: {
        draft.filters.status = STATUS_LIST;
        break;
      }
      case `${actions.storeEditHostCostFlag}`: {
        draft.editHostCostFlag = action.editHostCostFlag;
        break;
      }
      case `${actions.storeUpdatedHiddenColumns}`: {
        draft.hiddenColumns = action.hiddenColumns;
        break;
      }
      case `${actions.storeEditedFields}`: {
        draft.editedHotCostFields = action.editedHotCostFields;
        break;
      }
      case `${actions.clearEditedFields}`: {
        draft.editedHotCostFields = {};
        break;
      }
      default:
    }
  });
