import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

import SortArrows from 'components/Shared/SortArrows';
import { handleSortBy } from 'utils/reactTableUtils';
import { DEFAULT_SORT } from './dtsUtils';

const useStyles = makeStyles(({ palette }) => ({
  tableHeaderCell: {
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
    position: 'sticky',
    cursor: 'pointer',
    top: 0,
    zIndex: 1,
    backgroundColor: palette.primary['+8'],
    textAlign: 'left',
    padding: '0px 15px',
    height: 16,
    color: palette.primary.main,
    fontWeight: '500',
    fontStyle: 'normal',
    borderBottom: `1px solid ${palette.primary.main}`,
  },
  errorColumn: {
    width: 1,
    padding: '0px 12.5px',
  },
  endColumn: {
    width: '100%',
  },
}));

const HeaderRow = props => {
  const { headerGroups, setSortBy } = props;
  const classes = useStyles();
  return (
    <thead>
      {headerGroups.map(headerGroup => {
        const { key, headerProps } = headerGroup.getHeaderGroupProps();
        return (
          <tr className={classes.tableHeaderRow} key={key} {...headerProps}>
            {headerGroup.headers.map(column => {
              const isErrors = column.id === 'errors';

              const { key, ...headerProps } = column.getHeaderProps();

              return (
                <th
                  key={key}
                  {...headerProps}
                  onClick={() => {
                    handleSortBy(column, setSortBy, DEFAULT_SORT);
                  }}
                  className={clsx(classes.tableHeaderCell, {
                    [classes.endColumn]: column.id === 'comments',
                    [classes.errorColumn]: isErrors,
                  })}
                >
                  {column.render(props => (
                    <span
                      // className={isSortable ? classes.cell : ''}
                      id={column.id}
                    >
                      <span className={classes.title}>{column.Header}</span>
                      {isErrors ? (
                        <span></span>
                      ) : (
                        <SortArrows column={column} />
                      )}
                    </span>
                  ))}
                </th>
              );
            })}
          </tr>
        );
      })}
    </thead>
  );
};

HeaderRow.propTypes = {
  headerGroups: PropTypes.array.isRequired,
  setSortBy: PropTypes.func.isRequired,
};

export default HeaderRow;
