import React from 'react';

import { WTC } from 'containers/Employees/Reviews';

const WrapperWTC = props => {
  React.useEffect(() => {
    const opener = window.opener;

    //close if the window that opened us is closed
    const intervalId = setInterval(() => {
      // https://stackoverflow.com/a/55601462/901311
      if (typeof window.opener != 'undefined' && window.opener != null) {
        if (
          window.opener.closed ||
          `${window.opener.location.pathname}`.includes('wtc') === false
        ) {
          window.close();
        }
      } else {
        window.close();
      }
    }, 750);

    if (opener === null) {
      //redirect to base url if we weren't opened by another window
      window.location.href = '/';
    }

    return () => {
      //close window if this component unmounts (logout etc)
      clearInterval(intervalId);
    };
  }, []);

  return <WTC {...props} />;
};

export default WrapperWTC;
