import React from 'react';

import {
  Header,
  ProjectHeader,
  FullWidthLayout,
  DefaultLayout,
} from 'containers/Layout';

//sub nav
import { AuditLogs as AuditSubNav } from 'containers/Nav/Pages';
import { InvoiceTemplate as InvTempSubNav } from 'containers/Nav/Pages';

//components
import Settings from 'containers/Admin/Projects/Settings/Settings';

//something about lazy loading does not play nice with the Settings component....
// const Settings = React.lazy(() =>
//   import('containers/Admin/Projects/Settings/Settings'),
// );

const CrewList = React.lazy(() => import('feature/CrewList/CrewList'));
const DepartmentApprovers = React.lazy(() =>
  import('containers/Admin/Projects/DepartmentApprovers'),
);
const AuditLogs = React.lazy(() => import('containers/Admin/Projects/Events'));
const InvoiceTemplates = React.lazy(() =>
  import('containers/Admin/Projects/InvoiceTemplate/InvoiceTemplate'),
);

const adminProjectRoutes = [
  {
    title: 'Admin Crew List',
    path: '/admin/projects/:projectId/crew-list',
    headers: [Header, ProjectHeader],
    layout: FullWidthLayout,
    component: CrewList,
  },
  {
    title: 'Admin Dept Approvers',
    path: '/admin/projects/:projectId/review-flows',
    headers: [Header, ProjectHeader],
    layout: DefaultLayout,
    component: DepartmentApprovers,
  },
  {
    title: 'Audit Logs',
    path: '/admin/projects/:projectId/audit-logs',
    headers: [Header, ProjectHeader, AuditSubNav],
    layout: DefaultLayout,
    component: AuditLogs,
  },
  {
    title: 'Invoice Templates',
    path: '/admin/projects/:projectId/templates',
    headers: [Header, ProjectHeader, InvTempSubNav],
    layout: DefaultLayout,
    component: InvoiceTemplates,
  },
  {
    title: 'Admin Settings',
    path: '/admin/projects/:projectId/settings',
    headers: [Header, ProjectHeader],
    layout: DefaultLayout,
    component: Settings,
  },
];

export default adminProjectRoutes;
