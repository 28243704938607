import React, { useRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { compose } from 'utils/helperFunctions';
import { connect, batch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { push } from 'redux-first-history';
import { useHistory, useLocation } from 'react-router-dom';

import BulkEditContext from './BulkEditContext';
import EmptyState from '../Shared/EmptyState';
import LoadingSquare from '../Shared/LoadingSquare';

import { getProjectFromURL } from 'utils/helperFunctions';
import { setFromURL } from 'actions/timecards';
import {
  TableColumns,
  // BULK_EDIT_TIME_FIELDS,
  processSettings,
} from 'utils/bulkEditUtils';

// actions
import {
  clearEditedDays,
  clearEdits,
  fetch,
  setLastSaveAfterFetch,
  fetchAccountCodes,
  fetchBatches,
  fetchDepartments,
  fetchEmployeeNames,
  fetchStatuses,
  fetchWeekdays,
  fetchWECount,
  fetchEpisodes,
  fetchSets,
  initLoad,
  storeWeekEnding,
  clearFilter,
  preFetchDropdown,
  save,
  calculate,
  setProspectiveTimecard,
  storeEditedDays,
  storeEdits,
  storeSortOrder,
  resetDirtyFields,
  resetFilterSelected,
  fetchSelectedCount,
} from 'actions/bulkEdit';
import { hide as hideModal } from 'actions/modalDialog';
import { show as showModal } from 'actions/modalDialog';

//utils
import { parseTimecards } from 'utils/bulkEditUtils';

// selectors
import { getOpenReviewBatches } from 'selectors/reviews';
import {
  getWeekEnding,
  filteredDays,
  getSaving,
  getSearching,
  getCalculating,
  isDirty,
  getSortOrder,
  getInitLoad,
  getTcToBeCalculated,
  getTimecardCount,
} from 'selectors/bulkEdit';
import { getSettings } from 'selectors/settings';
import { getWeekendings } from 'selectors/timecards';

import { getCurrentProject } from 'selectors/project';

import Header from 'components/Employees/Reviews/BulkEdit/Header';
import Sidebar from 'components/Employees/Reviews/BulkEdit/Sidebar';
import BulkEditTable from './BulkEditTable';
import ConfirmTakeMeBack from './Modals/ConfirmTakeMeBack';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  main: {
    width: 'calc(100% - 300px)',
    height: 'calc(100vh - 200px)',
  },
  mainCollapsedSideBar: {
    width: 'calc(100% - 50px)',
  },
  sideBar: {
    height: 'calc(100vh - 200px)',
    display: 'flex',
    position: 'sticky',
  },
}));

const mapState = state => ({
  batches: getOpenReviewBatches(state),
  currentProject: getCurrentProject(state),
  days: filteredDays(state),
  tcCount: getTimecardCount(state),
  initLoad: getInitLoad(state),
  saving: getSaving(state),
  searching: getSearching(state),
  settings: getSettings(state),
  storedSortOrder: getSortOrder(state),
  calculating: getCalculating(state),
  weekEndings: getWeekendings(state),
  weekEnding: getWeekEnding(state),
  isDirty: isDirty(state),
  tcToBeCalculated: getTcToBeCalculated(state),
});

const mapDispatch = dispatch => ({
  dispatch,
  onFetch: () => {
    dispatch(fetch({}));
    dispatch(setLastSaveAfterFetch({ clickSave: false }));
  },
  onFetchLoadData: () =>
    batch(() => {
      dispatch(initLoad());
      dispatch(preFetchDropdown({ dropdownType: 'schedule' }));
      dispatch(preFetchDropdown({ dropdownType: 'episode' }));
    }),

  onSave: () => {
    dispatch(save());
  },
  onCalc: () => {
    dispatch(calculate());
    dispatch(hideModal({ dialog: 'ConfirmCalculate' }));
  },
  onWeekendingChange: week => {
    dispatch(storeWeekEnding({ weekEnding: week }));
    dispatch(clearFilter({ filterName: 'batches' }));
    dispatch(fetchBatches({ weekEnding: week }));
    dispatch(fetchStatuses());
    dispatch(fetchEpisodes());
    dispatch(fetchDepartments());
    dispatch(fetchAccountCodes());
    dispatch(fetchWeekdays());
    dispatch(fetchSets());
    dispatch(fetchEmployeeNames());
    dispatch(fetchSelectedCount({ week: week }));
    dispatch(fetchWECount({ weekEnding: week }));
    dispatch(resetFilterSelected());
  },
  showClearChangesModal: () => {
    dispatch(showModal({ dialog: 'ConfirmClear' }));
  },
  hideClearChangesModal: () => {
    dispatch(hideModal({ dialog: 'ConfirmClear' }));
  },
  showCalculateModal: () => {
    dispatch(showModal({ dialog: 'ConfirmCalculate' }));
  },
  onStoreSort: sortOrder => {
    dispatch(storeSortOrder({ sortOrder }));
  },
  onResetDirtyField: () => {
    dispatch(resetDirtyFields());
    dispatch(clearEdits());
    dispatch(clearEditedDays());
  },
  onEditTimeCard: (editedTCs, edits) => {
    dispatch(storeEditedDays({ editedDays: editedTCs }));
    dispatch(storeEdits({ edits: edits }));
  },
  onNavToWTCWithLocation: (
    timecardEntryHeaderId,
    batchWorksightId,
    location,
  ) => {
    const projectId = getProjectFromURL(location.pathname);
    dispatch(setFromURL({ fromURI: location.pathname }));

    const toURL = `/projects/${projectId}/review/ready/${batchWorksightId}/wtc?timecardHeaderId=${timecardEntryHeaderId}`;
    dispatch(push(toURL));
  },
  onShowDirtyAlert: prospectiveTimecard => {
    dispatch(setProspectiveTimecard({ prospectiveTimecard }));
    dispatch(showModal({ dialog: 'TakeMeBackBulkEdit', maxWidth: 'md' }));
  },
});

const BulkEdit = props => {
  const classes = useStyles();
  const {
    //data
    batches,
    currentProject,
    days = [],
    tcCount,
    reviewBatchId,
    weekEnding,
    weekEndings,
    settings,
    tcToBeCalculated,
    //functions
    dispatch,
    //dispatch functions
    showClearChangesModal,
    hideClearChangesModal,
    showCalculateModal,
    onFetch,
    onNavToWTCWithLocation,
    onFetchLoadData,
    onSave,
    onCalc,
    onWeekendingChange,
    onResetDirtyField,
    onShowDirtyAlert,
    //flags
    saving,
    calculating,
    isDirty,
    isCalculated,
    searching,
    initLoad,
    storedSortOrder,
    onStoreSort,
    onEditTimeCard,
  } = props;

  const history = useHistory();
  const location = useLocation();

  const onBackToList = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const onNavToWTC = React.useCallback(
    (timecardEntryHeaderId, batchWorksightId) => {
      onNavToWTCWithLocation(timecardEntryHeaderId, batchWorksightId, location);
    },
    [location, onNavToWTCWithLocation],
  );

  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current === false) {
      onFetchLoadData();
      didMountRef.current = true;
    }
  }, [onFetchLoadData]);

  const willUnmountRef = useRef(false);
  useEffect(() => {
    return () => {
      willUnmountRef.current = true;
    };
  }, []);

  useEffect(() => {
    return () => {
      if (willUnmountRef.current === true) {
        dispatch(setLastSaveAfterFetch({ clickSave: false }));
      }
    };
  }, [dispatch]);

  const [collapsed, setCollapsed] = useState(false);

  const accountMask = React.useMemo(
    () => currentProject.accountMask,
    [currentProject],
  );
  const [tcRows, setTcRows] = useState([]);
  const [allEditableRows, setEditableRows] = useState(0);
  const { Provider } = BulkEditContext;

  //for Canada region setting 'NDB In' to 'General Call'
  if (currentProject.region === 'Canada') {
    TableColumns.map(column => {
      if (column.accessor === 'ndbIn') {
        column.Header = 'General Call';
      }
      return column;
    });
  }
  const tableColumns = useMemo(
    () => processSettings(TableColumns, settings),
    [settings],
  );

  const editableTimefields = useMemo(() => {
    if (settings && settings.wtcEditable) {
      return Object.entries(settings.wtcEditable)
        .filter(field => field[1] === true)
        .map(field => field[0]);
    } else {
      return [];
    }
  }, [settings]);

  const clearChanges = React.useCallback(
    userClickedSave => {
      if (userClickedSave) {
        hideClearChangesModal();
        onFetch();
      } else {
        const { rows } = parseTimecards(days, accountMask, editableTimefields);
        setTcRows(rows);
        onResetDirtyField();
        hideClearChangesModal();
      }
    },
    [
      accountMask,
      days,
      editableTimefields,
      hideClearChangesModal,
      onFetch,
      onResetDirtyField,
    ],
  );
  useEffect(() => {
    const { rows, editableRowsCounts = 0 } = parseTimecards(
      days,
      accountMask,
      editableTimefields,
    );
    setTcRows(rows);
    setEditableRows(editableRowsCounts);
    onResetDirtyField();
  }, [accountMask, days, editableTimefields, onResetDirtyField]);

  const loadTimecard = React.useCallback(
    (timecardEntryHeaderId, batchWorksightId) => {
      if (isDirty) {
        onShowDirtyAlert({
          timecardEntryHeaderId,
          batchWorksightId,
          actionType: 'loadTimecard',
        });
      } else {
        onNavToWTC(timecardEntryHeaderId, batchWorksightId);
      }
    },
    [isDirty, onNavToWTC, onShowDirtyAlert],
  );

  const onBulkLeave = () => {
    if (isDirty) {
      onShowDirtyAlert({
        actionType: 'backToList',
      });
    } else {
      onBackToList();
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.sideBar}>
        <Sidebar
          onFetch={onFetch}
          saving={saving}
          searching={searching}
          isDirty={isDirty}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      </div>
      <div
        className={clsx({
          [classes.main]: !collapsed,
          [classes.mainCollapsedSideBar]: collapsed,
        })}
      >
        <Provider
          value={{
            accountMask,
            loadTimecard,
          }}
        >
          <Header
            //data
            batches={batches}
            reviewBatchId={reviewBatchId}
            weekEndings={weekEndings}
            weekEnding={weekEnding}
            tcCount={tcCount}
            //functions
            clearChanges={clearChanges}
            onBulkLeave={() => onBulkLeave()}
            onFetch={onFetch}
            onSave={onSave}
            onCalc={onCalc}
            onWeekendingChange={onWeekendingChange}
            showClearChangesModal={showClearChangesModal}
            showCalculateModal={showCalculateModal}
            //flags
            saving={saving}
            calculating={calculating}
            isDirty={isDirty}
            isCalculated={isCalculated}
            tcToBeCalculated={tcToBeCalculated}
          />

          {initLoad ? (
            <EmptyState />
          ) : searching ? (
            <LoadingSquare />
          ) : (
            <BulkEditTable
              allEditableRows={allEditableRows}
              tcRows={tcRows}
              tcCount={tcCount}
              currentProject={currentProject}
              saving={saving}
              editableTimefields={editableTimefields}
              tableColumns={tableColumns}
              storedSortOrder={storedSortOrder}
              onStoreSort={onStoreSort}
              onEditTimeCard={onEditTimeCard}
            />
          )}
          <ConfirmTakeMeBack
            hasSubmitAction
            onBackToList={onBackToList}
            onNavToWTC={onNavToWTC}
          />
        </Provider>
      </div>
    </div>
  );
};

BulkEdit.propTypes = {
  //data
  batches: PropTypes.array,
  currentProject: PropTypes.object,
  days: PropTypes.array,
  tcCount: PropTypes.number,
  reviewBatchId: PropTypes.string,
  weekEnding: PropTypes.string,
  weekEndings: PropTypes.array,
  settings: PropTypes.object,
  tcToBeCalculated: PropTypes.array,

  //functions
  dispatch: PropTypes.func.isRequired,
  //dispatch functions
  showClearChangesModal: PropTypes.func,
  hideClearChangesModal: PropTypes.func,
  showCalculateModal: PropTypes.func,
  onFetch: PropTypes.func,
  onNavToWTCWithLocation: PropTypes.func,
  onSave: PropTypes.func,
  onCalc: PropTypes.func,
  onWeekendingChange: PropTypes.func,
  onResetDirtyField: PropTypes.func,
  onShowDirtyAlert: PropTypes.func,
  //flags
  saving: PropTypes.bool,
  calculating: PropTypes.bool,
  isDirty: PropTypes.bool,
  isCalculated: PropTypes.bool,
  searching: PropTypes.bool,
  initLoad: PropTypes.bool,

  storedSortOrder: PropTypes.array,
  onStoreSort: PropTypes.func,
  onEditTimeCard: PropTypes.func,
  onFetchLoadData: PropTypes.func,
};

//lazy loaded in src\components\Routes\projectFeatureRoutes.js
export default compose(connect(mapState, mapDispatch))(BulkEdit);
