import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, MenuItem, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { getStatusBadge } from 'utils/helperFunctions';

const useStyles = makeStyles(({ palette }) => ({
  OtherTimecardItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
    gap: 16,
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  weekEnding: {
    fontSize: 16,
    fontWeight: 400,
    color: palette.text.secondary,
    maxWidth: 150,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  occupation: {
    fontSize: 12,
    fontWeight: 400,
    color: palette.text.secondary,
    maxWidth: 150,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const OtherTimecardItem = props => {
  const classes = useStyles();
  const { timecard, handleClick, isPA, isUPM, userLevel, openInWindow } = props;
  const { batchWorksightId, timecardEntryHeaderId } = timecard;

  const weekEndingDate = moment(timecard.weekEndingDate).format('MM-DD-YYYY');

  return (
    <MenuItem
      className={classes.OtherTimecardItem}
      onClick={() => handleClick(batchWorksightId, timecardEntryHeaderId)}
    >
      <Box className={classes.leftSide}>
        <Box className={classes.weekEnding}>{weekEndingDate}</Box>
        <Box className={classes.occupation}> {`${timecard?.occupation}`}</Box>
      </Box>
      <Box className={classes.rightSide}>
        {getStatusBadge(timecard.status, isPA, isUPM, userLevel)}
        <IconButton
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            openInWindow(batchWorksightId, timecardEntryHeaderId);
          }}
        >
          <OpenInNewIcon />
        </IconButton>
      </Box>
    </MenuItem>
  );
};

OtherTimecardItem.propTypes = {
  timecard: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  isPA: PropTypes.bool.isRequired,
  isUPM: PropTypes.bool.isRequired,
  userLevel: PropTypes.number.isRequired,
  openInWindow: PropTypes.func.isRequired,
};

export default OtherTimecardItem;
