import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, useParams } from 'react-router-dom';
import { buildUrl } from 'providers/api';
import { db } from 'utils/helperFunctions';

/**
 * Handle direct redirects to a URL.
 * Redirect must have same URL params as its parent path.
 * @param {string} redirect - URL to redirect to.
 * Anything that requires redux data is handled in the RedirectCheck component.
 */
const QuickRedirect = props => {
  const { redirect, path } = props;

  const params = useParams();

  const url = buildUrl(redirect, params);
  db(`QuickRedirect: from:${path} to: ${url}`);
  return <Redirect to={url} />;
};

QuickRedirect.propTypes = {
  redirect: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default QuickRedirect;
