import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles(theme => ({
  root: {
    padding: '0px 4px',
  },
}));

const Cell = props => {
  const classes = useStyles();
  const {
    cell,
    cell: {
      column: { sticky }, //no padding on sticky cells
    },
  } = props;

  const { key, ...cellProps } = cell.getCellProps();

  return (
    <td key={key} {...cellProps} className={sticky ? '' : classes.root}>
      {cell.render('Cell')}
    </td>
  );
};

Cell.propTypes = {
  cell: PropTypes.object.isRequired,
};

export default Cell;
