/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { change, FormName } from 'redux-form';
import makeStyles from '@mui/styles/makeStyles';
import { TextField } from 'components/Shared/redux';
import * as _isEmpty from 'lodash/isEmpty';
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Drawer,
  TableFooter,
  Button,
} from '@mui/material';
import BackIcon from '@mui/icons-material/KeyboardBackspace';

const useStyles = makeStyles(({ palette }) => ({
  EmployeeSignature: {
    backgroundColor: palette.gray.background,
    margin: 25,
    marginBottom: 20,
    padding: 20,
  },
  noBorder: {
    border: 0,
  },
  grow: {
    flexGrow: 1,
  },
  actionColumn: {
    display: 'flex',
    paddingRight: 20,
    paddingLeft: 20,
  },
  iconButton: {
    paddingRight: 10,
  },
  hidePlaceHolder: {
    color: 'transparent',
  },
}));

const EmployeeSignature = ({
  approvalFlowLoading,
  savingTimecard,
  isReadyForReview,
  onApproveTimecard,
  onHideSignature,
  open,
  timecard,
  dispatch,
  comments,
  isTimecardViewOnly,
  project,
}) => {
  const classes = useStyles();
  let fullName = null;
  if (!_isEmpty(timecard.user)) {
    fullName = timecard.user?.firstName + ' ' + timecard.user?.lastName;
  } else if (!_isEmpty(timecard.updatedBy)) {
    fullName =
      timecard.updatedBy?.firstName + ' ' + timecard.updatedBy?.lastName;
  }
  const [disableAction, setDisabledAction] = useState(true);
  const [showError, setShowError] = useState(false);
  const [formName, setFormName] = useState(null);
  const [showErrorTimer, setShowErrorTimerReal] = useState(null);
  //Always clear out prev timer when setting new one.
  const setShowErrorTimer = newTimer => {
    setShowErrorTimerReal(newTimer);
    clearTimeout(showErrorTimer);
  };

  const onSigChange = e => {
    const typedName = e.target.value;
    setShowError(false);

    const isTypedEqName =
      fullName.toLowerCase().trim() === typedName.toLowerCase().trim();

    if (formName && isTypedEqName) {
      setDisabledAction(false);
      dispatch(change(formName, 'eSignature', typedName));
    } else if (formName && disableAction === false) {
      setDisabledAction(true);
      dispatch(change(formName, 'eSignature', null));
    }

    const timer = setTimeout(() => {
      setShowError(!isTypedEqName);
    }, 1000); //delay to show error message

    setShowErrorTimer(timer);
  };

  const onBlur = e => {
    const typedName = e.target.value;

    if (fullName.toLowerCase().trim() !== typedName.toLowerCase().trim()) {
      setShowError(true);
    }
  };

  const onEnterToSubmit = e => {
    if (e.key === 'Enter' && !disableAction) {
      onApprove();
    }
  };

  const onApprove = () => {
    setDisabledAction(true);
    onApproveTimecard(
      timecard.eSignature,
      true,
      isReadyForReview,
      isTimecardViewOnly,
      comments,
      timecard.entryHeaderId,
    );
  };

  const getFormName = ({ form }) => {
    setFormName(form);
    return null;
  };
  const laborSpellCheck = project.region === 'Canada' ? 'labour' : 'labor';

  return (
    <Drawer anchor="bottom" open={open}>
      <Table>
        <TableBody className={classes.EmployeeSignature}>
          <TableRow>
            <TableCell className={classes.noBorder}>
              <Typography variant="body1">
                {`By signing this form, you certify that you have reviewed the 
                  information on this record and it accurately reflects all of your 
                  start and stop times of work, including meal breaks, in this period.
                  If no hours are indicated, time will be computed based on
                  specific union or ${laborSpellCheck} law minimums.
                  You also agree that the employer may take deductions
                  from your earnings to adjust previous overpayments 
                  if and when said overpayments occur. `}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.noBorder}>
              <FormName children={getFormName} />
              <TextField
                onChange={onSigChange}
                onKeyDown={onEnterToSubmit}
                onBlur={onBlur}
                inputProps={{
                  autoComplete: 'off',
                  autoFocus: true,
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.noBorder}>
              {showError ? (
                <Typography color={'error'}>
                  {
                    'Signature does not match user name, please sign your full name'
                  }
                </Typography>
              ) : (
                //placeholder so things don't jump when the error shows.
                <Typography className={classes.hidePlaceHolder}>.</Typography>
              )}

              <Typography>{fullName} *</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell className={classes.actionColumn}>
              <Button
                variant="outlined"
                className={classes.buttons}
                onClick={() => {
                  onHideSignature();
                  setShowError(false);
                }}
              >
                <BackIcon className={classes.iconButton} />
                {'Back'}
              </Button>
              <div className={classes.grow} />
              <Button
                variant="contained"
                onClick={onApprove}
                disabled={disableAction}
              >
                {'Approve'}
              </Button>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Drawer>
  );
};

EmployeeSignature.propTypes = {
  // Commenting these out until I can hide emp sig when its not needed
  // isReadyForReview: PropTypes.bool.isRequired,
  // onApproveTimecard: PropTypes.func.isRequired,
  // onHideSignature: PropTypes.func.isRequired,
  comments: PropTypes.object,
  // open: PropTypes.bool.isRequired,
  timecard: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(EmployeeSignature);
