import { produce } from 'immer';

import * as actions from 'actions/users';

const initialState = {
  loading: false,
  list: [],
  usersAllProjectsList: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.loading}`: {
        draft.loading = action.loading;
        break;
      }

      case `${actions.store}`: {
        draft.list = action.users;
        break;
      }

      case `${actions.storeUsersAllProjects}`: {
        draft.usersAllProjectsList = action.allUsers;
        break;
      }

      case `${actions.reset}`: {
        if (Object.keys(draft).length !== Object.keys(initialState).length) {
          console.warn(
            'Length of draft and initialState are not equal\n',
            'draft',
            Object.keys(draft),
            '\ninitialState',
            Object.keys(initialState),
          );
        }
        for (const key in initialState) {
          if (Object.hasOwnProperty.call(initialState, key)) {
            draft[key] = initialState[key];
          }
        }
        break;
      }

      default:
    }
  });
