import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { MyCnCHeader } from '@castandcrew/global-navbar';
import apiServer from 'constants/config/apiServer';
import { logout } from 'actions/authenticate';
import { getHideGlobalNav } from 'selectors/appReady';

const mapState = state => ({
  hideGlobalNav: getHideGlobalNav(state),
});

const mapDispatch = dispatch => ({
  onLogout: () => {
    dispatch(logout());
  },
});

const HeaderWrapper = props => {
  const { hideGlobalNav, onLogout } = props;

  if (hideGlobalNav) return null;

  return (
    <MyCnCHeader onLogout={onLogout} configs={apiServer.dashboardConfig} />
  );
};

HeaderWrapper.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default compose(connect(mapState, mapDispatch))(HeaderWrapper);
