import { produce } from 'immer';
import * as actions from 'actions/profile';

export const initialState = {
  preference: {
    notifications: {
      list: [],
    },
    selectedAll: '',
  },
  notification: { status: '' },
  notificationsLoading: false,
  phoneNumber: {},
  phoneNumberLoading: false,
  allowanceTypes: [],
  downloadingSupportingDocument: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      //notifications
      case `${actions.loadingNotifications}`: {
        draft.notificationsLoading = action.loadNotifications;
        break;
      }

      case `${actions.storeNotifications}`: {
        draft.preference.notifications.list = action.notifications;
        draft.preference.notifications.selectedAll = action.selectedAll;
        break;
      }

      case `${actions.editPreferences}`: {
        if (action.name === 'selectedAll') {
          draft.preference.notifications.selectedAll = action.value;
          draft.preference.notifications.list.map(
            item => (item.enabled = action.value),
          );
        }

        if (action.name === 'selectedSingle') {
          draft.preference.notifications.list.find(
            item => item.description === action.id,
          ).enabled = action.value;

          const enabled = draft.preference.notifications.list.filter(
            item => item.enabled,
          );

          if (enabled.length === 0) {
            draft.preference.notifications.selectedAll = false;
          }

          if (enabled.length === draft.preference.notifications.list.length) {
            draft.preference.notifications.selectedAll = true;
          } else {
            draft.preference.notifications.selectedAll = false;
          }
        }
        break;
      }

      case `${actions.setUpdateStatus}`: {
        draft.notification.status = action.status;
        break;
      }

      //phone number
      case `${actions.loadingPhoneNumber}`: {
        draft.phoneNumberLoading = action.loadPhoneNumber;
        break;
      }

      case `${actions.storePhoneNumber}`: {
        draft.phoneNumber = action.phoneNumber;
        break;
      }

      case `${actions.downloadingSupportingDocument}`: {
        draft.downloadingSupportingDocument = action.loading;
        break;
      }

      default:
    }
  });
