import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  MenuItem,
  Menu,
  Skeleton,
  Box,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OtherTimecardItem from './OtherTimecardItem';

const useStyles = makeStyles(({ palette }) => ({
  menu: {
    maxHeight: 400,
  },
  weekEndingText: {
    whiteSpace: 'nowrap',
  },
}));

const OtherTimecards = props => {
  const classes = useStyles();
  const {
    otherTimecards,
    onFetchOtherTimecards,
    loading,
    isPA,
    isUPM,
    userLevel,
    projectId,
    onDirtyFlagCheck,
    onShowAlert,
    weekEnding,
  } = props;

  const weekEndRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [anchorOrigin, setAnchorOrigin] = React.useState({ x: 0, y: 0 });
  const handleOpen = () => {
    const rect = weekEndRef.current.getBoundingClientRect();
    setAnchorOrigin({ x: rect.x, y: rect.y + rect.height });
    setOpen(true);
    onFetchOtherTimecards();
  };

  const handleClick = (batchWorksightId, timecardEntryHeaderId) => {
    onDirtyFlagCheck({
      actionType: 'navToBatchWTC',
      batchWorksightId,
      timecardEntryHeaderId,
    });
  };

  const childRef = React.useRef(null);
  const openInWindow = React.useCallback(
    (batchWorksightId, timecardEntryHeaderId) => {
      const options = 'height=800,width=1200,';
      childRef.current = window.open(
        `/projects/${projectId}/popOutWTC/${batchWorksightId}?timecardHeaderId=${timecardEntryHeaderId}`,
        '_blank',
        options,
      );
      if (
        !childRef.current ||
        childRef.current.closed ||
        typeof childRef.current.closed == 'undefined'
      ) {
        onShowAlert({
          message:
            'The pop-up window cannot be opened. Check your browser settings.',
        });
      } else {
        setOpen(false);
      }
    },
    [onShowAlert, projectId],
  );

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography className={classes.weekEndingText} ref={weekEndRef}>
        Week Ending: <span>{weekEnding}</span>
      </Typography>

      <IconButton onClick={handleOpen}>
        <ExpandMoreIcon />
      </IconButton>
      <Menu
        className={classes.menu}
        open={open}
        onClose={() => setOpen(false)}
        anchorPosition={{ top: anchorOrigin.y, left: anchorOrigin.x + 94 }}
        anchorReference="anchorPosition"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {loading ? (
          <MenuItem
            sx={{ display: 'flex', justifyContent: 'space-between', gap: 3 }}
          >
            <Box>
              <Skeleton variant="text" width={80} height={25} />
              <Skeleton variant="text" width={150} height={20} />
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Skeleton variant="text" width={80} height={40} />
              <Skeleton variant="text" width={25} height={40} />
            </Box>
          </MenuItem>
        ) : otherTimecards.length === 0 ? (
          <MenuItem
            sx={{
              display: 'flex',
              height: 60,
              width: 327,
              justifyContent: 'center',
            }}
            disabled
          >
            No other timecards
          </MenuItem>
        ) : (
          otherTimecards.map(timecard => (
            <OtherTimecardItem
              key={timecard.timecardEntryHeaderId}
              timecard={timecard}
              isPA={isPA}
              isUPM={isUPM}
              userLevel={userLevel}
              handleClick={handleClick}
              openInWindow={openInWindow}
            />
          ))
        )}
      </Menu>
    </Box>
  );
};

OtherTimecards.propTypes = {
  otherTimecards: PropTypes.array.isRequired,
  onFetchOtherTimecards: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isPA: PropTypes.bool.isRequired,
  isUPM: PropTypes.bool.isRequired,
  userLevel: PropTypes.number.isRequired,
  projectId: PropTypes.string.isRequired,
  onDirtyFlagCheck: PropTypes.func.isRequired,
  onShowAlert: PropTypes.func.isRequired,
  weekEnding: PropTypes.string.isRequired,
};

export default OtherTimecards;
