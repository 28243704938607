import React from 'react';

import { Header, ProjectHeader, MaxWidthLayout } from 'containers/Layout';

import projectInvoiceRoutes from './projectInvoiceRoutes';
import projectWtcRoutes from './projectWtcRoutes';

//components
import SearchTimecards from 'containers/Employees/Reviews/SearchTimecards/SearchTimecards';
import DTS from 'feature/DTS/DTS';

const BulkEdit = React.lazy(() =>
  import('containers/Employees/Reviews/BulkEdit/BulkEdit'),
);
const projectFeatureRoutes = [
  {
    title: 'Daily Timesheets',
    path: '/projects/:projectId/daily-timesheets',
    headers: [Header, ProjectHeader],
    layout: MaxWidthLayout,
    component: DTS,
    waitForUserToLoad: true,
  },
  {
    title: 'Search Timecards',
    path: '/projects/:projectId/review/search-timecards',
    headers: [Header, ProjectHeader],
    layout: MaxWidthLayout,
    component: SearchTimecards,
    waitForUserToLoad: true,
  },
  {
    title: 'Bulk Edit',
    path: '/projects/:projectId/review/bulk-edit',
    headers: [Header, ProjectHeader],
    layout: MaxWidthLayout,
    component: BulkEdit,
    waitForUserToLoad: true,
  },
  ...projectInvoiceRoutes,
  ...projectWtcRoutes,
];

export default projectFeatureRoutes;
