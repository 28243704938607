import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTable, useSortBy } from 'react-table';
import { useSticky } from 'react-table-sticky';
import clsx from 'clsx';

import withStyles from '@mui/styles/withStyles';

import { useDispatch, batch } from 'react-redux';

import BulkEditContext, { EditableRowsContext } from './BulkEditContext';
import ErrorsBar from './ErrorsBar';
import HeaderCell from './HeaderCell';
import CellData from './CellData';
import BulkProgress from './BulkProgress';

import {
  BULK_EDIT_SORT_ORDER,
  // BULK_EDIT_MAX_RESULTS, add back once we make it configurable
} from 'components/Shared/constants';
import {
  calculateAllRows,
  calculateSingleRow,
  normalizeValue,
  rowHasNoErrorsToShow,
} from 'utils/bulkEditUtils';
import { handleSortBy, handleSort } from 'utils/reactTableUtils';

import { copyToAllDirtyFields } from 'actions/bulkEdit';
import EmptyState from '../Shared/EmptyState';

const style = ({ palette }) => ({
  root: {},
  container: {
    fontSize: 12,
    margin: '0 0 10px 10px',
    position: 'relative',
    height: 'calc(100vh - 275px)',
  },
  // for react table
  tableContainer: {
    overflow: 'auto',
    maxHeight: '94%',
  },
  table: {
    position: 'relative',
    borderCollapse: 'collapse',
    '& > th, td': {
      height: '20px',
    },
  },

  tableHeaderRow: {
    '& :nth-child(4)': {
      boxShadow: '5px 0 5px -5px #303030',
    },
    '& :nth-child(n+5)': {
      position: 'sticky',
      top: 0,
      zIndex: 1,
    },
    whiteSpace: 'nowrap',
    backgroundColor: palette.misc.tableHeader,
    height: '20px',
  },
  tableHeaderCell: {
    top: 0,
    backgroundColor: palette.misc.tableHeader,
    height: '24px',
    fontWeight: '500',
    color: palette.text.primary,
    padding: 0,
    maxWidth: 300,
  },
  tableBody: {
    zIndex: 0,
    position: 'relative',
    '& > tr > td:nth-child(n+5)': {
      zIndex: 0,
    },
    '& > tr > td:nth-child(4)': {
      boxShadow: '5px 0 5px -5px #303030',
    },
    '& > tr:nth-child(odd)': {
      backgroundColor: palette.primary.table,
    },
    '& > tr:nth-child(odd) > td': {
      backgroundColor: palette.primary.table,
    },
    '& > tr:nth-child(even)': {
      backgroundColor: palette.background.paper,
    },
    '& > tr:nth-child(even) > td': {
      backgroundColor: palette.background.paper,
    },
  },
  tableBodyRoll: {
    position: 'relative',
    whiteSpace: 'nowrap',
  },
});

const BulkEditTable = props => {
  const {
    classes,
    tcRows = [],
    tcCount,
    allEditableRows = 0,
    onStoreSort,
    storedSortOrder,
    onEditTimeCard,
    currentProject,
    saving,
    editableTimefields,
    tableColumns,
  } = props;

  const rowCount = tcRows.length;
  const dispatch = useDispatch();
  const { Provider: EditableContextProvider } = EditableRowsContext;
  const { accountMask } = useContext(BulkEditContext);
  const [showErrors, setShowErrors] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editedTCs, setEditedTCs] = useState([]);
  const [edits, setEdits] = useState({});

  const overflowContainerRef = useRef(null);

  useEffect(() => {
    setTableData(tcRows);
    setShowErrors(false);

    setEditedTCs([]);
    setEdits({});
  }, [tcRows]);

  const DEFAULT_SORT = useMemo(() => BULK_EDIT_SORT_ORDER, []);

  const editableCounts = useMemo(() => {
    if (showErrors) {
      return tableData.filter(
        row =>
          (row.errors && row.errors.length > 0) ||
          (row.validationErrors && row.validationErrors.length > 0),
      ).length;
    }
    return allEditableRows;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, showErrors]);

  const totalErrorsCount = useMemo(() => {
    return tableData
      .filter(row => row.status === 'Ready for me')
      .reduce((counter, currentRow) => {
        const totalErrorsCount =
          counter +
          ((currentRow.errors || 0) && currentRow.errors.length) +
          ((currentRow.validationErrors || 0) &&
            currentRow.validationErrors.length);
        return totalErrorsCount;
      }, 0);
  }, [tableData]);
  useEffect(() => {
    if (totalErrorsCount === 0) setShowErrors(false);
  }, [totalErrorsCount]);

  useEffect(() => {
    //if there's a sort order stored, set it 1x
    if (storedSortOrder.length > BULK_EDIT_SORT_ORDER.length) {
      setSortReactTable(storedSortOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //store the edited timecards to the array
    onEditTimeCard(editedTCs, edits);
    //save changes to redux
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edits]);

  const updateMyData = (rowIndex, columnId, value, timecardId, tcDayIndex) => {
    setEditedTCs(oldEditedTCs => {
      if (
        oldEditedTCs.indexOf(timecardId) === -1 &&
        oldEditedTCs.indexOf('all') === -1
      ) {
        return [...oldEditedTCs, timecardId];
      } else {
        return [...oldEditedTCs];
      }
    });
    setEdits(currEdits => {
      const oldEdits = _.cloneDeep(currEdits);
      if (oldEdits[timecardId] === undefined) {
        oldEdits[timecardId] = {};
      }
      if (oldEdits[timecardId][tcDayIndex] === undefined) {
        oldEdits[timecardId][tcDayIndex] = {};
      }
      if (columnId === 'episode') {
        oldEdits[timecardId][tcDayIndex]['series'] = value.series;
        oldEdits[timecardId][tcDayIndex]['location'] = value.location;
      }
      oldEdits[timecardId][tcDayIndex][columnId] = value;
      return oldEdits;
    });
    setTableData(oldRows => {
      return calculateSingleRow(oldRows, {
        rowIndex,
        columnId,
        value,
        editableTimefields,
      });
    });
    delete window.TimeoutId;
  };

  const copyToAll = (columnId, value, showErrors) => {
    let normalized = normalizeValue(columnId, value, accountMask);

    setEditedTCs(['all']);
    setEdits(edits => {
      const oldEdits = _.cloneDeep(edits);
      if (oldEdits['all'] === undefined) {
        oldEdits['all'] = {};
      }
      oldEdits['all'][columnId] = normalized;
      if (columnId === 'episode') {
        oldEdits['all']['series'] = normalized.series;
        oldEdits['all']['location'] = normalized.location;
      }
      return oldEdits;
    });

    batch(() => {
      if (columnId === 'episode') {
        dispatch(
          copyToAllDirtyFields({
            columnId: 'series',
            value: normalized.series,
            showErrors,
            tableData,
          }),
        );
        dispatch(
          copyToAllDirtyFields({
            columnId: 'location',
            value: normalized.location,
            showErrors,
            tableData,
          }),
        );
      }
      dispatch(
        copyToAllDirtyFields({
          columnId,
          value: normalized,
          showErrors,
          tableData,
        }),
      );
    });
    setTableData(oldRows => {
      return calculateAllRows(
        oldRows,
        columnId,
        normalized,
        showErrors,
        editableTimefields,
      );
    });
    delete window.TimeoutId;
  };
  const reactTable = useTable(
    {
      columns: tableColumns,
      data: tableData,
      updateMyData,
      // copyToAll,
      initialState: {
        sortBy: DEFAULT_SORT,
        hiddenColumns: ['validationErrors'],
      },
      autoResetSortBy: false, //otherwise table re-sorts on data change
      disableMultiSort: false,
      // autoResetPage: !skipPageReset,
      manualSortBy: true,
    },
    useSortBy,
    useSticky,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    setSortBy: setSortReactTable,
    state: { sortBy },
  } = reactTable;

  //wrapper for reactTable setSortBy to store sort order in redux
  const setSortBy = newSort => {
    setSortReactTable(newSort);
    onStoreSort(newSort);
  };

  useEffect(() => {
    if (tableData.length > 0) {
      let sortBy = storedSortOrder;
      setTimeout(() => {
        setTableData(oldRows =>
          handleSort({ data: oldRows, sortBy, tableColumns }),
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  return rowCount > 0 ? (
    <div className={classes.container}>
      <BulkProgress saving={saving} />

      <EditableContextProvider
        value={{
          editableCounts: editableCounts,
          showErrors: showErrors,
          copyToAll: copyToAll,
          containerRef: overflowContainerRef,
        }}
      >
        <ErrorsBar
          showErrors={showErrors}
          setShowErrors={setShowErrors}
          totalErrorsCount={totalErrorsCount}
        />
        <div
          className={clsx({
            [classes.tableContainer]: true,
          })}
          id="BulkEditTableContainer"
          ref={overflowContainerRef}
        >
          <table
            {...getTableProps()}
            className={clsx({
              [classes.table]: true,
            })}
            id={'BulkEditTable'}
          >
            <thead>
              {headerGroups.map(headerGroup => {
                const { key, ...headerGroupProps } =
                  headerGroup.getHeaderGroupProps();
                return (
                  <tr
                    key={key}
                    {...headerGroupProps}
                    className={classes.tableHeaderRow}
                  >
                    {headerGroup.headers.map(column => {
                      if (
                        currentProject.region === 'Canada' &&
                        (column.id === 'locationType' ||
                          column.id === 'schedule')
                      ) {
                        return null;
                      }
                      const { key, ...headerProps } = column.getHeaderProps();
                      return (
                        <th
                          key={key}
                          {...headerProps}
                          onClick={e => {
                            handleSortBy(column, setSortBy, DEFAULT_SORT);
                          }}
                          className={classes.tableHeaderCell}
                        >
                          {column.render(
                            props => (
                              <HeaderCell
                                {...props}
                                currentProject={currentProject}
                              />
                            ),
                            {
                              myProps: 'test header props',
                            },
                          )}
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
            <tbody
              {...getTableBodyProps()}
              className={clsx({
                [classes.tableBody]: true,
              })}
            >
              {rows.map((row, rowIndex) => {
                prepareRow(row);
                if (showErrors && rowHasNoErrorsToShow(row)) {
                  return null;
                }
                const { key, ...rowProps } = row.getRowProps();
                return (
                  <tr
                    key={key}
                    {...rowProps}
                    className={clsx({
                      [classes.tableBodyRoll]: true,
                    })}
                  >
                    {row.cells.map((cell, i) => {
                      if (
                        currentProject.region === 'Canada' &&
                        (cell.column.id === 'locationType' ||
                          cell.column.id === 'schedule')
                      ) {
                        return null;
                      }
                      return (
                        <CellData
                          key={cell.column.id}
                          cell={cell}
                          cellIndex={i}
                          rowIndex={rowIndex}
                          currentProject={currentProject}
                        />
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </EditableContextProvider>
    </div>
  ) : tcCount > 100 ? (
    <EmptyState variant="maxResults" />
  ) : (
    <EmptyState variant="noResults" />
  );
};

BulkEditTable.propTypes = {
  tcRows: PropTypes.array.isRequired,
  tcCount: PropTypes.number.isRequired,
  allEditableRows: PropTypes.number.isRequired,
  onStoreSort: PropTypes.func.isRequired,
  storedSortOrder: PropTypes.array.isRequired,
  onEditTimeCard: PropTypes.func.isRequired,
  currentProject: PropTypes.object.isRequired,
  saving: PropTypes.bool.isRequired,
  editableTimefields: PropTypes.array.isRequired,
  tableColumns: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(BulkEditTable);
