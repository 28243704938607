import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { Box, List } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

//selectors
import { currentUser, token, getSessionUser } from 'selectors/session';
import { getProjectDetails, getIsProjectArchived } from 'selectors/project';

import MobileMenuTitle from 'containers/Layout/Headers/components/MobileMenuTitle';
import MobileMenuLink from 'containers/Layout/Headers/components/MobileMenuLink';
import MobileMenuYearPicker from 'containers/Layout/Headers/components/MobileMenuYearPicker';

import { getNavItems } from 'utils/helperFunctions';

const useStyles = makeStyles(({ palette }) => ({
  ProjectMobileV2: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}));

const mapState = state => ({
  isLoggedIn: !!token(state),
  currentUser: currentUser(state),
  project: getProjectDetails(state),
  projectsUser: getSessionUser(state),
  isProjectArchived: getIsProjectArchived(state),
});

const mapDispatch = dispatch => ({});

const ProjectMobileV2 = props => {
  const classes = useStyles();
  const { currentUser, project, isLoggedIn, isProjectArchived } = props;

  const name = project?.name;

  const updatedProject = { ...project, isProjectArchived };
  const navItemsAll = getNavItems({
    currentUser,
    project: updatedProject,
    isLoggedIn,
  });

  return (
    <Box className={classes.ProjectMobileV2}>
      <Box sx={{ flex: 0 }}>
        <MobileMenuTitle text={name} textLength={24} />
        <MobileMenuYearPicker />
      </Box>
      <List sx={{ flex: '1 1 auto', overflow: 'auto', p: '0px 0px 0px 8px' }}>
        {navItemsAll.map(n => (
          <MobileMenuLink key={n.label} text={n.label} to={n.to} />
        ))}
      </List>
    </Box>
  );
};

ProjectMobileV2.propTypes = {
  currentUser: PropTypes.object,
  project: PropTypes.object,
  isLoggedIn: PropTypes.bool,
};

export default compose(connect(mapState, mapDispatch))(ProjectMobileV2);
