import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles(({ palette }) => ({
  MobileMenuLink: {
    width: '100%',
  },
  text: {
    width: 180,
    color: palette.text.primary,

    '& > .MuiTypography-root': {
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  currentLink: {
    backgroundColor: palette.primary['+9'],
  },
  icon: {
    minWidth: 0,
    marginRight: 8,
  },
}));

const MobileMenuLink = props => {
  const classes = useStyles();
  const { icon, text, to } = props;

  const { url } = useRouteMatch();

  return (
    <Link to={to}>
      <ListItem
        className={clsx(classes.MobileMenuLink, {
          [classes.currentLink]: url === to,
        })}
        key={text}
        disablePadding
      >
        <ListItemButton>
          {icon && <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>}
          <ListItemText className={classes.text} primary={text} />
        </ListItemButton>
      </ListItem>
    </Link>
  );
};

MobileMenuLink.propTypes = {
  icon: PropTypes.element,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default MobileMenuLink;
