import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { LinearProgress, TextField } from '@mui/material';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';
import { TIMECARD_TYPES_OPTIONS } from 'components/Shared/constants';
import { AutoCompletePlain as AutoComplete } from 'components/Shared/AutoComplete';

// actions
import { submitTeamTimecard, submitToEmployees } from 'actions/timecards';
import { submitDraftTimecards } from 'actions/searchTimecards';
import { hide as hideMe } from 'actions/modalDialog';

//Selectors
import { getSubmitting } from 'selectors/searchTimecards';

const formName = 'emergencyTimecard';

const mapState = state => ({
  isSubmitting: getSubmitting(state),
});

const onSubmit = (
  values,
  dispatch,
  weekEnding,
  id,
  departmentId,
  isTeamTimecard,
  searchTCEmergency,
  selectedRowIds,
) => {
  if (searchTCEmergency) {
    dispatch(
      submitDraftTimecards({
        timecardIds: selectedRowIds,
        emergencyType: values.timecardType.value,
        notes: values.notes,
      }),
    );
  } else if (!isTeamTimecard) {
    dispatch(
      submitToEmployees({
        timecardId: id,
        emergencyType: values.timecardType.value,
        notes: values.notes,
      }),
    );
    dispatch(hideMe({ dialog: formName }));
  } else {
    dispatch(
      submitTeamTimecard({
        weekEnding: weekEnding,
        departmentId: departmentId,
        id: id,
        emergencyType: values.timecardType.value,
        notes: values.notes,
      }),
    );
    dispatch(hideMe({ dialog: formName }));
  }
};

const validateEmergencyTimecardSubmit = values => {
  const errors = {};

  if (!values.notes) errors.notes = 'Required';

  if (!values.timecardType) errors.timecardType = 'Required';

  return errors;
};

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => {
  return (
    <TextField
      helperText={touched && error}
      error={touched && !!error}
      {...input}
      {...custom}
    />
  );
};

const renderAutoComplete = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => {
  return (
    <AutoComplete
      helperText={touched && error}
      error={touched && !!error}
      {...input}
      {...custom}
    />
  );
};

function EmergencyTimecard({
  weekEnding,
  id,
  departmentId,
  onSubmit,
  pristine,
  reset,
  isSubmitting,
  handleSubmit,
  searchTCEmergency,
  selectedRowIds,

  ...others
}) {
  const content = (
    <div>
      <Field
        options={TIMECARD_TYPES_OPTIONS}
        placeholder={'Timecard Type'}
        name="timecardType"
        component={renderAutoComplete}
      />
      <Field
        name="notes"
        placeholder="Notes"
        component={renderTextField}
        fullWidth
        style={{ marginTop: 20, marginBottom: 20 }}
      />
      {isSubmitting && <LinearProgress />}
    </div>
  );

  return (
    <form
      onSubmit={handleSubmit((values, dispatch) =>
        onSubmit(
          values,
          dispatch,
          weekEnding,
          id,
          departmentId,
          others.isTeamTimecard,
          searchTCEmergency,
          selectedRowIds,
        ),
      )}
    >
      <ConfirmModal
        title={'Submitting as Emergency Timecard'}
        content={content}
        hasSubmitAction={false}
        buttonText="Submit"
        submitDisabled={pristine || isSubmitting}
        {...others}
      />
    </form>
  );
}

export default compose(
  withModalDialog({ dialog: formName, maxWidth: 'md' }), //emergencyTimecard
  connect(mapState, null),
  reduxForm({
    form: formName,
    onSubmit,
    validate: validateEmergencyTimecardSubmit,
    touchOnChange: true,
  }),
)(EmergencyTimecard);
