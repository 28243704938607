import React, { useState, useEffect, useCallback } from 'react';
import { Button, Tooltip, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import pluralize from 'pluralize';

import { anyInvalidTimeEntry } from 'utils/reactTableUtils';
import {
  MAX_TIMECARD_DELETE_ST,
  MAX_TIMECARD_SUBMIT_COUNT,
  MAX_TIMECARD_OPEN_COUNT,
} from 'feature/DTS/dtsUtils';
import { DH, UPM, PA } from 'components/props/profiles';

import ApproveRejectButtons from './ApproveRejectButtons';
import AdditionalFields from 'containers/Employees/Reviews/SearchTimecards/AdditionalFieldsContainer';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    // flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
  additionalFields: {
    margin: '0px 50px 20px 0px',
  },
}));

const Header = props => {
  const classes = useStyles();
  const {
    selectedRows,
    rows,
    onShowSubmitModal,
    permissions,
    setHiddenColumns,
    hiddenColumns,
    filteredColumns,
    onSubmitEmergencyTimecards,
    onShowDeleteModal,
    currentUserRole,
    bulkTimecardSubmission,
    submitting,
    showCount,
    onOpenTimecards,
  } = props;

  const [showSubmitBtn, setShowSubmitBtn] = useState(false);
  const [showEmergencySubmitBtn, setShowEmergencySubmitBtn] = useState(false);
  const [anyTimecardSelected, setAnyTimecardSelected] = useState(false);
  const showOpenTimecardsBtn =
    currentUserRole === PA || currentUserRole === UPM;

  const [openSubmitted, setOpenSubmitted] = useState(false);

  const disableOpenTimecards =
    openSubmitted ||
    (!anyTimecardSelected && showCount > MAX_TIMECARD_OPEN_COUNT) ||
    selectedRows.length > MAX_TIMECARD_OPEN_COUNT;

  const canEmergencySubmit = useCallback(
    rows => {
      return rows.every(row => {
        const currDept =
          permissions &&
          permissions.departments &&
          permissions.departments[parseInt(row.original.departmentId, 10)];
        if (currDept) {
          return currDept.canSubmitEmergencyTimecard;
        } else {
          return false;
        }
      });
    },
    [permissions],
  );

  useEffect(() => {
    if (selectedRows.length > 0) {
      setAnyTimecardSelected(true);
    } else {
      setAnyTimecardSelected(false);
    }
  }, [selectedRows]);

  useEffect(() => {
    const draftsSelected = selectedRows.filter(
      row => row.values.status === 'Draft',
    );

    const nonDraftSelected = selectedRows.length - draftsSelected.length;

    let showBtns = false;

    //show submit if only Draft timecards are selected
    if (
      draftsSelected.length > 0 &&
      nonDraftSelected === 0 &&
      (currentUserRole === PA || currentUserRole === DH)
    ) {
      showBtns = true;
    }

    setShowSubmitBtn(showBtns);

    const showEmergency = showBtns ? canEmergencySubmit(draftsSelected) : false;
    setShowEmergencySubmitBtn(showEmergency);
  }, [canEmergencySubmit, currentUserRole, selectedRows]);

  const isInvalidTimeSelected = selectedRows.reduce((val, curr) => {
    const currentInvalid = anyInvalidTimeEntry(curr.original.workedDaysDetails);
    return val || currentInvalid;
  }, false);

  const emergencyClick = isInvalidTimeSelected
    ? () => onShowSubmitModal({ selectedRows, variant: 'submitEmergency' })
    : onSubmitEmergencyTimecards;

  const isOverDeleteLimit = selectedRows.length > MAX_TIMECARD_DELETE_ST;
  const deleteTitle = isOverDeleteLimit
    ? `Maximum of ${MAX_TIMECARD_DELETE_ST} timecards can be deleted at one time.`
    : '';

  const isOverBulkLimit = selectedRows.length > MAX_TIMECARD_SUBMIT_COUNT;
  const submitTitle = isOverBulkLimit
    ? `Maximum of ${MAX_TIMECARD_SUBMIT_COUNT} timecards can be submitted at one time.`
    : '';

  const openTitle =
    disableOpenTimecards && !openSubmitted
      ? `Maximum of ${MAX_TIMECARD_OPEN_COUNT} timecards can be opened at one time.`
      : '';

  return (
    <header className={classes.root}>
      <Box sx={{ display: 'flex' }}>
        {showOpenTimecardsBtn && (
          <Tooltip title={openTitle}>
            <div>
              <Button
                disabled={disableOpenTimecards}
                onClick={() => {
                  if (selectedRows.length > 0) {
                    onOpenTimecards(selectedRows);
                  } else {
                    onOpenTimecards(rows);
                  }
                  setOpenSubmitted(true);
                }}
              >
                {anyTimecardSelected
                  ? 'Open Selected Timecards'
                  : ' Open All Timecards'}
              </Button>
            </div>
          </Tooltip>
        )}
        {showSubmitBtn && (
          <Tooltip arrow title={submitTitle}>
            <span>
              <Button
                color="primary"
                variant="contained"
                disabled={isOverBulkLimit || submitting}
                onClick={() => {
                  onShowSubmitModal({
                    selectedRows,
                    variant: 'addComment',
                  });
                }}
              >
                Submit to {pluralize('employee', selectedRows.length, false)}
              </Button>
            </span>
          </Tooltip>
        )}
        {showEmergencySubmitBtn && (
          <Tooltip arrow title={submitTitle}>
            <span>
              <Button
                variant="outlined"
                color="primary"
                disabled={isOverBulkLimit || submitting}
                onClick={emergencyClick}
              >
                Submit emergency{' '}
                {pluralize('timecard', selectedRows.length, false)}
              </Button>
            </span>
          </Tooltip>
        )}
        {showSubmitBtn && (
          <Tooltip arrow title={deleteTitle}>
            <span>
              <Button
                color="primary"
                variant="text"
                disabled={isOverDeleteLimit || submitting}
                onClick={() => {
                  onShowDeleteModal(selectedRows);
                }}
              >
                Delete ({selectedRows.length})
              </Button>
            </span>
          </Tooltip>
        )}
        <ApproveRejectButtons
          currentUserRole={currentUserRole}
          selectedRows={selectedRows}
          bulkTimecardSubmission={bulkTimecardSubmission}
          submitting={submitting}
        />
      </Box>
      <div className={classes.additionalFields}>
        <AdditionalFields
          hiddenColumns={hiddenColumns}
          setHiddenColumns={setHiddenColumns}
          filteredColumns={filteredColumns}
        />
      </div>
    </header>
  );
};

export default Header;
