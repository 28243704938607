import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Field } from 'redux-form';

import { TableCell } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TextField } from 'components/Shared/redux';

import LoadingTextField from 'components/Shared/Text/LoadingTextField';

//utils
import { WORK_TIME_FIELDS } from 'utils/wtcWeekUtils';
import {
  ACCOUNT_CODE_MAX_LENGTH,
  NO_BLANK_ALLOWED_FIELDS,
} from 'components/Shared/constants/index';
import { preventBlanks, accountsFieldMaxLength } from 'utils/weekUtils';
import { normalizeTimeEntry } from 'utils/bulkEditUtils';
import { normalizeStrNumber } from 'utils/helperFunctions';

const useStyles = makeStyles(({ palette }) => ({
  tableCell: {
    verticalAlign: 'middle',
    padding: 4,
  },
  inputProp: {
    width: 56,
    padding: '8px 4px',
    fontSize: 14,
  },
  accountField: {
    width: 71,
  },
  inputError: {
    backgroundColor: palette.button.yellow.background,
    borderRadius: 5,
  },
  canEditWorkTimesRoot: {
    marginLeft: 12,
    '&::before': {
      borderBottom: 'none !important',
    },
  },
  canEditWorkTimesInput: {
    width: 45,
    borderRadius: 3,
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 500,
    backgroundColor: `${palette.primary.main}10`,
    textAlign: 'center',
    color: palette.button.primary.disabled,
  },
}));

const upmEditableFields = ['accountCode', ...NO_BLANK_ALLOWED_FIELDS];

const TextCell = props => {
  const classes = useStyles();
  const {
    //all cell props
    field: { columnId, group },
    isMasterRow,
    onChange,
    member,
    wtcDisabled,
    isPartialDealMemo,
    isUnusedDay,
    disableCellClass,
    //TextCell Props
    timeFieldBlur,
    scaleLoading,
    rateFieldBlur,
    accountCodeMask,
    maskingFunc,
    upmEnabled,
    canEditWorkTimes,
    wtcEditable = {},
    isNoTimeDayType,
    isDraft,
    project,
  } = props;

  const name = isMasterRow ? columnId : `${member}.${columnId}`;

  const normalizeNoBlanks = useCallback(value => {
    return preventBlanks(value);
  }, []);

  const isUnusedDisabled = isUnusedDay && columnId !== 'dayType';

  const fieldProps = {
    InputProps: {
      inputProps: {
        maxLength: accountsFieldMaxLength(project, columnId),
      },
      classes: {
        input: clsx(classes.inputProp, {
          [classes.accountField]: columnId === 'accountCode',
        }),
        error: classes.inputError,
      },
    },
    disabled: wtcDisabled || isPartialDealMemo || isUnusedDisabled,
    variant: 'outlined',
    className: '',
  };

  if (NO_BLANK_ALLOWED_FIELDS.includes(columnId)) {
    fieldProps.normalize = normalizeNoBlanks;
  }

  if (upmEditableFields.includes(columnId)) {
    fieldProps.disabled = upmEnabled || isPartialDealMemo || isUnusedDisabled;
  }

  if (wtcEditable[columnId] !== undefined && !isDraft) {
    const disableField = !wtcEditable[columnId];
    fieldProps.disabled = fieldProps.disabled || disableField;
  }

  if (!canEditWorkTimes && WORK_TIME_FIELDS.includes(columnId) && !isDraft) {
    fieldProps.disabled = fieldProps.disabled || !canEditWorkTimes;
    if (fieldProps.disabled) {
      fieldProps.variant = 'standard';
      fieldProps.InputProps.classes.root = classes.canEditWorkTimesRoot;
      fieldProps.InputProps.classes.input = classes.canEditWorkTimesInput;
    }
  } else if (isNoTimeDayType) {
    const isFieldGroupTime = group === 'time';
    fieldProps.disabled = fieldProps.disabled || isFieldGroupTime;
  }

  if (group === 'time') {
    fieldProps.onBlur = timeFieldBlur;
    fieldProps.normalize = normalizeTimeEntry;
  }

  if (columnId === 'rate') {
    if (scaleLoading[member]) {
      return (
        <TableCell className={clsx(classes.tableCell)}>
          <LoadingTextField />
        </TableCell>
      );
    }
    fieldProps.onBlur = rateFieldBlur;
    fieldProps.normalize = value =>
      normalizeStrNumber(value, { decimalPrecision: 4 });
  }

  if (columnId === 'accountCode') {
    fieldProps.InputProps.inputProps = {
      placeholder:
        accountCodeMask !== '~'.repeat(ACCOUNT_CODE_MAX_LENGTH)
          ? accountCodeMask
          : '',
    };
    fieldProps.normalize = maskingFunc;
  }

  return (
    <TableCell
      className={clsx(classes.tableCell, {
        [disableCellClass]: isPartialDealMemo || isUnusedDay,
      })}
    >
      <Field
        name={name}
        component={TextField}
        hasHelperText={false}
        onChange={onChange}
        showErrorUntouched={true}
        {...fieldProps}
      />
    </TableCell>
  );
};

TextCell.propTypes = {
  field: PropTypes.object.isRequired,
  isMasterRow: PropTypes.bool,
  onChange: PropTypes.func,
  member: PropTypes.string,
  wtcDisabled: PropTypes.bool.isRequired,
  isPartialDealMemo: PropTypes.bool,
  isUnusedDay: PropTypes.bool,
  disableCellClass: PropTypes.string,
  timeFieldBlur: PropTypes.func.isRequired,
  scaleLoading: PropTypes.object.isRequired,
  rateFieldBlur: PropTypes.func.isRequired,
  accountCodeMask: PropTypes.string.isRequired,
  maskingFunc: PropTypes.func.isRequired,
  upmEnabled: PropTypes.bool.isRequired,
  canEditWorkTimes: PropTypes.bool.isRequired,
  wtcEditable: PropTypes.object.isRequired,
  isNoTimeDayType: PropTypes.bool,
  isDraft: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired,
};

export default React.memo(TextCell);
